
import React from "react";
import infos from "../../assets/icones/global/alert-circle.svg";


const InfoRedCard = ({ title, text }) => {

    return(
        <div className="infored-card">
            <img src={infos} alt="icon search"/>
            <div className="infored-card-column">
                <div className="infored-card-title">{title}</div>
                <div className="infored-card-text">{text}</div>
            </div>
        </div>
    )
}

export default InfoRedCard;