import { postRequestJsonNotToken } from '../request/apiRequest';


const Authentification = {

    login: async (data) => {
        return await postRequestJsonNotToken('/login', data);
    },

    register: async (data) => {
        return await postRequestJsonNotToken('/register', data);
    },

    logout: async () => {
        return await postRequestJsonNotToken('/logout');
    },

    generateTemporarySignInToken: async (data) => {
        return await postRequestJsonNotToken('/sign-in-token', data);
    }

}

export default Authentification;