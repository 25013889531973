import React, {useEffect, useState} from 'react';
import FormSection from "../../../components/form/formSection";
import Input from "../../../components/form/inputText/input";

import mail from '../../../assets/icones/global/mail.svg';
import tel from '../../../assets/icones/global/phone.svg';
import Checkbox from "../../../components/form/inputCheckbox/checkbox";

const Personal = ({mode, personal, setPartner}) => {

    const [checked, setChecked] = useState([]);

    const handleRadio = (e) => {
        const { name, value } = e.target;

        //add or remove value from checked array
        let newChecked = [...checked];
        if (newChecked.includes(value)) {
            newChecked = newChecked.filter((item) => item !== value);
            setPartner(`personal.${name}.value`, newChecked);
        } else {
            newChecked.push(value);
            setPartner(`personal.${name}.value`, newChecked);
        }
        setChecked(newChecked);
    };

    const handleChange= (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        setPartner(`personal.${name}.value`, value);
    };

    useEffect(() => {
        setChecked(personal.communicationPreference.value);
    }, [personal]);

    return(
        <FormSection title={"Personnel"}>

            {Object.keys(personal).map((field) => (
                field === "communicationPreference" ?
                    <div className={`form__row row__input`} key={field}>
                        <label>Préférence de communication</label>
                        <div className={`row__input__radio`}>
                            <Checkbox
                                name={field}
                                value={"email"}
                                label={"Email"}
                                onChange={handleRadio}
                                isChecked={checked.includes("email")}
                                icon={mail}
                            />
                            <Checkbox
                                name={field}
                                value={"phone"}
                                label={"Téléphone"}
                                onChange={handleRadio}
                                isChecked={checked.includes("phone")}
                                icon={tel}
                            />
                        </div>
                    </div>
                :
                    <Input
                        key={field}
                        type={field === "phoneNumber" ? "number" : "text"}
                        label={personal[field].label}
                        name={field}
                        valueInput={personal[field].value}
                        placeholder={personal[field].placeholder}
                        onChangeForm={handleChange}
                        validationType={personal[field].validationType}
                        errorEmpty={personal[field].error}
                        errorMessage={personal[field].errorMessage}
                    />
            ))}
        </FormSection>
    )
}

export default Personal;