import Input from "../../../../components/form/inputText/input";
import SubmitButton from "../../../../components/button/submitButton";
import React from "react";
import Button from "../../../../components/button/button";
import back from "../../../../assets/icones/arrows/left_chevron.svg";
import search from "../../../../assets/icones/global/search.svg";
import IconButton from "../../../../components/button/iconButton";
import InputAddress from "../../../../components/form/inputText/inputAddress";

const InformationsPartners = ({setPartner, handleSubmit, company, setStep}) => {


    const handleChange= (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        setPartner(`companyInfo.${name}.value`, value);
    };


    const handleAddress = (name, addressDetails) => {
        const newAddress = `${addressDetails.addressLine ? addressDetails.addressLine : ""}`;
        const newPostalCode = `${addressDetails.postalCode ? addressDetails.postalCode : ""}`;
        if (company.address.value !== newAddress) { // Only update if there's a change
            setPartner(`companyInfo.address.value`, newAddress);
        }

        if (company.postalCode.value !== newPostalCode) { // Only update if there's a change
            setPartner(`companyInfo.postalCode.value`, newPostalCode);
        }
    }

    return (
        <div className={"main__container__form__col"}>
            <div className={"main__container__form__row__title"}>
                <IconButton icon={back} text={"Retour"} isPrimary={false} onClick={() => setStep(2)}/>
                <h1 className={"title-25"}>Votre entreprise</h1>
            </div>

            <form className={"form__signin"} onSubmit={handleSubmit}>
                <Input
                    label={company.name.label}
                    name={"name"}
                    placeholder={company.name.placeholder}
                    type={"text"}
                    validationType={company.name.validationType}
                    isColumn={true}
                    onChangeForm={handleChange}
                    valueInput={company.name.value}
                    errorEmpty={company.name.error}
                    errorMessage={company.name.errorMessage}
                    isRequired={true}
                />
                <InputAddress
                    title={company.address.label}
                    name={"address"}
                    placeholder={company.address.placeholder}
                    type={"text"}
                    icon={search}
                    validationType={company.address.validationType}
                    isColumn={true}
                    onChangeForm={handleAddress}
                    valueInput={company.address.value}
                    errorEmpty={company.address.error}
                    errorMessage={company.address.errorMessage}
                    isRequired={true}
                />
                <Input
                    label={company.postalCode.label}
                    name={"postalCode"}
                    placeholder={company.postalCode.placeholder}
                    type={"text"}
                    validationType={company.postalCode.validationType}
                    isColumn={true}
                    onChangeForm={handleChange}
                    valueInput={company.postalCode.value}
                    errorEmpty={company.postalCode.error}
                    errorMessage={company.postalCode.errorMessage}
                    isRequired={true}
                />
                <Input
                    label={company.website.label}
                    name={"website"}
                    placeholder={company.website.placeholder}
                    type={"text"}
                    validationType={company.website.validationType}
                    isColumn={true}
                    onChangeForm={handleChange}
                    valueInput={company.website.value}
                    errorEmpty={company.website.error}
                    errorMessage={company.website.errorMessage}
                    isRequired={true}
                />
                <Input
                    label={company.siretNumber.label}
                    name={"siretNumber"}
                    placeholder={company.siretNumber.placeholder}
                    type={"text"}
                    validationType={company.siretNumber.validationType}
                    isColumn={true}
                    onChangeForm={handleChange}
                    valueInput={company.siretNumber.value}
                    errorEmpty={company.siretNumber.error}
                    errorMessage={company.siretNumber.errorMessage}
                    isRequired={true}
                />
                <div className={"form__signin__row"}>
                    <Input
                        label={company.emailPartner.label}
                        name={"emailPartner"}
                        placeholder={company.emailPartner.placeholder}
                        type={"email"}
                        validationType={company.emailPartner.validationType}
                        isColumn={true}
                        onChangeForm={handleChange}
                        valueInput={company.emailPartner.value}
                        errorEmpty={company.emailPartner.error}
                        errorMessage={company.emailPartner.errorMessage}
                        isRequired={true}
                    />
                    <Input
                        label={company.phoneNumber.label}
                        name={"phoneNumber"}
                        placeholder={company.phoneNumber.placeholder}
                        type={"text"}
                        validationType={company.phoneNumber.validationType}
                        isColumn={true}
                        onChangeForm={handleChange}
                        valueInput={company.phoneNumber.value}
                        errorEmpty={company.phoneNumber.error}
                        errorMessage={company.phoneNumber.errorMessage}
                        isRequired={true}
                    />
                </div>

                <div className={"form__signin__row"}>
                    <SubmitButton text={"Soumettre mon établissement"} isFull={true}/>
                </div>
            </form>


        </div>
    )
}

export default InformationsPartners;