import { useState,useEffect } from 'react';
const useOpenModal = (initialState = {}) => {
    const [openStates, setOpenStates] = useState(initialState);

    const openModal = (index) => {
        setOpenStates(prevStates => {
            return { ...prevStates, [index]: true };
        });
    };

    const closeModal = (index) => {
        setOpenStates(prevStates => {
            const newState = { ...prevStates, [index]: false };
            return newState;
        });
    };


    return [openStates, openModal, closeModal];
};

export default useOpenModal;
