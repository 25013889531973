import React, {useState} from 'react';
import Button from "../../button/button";
import close from "../../../assets/icones/global/close-stroke.svg";
import deleting from "../../../assets/images/svg/rocket_icon.svg";
import Input from "../../form/inputText/input";

const modalAddTypeActivity = ({isOpen, closeModal, addType}) => {

    const [newItemName, setNewItemName] = useState(""); // Initialize with an empty string

    const handleSend = () => {
        addType(newItemName);
        setNewItemName("");
        closeModal();
    }

    return (
        <div className={`bg__modal ${isOpen ? "open" : ""}`}>
            <div className={`modal deleting ${isOpen ? "open" : ""}`}>
                <div className={"modal__header"}>
                    <div className={"modal__header__text"}>
                        <p className={"title-14"}>Ajouter un type d'activité</p>
                    </div>
                </div>
                <div className={"modal__content"}>
                    <Input
                        key={isOpen ? 'open' : 'closed'} // Changes the key based on the modal state
                        name={"included"}
                        type={"text"}
                        valueInput={newItemName}
                        onChangeForm={(e) => setNewItemName(e.target.value)}
                        placeholder={"Ex : 🛹 Skateboard"}
                    />
                    <div className={"modal__content__actions"}>
                        <Button text={"Annuler"} isPrimary={false} onClick={closeModal}/>
                        <Button text={"Ajouter"} isPrimary={true} onClick={handleSend}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default modalAddTypeActivity;