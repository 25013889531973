const useParseBookingTimeSlot= () => {

    const parseBookingTimeSlot = (booking) => {

        const activityID = {
            name: "activity",
            value: booking && booking.activityID ? booking.activityID : "",
            type: "text",
            error: false,
            errorMessage: false,
            placeholder: "Activité",
            validationType: "text",
            isRequired: true
        }


        const availabilityType = {
            name: "typeTimeSlot",
            value: booking && booking.typeTimeSlot ? booking.typeTimeSlot : "",
            type: "text",
            error: false,
            errorMessage: false,
            placeholder: "Type de disponibilité",
            validationType: "text",
            isRequired: true
        }

        const activityDuration = {
            name: "activityDuration",
            value: booking && booking.activityDuration ? booking.activityDuration : "",
            type: "text",
            error: false,
            errorMessage: false,
            placeholder: "Durée de l'activité",
            validationType: "text",
            isRequired: true
        }

        const activityTimeGap = {
            name: "activityTimeGap",
            value: booking && booking.activityTimeGap ? booking.activityTimeGap : "",
            type: "number",
            error: false,
            errorMessage: false,
            placeholder: "Intervalle de temps",
            validationType: "number",
            isRequired: false
        }

        const daysOfWeek = {
            name: "daysOfWeek",
            value: booking && booking.daysOfWeek ? booking.daysOfWeek : [],
            type: "number",
            error: false,
            errorMessage: false,
            placeholder: "Jours de la semaine",
            validationType: "number",
            isRequired: false
        }

        const specificDateRanges = {
            name: "specificDateRanges",
            value: booking && booking.specificDateRanges ? booking.specificDateRanges : [],
            type: "date",
            error: false,
            errorMessage: false,
            placeholder: "Plages de dates spécifiques",
            validationType: "date",
            isRequired: false
        }

        const generatedTimeSlots = {
            name: "generatedTimeSlots",
            value: booking && booking.generatedTimeSlots ? booking.generatedTimeSlots : [],
            type: "time",
            error: false,
            errorMessage: false,
            placeholder: "Créneaux horaires générés",
            validationType: "time",
            isRequired: false
        }

        return {
            activityID,
            availabilityType,
            activityDuration,
            activityTimeGap,
            daysOfWeek,
            specificDateRanges,
            generatedTimeSlots
        }
    }


    const parseBookingTimeSlotForAPI = (booking) => {

        return {
            activityID: booking.activityID && booking.activityID.value? booking.activityID.value : "",
            typeTimeSlot: booking.availabilityType && booking.availabilityType.value ? booking.availabilityType.value : "",
            activityDuration: booking.activityDuration && booking.activityDuration.value ? booking.activityDuration.value : "",
            activityTimeGap: booking.activityTimeGap && booking.activityTimeGap.value ? booking.activityTimeGap.value : "",
            daysOfWeek: booking.daysOfWeek && booking.daysOfWeek.value ? booking.daysOfWeek.value : [],
            specificDateRanges: booking.specificDateRanges && booking.specificDateRanges.value ? booking.specificDateRanges.value : [],
            generatedTimeSlots: booking.generatedTimeSlots && booking.generatedTimeSlots.value ? booking.generatedTimeSlots.value : []
        }
    }

    return {
        parseBookingTimeSlot,
        parseBookingTimeSlotForAPI
    }
}

export default useParseBookingTimeSlot;