import React from "react";
import "../../../styles/components/modal.css";
import useStatusName from "../../../hooks/parseText/useStatusName";
import Tag from "../../tag/tag";
import close from "../../../assets/icones/global/close-stroke.svg";



const ModalFilter = ({isModalOpen, setIsModalClose, listFilter, handleAddFilter}) => {

    const {getStatusName} = useStatusName();

    return(
        <div className={`modal filter ${isModalOpen ? "open" : ""}`}>
            <div className={"modal__header"}>
                <p className={"title-16"}>Filtrer</p>
                <div className={"modal__header__actions"}>
                    <p className={"title-14"} onClick={() => setIsModalClose(false)}>
                        <img src={close} alt={"close"}/>
                    </p>
                </div>
            </div>
            <div className={"modal__container__body"}>
                {
                    listFilter.map((filter, index) => {
                        return (
                            <div className={"status_filter"} onClick={() => handleAddFilter(filter)}>
                               <Tag text={getStatusName(filter)} status={filter}/>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ModalFilter;