import React from "react";
import mail from "../../../assets/icones/global/mail_light.svg";
import '../../../styles/views/policy.css';
import logo from "../../../assets/images/svg/logo_row.svg";
import Button from "../../../components/button/button";
import {useNavigate} from "react-router-dom";

const CGU = () => {

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    return (
        <div className="main policy">
            <div className="main__container text">
                <div className="main__container__section">
                    <div className="main__container__form">
                        <img src={logo} alt="logo"/>

                        <div className={"container__infos"}>
                            <h1 className={"title-25"}>Les Conditions Générales d'Utilisation du Dashboard Frimity</h1>

                            <p className={"text-16"}>En utilisant le tableau de bord Frimity, vous acceptez les
                                conditions générales
                                d'utilisation suivantes :</p>

                            <ol>
                                <li><strong>Confidentialité :</strong> Toutes les informations que vous saisissez sur le
                                    tableau de bord, y compris vos données personnelles, sont confidentielles et ne
                                    seront pas partagées avec des tiers sans votre consentement explicite.
                                </li>
                                <li><strong>Contenu :</strong> Vous êtes responsable du contenu que vous publiez sur le
                                    tableau de bord. Assurez-vous qu'il respecte les lois en vigueur, les droits
                                    d'auteur et les normes de la plateforme.
                                </li>
                                <li><strong>Exactitude des Informations :</strong> Vous garantissez que toutes les
                                    informations que vous fournissez sur le tableau de bord sont exactes, complètes et à
                                    jour, et vous vous engagez à les actualiser régulièrement.
                                </li>
                                <li><strong>Utilisation Personnelle :</strong> Votre compte et les informations que vous
                                    y saisissez sont strictement personnelles. Ne partagez pas vos identifiants avec
                                    d'autres personnes.
                                </li>
                                <li><strong>Respect des Lois :</strong> Vous vous engagez à respecter toutes les lois et
                                    réglementations applicables lors de l'utilisation du tableau de bord.
                                </li>
                                <li><strong>Réponses Rapides :</strong> Nous attendons de vous des réponses rapides et
                                    précises lors de nos échanges, notamment pour confirmer votre participation active
                                    sur l'application.
                                </li>
                                <li><strong>Feedback Constructif :</strong> Lors des entretiens programmés, nous
                                    attendons de vous un feedback constructif et honnête sur notre solution.
                                </li>
                                <li><strong>Respect des Autres Utilisateurs :</strong> Soyez respectueux envers les
                                    autres utilisateurs et notre équipe. Tout comportement considéré comme inapproprié
                                    entraînera la résiliation de votre accès au tableau de bord.
                                </li>
                                <li><strong>Modification des Conditions :</strong> Frimity se réserve le droit de
                                    modifier ces conditions d'utilisation à tout moment. Les utilisateurs seront
                                    informés des changements par email ou via une notification sur le tableau de bord.
                                </li>
                            </ol>
                            <p className={"text-14 light"}>Date de la Dernière Mise à Jour : 12/12/2023</p>
                        </div>
                        <Button isPrimary={true} text={"Revenir en arrière"} isFull={true} onClick={goBack}/>
                    </div>
                </div>
            </div>
            <div className="main__container last">
                <div className="main__footer">
                    <p className={"text-12"}>© Frimity 2023</p>
                    <div className={"main__footer__links"}>
                        <img src={mail} alt="logo"/>
                        <a href={"mailto:contact@frimity.fr"}><p
                            className={"text-12"}>contact@frimity.fr</p></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CGU;