import React, {useState} from 'react';
import question from '../../assets/icones/global/help-circle.svg';
import '../../styles/components/tooltip.css';

const Tooltip = ({children}) => {

    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    return(
        <div className={"tooltip"}>
            <img src={question} alt={"question"} className={"tooltip__icon"} onMouseEnter={() => setIsTooltipOpen(true)} onMouseOut={() => setIsTooltipOpen(false)} />

            <div className={`tooltip__message ${isTooltipOpen ? "tooltip__message--open" : ""}`}>
                {children}
            </div>
        </div>
    );
}

export default Tooltip;