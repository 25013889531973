import React, {useState}  from 'react';
import HeaderView from "../../components/header/headerView";
import '../../styles/views/support.css';
import HelpCard from "../../components/card/helpCard";
import {useSelector} from "react-redux";
import FormSupport from "./form/formSupport";
import ModalEmailSend from "../../components/modal/modalInfos/modalEmailSend";

const Support = () => {

    const user = useSelector((state) => state.auth.user);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    return (
        <div className={"main-container support is__grey"}>
            <HeaderView title={"Support"} isGoBack={true} actions={<></>} />

            <div className={"main-infos__container__col"}>
                <FormSupport setModalIsOpen={setModalIsOpen} />
                <HelpCard
                    text={"Si vous avez la moindre question ou besoin d'aide, n'hésitez pas à nous contacter. Nous sommes disponibles 24/7 pour vous offrir le support dont vous avez besoin."}
                />
            </div>

            <ModalEmailSend isOpen={modalIsOpen} link={`/dashboard/${user.partnerID._id}`} />
        </div>
    );

}

export default Support;