import React from 'react';
import FormSectionRow from "../../../components/form/formSectionRow";
import Textarea from "../../../components/form/InputTextarea/textarea";

const PraticalInfos = ({setActivity, practicalInfo}) => {

    const handleChange = (event) => {
        event.preventDefault();
        const { value } = event.target;

        setActivity(`practicalInfo.value`, value);
    }

    return(
        <FormSectionRow title={"Les infos pratiques"}>
            <Textarea
                title={practicalInfo.label}
                name={"practicalInfo"}
                subtitle={practicalInfo.subtitle}
                value={practicalInfo.value}
                onChange={handleChange}
                errorEmpty={practicalInfo.errorEmpty}
                errorMessage={practicalInfo.errorMessage}
                placeholder={practicalInfo.placeholder}
            />
        </FormSectionRow>
    )
}

export default PraticalInfos;