import React, {useEffect, useState} from "react";
import FormSectionRow from "../../../components/form/formSectionRow";
import Radio from "../../../components/form/inputRadio/radio";
import Checkbox from "../../../components/form/inputCheckbox/checkbox";
import ActivitiesServices from "../../../api/services/activities";
import InputPrice from "../../../components/form/inputText/inputPrice";

const Conditions = ({ setOffer, offerActivity, conditionsList, isDisabled }) => {
    const [conditions, setConditions] = useState([
        { name: "Tous les tarifs de l'activité", value: "all-rates" },
        { name: "Tarifs spécifique(s)", value: "specific-rates" },
        { name: "Sur un montant défini", value: "defined-amount" }
    ]);
    const [activityInfos, setActivityInfos] = useState([]);
    const [selectedOfferCondition, setSelectedOfferCondition] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);


    useEffect(() => {

        const fetchData = async () => {
            try{
                const response = await ActivitiesServices.getActivityById(offerActivity);
                if(response.data.details.pricingOptions.length > 0){
                    setActivityInfos([...response.data.details.pricingOptions, ...response.data.details.extraPrice]);
                }else{
                    setActivityInfos([]);
                }
            } catch (error){
                console.log(error);
            }
        }

        fetchData();

        setSelectedOfferCondition(conditionsList.value.conditionValue)
        setSelectedOptions(conditionsList.value.options || []);
    }, [offerActivity]);


    const handleOfferConditionChange = (conditionValue) => {
        setSelectedOfferCondition(conditionValue);

        //erase the selected options
        setSelectedOptions([]);

        let newOptions;
        if (conditionValue === "specific-rates") {
            // Keep the current selected options if they exist
            newOptions = Array.isArray(selectedOptions) ? [...selectedOptions] : [];
        } else if (conditionValue === "defined-amount" && selectedOptions.length > 0) {
            // Keep only the first selected option if it's "defined-amount"
            newOptions = [selectedOptions[0]];
        } else {
            // Otherwise, clear the options
            newOptions = [];
        }

        setOffer('conditions.value', [{
            conditionValue: conditionValue,
            options: newOptions
        }]);

    };

    const handleOfferOptionsChange = (item) => {
        setSelectedOptions((prevOptions) => {
            let updatedOptions;

            if (selectedOfferCondition === "specific-rates") {
                // For "specific-rates", we want an array of selected option IDs
                const isOptionSelected = prevOptions.includes(item);
                updatedOptions = isOptionSelected
                    ? prevOptions.filter((option) => option !== item)
                    : [...prevOptions, item];
            } else if (selectedOfferCondition === "defined-amount") {
                // For "defined-amount", we only want a single value
                updatedOptions = [item];
            }


            // Assuming setOffer needs a path and a value, and that it's implemented to handle this correctly
            setOffer('conditions.value', [{
                conditionValue: selectedOfferCondition,
                options: updatedOptions
            }]);

            return updatedOptions;
        });
    };

    const SpecificRatesSection = () => {
        return (
            <div className={"form__row grid text-grid"}>
                {activityInfos.length > 0 ? activityInfos.map((option, index) => (
                    <div className={"form__container__col"} key={index}>
                        <Checkbox
                            name={"offerConditionTarif"}
                            value={option.name}
                            label={option.name}
                            onChange={(e) => handleOfferOptionsChange(e.target.value)}
                            isChecked={selectedOptions.includes(option.name)}
                            disabled={isDisabled}

                        />
                    </div>
                ))
                :
                    <div className={"error-message"}>Vous n'avez pas encore sélectionné d'activité</div>
                }
            </div>
        );
    };

    const DefinedAmountSection = () => {
        const amountValue = selectedOfferCondition === "defined-amount" && selectedOptions.length > 0
            ? selectedOptions[0]
            : '';

        return (
            <InputPrice
                name={"offerConditionMontant"}
                label={"Montant"}
                valueInput={amountValue}
                onChangeForm={(e) => handleOfferOptionsChange(e.target.value)}
                placeholder={"Entrez le montant"}
                errorMessage={""}
                isColumn={true}
                typeUnit={"€"}
                type={"text"}
                disabled={isDisabled}
                readOnly={isDisabled}
            />
        );
    };

    return (
        <FormSectionRow title={"Conditions"} description={""} classname={"to-know"}>
            <div className={"form__container__col categories"}>
                <div className={"form__row grid"}>
                    {conditions.map((condition, index) => (
                        <div className={"form__container__col"} key={index}>
                            {
                                condition.value === "specific-rates" && activityInfos.length === 0 ? null :
                                    <Radio
                                        name={"offerCondition"}
                                        value={condition.value}
                                        label={condition.name}
                                        onChange={(e) => handleOfferConditionChange(e.target.value)}
                                        checked={selectedOfferCondition === condition.value}
                                        disabled={isDisabled}
                                    />
                            }
                        </div>
                    ))}
                </div>
            </div>

            {selectedOfferCondition === 'specific-rates' && <SpecificRatesSection />}
            {selectedOfferCondition === 'defined-amount' && <DefinedAmountSection />}
        </FormSectionRow>
    );
};

export default Conditions;
