import React, {useState, useEffect, useContext} from 'react';
import HeaderView from "../../components/header/headerView";
import IconButton from "../../components/button/iconButton";
import add from '../../assets/icones/global/add.svg';
import TabBar from "../../components/navbar/tabBar";
import Table from "../../components/table/table";
import Tag from "../../components/tag/tag";
import Dot from "../../components/dot/dot";
import {useParams} from "react-router";
import useStatusName from "../../hooks/parseText/useStatusName";
import ActivitiesServices from "../../api/services/activities";
import useOpenModal from "../../hooks/useOpenModal";
import Button from "../../components/button/button";
import useFilter from "../../hooks/useFilter";
import FilterTable from "../../components/table/filterTable";
import '../../styles/views/activities.css';
import {ErrorContext} from "../../context/errorContext";
import {useLoader} from "../../context/loaderContext";
import ModalActivity from "../../components/modal/modalTable/modalActivity";
import {useSelector} from "react-redux";
import {usePartner} from "../../context/partnerContext";

const Activities = () => {
    const [refresh, setRefresh] = useState(false);
    const [filterList, setFilterList] = useState([]);

    const partnerId = useParams().partnerId;
    const [activities, setActivities] = useState([]);
    const [nbOffers, setNbOffers] = useState(0);

    const { getStatusName } = useStatusName();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const { currentPartnerId } = usePartner();
    const user = useSelector(state => state.auth.user);
    const currentPartner = useSelector((state) => state.auth.user.partnerID.filter((partner) => partner._id === currentPartnerId)[0] || null);



    const [isModalOpen, openModal, closeModal] = useOpenModal(activities.map(() => false));
    const { updateFilters, filteredData } = useFilter([], activities, (activity, filters) => {
        return filters.length === 0 || filters.includes(activity.status);
    });

    useEffect(() => {
        setRefresh(false)
        setLoading(true);
        ActivitiesServices.getActivitiesByPartnerId(partnerId)
            .then((response) => {
                if(response.status === 200){
                    setActivities(response.data.reverse());
                    setRefresh(false);
                    setFilterList([...new Set(response.data.map((activity) => activity.status))]);
                    setNbOffers(response.data.map((activity) => activity.offers.length).reduce((a, b) => a + b, 0));
                }
            })
            .catch((error) => {
                showError(error);
            }).finally(() => {
                setLoading(false);
            })
    }, [refresh]);
    const handleDuplicate = (activityId) => {
        setLoading(true);
        ActivitiesServices.duplicateActivity(partnerId, activityId)
        .then((response) => {
            if(response.status === 200){
                setRefresh(true);
            }
        })
        .catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleDelete = (activityId) => {
        setLoading(true);
        ActivitiesServices.deleteActivity(activityId, partnerId)
        .then((response) => {
            if(response.status === 200){
                setRefresh(true);
            }
        })
        .catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    return(
        <div className={"main-container is__grey"}>
            <HeaderView title={"Activités proposées"} actions={
                    <>
                        <IconButton icon={add} text={"Ajouter une activité"} isPrimary={false} link={`/partners/${partnerId}/activities/add`} />
                    </>
            } isActionsWithTitle={true} />

            <TabBar
                tabs={[
                    {
                        name: "Fiche du partenaire",
                        link: `/partners/${partnerId}`
                    },
                    {
                        name: "Liste d’activités",
                        link: `/partners/${partnerId}/activities`,
                        index: activities.length
                    },
                    {
                        name: "Gérer vos offres",
                        link: `/partners/${partnerId}/offers`
                    },
                    {
                        name: "Plan tarifaire",
                        link: `/partners/${partnerId}/subscription`,
                    }
                ]}
                activeTab={1}
            />


            <div className={"header__container activities"}>
                <h1><span className={"text-20"}>Nom de votre établissement : </span>{ user.role === "partner" || user.role === "partners-admin"? currentPartner.name : ""}</h1>
                <div className={"header__container__col"}>
                </div>
            </div>

            <div className={"main-infos__container"}>
                <div className={"main-infos__container__col"}>
                    <h2 className={"title-18"}>Détail des activités</h2>
                    <p className={"text-14"}>Vous pouvez voir ou modifier vos informations concernant vos activités proposées</p>
                </div>
            </div>

            <div className={"main-container__row list"}>
                {
                    filterList && filterList.length > 0 ?
                        <FilterTable
                            filterArray={filterList}
                            onFilterChange={updateFilters}
                            typeValue={"activity"}
                            searchData={activities}
                        />
                        :
                        null
                }
                {
                    filteredData && filteredData.length > 0 ?
                        <Table
                            columns={[
                                {name: "Référence", align: "left", width: "15%", field: "reference"},
                                {name: "Activité", align: "left", width: "15%", field: "activity"},
                                {name: "Statut", align: "left", width: "20%", field: "status"},
                                { name: "Actions", align: "center", width: "10%", style: {
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }, field: "actions"},
                                { name: "Voir plus", align: "center", width: "15%", style: {
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }, field: "link"},
                            ]}

                            data={
                                filteredData && filteredData.length > 0 ?
                                    filteredData.map((activity, index) => {
                                        return {
                                            reference: activity.reference,
                                            activity: activity.name,
                                            status: <Tag text={getStatusName(activity.status)} status={activity.status} />,
                                            isVerified: activity.isVerified,
                                            actions:(
                                                <Dot size={"big"} click={() => openModal(index)}>
                                                    <ModalActivity
                                                        isOpen={isModalOpen[index]}
                                                        closeModal={() => closeModal(index)}
                                                        handleDuplicate={() => handleDuplicate(activity._id)}
                                                        handleDelete={() => handleDelete(activity._id)}
                                                    />
                                                </Dot>
                                            ),
                                            link: <Button text={"Voir l'activité"} link={`/partners/${partnerId}/activities/${activity._id}`} />
                                        }
                                    }) : []
                            }
                        />
                        :
                        <div className={"container__empty"}>
                            <p className={"text-14"}>Vous n'avez pas encore d'activité</p>
                            <Button text={"Ajouter une activité"} link={`/partners/${partnerId}/activities/add`} isPrimary={true} />
                        </div>

                }
            </div>

        </div>
    )
}

export default Activities;