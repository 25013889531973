import InputSelect from "../../../../components/form/inputSelect/inputSelect";


const Activity = ({ activitiesList, activity, setActivity }) => {
    const handleChange = (e) => {
        setActivity(`activityID.value`, e.target.value);
    }

    return(
        <div className="sidebar__form__container__row">
            <div className="sidebar__form__content">
                <InputSelect
                    name={"activity"}
                    label={"Activité"}
                    options={activitiesList.map((activity) => ({id: activity._id, name: activity.name, value: activity._id}))}
                    valueSelected={activity.value}
                    onChange={handleChange}
                    isColumn={true}
                    placeholder={"Sélectionner une activité"}
                    errorMessage={activity.errorMessage}
                    error={activity.error}
                />
            </div>
        </div>
    );
}

export default Activity;