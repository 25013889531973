import React from "react";
import mail from "../../../assets/icones/global/mail_light.svg";
import '../../../styles/views/policy.css';
import logo from "../../../assets/images/svg/logo_row.svg";
import Button from "../../../components/button/button";
import {useNavigate} from "react-router-dom";

const LegalMentions = () => {

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    return (
        <div className="main policy">
            <div className="main__container text">
                <div className="main__container__section">
                    <div className="main__container__form">
                        <img src={logo} alt="logo"/>

                        <div className={"container__infos"}>
                            <h1 className={"title-25"}>Mentions légales</h1>

                            <div className={"text-16"}>Le site accessible par les url suivants : frimity-partenaires.fr
                                est exploité dans le respect de la législation française. <br/>L'utilisation de ce site
                                est régie par les présentes conditions générales. En utilisant le site, vous
                                reconnaissez avoir pris connaissance de ces conditions et les avoir acceptées. Celles-ci
                                pourront êtres modifiées à tout moment et sans préavis par la société Frimity.
                            </div>

                            <div className={"container__infos__cards"}>
                                <div className={"container__infos__cards__card"}>
                                    <h2 className={"title-20"}>Éditeur & Propriétaire</h2>
                                    <p className={"text-16"}>Frimity</p>
                                    <p className={"text-16"}>Siège social : 17 avenue de la Belle Gabrielle - 91390,
                                        Morsang-sur-orge</p>
                                    <p className={"text-16"}>Siret : 93033369500011</p>
                                    <p className={"text-16"}>RCS : Evry B 930 333 695</p>
                                    <p className={"text-16"}>Téléphone : <a href={"tel:0669931543"}>(+33) 0669931543</a>
                                    </p>
                                    <p className={"text-16"}>Numéro de TVA intracommunautaire : FR18930333695</p>
                                    <p className={"text-16"}>Forme juridique : Société par actions simplifiée</p>
                                    <p className={"text-16"}>Directeur de la publication : Frimity</p>
                                    <p className={"text-16"}>Email: <a
                                        href={"mailto:contact@frimity.fr "}>contact@frimity.fr</a></p>
                                </div>
                                <div className={"container__infos__cards__card"}>
                                    <h2 className={"title-20"}>Hébergeur</h2>
                                    <p className={"text-16"}>Hostinger International Ltd.</p>
                                    <p className={"text-16"}>UAB Svitrigailos str. 34, LT-03228 Vilnius, Lithuania</p>
                                    <p className={"text-16"}>Email : <a
                                        href={"mailto:domains@hostinger.com"}>domains@hostinger.co</a></p>
                                    <p className={"text-16"}>Téléphone : <a href={"tel:+37064503378"}>(+37)
                                        064503378</a></p>
                                </div>
                            </div>
                            <p className={"text-16"}>Propriété Intellectuelle: Tous les contenus présents sur le
                                dashboard Frimity, tels que les textes, images, vidéos, graphiques, logos et marques,
                                sont la propriété intellectuelle de Frimity ou de ses partenaires et sont protégés par
                                les lois sur la propriété intellectuelle en vigueur. Toute reproduction, modification,
                                distribution ou utilisation non autorisée de ces contenus est strictement interdite.</p>
                            <p className={"text-14 light"}>Date de la Dernière Mise à Jour : 12/12/2023</p>
                        </div>

                        <Button isPrimary={true} text={"Revenir en arrière"} isFull={true} onClick={goBack} />
                    </div>
                </div>
            </div>
            <div className="main__container last">
                <div className="main__footer">
                    <p className={"text-12"}>© Frimity 2023</p>
                    <div className={"main__footer__links"}>
                        <img src={mail} alt="logo"/>
                        <a href={"mailto:contact@frimity.fr"}><p
                            className={"text-12"}>contact@frimity.fr</p></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LegalMentions;