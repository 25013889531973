import React, {useContext, useEffect, useState} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import close from "../../../assets/icones/arrows/right_chevron.svg";
import "../../../styles/views/bookingsSidebar.css";
import Button from "../../../components/button/button";
import useParseDate from "../../../hooks/useParseDate";
import {ErrorContext} from "../../../context/errorContext";
import useStatusName from "../../../hooks/parseText/useStatusName";
import Tag from "../../../components/tag/tag";
import IconButton from "../../../components/button/iconButton";
import recipe from "../../../assets/icones/global/receipt-check.svg";
import eye from "../../../assets/icones/global/eye_white.svg";

const BookingUserDetails = ({ open, setOpen, booking, setRefresh, openCancelModal, setCurrentBookingSelected }) => {

    const { showError } = useContext(ErrorContext);

    //booking { booking:
    const { activity, booking: bookingDetails } = booking;

    console.log(activity.partnerID);

    //check if current time is in range of bookingDate and bookingDate + bookingDuration
    const currentTime = new Date();
    const bookingStartTime = new Date(new Date(bookingDetails.bookingDate).getTime() - 2 * 60 * 60 * 1000);
    const bookingEndTime = new Date(bookingStartTime.getTime() + bookingDetails.bookingDuration * 60000);
    const isActivityInProgress = bookingStartTime <= currentTime && currentTime <= bookingEndTime;

    const {parseSlotTimeBooking, parseBookingDateDay, parseTimeCancellationPeriodEndBooking} = useParseDate();
    const { getStatusName } = useStatusName();

    const mediaQuery = window.matchMedia('(max-width: 991px)').matches;

    const backdrop = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { duration: 0.5, ease: "easeInOut" } // Smooth easing for fade
        },
        exit: {
            opacity: 0,
            transition: { duration: 0.5, ease: "easeInOut" }
        }
    };

    const sidebarDesktop = {
        hidden: { x: '100%' },
        visible: {
            x: '0',
            transition: {
                type: 'spring',
                stiffness: 120,  // Lower stiffness makes it less rigid
                damping: 20,     // Lower damping makes it more bouncy
                mass: 1          // Mass affects the bounciness; increase for more lethargic movement
            }
        },
        exit: {
            x: '100%',
            transition: {
                type: 'spring',
                stiffness: 120,
                damping: 20,
                mass: 1,
                duration: 0.5  // Optional if you want to explicitly control duration on hiding
            }
        }
    };

    const sidebarMobile = {
        //from bottom
        hidden: { y: '100%' },
        visible: {
            y: '0',
            transition: {
                type: 'spring',
                stiffness: 120,
                damping: 20,
                mass: 1
            }
        },
        exit: {
            y: '100%',
            transition: {
                type: 'spring',
                stiffness: 120,
                damping: 20,
                mass: 1,
                duration: 0.5
            }
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleDownloadVoucherBilling = (identifier) => {
        const link = document.createElement('a');
        //remove # from the identifier
        link.href = `${process.env.REACT_APP_API_URL}/booking-users/download-voucher-billing/${bookingDetails.bookingAppId}/${identifier.replace("#", "")}`;
        link.setAttribute('download', `facture_${identifier.replace("#", "")}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleCancelBooking = () => {
        //cancel booking
        setOpen(false);
        setCurrentBookingSelected(bookingDetails._id);
        openCancelModal();
    }

    const handleDownloadBilling = async (identifier, amountTransfered, type) => {
        try {
            // Replace # from the identifier
            const cleanedIdentifier = identifier.replace("#", "");

            // Fetch the PDF from the backend
            const response = await fetch(`${process.env.REACT_APP_API_URL}/booking-stripe/download-billing-report/${activity.partnerID._id}/${cleanedIdentifier}`, {
                method: 'POST', // Change method to POST
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    // Pass any additional data needed by the backend
                    type: type,
                    amountOfTransfer: amountTransfered,
                }),
            });

            // Check if the response is okay
            if (!response.ok) {
                throw new Error('Failed to download the billing report.');
            }

            // Convert the response to a blob
            const blob = await response.blob();

            // Create a URL for the blob
            const url = window.URL.createObjectURL(new Blob([blob]));

            // Create a link element, trigger the download, and remove the link
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `facture_${cleanedIdentifier}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            // Revoke the URL to free up memory
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading billing report:', error);
        }
    };


    return !mediaQuery && (
        <AnimatePresence>
            {open && (
                <motion.div className="booking__sidebar__background"
                            variants={backdrop}
                            initial="hidden"
                            animate="visible"
                            exit="exit">
                    <motion.div className="booking__sidebar__section"
                                variants={sidebarDesktop}
                                initial="hidden"
                                animate="visible"
                                exit="exit">
                        <div className="booking__sidebar__close">
                            <img src={close} alt="close" onClick={handleClose}/>
                        </div>
                        <div className="booking__sidebar__container spaceBetween">
                            <div className="booking__sidebar__main_content">
                                <div className="booking__sidebar__header_top">
                                    <div className="sidebar__form__container__row">
                                        <p className="title-23 bold">Détail de votre réservation</p>
                                        {
                                            isActivityInProgress && bookingDetails.bookingStatus === "booked" && bookingDetails.bookingStatus !== "completed" ? (
                                                <Tag text={"En cours"} status={"progress"}/>
                                            ) :(
                                                <Tag text={getStatusName(bookingDetails.bookingStatus)}
                                                     status={bookingDetails.bookingStatus}/>
                                            )
                                        }

                                    </div>

                                    <div className="booking__sidebar__row-start">
                                        <IconButton icon={recipe} text={"Bon de réservation"}
                                                    onClick={() => handleDownloadVoucherBilling(bookingDetails.bookingIdentifier)}
                                        />
                                        {bookingDetails.bookingStatus === "completed" && bookingDetails.stripeTransfer && (
                                            <IconButton icon={eye} text={"Voir la facture"} onClick={() => handleDownloadBilling(bookingDetails.bookingIdentifier, bookingDetails.stripeTransfer.stripeTransferAmount, bookingDetails.stripeTransfer.stripeTransferType)} isDark={true}/>
                                        )}
                                    </div>
                                </div>

                                <div className="booking__sidebar__body noPadding">
                                    <div className="booking__sidebar__form">
                                        <div className="sidebar__form__container__group_row">
                                            <div className="sidebar__form__container__group_col">
                                                <p className="text-16 grey lighter">Date</p>
                                                <p className="text-16">{parseBookingDateDay(bookingDetails.bookingDate)}</p>
                                            </div>

                                            <div className="sidebar__form__container__group_col">
                                                <p className="text-16 grey lighter">Créneau</p>
                                                <p className="text-16">{parseSlotTimeBooking(bookingDetails.bookingDate, bookingDetails.bookingDuration)}</p>
                                            </div>
                                        </div>

                                        <div className="sidebar__form__container__group_col">
                                            <p className="text-16 grey lighter">Nombre de personnes</p>
                                            <p className="text-16">{bookingDetails.bookingPersonNumber} {bookingDetails.bookingPersonNumber > 1 ? "personnes" : "personne"}</p>
                                        </div>


                                        <div className="sidebar__form__container__group_col">
                                            <div className="sidebar__form__container__head">
                                                <p className="text-16 grey lighter">Détail du montant</p>
                                                <div className="booking__sidebar__content__col">
                                                    {
                                                        bookingDetails.bookingPricingDetails.map((option, index) => (
                                                            <div key={index} className="booking__sidebar__content__col__stretch">
                                                                <p className="text-16 bold">{option.pricingQuantity}x {option.pricingName}</p>
                                                                <p className="text-16">{option.pricingAmount}€</p>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="sidebar__form__container__group_col">
                                            <p className="text-16 grey lighter">Montant total de la réservation</p>
                                            <p className="text-16">{bookingDetails.bookingAmount}€</p>
                                        </div>
                                    </div>
                                    <div className={"separation-line"}/>
                                    <div className="booking__sidebar__content">
                                        <div className="booking__sidebar__content__group">
                                            <p className="title-23 bold">Les coordonnées du client</p>
                                            <p className={"text-14"}>Les coordonnées du client vous seront transmises {activity.details.cancellationPolicy} avant l'activité, lorsque le délai d'annulation sera écoulé.</p>
                                        </div>
                                        <div className={`booking__sidebar__content__group__border ${parseTimeCancellationPeriodEndBooking(bookingDetails.bookingDate, activity.details.cancellationPolicy) ? "" : "booking__sidebar__content__blurred"}`}>
                                            <p className="title-16 bold">Contact de {bookingDetails.bookingUser.firstname} :</p>
                                            <div className="booking__sidebar__content__group__row">
                                                <p className="text-16">
                                                    <a href={`tel:${bookingDetails.bookingUser.phoneNumber}`}>
                                                        <span className="text-16">{parseTimeCancellationPeriodEndBooking(bookingDetails.bookingDate, activity.details.cancellationPolicy) ? bookingDetails.bookingUser.phoneNumber : "pas encore disponible"}</span>
                                                    </a>
                                                </p>
                                                <p className="text-16">
                                                    <a href={`mailto:${bookingDetails.bookingUser.email}`}>
                                                        <span className="text-16"> {parseTimeCancellationPeriodEndBooking(bookingDetails.bookingDate, activity.details.cancellationPolicy) ? bookingDetails.bookingUser.email : "pas encore disponible"}</span>
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="booking__sidebar__actions">
                                <Button text={"Annuler"} isPrimary={false} onClick={handleClose} isFull={true}/>
                                {['in-progress', 'booked', 'waiting-date'].includes(bookingDetails.bookingStatus) && !isActivityInProgress && (
                                    <Button text={"Annuler la réservation"} isRed={true}
                                        onClick={handleCancelBooking} isFull={true}/>
                                )}
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    )
};

export default BookingUserDetails;
