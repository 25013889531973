import React, {useEffect, useState} from 'react';
import mail from '../../../assets/images/svg/mail_round_yellow.svg';
import Button from "../../../components/button/button";
import { Link } from "react-router-dom";
import arrow from '../../../assets/icones/arrows/left.svg';
import '../../../styles/views/verifications.css';
import {useParams} from "react-router";
import VerificationServices from "../../../api/services/verification";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import {setEmailSent} from "../../../redux/slice/signInSlice";

const VerificationEmail = () => {

    const email = useParams().email;
    const user = useSelector(state => state.signIn.user);

    return (
        <div className="main verification">
            <div className="main__container__col">
                <div className="main__container__col32">
                    <img src={mail} alt="logo"/>
                </div>
                <div className="main__container__col32">
                    <h1 className={"title-25"}>Vérifiez votre boîte mail</h1>
                    <p className={"text-14 light"}>Nous avons envoyé un lien de vérification à {user.email}. Veuillez vérifier votre boîte mail et cliquer sur le lien pour continuer.</p>
                </div>
                <div className="main__container__col32">
                    <Button text={"Entrez le code manuellement"} link={"/signin/verification/code"} isPrimary={true} isBig={true} />
                </div>
                <div className="main__container__col32  action_row">
                    <img src={arrow} alt="arrow"/>
                    <Link to={"/signin"} className={"text-14 bold"}>Retour à l'inscription</Link>
                </div>
            </div>
        </div>
    );
}

export default VerificationEmail;