import React, {useContext, useEffect, useState} from "react";
import mail from "../../../assets/icones/global/mail_light.svg";
import people from "../../../assets/images/png/people_illustration.png";
import star from "../../../assets/images/svg/star_illustration.svg";
import '../../../styles/views/signin.css';
import logo from "../../../assets/images/svg/logo_row.svg";
import StepBar from "../../../components/stepBar/stepBar";
import PartnersServices from "../../../api/services/partners";
import {useNavigate} from "react-router-dom";
import InformationsPersonel from "./form/informationsPersonel";
import InformationsPartners from "./form/informationsPartners";
import UsersServices from "../../../api/services/users";
import {useSelector} from "react-redux";
import {ErrorContext} from "../../../context/errorContext";
import useFormValidation from "../../../hooks/form/useFormValidation";
import useFormUpdate from "../../../hooks/form/useFormUpdate";
import useParseSignIn from "../../../hooks/parseDataApi/useParseSignIn";
import Authentification from "../../../api/services/authentification";
import {setSignInToken} from "../../../redux/slice/signInSlice";
import {useDispatch} from "react-redux";
import {useLoader} from "../../../context/loaderContext";
import {userLogout} from "../../../redux/slice/authSlice";

const SignInPartner = () => {

    const [step, setStep] = useState(2);

    const {createPartner} = PartnersServices;
    const { validateForm } = useFormValidation();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const { parseSignIn, parseSignInForApi } = useParseSignIn();
    const { formState, setFormState, handleFormChange, getFormChanges } = useFormUpdate(parseSignIn([]));

    const navigate = useNavigate();
    const user = useSelector(state => state.signIn.user);
    const dispatch = useDispatch();

    //remove redux auth user and token
    useEffect(() => {
        dispatch(userLogout());
        handleFormChange('personalInfo.email.value', user.email);
        handleFormChange('personalInfo.firstname.value', user.firstname);
        handleFormChange('personalInfo.lastname.value', user.lastname);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        Authentification.generateTemporarySignInToken({userID: user._id})
        .then((response) => {
            dispatch(setSignInToken({token: response.data.token}));
        })
        .catch((error) => {
            showError(error);
        })

        const { parseSignIn } = useParseSignIn();

        const isFormValid = validateForm(formState, setFormState);

        if (isFormValid.isFormValid) {
            const partnerForApi= parseSignInForApi(formState);

            createPartner(partnerForApi)
            .then((res) => {
                if (res.status === 200) {
                    UsersServices.updateUser(user._id, {partnerID: [res.data._id]})
                    .then((res) => {
                        if (res.status === 200) {
                            navigate('/signin/partner/check');
                        }
                    })
                    .catch((error) => {
                        showError(error);
                    })
                }
            })
            .catch((error) => {
                showError(error);
            })
        }
    }

    return (
        <div className="main signin">
            <div className="main__container">
                <StepBar step={step}/>
            </div>
            <div className="main__container infos partner_infos">
                <div className="main__container__section">
                    <div className="main__container__form">
                        <img src={logo} alt="logo"/>
                        {
                            step === 2 ? <InformationsPersonel setPartner={handleFormChange} personal={formState.personalInfo} setStep={setStep} /> : null
                        }
                        {
                            step === 3 ? <InformationsPartners setPartner={handleFormChange} handleSubmit={handleSubmit} company={formState.companyInfo} setStep={setStep}/> : null
                        }
                    </div>
                </div>
                <div className="main__container__image">
                    <div className={"main__container__infos"}>
                        <img src={star} alt={"stars"} className={'stars'}/>
                        <h1 className={"title-25"}>Votre visibilité ne connaît pas de limites avec Frimity</h1>
                        <p className={"text-16"}>Rejoignez Frimity pour propulser votre établissement vers de nouveaux horizons. Augmentez votre visibilité, attirez plus de clients et boostez votre activité dès aujourd'hui.</p>
                        <div className={"main-container__row invite"}>
                            <img src={people} alt={"logo"}/>
                            <p className={"text-16"}>+380 partenaires ont rejoint l'aventure 🙌🏻</p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="main__container last">
                <div className="main__footer">
                    <p className={"text-12"}>© Frimity 2023</p>
                    <div className={"main__footer__links"}>
                        <img src={mail} alt="logo"/>
                        <a href={"mailto:contact@frimity.fr"}><p
                            className={"text-12"}>contact@frimity.fr</p></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignInPartner;