import React from 'react';
import FormSectionRow from "../../../components/form/formSectionRow";
import Textarea from "../../../components/form/InputTextarea/textarea";

const Description = ({description, setActivity}) => {

    const handleChange = (e) => {
        const {name, value} = e.target;

        setActivity(`description.${name}.value`, value);
    }

    return(
        <FormSectionRow title={"Description de l’activité"} description={"Indiquer ici le déroulement de votre activité en prenant en compte les éléments les plus importants à mettre en avant"} classname={"description"} isRequired={true}>
            {
                Object.keys(description).map((key, index) => {
                    return(
                        <Textarea
                            title={description[key].label}
                            subtitle={description[key].subtitle}
                            placeholder={description[key].error ? "Remplissez ce champ" : description[key].placeholder}
                            value={description[key].value}
                            name={key}
                            onChange={handleChange}
                            errorEmpty={description[key].error}
                            errorMessage={description[key].errorMessage}
                            validationType={description[key].validationType}
                        />
                    )
                })
            }
        </FormSectionRow>
    )
}

export default Description;