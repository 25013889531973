import React, {useContext, useEffect, useState} from 'react';
import PayementCard from "../../components/stripe/payementCard";
import add from "../../assets/icones/global/add.svg";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import SubmitButton from "../../components/button/submitButton";
import {useNavigate} from "react-router-dom";
import PayementServices from "../../api/services/payement";
import {ErrorContext} from "../../context/errorContext";
import {useLoader} from "../../context/loaderContext";

const PaymentPatch = ({customerId, defaultCard, savedMethods, setReload, setModalOpen}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [isFormVisible, setIsFormVisible] = useState(true);
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();



    const handleSubmitNewMethod = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError(null); // Clear any previous errors

        const { error, setupIntent } = await stripe.confirmSetup({
            elements,
            redirect: 'if_required'
        });

        if (error) {
            setError(error.message);
            setLoading(false);
        }else{
            if (setupIntent.status === "succeeded") {
                PayementServices.attachPaymentMethod({ customerId, paymentMethodId: setupIntent.payment_method })
                .then((res) => {
                    if(res.status === 200){
                        setLoading(true);
                        setIsFormVisible(false);
                        setModalOpen(true);
                    }
                })
                .catch((error) => {
                    showError(error);
                    setLoading(false);
                });

            }
        }
    }

    useEffect(() => {
        savedMethods.length !== 0 ? setIsFormVisible(false) : setIsFormVisible(true);
    }, [savedMethods]);


    return (
        <div className={"subscription__content__col__content"}>
            {
                !isFormVisible ?
                    <div className={"payment-methods__container"}>
                        {
                            savedMethods.length !== 0 && savedMethods.map((method) => (
                                <PayementCard
                                    method={method}
                                    setModalOpen={setModalOpen}
                                    defaultMethod={method.id === defaultCard}
                                    setReload={setReload}
                                    customerId={customerId}
                                />
                            ))
                        }
                        <div className={"payment-methods__row actions"}
                             onClick={() => setIsFormVisible(true)}>
                            <img src={add} alt="add"/>
                            <p className={"text-16 light"}>Ajouter une nouvelle carte</p>
                        </div>
                    </div>
                    :
                    <form id="payment-form" onSubmit={handleSubmitNewMethod}
                          className={!isFormVisible ? "hidden" : ""}>
                        <PaymentElement id="payment-element"/>
                        <SubmitButton text={"Ajouter cette carte"} isPrimary={true} disabled={setLoading}/>
                        {error && <div className="error">{error}</div>}
                    </form>
            }
        </div>
    )

}

export default PaymentPatch;