import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
    RouterProvider,
} from "react-router-dom";

import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from "./redux/store";
import routes from './router/routes';
import {ErrorProvider} from "./context/errorContext";
import {LoaderProvider} from "./context/loaderContext";
import {PartnerProvider} from "./context/partnerContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ErrorProvider>
            <LoaderProvider>
                <PartnerProvider>
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                                <RouterProvider router={routes}/>
                        </PersistGate>
                    </Provider>
                </PartnerProvider>
            </LoaderProvider>
        </ErrorProvider>
    </React.StrictMode>
);

reportWebVitals();
