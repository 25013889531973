import React, {useState} from 'react';
import Button from "../../button/button";
import close from "../../../assets/icones/global/close-stroke.svg";
import add from "../../../assets/images/svg/check_round_green.svg";
import InputSelect from "../../form/inputSelect/inputSelect";

const ModalBookingAdd = ({isOpen, closeModal, addBooking, mediaQuery}) => {
    const handleDelete = () => {
        addBooking();
        closeModal();
    }

    const handleClose = () => {
        closeModal();
    }

    return(
        <div className={`bg__modal ${isOpen ? "open" : ""}`}>
            <div className={`modal booking accept ${isOpen ? "open" : ""} ${mediaQuery ? "media-query" : ""}`}>
                <div className={"modal__header"}>
                    <div className={"modal__header__col"}>
                        <div className={"modal__delete__icon"}>
                            <img src={add} alt={"add"}/>
                        </div>
                        <div className={"modal__header__text"}>
                            <p className={"title-16"}>Confirmer cette réservation</p>
                            <p className={"text-14"}>En confirmant cette réservation, vous reconnaissez avoir pris connaissance de ces informations et vous vous engagez à accueillir l'utilisateur de Frimity dans les meilleures conditions possibles.</p>
                        </div>
                    </div>
                    <div className={"modal__header__col"}>
                        <div className={"modal__close"} onClick={handleClose}>
                            <img src={close} alt={"close"}/>
                        </div>
                    </div>
                </div>
                <div className={"modal__content"}>
                    <div className={"modal__content__actions"}>
                        <Button text={"Annuler"} isPrimary={false} onClick={handleClose} />
                        <Button text={"Confirmer la réservation"} isPrimary={true} onClick={handleDelete}  />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalBookingAdd;