import React from 'react';
import check from '../../../assets/images/svg/check_round_green.svg';
import Button from "../../../components/button/button";
import '../../../styles/views/verifications.css';

const PasswordUserCheck = () => {

    return (
        <div className="main verification">
            <div className="main__container__col password-half">
                <div className="main__container__col32">
                    <img src={check} alt="logo"/>
                </div>
                <div className="main__container__col32">
                    <h1 className={"title-25"}>Votre mot de passe a été réinitialisé</h1>
                    <p className={"text-14 light"}>Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.</p>
                </div>
                <div className="main__container__col32">
                    <Button text={"Retourner à l'accueil"} isPrimary={true} isBig={true} link={'/login'} />
                </div>

            </div>
        </div>
    );
}

export default PasswordUserCheck;