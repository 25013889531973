import React, {useState} from 'react';
import Radio from "../../../components/form/inputRadio/radio";
import FormSectionRow from "../../../components/form/formSectionRow";


const Activity = ({activities, setOffer, offerActivity, errorEmpty, isDisabled}) => {

    const [checked, setChecked] = useState(offerActivity);

    const handleChange = (event) => {
        setChecked(event.target.value);
        event.preventDefault();
        const { value} = event.target;

        setOffer("activities.value", value);
    }

    return(
        <FormSectionRow title={"Attribution de l'offre"}
                        isRequired={true}
                        description={"Attribuez votre offre spéciale pour vos activités sur Frimity"}>
            <div className={"row__input wrap"}>
                {
                    activities.map((activity, index) => {
                        return (
                            <Radio
                                key={index}
                                name={"activities"}
                                label={activity.name}
                                value={activity._id}
                                checked={offerActivity === activity._id}
                                onChange={handleChange}
                                errorEmpty={errorEmpty}
                                disabled={isDisabled}
                            />
                        )
                    })
                }
            </div>
        </FormSectionRow>
)

}

export default Activity;