import InputSelect from "../../../../components/form/inputSelect/inputSelect";


const TimeGap = ({ timeGap, setTimeGap }) => {

    //time gap in minutes
    const timeGapOptions = Array.from({length: 60}, (_, i) => i);


    const handleChange = (e) => {
        setTimeGap(`activityTimeGap.value`, parseInt(e.target.value));
    }

    return (
        <div className="sidebar__form__container__row">
            <div className="sidebar__form__label">
                <p className="text-16">Intervalle entre les sessions</p>
                <p className="text-12">Indiquez l'écart de temps entre chaque session.</p>
            </div>
            <div className="sidebar__form__content is__content__row">
                <InputSelect
                    name={"timeGap"}
                    label={"Minute"}
                    options={timeGapOptions.map((minute, index) => ({id: minute, name: `${minute} minute${minute > 1 ? "s" : ""}`, value: minute}))}
                    valueSelected={timeGap.value}
                    onChange={handleChange}
                    isColumn={true}
                    errorMessage={timeGap.errorMessage}
                    error={timeGap.error}
                    type={"number"}
                />
            </div>
        </div>
    );
}

export default TimeGap;