import React, { useEffect, useState } from 'react';

function RemainingTime({ createdAt }) {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(createdAt));
    const mediaQuery = window.matchMedia('(max-width: 991px)');


    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft(createdAt));
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (
        <div>
            {timeLeft.minutes !== undefined ? (
                <p className={`${mediaQuery ? "text-12 is__red" :"text-14 is__red"}`}>
                    Il vous reste {
                        timeLeft.hours > 0 ? (
                            <>
                                 {timeLeft.hours}h et {timeLeft.minutes}min
                            </>
                        ) : (
                            <>
                                 {timeLeft.minutes}min et {timeLeft.seconds}sec
                            </>
                        )
                    } pour vous décider.
                </p>
            ) : (
                <p className="text-14 is__red">Le temps pour annuler est expiré.</p>
            )}
        </div>
    );
}

function calculateTimeLeft(createdAt) {
    const cancelDate = new Date(createdAt).getTime() + 86400000; // 24 hours after created time
    const now = new Date().getTime();
    const difference = cancelDate - now;

    let timeLeft = {};


    if (difference > 0) {
        timeLeft = {
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
        };
    }

    return timeLeft;
}


export default RemainingTime;
