import {getRequest, postRequestJson, patchRequest, deleteRequest} from '../request/apiRequest';

const BookingTimeSlotsServices = {

    // Get all booking time slots
     getAllBookingTimeSlots: async () => {
        return await getRequest('/booking-timeslots');
    },

    // Get a booking time slot by id
     getBookingTimeSlotByPartnerId : async (bookingPartnerID) => {
        return await getRequest(`/booking-timeslots/${bookingPartnerID}`);
     },

    // Get a booking time slot by activity id
     getBookingTimeSlotByActivityId : async (activityID) => {
        return await getRequest(`/booking-timeslots/activity/${activityID}`);
    },

    getBookingTimeSlotById : async (timeSlotID) => {
        return await getRequest(`/booking-timeslots/timeslot/${timeSlotID}`);
    },

    // Create a booking time slot
     createBookingTimeSlot : async (bookingPartnerID, data) => {
        return await postRequestJson(`/booking-timeslots/${bookingPartnerID}`, data);
    },

    // Update a booking time slot
     updateBookingTimeSlot : async (bookingPartnerID, timeSlotID, data) => {
        return await patchRequest(`/booking-timeslots/${bookingPartnerID}/${timeSlotID}`, data);
    },

    // Delete a booking time slot
     deleteBookingTimeSlot : async (bookingPartnerID, timeSlotID) => {
        return await deleteRequest(`/booking-timeslots/${bookingPartnerID}/${timeSlotID}`);
    }
}

export default BookingTimeSlotsServices;