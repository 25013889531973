
import {getRequest, postRequestJson, patchRequest, deleteRequest} from '../request/apiRequest';

const SubsrciptionsServices = {

    getSubscriptions: async () => {
        return await getRequest('/subscriptions');
    },

    getSubscriptionById: async (subscriptionId) => {
        return await getRequest(`/subscriptions/${subscriptionId}`);
    },
}

export default SubsrciptionsServices;