import React, { useEffect, useState } from 'react';
import FormSectionRow from '../../../components/form/formSectionRow';
import InputSelect from '../../../components/form/inputSelect/inputSelect';
import DateRangePicker from "../../../components/calendar/doubleView/dateRangePicker";
import WarningMessage from "../../../components/calendar/warningMessage";

const daysOfWeek = [
    { name: 'Lundi', value: 0 },
    { name: 'Mardi', value: 1 },
    { name: 'Mercredi', value: 2 },
    { name: 'Jeudi', value: 3 },
    { name: 'Vendredi', value: 4 },
    { name: 'Samedi', value: 5 },
    { name: 'Dimanche', value: 6 }
];

const defaultTimeSlot = { startTime: '00:00', endTime: '23:59' };

const Validity = ({ validity = {}, isLastMinute = false, setOffer = () => {}, isDisabled = false }) => {
    const [scheduleHours, setScheduleHours] = useState([]);
    const [useRecurringDays, setUseRecurringDays] = useState(validity.recurringDays.value?.length > 0 || false);
    const [isRecurringHours, setIsRecurringHours] = useState(false);
    const [recurringDays, setRecurringDays] = useState(validity.recurringDays || []);

    useEffect(() => {
        // Set schedule hours from 0h00 to 23h59 every 30 minutes
        const hours = [];
        for (let i = 0; i < 24; i++) {
            const hour = i < 10 ? `0${i}` : `${i}`;
            hours.push({ name: `${hour}:00`, value: `${hour}:00` });
            hours.push({ name: `${hour}:30`, value: `${hour}:30` });
        }
        // Add 23:59 as a special case after the loop
        hours.push({ name: `23:59`, value: `23:59` });

        setScheduleHours(hours);

        // Initialize recurringDays from validity data
        if (validity.recurringDays.value && validity.recurringDays.value.length > 0) {
            const updatedDays = validity.recurringDays.value.map(day => {
                if (day.times.length === 0) {
                    return { ...day, times: [defaultTimeSlot] };
                }
                return day;
            });
            setUseRecurringDays(true);
            setRecurringDays(updatedDays);
            setOffer('validity.recurringDays', updatedDays);
            setIsRecurringHours(updatedDays.some(day => day.times.length > 0));
        }
    }, [validity]);

    const handleRecurringDaysChange = (dayValue) => {
        const updatedDays = [...recurringDays];
        const dayIndex = updatedDays.findIndex(day => day.day === dayValue);
        if (dayIndex > -1) {
            updatedDays.splice(dayIndex, 1);
        } else {
            updatedDays.push({ day: dayValue, times: [defaultTimeSlot] });
        }
        setRecurringDays(updatedDays);
        setOffer('validity.recurringDays', updatedDays);
    };

    const handleTimeSlotChange = (dayValue, timeIndex, field, value) => {
        const updatedDays = [...recurringDays];
        const dayIndex = updatedDays.findIndex(day => day.day === dayValue);

        if (dayIndex > -1) {
            const currentTimes = updatedDays[dayIndex].times[timeIndex];

            if (field === 'startTime' && currentTimes.endTime && value > currentTimes.endTime) {
                alert('L\'heure de début ne peut pas être après l\'heure de fin.');
                return;
            }

            if (field === 'endTime' && currentTimes.startTime && value < currentTimes.startTime) {
                alert('L\'heure de fin ne peut pas être avant l\'heure de début.');
                return;
            }

            updatedDays[dayIndex].times[timeIndex] = {
                ...currentTimes,
                [field]: value
            };

            setRecurringDays(updatedDays);
            setOffer('validity.recurringDays', updatedDays);
        }
    };

    const toggleRecurringDays = () => {
        setUseRecurringDays(!useRecurringDays);
        if (!useRecurringDays) {
            setRecurringDays([]);
            setOffer('validity.recurringDays', []);
        } else {
            setRecurringDays([]);
            setOffer('validity.recurringDays', []);
        }
    };

    return (
        <FormSectionRow title={"Validité de l’offre"} isRequired={true}>
            <div className="form__row checkbox" style={{ marginBottom: "20px" }}>
                <input type="checkbox"
                       name={"useRecurringDays"}
                       id={"useRecurringDays"}
                       checked={useRecurringDays}
                       onChange={toggleRecurringDays}
                       disabled={isDisabled} />
                <div className={"form__col__radio"}>
                    <label htmlFor={"useRecurringDays"} className={"text-14 bold"}>Utiliser des jours récurrents</label>
                    <p className={"text-14 light"}>Cochez cette option si vous souhaitez que l'offre soit valable les mêmes jours chaque semaine.</p>
                </div>
            </div>

            {useRecurringDays && (
                <div className={"pricing__col_grid"}>
                    <label className="text-14 bold">Jours récurrents</label>
                    <div className={"pricing__row grid"}>
                        {daysOfWeek.map(day => (
                            <div
                                key={day.value}
                                className={`pricing__row__checkbox ${recurringDays.some(d => d.day === day.value) ? 'active' : ''} ${isDisabled ? 'disabled' : ''}`}
                                onClick={() => handleRecurringDaysChange(day.value)}
                            >
                                <input
                                    type="checkbox"
                                    id={`recurringDay_${day.value}`}
                                    name={day.name}
                                    value={day.value}
                                    checked={recurringDays.some(d => d.day === day.value)}
                                    onChange={() => handleRecurringDaysChange(day.value)}
                                    disabled={isDisabled}
                                />
                                <label htmlFor={`recurringDay_${day.value}`}>
                                    {day.name.charAt(0).toUpperCase() + day.name.slice(1)}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {useRecurringDays && (
                <div className="form__row checkbox" style={{marginBottom: "30px", marginTop: "30px"}}>
                    <input type="checkbox"
                           name={"isRecurringHours"}
                           id={"isRecurringHours"}
                           checked={isRecurringHours}
                           onChange={() => setIsRecurringHours(!isRecurringHours)}
                           disabled={isDisabled}/>
                    <div className={"form__col__radio"}>
                        <label htmlFor={"useRecurringDays"} className={"text-14 bold"}>
                            Choisir des horaires récurrents
                        </label>
                        <p className={"text-14 light"}>
                            Cochez cette option si vous souhaitez choisir des horaires récurrents pour chaque jour sélectionné.
                        </p>
                    </div>
                </div>
            )}

            {isRecurringHours &&
                <div className={"form__col"} style={{marginBottom: "30px"}}>
                    {recurringDays.map((day, dayIndex) => (
                        <div key={day.day} className={"day-time-slots"}>
                            <p className={"text-16 bold"}>{daysOfWeek.find(d => d.value === day.day).name}</p>
                            {day.times.map((timeSlot, timeIndex) => (
                                <div key={timeIndex} className="form__row__days-times" style={{ alignItems: "flex-end" }}>
                                    <InputSelect
                                        name={`startTime_${day.day}_${timeIndex}`}
                                        label={`Heure de début`}
                                        placeholder={"Choisir"}
                                        type={"hour"}
                                        valueSelected={timeSlot.startTime || ''}
                                        options={scheduleHours}
                                        onChange={(e) => handleTimeSlotChange(day.day, timeIndex, 'startTime', e.target.value)}
                                        isRequired={true}
                                        isDisabled={isDisabled}
                                        isColumn={true}
                                    />
                                    <InputSelect
                                        name={`endTime_${day.day}_${timeIndex}`}
                                        label={`Heure de fin`}
                                        placeholder={"Choisir"}
                                        type={"hour"}
                                        valueSelected={timeSlot.endTime || ''}
                                        options={scheduleHours}
                                        onChange={(e) => handleTimeSlotChange(day.day, timeIndex, 'endTime', e.target.value)}
                                        isDisabled={isDisabled}
                                        isRequired={true}
                                        isColumn={true}
                                    />
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            }

            {validity.excludedDates?.length > 0 &&
                <WarningMessage excludedDates={validity.excludedDates || []}/>
            }

            {!isLastMinute && (
                <DateRangePicker
                    offer={validity}
                    setOffer={setOffer}
                    errorEmpty={validity.startDate?.error || false}
                    errorMessage={validity.startDate?.errorMessage || ''}
                    disabled={isDisabled}
                />
            )}

            {validity.startDate?.error && validity.startDate?.errorMessage !== "" && (
                <div className={"error-message"}>Veuillez renseigner une date de début et de fin</div>
            )}
        </FormSectionRow>
    );
};

export default Validity;
