import React from 'react';

import Button from "../../button/button";

const ModalNotSubscription = ({isOpen, setModalOpen}) => {

    const handleClick = () => {
        setModalOpen(false);
    }

    return(
        <div className={`bg__modal ${isOpen ? "open" : ""}`}>
            <div className={`modal verification ${isOpen ? "open" : ""}`}>
                <div className={"modal__header"}>
                    <div className={"modal__header__col"}>
                        <p className={"title-20 center"}>Votre abonnement actuel ne peut pas être rétrogradé vers un plan inférieur</p>
                    </div>
                </div>
                <div className={"modal__content center__container"}>
                    <p className={"text-14"}>Nous ne pouvons pas rétrograder votre abonnement actuel vers un plan
                        inférieur.</p>
                    <p className={"text-14"}>Vous pouvez cependant souscrire à un nouvel abonnement ou si besoin contacter notre
                        équipe à l'adresse mail suivante : <a href="mailto:contact@frimity.fr">contact@frimity.fr</a>
                    </p>
                    <Button text={`Retour à votre espace`} isPrimary={true} onClick={handleClick}/>
                </div>
            </div>
        </div>
    )
}

export default ModalNotSubscription;