import {getRequest, postRequestJson, patchRequest, deleteRequest} from '../request/apiRequest';


const BookingStripeServices = {

    getStripeAccount: async (data) => {
        return await postRequestJson('/booking-stripe/get-stripe-account', data);
    },

    getTransfers: async (data) => {
        return await postRequestJson('/booking-stripe/get-transfers', data);
    },

    getPayouts: async (data) => {
        return await postRequestJson('/booking-stripe/get-payouts', data);
    },

    getCustomers: async (data) => {
        return await postRequestJson('/booking-stripe/get-customers', data);
    },

    getAmounts: async (data) => {
        return await postRequestJson('/booking-stripe/get-amounts', data);
    },

    getBankAccounts: async (data) => {
        return await postRequestJson('/booking-stripe/get-bank-accounts', data);
    },

    downloadBillingReport: async (data) => {
        return await postRequestJson('/booking-stripe/download-billing-report', data);
    }
}

export default BookingStripeServices;