import React from 'react';
import FormSection from "../../../components/form/formSection";
import Input from "../../../components/form/inputText/input";
import InputIcon from "../../../components/form/inputText/inputIcon";
import website from '../../../assets/icones/global/link_stroke.svg';
import phone from '../../../assets/icones/global/phone.svg';
import mail from '../../../assets/icones/global/mail_light.svg';
import InputAddress from "../../../components/form/inputText/inputAddress";
import search from '../../../assets/icones/global/search.svg';

const Company = ({company, setPartner, user = null}) => {


    const handleChange= (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        setPartner(`company.${name}.value`, value);
    };

    const handleAddress = (name, addressDetails) => {
        const newAddress = `${addressDetails.addressLine ? addressDetails.addressLine : ""}`;
        const newPostalCode = `${addressDetails.postalCode ? addressDetails.postalCode : ""}`;
        if (company.address.value !== newAddress) { // Only update if there's a change
            setPartner(`company.address.value`, newAddress);
        }

        if (company.postalCode.value !== newPostalCode) { // Only update if there's a change
            setPartner(`company.postalCode.value`, newPostalCode);
        }
    }

    return(
        <FormSection title={"Entreprise"}>
            {Object.keys(company).map((field) => (
                field === "email" || field === "website" || field === "phoneNumber" ?
                    <InputIcon
                        key={field}
                        type={field === "phoneNumber" ? "number" : "text"}
                        label={company[field].label}
                        name={field}
                        placeholder={company[field].placeholder}
                        valueInput={company[field].value}
                        onChangeForm={handleChange}
                        validationType={company[field].validationType}
                        icon={field === "email" ? mail : field === "website" ? website : phone}
                        isRow={true}
                        disabled={user && user.role === "admin" ? false : !company[field].isLoadEmpty}
                        errorEmpty={company[field].error}
                        errorMessage={company[field].errorMessage}
                    />
                : field === "address" ?
                    <InputAddress
                        key={field}
                        title={company[field].label}
                        name={field}
                        icon={search}
                        placeholder={company[field].placeholder}
                        valueInput={company[field].value}
                        onChangeForm={handleAddress}
                        validationType={company[field].validationType}
                        errorEmpty={company[field].error}
                        errorMessage={company[field].errorMessage}
                        disabled={user && user.role === "admin" ? false : !company[field].isLoadEmpty}
                        isRow={true}
                        isRequired={true}
                    />
                : field === "comment" ?
                        user && user.role === "admin" ?
                            <Input
                                key={field}
                                type={field === "postalCode" ? "number" : "text"}
                                label={company[field].label}
                                name={field}
                                placeholder={company[field].placeholder}
                                valueInput={company[field].value}
                                onChangeForm={handleChange}
                                validationType={company[field].validationType}
                                errorEmpty={company[field].error}
                                errorMessage={company[field].errorMessage}
                                disabled={user && user.role === "admin" ? false : !company[field].isLoadEmpty}
                            />
                            : null
                :
                    <Input
                        key={field}
                        type={field === "postalCode" ? "number" : "text"}
                        label={company[field].label}
                        name={field}
                        placeholder={company[field].placeholder}
                        valueInput={company[field].value}
                        onChangeForm={handleChange}
                        validationType={company[field].validationType}
                        errorEmpty={company[field].error}
                        errorMessage={company[field].errorMessage}
                    />
                ))}
        </FormSection>
    )

}

export default Company;