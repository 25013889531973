import React from 'react';
import {createBrowserRouter} from "react-router-dom";
import GlobalLayout from "../GlobalLayout";
import OutLayout from "../OutLayout";
import NotFound from "../views/notFound";
import Account from "../views/settings/account";
import Preferences from "../views/settings/preferences";
import Login from "../views/authentification/login";
import Partners from "../views/partners/partners";
import SinglePartner from "../views/partners/singlePartner";
import Pending from "../views/pending";
import Activities from "../views/activities/activities";
import SingleActivity from "../views/activities/singleActivity";
import AddPartner from "../views/partners/addPartner";
import AddActivity from "../views/activities/addActivity";
import SigninUser from "../views/authentification/signIn/signinUser";
import VerificationEmail from "../views/authentification/verification/verificationEmail";
import VerificationCode from "../views/authentification/verification/verificationCode";
import VerificationCheck from "../views/authentification/verification/verificationCheck";
import VerificationUserEmail from "../views/users/verification/verificationUserEmail";
import VerificationUserCode from "../views/users/verification/verificationUserCode";
import VerificationUserCheck from "../views/users/verification/verificationUserCheck";
import SigninPartner from "../views/authentification/signIn/signinPartner";
import SignInCheck from "../views/authentification/signIn/signinCheck";
import Offers from "../views/offers/offers";
import SingleOffers from "../views/offers/singleOffers";
import AddOffer from "../views/offers/addOffer";
import useUserRole from "../hooks/useUserRole";
import VerificationPartners from "../views/verification/verificationPartners";
import VerificationOffers from "../views/verification/verificationOffers";
import VerificationActivities from "../views/verification/verificationActivities";
import CGU from "../views/frimity/legalsDocuments/cgu";
import LegalMentions from "../views/frimity/legalsDocuments/legalsMentions";
import ConfidentialPolicy from "../views/frimity/legalsDocuments/confidentialPolicy";
import Support from "../views/support/support";
import Users from "../views/users/users"
import Subscription from "../views/subscription/subscription";
import Invoice from "../views/subscription/invoice";
import Payment from "../views/subscription/payment";
import StripePayment from "../views/subscription/stripe/stripePayment";
import ConfirmPayment from "../views/subscription/stripe/confirmPayment";
import SubscriptionPlan from "../views/authentification/stripe/subscriptionPlan";
import StripeUpgradePayement from "../views/subscription/stripe/stripeUpgradePayement";
import FAQ from "../views/frimity/legalsDocuments/faq";
import SubscriptionPolicy from "../views/frimity/legalsDocuments/subscriptionPolicy";
import Dashboard from "../views/dashboard";
import Analytics from "../views/analytics/analytics";
import PasswordUserEmail from "../views/users/passwordReset/passwordUserEmail";
import PasswordUserCode from "../views/users/passwordReset/passwordUserCode";
import PasswordUserCheck from "../views/users/passwordReset/passwordUserCheck";
import PasswordUserChange from "../views/users/passwordReset/passwordUserChange";
import TypeActivityManager from "../views/manager/typeActivityManager";
import MultiPartnerDashboard from "../views/multiPartnerDashboard";
import NoBooking from "../views/bookings/noBooking";
import BookingConfigAPI from "../views/bookings/bookingAPI/bookingConfigAPI";
import BookingSchedule from "../views/bookings/bookingSchedule/bookingSchedule";
import BookingManageBookingUser from "../views/bookings/bookingManage/bookingManageBookingUser";
import BookingPayment from "../views/bookings/bookingPayment/bookingPayment";
import GenerateProofOfBookingUser from "../views/bookings/generateProofOfBookingUser";
import BookingStripeSuccess from "../views/bookings/bookingStripe/bookingStripeSuccess";
import BookingStripeError from "../views/bookings/bookingStripe/bookingStripeError";
import ActivityPreview from "../views/activities/activityPreview";

const {PrivateRoute, AdminRoute, VerificationEmailSignInRoute, MainRouteSignIn, VerificationUserRoute, PartnerNoSubscriptionRoute, PartnerAdminRoute, PartnerBookingRoute} = useUserRole();

const routes = createBrowserRouter([
    {
        path: "/",
        element: <GlobalLayout />,
        notFoundElement: <NotFound />,
        errorElement: <Login />,
        children: [
            {
                path: "/",
                errorElement: <Login />,
                element: <AdminRoute element={<Partners />} />
            },
            {
                path: "partner",
                element: <PrivateRoute element={<SinglePartner />} />
            },
            {
                path: "dashboard/:partnerId",
                element: <PrivateRoute element={<Dashboard />} />
            },
            {
                path: "dashboard-partners",
                element: <PartnerAdminRoute element={<MultiPartnerDashboard />} />
            },
            {
                path: "/partners",
                errorElement: <NotFound />,
                children: [
                    {
                        path: "",
                        element: <AdminRoute element={<Partners />} />
                    },
                    {
                        path: "add",
                        element: <AdminRoute element={<AddPartner />} />
                    },
                    {
                        path: ":partnerId",
                        errorElement: <NotFound />,
                        children: [
                            {
                                path: "",
                                element: <PrivateRoute element={<SinglePartner />} />
                            },
                            {
                                path: "activities",
                                children: [
                                    {
                                        path: "",
                                        element: <PrivateRoute element={<Activities />} />
                                    },
                                    {
                                        path: ":activityId",
                                        element: <PrivateRoute element={<SingleActivity />} />
                                    },
                                    {
                                        path: "add",
                                        element: <PrivateRoute element={<AddActivity />} />
                                    }
                                ]
                            },
                            {
                                path: "offers",
                                errorElement: <NotFound />,
                                children: [
                                    {
                                        path: "",
                                        element: <PrivateRoute element={<Offers />} />
                                    },
                                    {
                                        path: ":offerId",
                                        element: <PrivateRoute element={<SingleOffers />} />
                                    },
                                    {
                                        path: "add",
                                        element: <PrivateRoute element={<AddOffer />} />
                                    }
                                ]
                            },
                            {
                                path: "subscription",
                                errorElement: <NotFound />,
                                children: [
                                    {
                                        path: "",
                                        element: <PrivateRoute element={<Subscription />} />
                                    },
                                    {
                                        path: "billing",
                                        element: <PrivateRoute element={<Invoice />} />
                                    },
                                    {
                                        path: "payment",
                                        element: <PrivateRoute element={<Payment />} />
                                    }
                                ]
                            },
                            {
                                path: "bookings",
                                errorElement: <NotFound />,
                                children: [
                                    {
                                        path: "",
                                        element: <PartnerBookingRoute element={<BookingManageBookingUser />} />
                                    },
                                    {
                                        path: "no-booking",
                                        element: <PrivateRoute element={<NoBooking />} />
                                    },
                                    {
                                        path: "transactions",
                                        element: <PartnerBookingRoute element={<BookingPayment />} />
                                    },
                                    {
                                        path: "configuration-api",
                                        element: <PartnerBookingRoute element={<BookingConfigAPI />} />
                                    },
                                    {
                                        path: "schedule",
                                        element: <PartnerBookingRoute element={<BookingSchedule />} />
                                    }
                                ]
                            },
                            {
                                path: "analytics",
                                element: <AdminRoute element={<Pending />} />
                            }
                        ]
                    }
                ]
            },
            {
                path: "/analytics/:partnerId",
                errorElement: <NotFound />,
                element: <PrivateRoute element={<Analytics />} />
            },
            {
                path: "/verification",
                notFoundElement: <NotFound />,
                children: [
                    {
                        path: "",
                        element: <AdminRoute element={<VerificationPartners />} />
                    },
                    {
                        path: "partners",
                        element: <AdminRoute element={<VerificationPartners />} />
                    },
                    {
                        path: "activities",
                        element: <AdminRoute element={<VerificationActivities />} />
                    },
                    {
                        path: "offers",
                        element: <AdminRoute element={<VerificationOffers />} />
                    }
                ]
            },
            {
                path: "/manager",
                errorElement: <NotFound />,
                children: [
                    {
                        path: "",
                        element: <AdminRoute element={<TypeActivityManager />} />
                    },
                ]
            },
            {
                path: "/users",
                errorElement: <NotFound />,
                element: <AdminRoute element={<Users />} />
            },
            {
                path: "/support",
                errorElement: <NotFound />,
                element: <PrivateRoute element={<Support />} />
            },
            {
                path: "/settings",
                errorElement: <NotFound />,
                children: [
                    {
                        path: "",
                        element: <PrivateRoute element={<Account />} />
                    },
                    {
                        path: "account",
                        element: <PrivateRoute element={<Account />} />
                    },
                    {
                        path: "preferences",
                        element: <PrivateRoute element={<Preferences />} />
                    }
                ]
            }]
    },
    {
        path: "/",
        element: <OutLayout />,
        errorElement: <NotFound />,
        children: [
            {
                path: "/stripe-payement",
                notFoundElement: <NotFound />,
                children: [
                    {
                        path: "",
                        element: <PartnerNoSubscriptionRoute element={<SubscriptionPlan />} />
                    },
                    {
                        path: ":partnerId/:stripeProductId",
                        element: <PartnerNoSubscriptionRoute element={<StripePayment />} />
                    },
                    {
                        path: ":stripeProductId",
                        element: <PartnerNoSubscriptionRoute element={<StripePayment />} />
                    },
                    {
                        path: "confirmation",
                        element: <PartnerNoSubscriptionRoute element={<ConfirmPayment />} />
                    },
                ]
            },
            {
                path: "/partner/subscription",
                errorElement: <NotFound />,
                children: [
                    {
                        path: "",
                        element: <PrivateRoute element={<Subscription />} />
                    },
                    {
                        path: "update/:stripeProductId",
                        element: <PrivateRoute element={<StripeUpgradePayement />} />
                    },
                    {
                        path: "update/confirmation",
                        element: <PrivateRoute element={<ConfirmPayment />} />
                    }
                ]
            },
            {
                path: "/verify-email",
                notFoundElement: <NotFound />,
                children: [
                    {
                        path: "",
                        element: <VerificationUserRoute element={<VerificationUserEmail/>} />
                    },
                    {
                        path: "code",
                        element: <VerificationUserRoute element={<VerificationUserCode/>} />
                    },
                    {
                        path: "code/:email/:code",
                        element: <VerificationUserRoute element={<VerificationUserCode/>} />
                    },
                    {
                        path: "check",
                        element: <VerificationUserRoute element={<VerificationUserCheck/>} />
                    }
                ]
            },
            {
                path: "/password-reset",
                notFoundElement: <NotFound />,
                children: [
                    {
                        path: "",
                        element: <PasswordUserEmail />
                    },
                    {
                        path: "verify/:email",
                        element: <PasswordUserCode />
                    },
                    {
                        path: "verify/:email/:code",
                        element: <PasswordUserCode />
                    },
                    {
                        path: "reset",
                        element: <PasswordUserChange />
                    },
                    {
                        path: "check",
                        element: <PasswordUserCheck />
                    }
                ]
            },
            {
                path: "/booking-stripe",
                notFoundElement: <NotFound/>,
                children: [
                    {
                        path: "stripe-success/:partnerId",
                        element: <BookingStripeSuccess/>
                    },
                    {
                        path: "stripe-error",
                        element: <BookingStripeError/>
                    },
                ]
            },
            {
                path: "/generate-proof-of-booking-user/:bookingIdentifier/:bookingAppId",
                notFoundElement: <NotFound />,
                element: <GenerateProofOfBookingUser />
            },
            {
                path: "/activities/:activityId",
                notFoundElement: <NotFound />,
                element: <ActivityPreview />
            },
            {
                path: "/login",
                element: <Login />
            },
            {
                path: "/signin",
                children: [
                    {
                        path: "",
                        element: <SigninUser />
                    },
                    {
                        path: "verification",
                        errorElement: <NotFound />,
                        children: [
                            {
                                path: "",
                                element: <VerificationEmailSignInRoute element={<VerificationEmail/>} />
                            },
                            {
                                path: "code",
                                element: <VerificationEmailSignInRoute element={<VerificationCode/>} />
                            },
                            {
                                path: "code/:email/:code",
                                element: <VerificationEmailSignInRoute element={<VerificationCode/>} />
                            },
                            {
                                path: "check",
                                element: <VerificationEmailSignInRoute element={<VerificationCheck/>} />
                            }
                        ]
                    },
                    {
                        path: "partner",
                        errorElement: <NotFound />,
                        children: [
                            {
                                path: "",
                                element: <MainRouteSignIn element={<SigninPartner/>} />
                            },
                            {
                                path: "check",
                                element: <MainRouteSignIn element={<SignInCheck/>} />
                            }
                        ]
                    }
                ]
            },
            {
                path: "privacy-policy",
                element: <ConfidentialPolicy />
            },
            {
                path: "legals-mentions",
                element: <LegalMentions />
            },
            {
                path: "usage-policy",
                element: <CGU />
            },
            {
                path: "faq",
                element: <FAQ />
            },
            {
                path: "subscription-policy",
                element: <SubscriptionPolicy />
            },
            {
                path: "*",
                element: <NotFound />
            },
        ]
    },

]);

export default routes;
