import React, { useEffect, useRef } from 'react';

const InputCode = ({ onChange, index, code, focusNextInput }) => {
    const inputRef = useRef(null);

    useEffect(() => {
        if (focusNextInput && inputRef.current) {
            inputRef.current.focus();
        }
    }, [focusNextInput]);

    const handleOnChange = (e) => {
        const inputValue = e.target.value;
        // Allow both erasing and changing of the input value
        if (inputValue.length === 0 || /^[0-9]$/.test(inputValue)) {
            onChange(e, index);
        }
    };

    return (
        <input
            ref={inputRef}
            type="text" // Allow changes to the entered value
            value={code}
            onChange={handleOnChange}
            maxLength={1}
            className="input-code"
        />
    );
};

export default InputCode;
