import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router";
import HeaderView from "../../components/header/headerView";
import Form from "../../components/form/form";
import TabBar from "../../components/navbar/tabBar";
import '../../styles/views/singleOffer.css';
import Activity from "./formOffer/activity";
import Name from "./formOffer/name";
import Validity from "./formOffer/validity";
import ActivitiesServices from "../../api/services/activities";
import OffersServices from "../../api/services/offers";
import useParseOffer from "../../hooks/parseDataApi/useParseOffer";
import Modalities from "./formOffer/modalities";
import useFormState from "../../hooks/form/useFormUpdate";
import useFormValidation from "../../hooks/form/useFormValidation";
import Button from "../../components/button/button";
import ModalVerification from "../../components/modal/modalInfos/modalVerification";
import {ErrorContext} from "../../context/errorContext";
import {useLoader} from "../../context/loaderContext";
import Conditions from "./formOffer/conditions";
import RadioRound from "../../components/form/inputRadio/radioRound";
import HelpCard from "../../components/card/helpCard";
import LastMinute from "./formOffer/lastMinute";


const SingleOffers = () => {
    const [refresh, setRefresh] = useState(false);
    const partnerId = useParams().partnerId;
    const offerId = useParams().offerId;
    const [isOfferValid, setIsOfferValid] = useState(false);
    const [activities, setActivities] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { parseOffer, parseOfferforUpdate } = useParseOffer();
    const { validateForm } = useFormValidation();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const { formState, setFormState, handleFormChange, getFormChanges } = useFormState(parseOffer([]));

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                // Fetch activities
                const activitiesResponse = await ActivitiesServices.getActivitiesByPartnerId(partnerId);
                if (activitiesResponse.status === 200) {
                    setActivities(activitiesResponse.data);
                }

                // Fetch offer by ID
                const offerResponse = await OffersServices.getOfferById(offerId);
                if (offerResponse.status === 200) {
                    setFormState(parseOffer(offerResponse.data));

                    const offerStatus = offerResponse.data.status;
                    if (["active", "waiting", "inactive"].includes(offerStatus)) {
                        setIsOfferValid(true);
                    }
                }

            } catch (error) {
                showError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [refresh]);


    const handleSubmit = (e) => {
        e.preventDefault();
        const isFormValid = validateForm(formState, setFormState);
        if(isFormValid.isFormValid){
            setLoading(true);
            const newOfferChanges = getFormChanges();
            const offerForApi = parseOfferforUpdate(newOfferChanges);

            OffersServices.updateOffer(offerId, offerForApi)
            .then((response) => {
                if (response.status === 200) {
                    setIsModalOpen(true);
                }
            }).catch((error) => {
                showError(error);
            }).finally(() => {
                setLoading(false);

            });
        }
    };

    return activities && (
        <div className={"main-container is__grey"}>
            <HeaderView title={"Ajouter une offre"} />

            <TabBar
                tabs={[
                    {
                        name: "Fiche du partenaire",
                        link: `/partners/${partnerId}`
                    },
                    {
                        name: "Liste d’activités",
                        link: `/partners/${partnerId}/activities`,
                        index: formState.activities ? formState.activities.length : 0
                    },
                    {
                        name: "Gérer vos offres",
                        link: `/partners/${partnerId}/offers`
                    },
                    {
                        name: "Plan tarifaire",
                        link: `/partners/${partnerId}/subscription`,
                    }
                ]}
                activeTab={2}
            />

            <div className={"main-offer__container offer"}>
                <Form
                    children={
                        formState && formState.activities !== {} && formState.name !== {} && formState.modalities !== {} && formState.length !== {} && formState.validity !== {} && formState.conditions !== {} &&
                        <>
                            <LastMinute isLastMinute={formState.isLastMinute.value} setOffer={handleFormChange} isDisabled={true}/>
                            <Activity activities={activities} setOffer={handleFormChange}
                                      offerActivity={formState.activities.value} isDisabled={true}
                                      errorEmpty={formState.activities.error} isDisabled={true}/>
                            <Name name={formState.name} setOffer={handleFormChange} isDisabled={true}/>
                            <Conditions conditionsList={formState.conditions} setOffer={handleFormChange}
                                        offerActivity={formState.activities.value} isDisabled={true}/>
                            <Modalities modalities={formState.modalities} setOffer={handleFormChange}
                                        isDisabled={true}/>
                            <Validity validity={formState.validity} isLastMinute={formState.isLastMinute.value} setOffer={handleFormChange} isDisabled={true}/>
                            <div className={"form-section__row"}>
                                <div className={"form__col__input__container__radio form__col  form__signin__col"}>
                                    <p className={"title-14"}>Confirmation de création d'offre <span
                                        className={"is__required"}>*</span></p>
                                    <p className={"text-14"}>Assurez-vous de bien comprendre et d'accepter les termes de
                                        votre offre promotionnelle. Cette confirmation est essentielle pour maintenir la
                                        qualité et la conformité de nos services sur Frimity</p>
                                    <div className="form__col radio">
                                        <RadioRound
                                            label={"En cochant cette case, je confirme avoir créé cette offre, je comprends les termes et m'engage à respecter les règles de Frimity, assurant l'exactitude et la conformité des informations fournies."}
                                            name={"insurance"}
                                            value={"accept"}
                                            checked={true}
                                            isLabel={false}
                                            onChange={(e) => {}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    mode={"edit"}
                    onSubmit={handleSubmit}
                    isSubmitDisabled={true}
                    isDisabled={true}
                />

                <HelpCard text={"Si vous avez la moindre question ou besoin d'aide, n'hésitez pas à nous contacter. Nous sommes disponibles 24/7 pour vous offrir le support dont vous avez besoin."}/>
            </div>
            <ModalVerification isOpen={isModalOpen} link={`/partners/${partnerId}/offers`} type={"offre"} action={"modifiée"} />
        </div>
    )
}

export default SingleOffers;