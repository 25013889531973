import InputSelect from "../../../../components/form/inputSelect/inputSelect";
import React, { useEffect, useState } from "react";
import timer from "../../../../assets/icones/global/time_circle_grey.svg";
import close from "../../../../assets/icones/global/close-stroke.svg";
import add from '../../../../assets/icones/global/add.svg';
import less from '../../../../assets/icones/global/minus.svg';

const TimeSlotHours = ({ timeSlotHours, setTimeSlotHours, activityDuration, activityTimeGap }) => {
    const [rows, setRows] = useState([{
        startHour: null,
        startMinute: null,
        endHour: null,
        endMinute: null,
        slots: []
    }]);

    const [error, setError] = useState(false);

    // Function to handle input changes for each row
    const handleInputChange = (index, name, value) => {
        setRows(currentRows => {
            const updatedRows = [...currentRows];
            // Ensure that we are only updating the field that has changed
            // This spread operation ensures that other values are not overwritten unintentionally
            updatedRows[index] = {
                ...updatedRows[index],
                [name]: value
            };
            return updatedRows;
        });
    };


    //change row slots if activityDuration or activityTimeGap changes
    useEffect(() => {

        rows.forEach((row, index) => {
            const startHour = parseInt(row.startHour, 10);
            const startInMinutes = parseInt(row.startHour, 10) * 60 + parseInt(row.startMinute, 10);

            const endHour = parseInt(row.endHour, 10);
            const endInMinutes = parseInt(row.endHour, 10) * 60 + parseInt(row.endMinute, 10);

            if (startInMinutes && endInMinutes && startInMinutes >= endInMinutes) {
                setError(true);
                return;
            }else{
                setError(false);
                generateSlotsForRow(index, row);
            }
        });
    }, [activityDuration, activityTimeGap, rows]);

    // Function to add a new row
    const addRow = () => {
        setRows([...rows, { startHour: null, startMinute: null, endHour: null, endMinute: null, slots: [] }]);
    };

    // Function to remove a row
    const removeRow = (index) => {
        const newRows = [...rows];
        newRows.splice(index, 1);
        setRows(newRows);

        const updatedTimeSlots = newRows.flatMap(row => row.slots);
        setTimeSlotHours(`generatedTimeSlots.value`, updatedTimeSlots);
    };

    const removeTimeSlot = (rowIndex, slotIndex) => {
        const updatedRows = [...rows];
        updatedRows[rowIndex].slots.splice(slotIndex, 1);
        setRows(updatedRows);

        const updatedTimeSlots = updatedRows.flatMap(row => row.slots);
        setTimeSlotHours(`generatedTimeSlots.value`, updatedTimeSlots);
    };

    // Function to generate slots for a specific row
    const generateSlotsForRow = (index, row) => {
        let newSlots = [];
        let currentStartHour = parseInt(row.startHour, 10);
        let currentStartMinute = parseInt(row.startMinute, 10);
        const endTimeInMinutes = parseInt(row.endHour, 10) * 60 + parseInt(row.endMinute, 10);

        const [hoursPart, minutesPart] = activityDuration.split('h').map(num => parseInt(num, 10));
        const durationMinutes = hoursPart * 60 + minutesPart;
        const slotDuration = durationMinutes + activityTimeGap;

        while ((currentStartHour * 60 + currentStartMinute + durationMinutes) <= endTimeInMinutes) {
            let endHour = Math.floor((currentStartHour * 60 + currentStartMinute + durationMinutes) / 60);
            let endMinute = (currentStartHour * 60 + currentStartMinute + durationMinutes) % 60;

            let startString = `${currentStartHour.toString().padStart(2, '0')}:${currentStartMinute.toString().padStart(2, '0')}`;
            let endString = `${endHour.toString().padStart(2, '0')}:${endMinute.toString().padStart(2, '0')}`;

            newSlots.push({
                startTime: startString,
                endTime: endString
            });

            currentStartHour = Math.floor((currentStartHour * 60 + currentStartMinute + slotDuration) / 60);
            currentStartMinute = (currentStartHour * 60 + currentStartMinute + slotDuration) % 60;
        }

        const updatedRows = [...rows];
        updatedRows[index].slots = newSlots;
        setRows(updatedRows);

        const updatedTimeSlots = updatedRows.flatMap(row => row.slots);
        setTimeSlotHours(`generatedTimeSlots.value`, updatedTimeSlots);
    };

    return (
        <div className="form-group__schedule_time_container">
            {rows.map((row, index) => (
                <>
                    <div className="form-group__schedule_time">
                        <div className={"form-group__select-hours"}>
                            <div className="container__column__schedule_time">
                                <div className={"form-group__row__schedule_time"}>
                                    {/* Time input selectors */}
                                    <div className="container__column__schedule_time">
                                        <div className={"form-group__row__label"}>
                                            <p className={"text-14"}>Heure du début</p>
                                        </div>
                                        <div className={"form-group__row__schedule_time__input_row"}>

                                            <InputSelect
                                                name="startHour"
                                                subtitle="Heure"
                                                options={Array.from({length: 18}, (_, i) => ({
                                                    id: i + 6,
                                                    value: `${i + 6}`.padStart(2, '0'),
                                                    name: `${i + 6}`.padStart(2, '0')
                                                }))}
                                                valueSelected={row.startHour}  // Use an empty string as fallback if null
                                                onChange={(e) => {
                                                    handleInputChange(index, e.target.name, e.target.value);

                                                }}
                                            />
                                            <InputSelect
                                                name="startMinute"
                                                subtitle="Minute"
                                                options={Array.from({length: 60}, (_, i) => ({
                                                    id: i,
                                                    value: `${i}`.padStart(2, '0'),
                                                    name: `${i}`.padStart(2, '0')
                                                }))}
                                                valueSelected={row.startMinute}
                                                onChange={(e) => {
                                                    handleInputChange(index, e.target.name, e.target.value);

                                                }}
                                            />
                                        </div>
                                    </div>
                                    <p className={"text-14 light"} style={{marginTop: 10}}>-</p>
                                    <div className="container__column__schedule_time">
                                        <div className={"form-group__row__label"}>
                                            <p className={"text-14"}>Heure de fin</p>
                                        </div>
                                        <div className={"form-group__row__schedule_time__input_row"}>
                                            <InputSelect
                                                name="endHour"
                                                subtitle="Heure"
                                                options={Array.from({length: 18}, (_, i) => ({
                                                    id: i + 6,
                                                    value: `${i + 6}`.padStart(2, '0'),
                                                    name: `${i + 6}`.padStart(2, '0')
                                                }))}
                                                valueSelected={row.endHour}
                                                onChange={(e) => {
                                                    handleInputChange(index, e.target.name, e.target.value);

                                                }}
                                            />
                                            <InputSelect
                                                name="endMinute"
                                                subtitle="Minute"
                                                options={Array.from({length: 60}, (_, i) => ({
                                                    id: i,
                                                    value: `${i}`.padStart(2, '0'),
                                                    name: `${i}`.padStart(2, '0')
                                                }))}
                                                valueSelected={row.endMinute}
                                                onChange={(e) => {
                                                    handleInputChange(index, e.target.name, e.target.value);

                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="container__button__actions">
                                        {index > 0 ?
                                            <button type="button" className={"button_dateRanges"}
                                                    onClick={() => removeRow(index)}>
                                                <img src={less} alt="Remove date range"/>
                                            </button>
                                            : null}
                                        <button type="button" className={"button_dateRanges primary"}
                                                onClick={() => addRow()}>
                                            <img src={add} alt="Add date range"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            error && <p className={"text-14 is__error"}>L'heure de début doit être inférieure à l'heure de fin</p>
                        }
                        <div className="container__generate__hours">
                            <p className={"text-14 light"}>Créneau(x) généré(s)</p>
                            <div className="time-slot__container__wrapper">
                                {row.slots.map((slot, slotIndex) => (
                                    <div key={slotIndex} className="time-slot__container">
                                        <img src={timer} alt="timer"/>
                                        <img src={close} alt="close" className="close" onClick={() => removeTimeSlot(index, slotIndex)}/>
                                        {slot.startTime} - {slot.endTime}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            ))}
        </div>
    );
};

export default TimeSlotHours;
