import React from "react";
const Checkbox = ({ label, name, value, isIconLeft = false, icon = false, isChecked = false, disabled = false, onChange, errorEmpty = false }) => {

    const onChangeForm = (event) => {
        // Directly call the passed in onChange handler
        onChange(event); // The parent component will manage the state and pass down the new isChecked prop
    }

    return (
        <div className={`checkbox-container ${isChecked ? "checked" : ""} ${errorEmpty ? 'error' : null} ${disabled ? "disabled" : ""}`}>
            {icon && isIconLeft && <img src={icon} alt={label} />}
            <input
                type="checkbox" // Changed from type="radio" to type="checkbox"
                name={name}
                value={value}
                checked={isChecked} // Controlled by the parent component
                onChange={onChangeForm} // Call the onChange handler when the input value changes
                disabled={disabled} // Controlled by the parent component
            />
            {icon && !isIconLeft && <img src={icon} alt={label} />}
            <label>{label}</label>
        </div>
    );
};

export default Checkbox;
