import {getRequest, postRequestJson, patchRequest, deleteRequest} from '../request/apiRequest';

const PayementServices = {

    getSavedPaymentMethods: async (data) => {
        return await postRequestJson(`/stripe/payement/get-saved-payment-methods`, data);
    },

    createDefaultSetupIntent: async (data) => {
        return await postRequestJson(`/stripe/payement/create-default-setup-intent`, data);
    },

    createSetupIntent: async (data) => {
        return await postRequestJson(`/stripe/payement/create-setup-intent`, data);
    },

    createPayementIntent: async (data) => {
        return await postRequestJson(`/stripe/payement/create-payement-intent`, data);
    },

    attachPaymentMethod: async (data) => {
        return await postRequestJson(`/stripe/payement/attach-payment-method`, data);
    },

    updatePaymentMethod: async (data) => {
        return await postRequestJson(`/stripe/payement/update-payment-method`, data);
    },

    deletePaymentMethod: async (data) => {
        return await postRequestJson(`/stripe/payement/delete-payment-method`, data);
    }
}

export default PayementServices;