import React, {useEffect, useState, useContext} from 'react';
import HeaderView from "../../components/header/headerView";
import IconButton from "../../components/button/iconButton";
import add from "../../assets/icones/global/add.svg";
import '../../styles/views/users.css'
import trash from "../../assets/icones/global/trash_can.svg";
import UsersServices from "../../api/services/users";
import FilterTable from "../../components/table/filterTable";
import Table from "../../components/table/table";
import useFilter from "../../hooks/useFilter";
import useOpenModal from "../../hooks/useOpenModal";
import Button from "../../components/button/button";
import SidebarUser from "../../components/sidebar/sidebarUser";
import {Link} from "react-router-dom";
import ModalDeleting from "../../components/modal/modalDeleting/modalDeleting";
import Avatar from "../../components/avatar/avatar";
import {ErrorContext} from "../../context/errorContext";
import {useLoader} from "../../context/loaderContext";
import close from "../../assets/icones/global/close-stroke.svg";

const Users = () => {

    const [refresh, setRefresh] = useState(false);
    const [filterList, setFilterList] = useState([]);

    const [users, setUsers] = useState([]);
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();


    const [openModalAdd ,setOpenModalAdd] = useState(false);

    const [isModalOpen, openModal, closeModal] = useOpenModal(users.map(() => false));
    const [isModalOpenDelete, openModalDelete, closeModalDelete] = useOpenModal(users.map(() => false));

    const { updateFilters, filteredData } = useFilter([], users, (user, filters) => {
        return filters.length === 0 || filters.includes(user.role);
    });

    useEffect(() => {
        setLoading(true);
        UsersServices.getUsers()
            .then((response) => {
                if(response.status === 200){
                    setUsers(response.data.users);
                    setRefresh(false);
                    setFilterList([...new Set(response.data.users.map((user) => user.role))]);
                }
            })
            .catch((error) => {
                showError(error);
            }).finally(() => {
                setLoading(false);
            });
    }, [refresh]);

    const handleDelete = (id) => {
        setLoading(true);
        UsersServices.deleteUser(id)
        .then((response) => {
            if(response.status === 200){
                setRefresh(true);
            }
        })
        .catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        });
    }
    return filteredData && (
        <div className={"main-container"}>
            <HeaderView title={"Utilisateurs"} isGoBack={false} actions={
                <>
                    <IconButton icon={add} text={"Ajouter un utilisateur"} isPrimary={false} onClick={() => setOpenModalAdd(true)}/>
                </>
            }/>

            <div className={"main-infos__container"}>
                <div className={"main-infos__container__col"}>
                    <h2 className={"title-18"}>Liste des utilisateurs du dashboard</h2>
                    <p className={"text-14"}>Vous pouvez voir et modifier ici les informations des utilisateurs du
                        dashboard</p>
                </div>
            </div>

            <div className={"main-container__row list"}>
                {
                    filterList && filterList.length > 0 ?
                        <FilterTable
                            filterArray={filterList}
                            onFilterChange={updateFilters}
                            typeValue={"users"}
                            searchData={users}
                            isFilter={false}
                        />
                        :
                        null
                }
                {
                    filteredData && filteredData.filter((user) => user.role === "partner" || user.role === "partners-admin").length > 0 ?
                        <Table
                            columns={[
                                {name: "Avatar", align: "left", width: "10%", field: "avatar"},
                                {name: "Nom/Prénom", align: "left", width: "15%", field: "name"},
                                {name: "Email", align: "left", width: "25%", field: "email"},
                                {name: "Téléphone", align: "left", width: "15%", field: "phoneNumber"},
                                {name: "Partenaire", align: "left", width: "15%", field: "partner"},
                                {name: "Actions", align: "center", width: "20%", style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }, field: "actions"
                                }
                            ]}

                            data={
                                filteredData && filteredData.length > 0 ?
                                    filteredData.filter((user) => user.role === "partner" || user.role === "partners-admin").map((user, index) => {
                                            return {
                                                avatar: <Avatar avatarURL={user.userAvatarID ? user.userAvatarID.avatarURL : null} name={user.firstname}/>,
                                                name: user.firstname + " " + user.lastname,
                                                email: user.email,
                                                phoneNumber: user.phoneNumber ? user.phoneNumber : "Aucun numéro",
                                                partner: user.partnerID ?
                                                    <div className={"form__group__tag"}>
                                                        {user.partnerID .map((partner) => {
                                                                return <div className={"form__group__tag__item"} key={partner._id}><p>{partner.name}</p></div>
                                                            })
                                                        }
                                                    </div>
                                                    : "Aucun partenaire",
                                                actions: (
                                                    <div className={"actions__row"} key={index}>
                                                        <Button text={"Voir plus"} isPrimary={false}
                                                                onClick={() => openModal(index)}></Button>
                                                        <IconButton icon={trash} isRed={true}
                                                                    onClick={() => openModalDelete(index)}/>
                                                        <ModalDeleting isOpen={isModalOpenDelete[index]}
                                                                       closeModal={() => closeModalDelete(index)}
                                                                       deleteItem={() => handleDelete(user._id)}
                                                                       itemName={user.firstname + " " + user.lastname}/>
                                                        <SidebarUser setRefresh={setRefresh} refresh={refresh}
                                                                     isModalOpen={isModalOpen[index]}
                                                                     closeModal={() => closeModal(index)}
                                                                     openModal={() => openModal(index)} user={user}
                                                                     type={"edit"}/>
                                                    </div>
                                                )
                                            }
                                        }
                                    ).reverse() : []
                            }
                        />
                        :
                        <div className={"container__empty"}>
                            <p className={"text-14"}>Aucun utilisateur n'a été ajouté pour le moment</p>
                            <Button text={"Ajouter un utilisateur"} isPrimary={true} onClick={() => setOpenModalAdd(true)}/>
                        </div>
                }
            </div>
            <SidebarUser setRefresh={setRefresh} isModalOpen={openModalAdd} closeModal={() => setOpenModalAdd(false)} type={"add"} user={null}/>
        </div>
    );
}
export default Users;