import React from 'react';

const pastelColorMap = {
    A: "#fff3ca", B: "#FFE5DB", C: "#C8F6E9", D: "#E1DDE4",
    E: "#FFEDD9", F: "#fff3ca", G: "#F4D9D0", H: "#EDEDED",
    I: "#FFDFE0", J: "#D5F4E5", K: "#FFE4C5", L: "#E6E6E6",
    M: "#CDE4D9", N: "#EFD9EB", O: "#DFF4E3", P: "#C9EDE7",
    Q: "#FFE5DB", R: "#C8F6E9", S: "#E1DDE4", T: "#FFEDD9",
    U: "#C9EDE7", V: "#F4D9D0", W: "#EDEDED", X: "#FFDFE0",
    Y: "#D5F4E5", Z: "#FFE4C5"
};

const useAvatarBackground = (letter) => {
    const upperCaseLetter = letter.toUpperCase();
    return pastelColorMap[upperCaseLetter] || 'var(--neutral-grey-200)'
};

export default useAvatarBackground;
