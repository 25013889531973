import React, { useState } from 'react';
import Button from "../button/button";
import '../../styles/components/table.css';
import waitingTime from "../../assets/icones/global/time_circle.svg";

const Table = ({ columns, data, rowClickable = false, onRowClick = () => {} }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Default value

    const totalPages = Math.ceil(data.length / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = data.slice(startIndex, endIndex);

    const handlePrevious = () => {
        setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
    };

    const handleNext = () => {
        setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages);
    };

    const handleFirst = () => {
        setCurrentPage(1);
    };

    const handleLast = () => {
        setCurrentPage(totalPages);
    };

    const handlePageInput = (e) => {
        const page = Math.min(Math.max(Number(e.target.value), 1), totalPages);
        setCurrentPage(page);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to first page whenever items per page change
    };

    return (
        <div className={"table-container"}>
            <table className={"main-table"}>
                <thead>
                <tr>
                    {columns.map((column, index) => (
                        <th key={index} style={{maxWidth: column.width, textAlign: column.align}}>
                            {column.name}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {currentData.map((row, rowIndex) => {
                    const rowIsClickable = row.rowClickable !== false; // Default to true unless explicitly set to false
                    return (
                        <tr
                            key={rowIndex}
                            className={`${row.hasOwnProperty('isVerified') && !row.isVerified ? "table-row__disabled" : ""} ${rowIsClickable ? "clickable-row" : ""}`}
                            onClick={() => rowIsClickable && onRowClick(row, rowIndex)}
                        >
                            {columns.map((column, columnIndex) => {
                                const hasVerified = row.hasOwnProperty('isVerified');
                                const cellData = row[column.field];
                                return (
                                    <td
                                        key={columnIndex}
                                        style={{maxWidth: column.width, textAlign: column.align, ...column.style}}
                                    >
                                        <div className="table__item" style={{...column.styleTableItem}}>
                                            {!hasVerified ? (
                                                cellData
                                            ) : hasVerified && row.isVerified ? (
                                                cellData
                                            ) : hasVerified && !row.isVerified && column.field === "actions" ? (
                                                null
                                            ) : hasVerified && !row.isVerified && column.field === "link" ? (
                                                <img src={waitingTime} alt={"waiting"}/>
                                            ) : (
                                                cellData
                                            )}
                                        </div>
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
                </tbody>
                {totalPages > 1 && (
                    <div className="pagination">
                        <div className={"pagination__button"}>
                            <Button text={"Début"} onClick={handleFirst}/>
                        </div>
                        <div className={"pagination__group"}>
                            <div className={"pagination__button width-100"}>
                                <Button text={"Précédent"} onClick={handlePrevious}/>
                            </div>

                            <div className={"pagination__group__item"}>
                                <input
                                    type="number"
                                    value={currentPage}
                                    onChange={handlePageInput}
                                    className={"pagination__group__item__input"}
                                    min="1"
                                    max={totalPages}

                                />
                                <p className={"text-14"}> / {totalPages}</p>
                            </div>
                            <div className={"pagination__button"}>
                                <Button text={"Suivant"} onClick={handleNext}/>
                            </div>
                        </div>
                        <div className={"pagination__button last"}>
                            <Button text={"Fin"} onClick={handleLast}/>
                        </div>
                    </div>
                )}
            </table>

            <div className="items-per-page">
                <label htmlFor="itemsPerPageSelect">affiché</label>
                <select
                    id="itemsPerPageSelect"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={200}>200</option>
                    <option value={500}>500</option>
                </select>
                <label htmlFor="itemsPerPageSelect">éléments par page</label>
            </div>
        </div>
    );
};

export default Table;
