import React, {useState, useEffect, useContext} from 'react';
import mail from '../../../assets/images/svg/mail_round_yellow.svg';
import Button from "../../../components/button/button";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import arrow from '../../../assets/icones/arrows/left.svg';
import '../../../styles/views/verifications.css';
import InputCode from "../../../components/form/inputText/InputCode";
import {useParams} from "react-router";
import {ErrorContext} from "../../../context/errorContext";
import {useLoader} from "../../../context/loaderContext";
import PasswordResetServices from "../../../api/services/passwordReset";

const PasswordUserCode = () => {

    const emailUrl = useParams().email;
    const codeUrl = useParams().code;
    const [code, setCode] = useState(Array(4).fill(""));
    const [focusIndex, setFocusIndex] = useState(0);
    const {checkPasswordCode, sendPasswordCode} = PasswordResetServices;
    const navigate = useNavigate();
    const {showError} = useContext(ErrorContext);
    const {setLoading} = useLoader();
    const [isEmailSent, setIsEmailSent] = useState(false);


    const handleChange = (e, index) => {
        const inputCode = e.target.value;
        setCode((prevState) => {
            const newState = [...prevState];
            newState[index] = inputCode;
            return newState;
        });
        if (inputCode && index < 3) {
            setFocusIndex(index + 1);
        }
    };

    useEffect(() => {
        if (emailUrl && codeUrl) {
            // Automatically submit the verification if emailUrl and codeUrl are present
            autoSubmitVerification(emailUrl, codeUrl);
        }
    }, [emailUrl, codeUrl]);

    useEffect(() => {
        const inputRefs = Array.from({ length: 4 }, () => React.createRef());
        const handleKeyPress = (event) => {
            if (event.key >= 0 && event.key <= 9) {
                const index = parseInt(event.key);
                if (focusIndex < 4) {
                    setCode((prevState) => {
                        const newState = [...prevState];
                        newState[focusIndex] = index.toString();
                        return newState;
                    });
                    setFocusIndex(focusIndex + 1);
                }
            }
        };

        window.addEventListener("keypress", handleKeyPress);

        return () => {
            window.removeEventListener("keypress", handleKeyPress);
        };
    }, [focusIndex]);

    const handleSubmit = () => {
        setLoading(true);
        const codeString = code.join("");

        const data = {
            email: emailUrl,
            code: codeString
        }

        checkPasswordCode(data)
        .then((response) => {
            if (response.status === 200 || response.status === 201){
                localStorage.setItem('ephemeralToken', response.data.ephemeralToken);
                navigate('/password-reset/reset');
            }
        }).catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    const autoSubmitVerification = (email, code) => {
        setLoading(true);
        const data = {
            email: email,
            code: code,
        }
        checkPasswordCode(data)
            .then(response => {
                if (response.status === 200 || response.status === 201){
                    localStorage.setItem('ephemeralToken', response.data.ephemeralToken);
                    navigate('/password-reset/reset');
                }
            })
            .catch(error => {
                showError(error);
            }).finally(() => {
                setLoading(false);
            });
    };

    const handleResend = () => {
        setLoading(true);

        const data = {
            email: emailUrl,
        }

        sendPasswordCode(data)
        .then((response) => {
            if (response.status === 200) {
                setIsEmailSent(true);
            }
        }).catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className="main verification">
            <div className="main__container__col password-half">
                <div className="main__container__col32">
                    <img src={mail} alt="logo"/>
                </div>
                <div className="main__container__col32">
                    <h1 className={"title-25"}>Vérifiez votre email</h1>
                    <p className={"text-14 light"}>Nous avons envoyé un code de vérification à {emailUrl}</p>
                </div>

                <div className="main__container__col32 digit_code">
                    {
                        code.map((value, index) => (
                            <InputCode
                                key={index}
                                onChange={handleChange}
                                index={index}
                                code={code[index]}
                                focusNextInput={focusIndex === index}
                            />
                        ))
                    }
                </div>

                <div className="main__container__col32">
                    <Button text={"Vérifier"} isPrimary={true}
                            isBig={true} onClick={handleSubmit} />
                </div>
                <div className="main__container__col32" onClick={handleResend}>
                    {
                        isEmailSent ?
                            <p className={"text-14 light"}>L'email a été renvoyé à {emailUrl}</p>
                            :
                            <p className={"text-14 light pointer"}>Vous n'avez pas reçu l'email ? <span className={"text-14 bold"}>Cliquez pour renvoyer</span></p>
                    }
                </div>
                <div className="main__container__col32  action_row">
                    <img src={arrow} alt="arrow"/>
                    <Link to={"/login"} className={"text-14 bold"}>Retour à l'accueil</Link>
                </div>
            </div>
        </div>
    );
}

export default PasswordUserCode;