import InputSelect from "../../../../components/form/inputSelect/inputSelect";


const AvailabilityType = ({ setAvailabilityType, availabilityType }) => {

    const checkBoxType = [
        {id: "openDates", name: "Ouvrir une plage de dates", subtitle: "Choisissez les dates pour lesquelles vous souhaitez ouvrir des créneaux horaires."},
        {id: "openAllTime", name: "Ouvrir des créneaux horaires pour toutes les dates", subtitle: "Choisissez les horaires pour lesquelles vous souhaitez ouvrir des créneaux horaires."}
    ];

    const handleChange = (e) => {
        setAvailabilityType(`availabilityType.value`, e.target.value);
    }

    return(
        <div className="sidebar__form__container__column">
            <div className="sidebar__form__label is__full__width">
                <p className="text-16 bold">Type de disponibilité</p>
                <p className="text-14">Choisissez le type de disponibilité que vous souhaitez ouvrir pour cette
                    activité.</p>
            </div>
            <div className="sidebar__form__content is__content__col">
                {
                    checkBoxType.map((type) => (
                        <div className="form__row checkbox">
                            <input type="checkbox" className={availabilityType.error ? 'error' : null} name={type.id} id={type.id} checked={availabilityType.value === type.id}
                                   onClick={handleChange} value={type.id} />
                            <div className="form__col__radio">
                                <label htmlFor={type.id} className="text-14">{type.name}</label>
                                <p className="text-12 grey">{type.subtitle}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default AvailabilityType;