import React from 'react';
import RadioRound from "../../../components/form/inputRadio/radioRound";
const Insurance = ({insurance, isUpdated, setIsUpdated, setActivity, errorIsUpdated}) => {

    const handleChange = (event) => {
        event.preventDefault();

        setActivity(`hasInsurance.value`, true);
    }


    return (
        <div className={"form-section__row"}>
            <div className={"form__col__input__container__radio gap-25"}>
                <div className="form__col no-gap">
                    <p className={"title-14"}>Informations de l'activité et Contrat d'assurance responsabilité
                        civile <span className={"is__required"}>*</span></p>
                    <p className={"text-14"}>En cliquant ci-dessous, je confirme avoir lu et accepté les conditions
                        d'engagements énoncés ci-dessus.</p>
                </div>

                <div className="form__col radio no-gap">
                    <RadioRound
                        name={"insurance"}
                        id={"insurance"}
                        value={insurance.value}
                        checked={insurance.value}
                        onChange={handleChange}
                        isLabel={false}
                        error={insurance.error}
                        label={"En cochant cette case, je confirme qu'une assurance responsabilité civile générale couvrant les risques liés à l'activité et aux services fournis est maintenue."}
                    />

                    <RadioRound
                        name={"isUpdated"}
                        id={"isUpdated"}
                        value={isUpdated}
                        checked={isUpdated}
                        onChange={(e) => setIsUpdated(e.target.checked)}
                        isLabel={false}
                        error={errorIsUpdated}
                        label={"En cochant cette case, je confirme que toutes les informations relatives à cette activité sont exactes et à jour."}
                    />
                </div>
            </div>
        </div>
    )

}

export default Insurance;