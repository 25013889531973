import React, { useState, useEffect, useMemo, useCallback } from 'react';
import HeaderView from "../../components/header/headerView";
import '../../styles/views/partners.css';
import AnalyticsSection from "../../components/analytics/analyticsSection";
import BookingSection from "../../components/analytics/bookingSection";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import AnalyticsServices from '../../api/services/analytics';
import InputSelect from "../../components/form/inputSelect/inputSelect";
import calendarIcon from "../../assets/icones/global/calendar.svg";
import IconButton from "../../components/button/iconButton";
import SingleDateRangePicker from "../../components/calendar/singleView/singleDateRangePicker";

// Helper function to process the raw data from the API response
const processData = (data) => {
    const result = {
        booking: [],
        call: [],
        mapClick: [],
        websiteClick: []
    };

    data.forEach(item => {
        const date = new Date(item._id.date).toISOString(); // Preserve full date and time
        const actionType = item._id.actionType;

        if (result[actionType]) {
            result[actionType].push({
                date,
                count: item.count
            });
        }
    });

    return result;
};

// Helper function to get the start and end dates based on the selected period
const getDateRange = (period) => {
    const today = new Date();
    let startDate, endDate;

    switch (period) {
        case 'yesterday':
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);
            yesterday.setUTCHours(0, 0, 0, 0);
            startDate = yesterday.toISOString();
            yesterday.setUTCHours(23, 59, 59, 999);
            endDate = yesterday.toISOString();
            break;

        case 'weekly':
            const startOfWeek = new Date(today);
            startOfWeek.setDate(today.getDate() - today.getDay() + 1);
            startDate = startOfWeek.toISOString().split('T')[0];
            endDate = today.toISOString().split('T')[0];
            break;

        case 'monthly':
            startDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1, 0, 0, 0));
            endDate = new Date(Date.UTC(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59)); // Last day of the current month
            break;

        case 'quarterly':
            const currentQuarter = Math.floor((today.getMonth() + 3) / 3);
            startDate = new Date(today.getFullYear(), (currentQuarter - 1) * 3, 1).toISOString().split('T')[0];
            endDate = today.toISOString().split('T')[0];
            break;

        case 'yearly':
            startDate = new Date(today.getFullYear(), 0, 1).toISOString().split('T')[0];
            endDate = today.toISOString().split('T')[0];
            break;

        default:
            startDate = endDate = today.toISOString().split('T')[0];
            break;
    }

    return { startDate, endDate };
};

// Helper function to calculate the previous date range based on the current range
const calculatePreviousDateRange = (currentRange) => {
    const currentStart = new Date(currentRange.startDate);
    const currentEnd = new Date(currentRange.endDate);

    if (isNaN(currentStart.getTime()) || isNaN(currentEnd.getTime())) {
        return { startDate: null, endDate: null };
    }

    const daysDiff = Math.ceil((currentEnd - currentStart) / (1000 * 60 * 60 * 24));

    const previousStart = new Date(currentStart);
    previousStart.setDate(currentStart.getDate() - daysDiff);

    const previousEnd = new Date(currentEnd);
    previousEnd.setDate(currentEnd.getDate() - daysDiff);

    return {
        startDate: previousStart.toISOString().split('T')[0],
        endDate: previousEnd.toISOString().split('T')[0]
    };
};

const Analytics = () => {
    const { partnerId } = useParams();
    const [analyticsData, setAnalyticsData] = useState({});
    const [previousAnalyticsData, setPreviousAnalyticsData] = useState({});
    const user = useSelector((state) => state.auth.user);

    const [openCalendar, setOpenCalendar] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState('monthly');
    const [customDateRange, setCustomDateRange] = useState(null);

    const currentFilter = useMemo(() => customDateRange || getDateRange(selectedPeriod), [selectedPeriod, customDateRange]);
    const previousFilter = useMemo(() => calculatePreviousDateRange(currentFilter), [currentFilter]);

    const fetchAnalytics = useCallback(async () => {
        try {
            const currentData = await AnalyticsServices.getAnalytics({ partnerId, ...currentFilter });
            const processedCurrentData = processData(currentData.data);
            setAnalyticsData(processedCurrentData);

            const previousData = await AnalyticsServices.getAnalytics({ partnerId, ...previousFilter });
            const processedPreviousData = processData(previousData.data);
            setPreviousAnalyticsData(processedPreviousData);

        } catch (error) {
            console.error('Failed to fetch analytics data', error);
        }
    }, [partnerId, currentFilter, previousFilter]);

    useEffect(() => {
        fetchAnalytics();
    }, [fetchAnalytics]);

    return (
        <div className={"main-container"}>
            <HeaderView
                title={"Analyse de vos données"}
                isGoBack={false}
                actions={
                    <>
                        <div className={"analytics__filters"}>
                            <IconButton
                                icon={calendarIcon}
                                isPrimary={false}
                                text={"Définir la durée de votre offre"}
                                onClick={() => setOpenCalendar(!openCalendar)}
                            />
                            <div className={`analytics__filters__calendar ${openCalendar ? "open" : ""}`}>
                                <SingleDateRangePicker
                                    disabled={false}
                                    setDateSelected={(startDate, endDate) => {
                                        setCustomDateRange({ startDate, endDate });
                                        setOpenCalendar(false);
                                    }}
                                    arrayDates={[]}
                                    arrayDays={[]}
                                    isBooking={false}
                                    title={"Période personnalisée"}
                                    currentDateCard={false}
                                    disabledPastDates={false}
                                />
                            </div>
                        </div>
                        <InputSelect
                            options={[
                                { name: "Hier", value: "yesterday" },
                                { name: "Cette semaine", value: "weekly" },
                                { name: "Ce mois", value: "monthly" },
                                { name: "Ce trimestre", value: "quarterly" },
                                { name: "Cette année", value: "yearly" },
                            ]}
                            placeholder={"Sélectionner une période"}
                            valueSelected={selectedPeriod}
                            value={selectedPeriod}
                            onChange={(e) => {
                                setSelectedPeriod(e.target.value);
                                setCustomDateRange(null);
                            }}
                            isColumn={true}
                        />
                    </>
                }
            />
            <AnalyticsSection
                analyticsData={analyticsData}
                previousAnalyticsData={previousAnalyticsData}
            />
            <BookingSection
                previousBookingData={previousAnalyticsData.booking || []}
                bookingData={analyticsData.booking || []}
                selectedPeriod={selectedPeriod}
            />
        </div>
    );
};

export default Analytics;
