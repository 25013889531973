import React, {useState, useContext} from "react";
import Form from "../../../components/form/form";
import Input from "../../../components/form/inputText/input";
import Textarea from "../../../components/form/InputTextarea/textarea";
import {useSelector} from "react-redux";
import SupportServices from "../../../api/services/support";
import {ErrorContext} from "../../../context/errorContext";
import {useLoader} from "../../../context/loaderContext";

const FormSupport = ({setModalIsOpen}) => {

    const user = useSelector((state) => state.auth.user);
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();


    const [formState, setFormState] = useState({
        subject: "",
        firstname: user.firstname ? user.firstname : "",
        phone: user.phone ? user.phone : "",
        emailUser: user.email ? user.email : "",
        message: "",
        partner: user.partnerID ? user.partnerID.name : ""
    });

    const hanleChange = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        SupportServices.sendSupportPartnerEmail(formState)
        .then((response) => {
            if (response.status === 200) {
                setModalIsOpen(true);
            }
        })
        .catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        });

    }

    return(
        <Form
            children={
                <>
                    <Input
                        label={"Sujet"}
                        type={"text"}
                        name={"subject"}
                        placeholder={"Sujet"}
                        required={true}
                        valueInput={formState.subject}
                        onChangeForm={hanleChange}
                        validationType={"text"}
                        errorEmpty={""}
                        isColumn={true}
                    />
                    <Input
                        label={"Prénom"}
                        type={"text"}
                        name={"firstname"}
                        valueInput={formState.firstname}
                        placeholder={"Prénom"}
                        onChangeForm={hanleChange}
                        required={true}
                        validationType={"text"}
                        errorEmpty={""}
                        isColumn={true}
                    />
                    <Input
                        label={"Téléphone"}
                        type={"text"}
                        name={"phone"}
                        valueInput={formState.phone}
                        placeholder={"Téléphone"}
                        onChangeForm={hanleChange}
                        required={true}
                        validationType={"phone"}
                        errorEmpty={""}
                        isColumn={true}
                    />
                    <Input
                        label={"Email"}
                        type={"email"}
                        name={"emailUser"}
                        valueInput={formState.emailUser}
                        placeholder={"Email"}
                        onChangeForm={hanleChange}
                        required={true}
                        validationType={"email"}
                        errorEmpty={""}
                        isColumn={true}
                    />

                    <Textarea
                        title={"Votre message"}
                        name={"message"}
                        value={formState.message}
                        placeholder={"Maximum 300 caractères"}
                        required={true}
                        onChange={hanleChange}
                        validationType={"text"}
                        errorEmpty={""}
                        maxLength={500}
                    />
                </>
            }
            onSubmit={handleSubmit}
            mode={"send"}
        />
    )
}

export default FormSupport;