import React from 'react';

const FormSectionRow = ({children, infosSection, title, description, isRow, classname = "", classContainer= "", isRequired = false }) => {

    return(
        <div className={`form-section__line ${classContainer}`}>
            {infosSection}
            <div className={"form-section__row"}>
                <div className={"form-section__row__col textInfos"}>
                    <h2 className={"title-16"}>{title} {isRequired ? <span className={"is__required"}>*</span> : null}</h2>
                    <p className={"text-14"}>{description}</p>
                </div>
                <div className={`form-section__row__col formInfos ${isRow ? "isRow" : ""} ${classname}`}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default FormSectionRow;
