import React from 'react';
import '../../styles/components/stripe.css';
import '../../styles/components/form/form.css';
import RadioRound from "../form/inputRadio/radioRound";
import PayementServices from "../../api/services/payement";



const PayementCard = ({method, defaultMethod, customerId, setReload, setModalOpen}) => {

    const handleDefaultCard = (e) => {
        e.preventDefault();

        const data = {
            customerId: customerId,
            paymentMethodId: method.id,
        }

        PayementServices.updatePaymentMethod(data)
        .then((response) => {
            setModalOpen(true);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const handleDeleteCard = (e) => {
        e.preventDefault();

        const data = {
            paymentMethodId: method.id,
        }

        PayementServices.deletePaymentMethod(data)
        .then((response) => {
            setModalOpen(true);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    return (
        <div className={`payment-methods__container__card ${defaultMethod ? "default" : ""}`}>
            <div className={"payment-methods__container__card__infos"}>
                <div className={"payment-methods__container__card__infos__icon"}>
                    <img
                        src={method.card.brand === "visa" ? "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Visa_Inc._logo.svg/1200px-Visa_Inc._logo.svg.png" : "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/MasterCard_Logo.svg/1200px-MasterCard_Logo.svg.png"}
                        alt="logo"/>
                </div>
                <div className={"payment-methods__container__card__infos__text"}>
                    <p className={"text-16"}>**** **** **** {method.card.last4}</p>
                    <p className={"text-14"}>Expire le {method.card.exp_month}/{method.card.exp_year}</p>

                    <div className={"payment-methods__container__card__infos__text__actions"}>
                        { !defaultMethod ? <p className={"title-14 underline"} onClick={handleDefaultCard}>Définir par défaut</p> : null }
                        <p className={"text-14 bold pointer"} onClick={handleDeleteCard}>Supprimer</p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PayementCard;