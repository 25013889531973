import React, {useState} from 'react';
import '../../../styles/components/modal.css';
import error from "../../../assets/icones/global/shield_error.svg";
import validate from "../../../assets/icones/global/shield_validate.svg";
import close from "../../../assets/icones/global/close-stroke.svg";
import ModalVerifiedStatus from "./modalVerifiedStatus";
import useOpenModal from "../../../hooks/useOpenModal";
import {useNavigate} from "react-router-dom";

const ModalIsVerified = ({isOpen, closeModal, handleChange, type, link}) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const navigate = useNavigate();


    const handleValidate = (status) => {
        handleChange(status);
        closeModal();
    }


    return(
        <>
            <div className={`modal offer ${isOpen ? "open" : ""}`}>
                <div className={"modal__header grey"}>
                    <p className={"text-14"}>{
                        type === "partner" ? "Status du partenaire" :
                            type === "activity" ? "Status de l'activité" :
                                type === "offer" ? "Status de l'offre" : null
                    }</p>
                    <div className={"modal__header__actions"}>
                        <p className={"title-14"} onClick={closeModal}>
                            <img src={close} alt={"close"}/>
                        </p>
                    </div>
                </div>
                <div className={"modal__content"}>
                    <div className={"modal__content"}>
                        <p className={"text-14 offer_div"} onClick={() => setModalOpen(true)}>Modifier le
                            status</p>
                        {
                            type === "partner" ?
                                <p className={"text-14 offer_div"} onClick={() => navigate(link)}>Voir le
                                    partenaire</p>
                                : type === "offer" ?
                                    <p className={"text-14 offer_div"} onClick={() => navigate(link)}>Voir l'offre</p>
                                : <p className={"text-14 offer_div"} onClick={() => navigate(link)}>Voir
                                    l'activité</p>
                        }
                    </div>
                </div>
            </div>
            <div className={"modal__isVerifiedStatus"}>
                <ModalVerifiedStatus isOpen={modalOpen} closeModal={() => setModalOpen(false)} handleChange={handleValidate.bind(this)} type={type}/>
            </div>
        </>
    )
}

export default ModalIsVerified;
