import React from "react";
import mail from "../../../assets/icones/global/mail_light.svg";
import '../../../styles/views/policy.css';
import logo from "../../../assets/images/svg/logo_row.svg";
import Button from "../../../components/button/button";
import {useNavigate} from "react-router-dom";

const SubscriptionPolicy = () => {

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    return (
        <div className="main policy">
            <div className="main__container text">
                <div className="main__container__section">
                    <div className="main__container__form">
                        <img src={logo} alt="logo"/>

                        <div className={"container__infos"}>
                            <h1 className={"title-25"}>Les conditions d'abonnement du Dashboard Frimity</h1>


                            <p className={"text-14 light"}>Date de la Dernière Mise à Jour : 01/01/2024</p>
                        </div>
                        <Button isPrimary={true} text={"Revenir en arrière"} isFull={true} onClick={goBack}/>
                    </div>
                </div>
            </div>
            <div className="main__container last">
                <div className="main__footer">
                    <p className={"text-12"}>© Frimity 2023</p>
                    <div className={"main__footer__links"}>
                        <img src={mail} alt="logo"/>
                        <a href={"mailto:contact@frimity.fr"}><p
                            className={"text-12"}>contact@frimity.fr</p></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubscriptionPolicy;