import React, { useState, useEffect } from 'react';
import useInputValidation from "../../../hooks/form/useInputValidation";

const InputPrice = ({type, label, name, placeholder, errorEmpty = false, errorMessage = false, validationType, onChangeForm, valueInput, isColumn, readOnly, subtitle = false, disabled = false, typeUnit}) => {

    const { error } = useInputValidation('', validationType);
    const [numberValue, setNumberValue] = useState(0);
    // State to keep track of input for display
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        // Format the initial value from the database for display
        const initialNumber = parseFloat(valueInput) || 0;
        setNumberValue(initialNumber);
        setInputValue(typeUnit === "€" ? `${formatValueForDisplay(initialNumber)}` : `${initialNumber}`);
    }, [valueInput]);

    const handleChange = (event) => {
        const value = event.target.value;
        let numericValue = parseFloat(value) || 0;
        // Replace commas in case of European decimal format
        if(typeUnit === "€") {
            numericValue = value ? parseFloat(value.replace(',', '.')) : null;
        }

            // Update the number value
        setNumberValue(numericValue);
        // Update the input value for display without the € sign
        setInputValue(value);

    };


    const handleBlur = () => {
        // Format the display of the input value with € sign on blur
        if (numberValue !== null) {
            setInputValue(formatValueForDisplay(numberValue));
        }
        if (onChangeForm) {
            onChangeForm({ target: { name, value: numberValue } });
        }
    };

    const formatValueForDisplay = (numericValue) => {
        return `${parseFloat(numericValue).toFixed(2).replace('.', ',')}`;
    };


    return (
        <div className={`form__row ${isColumn ? 'column' : ''} max_width`}>
            {label && <label htmlFor={name} className="form__row__label">{label}</label>}
            <div className="form__col">
                <div className="form__col_row">
                    <input
                        type={type}
                        className={`form__input ${error || errorMessage ? 'error' : ''} ${errorEmpty ? 'error' : ''} ${disabled ? 'readOnly' : ''}`}
                        name={name}
                        id={name}
                        placeholder={errorEmpty || errorMessage ? "Remplissez ce champ" : placeholder}
                        value={inputValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={readOnly}
                        disabled={disabled}
                    />
                    {typeUnit && <span className={"text-14"}>{typeUnit}</span>}
                </div>
                {error && <div className="error-message">{error}</div>}
            </div>
        </div>
    );
};

export default InputPrice;
