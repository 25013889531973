const useParseSignIn = () => {

    const parseSignIn = (data) => {
        const personalInfo = {
            lastname: {
                value: "",
                error: false,
                errorMessage: false,
                validationType: "text",
                label: "Nom",
                placeholder: "Entrer votre nom",
                isRequired: true,
            },
            firstname: {
                value: "",
                error: false,
                errorMessage: false,
                validationType: "text",
                label: "Prénom",
                placeholder: "Entrer votre prénom",
                isRequired: true,
            },
            phone: {
                value: "",
                error: false,
                errorMessage: false,
                validationType: "phone",
                label: "Numéro de téléphone",
                placeholder: "07 23 81 39 94",
                isRequired: true,
            },
            email: {
                value: "",
                error: false,
                errorMessage: false,
                validationType: "email",
                label: "Adresse mail personellle",
                placeholder: "antho@gmail.com",
                isRequired: true,
            },
            communicationPreference: {
                value: [],
                error: false,
                errorMessage: false,
                validationType: "text",
                label: "Préférence de communication",
                placeholder: "Préférence de communication",
                isRequired: true,
            }
        }

        const companyInfo = {
            name: {
                value: "",
                error: false,
                errorMessage: false,
                validationType: "text",
                label: "Raison sociale de l’entreprise",
                placeholder: "FRIMITY SAS",
                isRequired: true,
            },
            siretNumber: {
                value: "",
                error: false,
                errorMessage: false,
                validationType: "siret",
                label: "SIRET",
                placeholder: "123 456 789 00012",
                isRequired: true,
            },
            address: {
                value: "",
                error: false,
                errorMessage: false,
                validationType: "text",
                label: "Adresse de votre l’entreprise",
                placeholder: "12 rue des brunas",
                isRequired: true,
            },
            postalCode: {
                value: "",
                error: false,
                errorMessage: false,
                validationType: "postalCode",
                label: "Code postal",
                placeholder: "91390",
                isRequired: true,
            },
            website: {
                value: "",
                error: false,
                errorMessage: false,
                validationType: "website",
                label: "Site web de l'entreprise",
                placeholder: "http://entreprise.com",
                isRequired: true,
            },
            emailPartner: {
                value: "",
                error: false,
                errorMessage: false,
                validationType: "email",
                label: "Email de l'entreprise",
                placeholder: "frimity@gmail.com",
                isRequired: true,
            },
            phoneNumber: {
                value: "",
                error: false,
                errorMessage: false,
                validationType: "phone",
                label: "Téléphone de l'entreprise",
                placeholder: "07 23 81 39 94",
                isRequired: true,
            },
        }

        const partnerSignin = {
            personalInfo: personalInfo,
            companyInfo: companyInfo,
        }

        return partnerSignin;
    }

    const parseSignInForApi = (data) => {

        const parseData = {
            name: data.companyInfo.name ? data.companyInfo.name.value : "",
            address: data.companyInfo.address ? data.companyInfo.address.value : "",
            postalCode: parseInt(data.companyInfo.postalCode ? data.companyInfo.postalCode.value : ""),
            email: data.companyInfo.emailPartner ? data.companyInfo.emailPartner.value : "",
            website: data.companyInfo.website ? data.companyInfo.website.value : "",
            phoneNumber: data.companyInfo.phoneNumber ? data.companyInfo.phoneNumber.value : "",
            legalInfoID:{
                siretNumber: data.companyInfo.siretNumber ? data.companyInfo.siretNumber.value : "",
            },
            managerID:{
                lastname: data.personalInfo.lastname ? data.personalInfo.lastname.value : "",
                firstname: data.personalInfo.firstname ? data.personalInfo.firstname.value : "",
                email: data.personalInfo.email ? data.personalInfo.email.value : "",
                phoneNumber: data.personalInfo.phone ? data.personalInfo.phone.value : "",
                communicationPreference: data.personalInfo.communicationPreference ? data.personalInfo.communicationPreference.value : [],
            },
            status: "pending",
        }

        return parseData;
    }

    return {
        parseSignIn,
        parseSignInForApi,
    }
}

export default useParseSignIn