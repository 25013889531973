import React, { useState, useEffect } from "react";
import RadioMultiple from "../../../components/form/inputRadio/radioMultiple";
import FormSectionRow from "../../../components/form/formSectionRow";
import ActivitiesTypes from "../../../api/services/activitiesTypes";
import Button from "../../../components/button/button";
import Radio from "../../../components/form/inputRadio/radio";

const Types = ({ type, setActivity, isDisabled }) => {
    const [typeList, setTypeList] = useState([]);
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        ActivitiesTypes.getActivitiesTypes()
            .then((response) => {
                if (response.status === 200) {
                    //set item "Autre" at the end of the list
                    const otherItem = response.data.find((item) => item.name === "🤷️ Autre");
                    const otherItemIndex = response.data.indexOf(otherItem);
                    response.data.splice(otherItemIndex, 1);
                    response.data.push(otherItem);

                    setTypeList(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [type]);

    const handleTypeChange = (event) => {
        const value = event.target.value;
        setActivity('type.value', value);
    };

    const handleShowMore = () => {
        setShowAll(!showAll);
    };

    return (
        <FormSectionRow title={"Quel est votre type d’activité"}
                        classname={"category"} isRequired={true}>
            <div className={"form__container__col grid"}>
                {
                    typeList.slice(0, showAll ? typeList.length : 12).map((item) => (
                        <Radio
                            key={item.id}
                            name="type"
                            value={item.name}
                            onChange={handleTypeChange}
                            label={item.name}
                            checked={type.value === item.name}
                            errorEmpty={type.error}
                            disabled={isDisabled}
                            className={"type__item"}
                        />
                    ))
                }
            </div>
            {typeList.length > 12 && (
                <Button
                    text={showAll ? "Voir moins" : "Voir plus ..."}
                    onClick={handleShowMore}
                    className={"button__link"}
                />
            )}
        </FormSectionRow>
    );
};

export default Types;
