import { postRequestFormData, deleteRequest } from '../request/apiRequest';

const ActivitiesImages = {

    create: async (activityId, data, partnerId) => {
        return await postRequestFormData(`/activities/images/${partnerId}/activity/${activityId}`, data);
    },

    delete: async (activityId, imageFilename, partnerId) => {
        return await deleteRequest(`/activities/images/${partnerId}/activity/${activityId}/${imageFilename}`);
    }

}

export default ActivitiesImages;