import React, {useState} from 'react';
import '../../../styles/components/form/form.css';
import useInputValidation from "../../../hooks/form/useInputValidation";
import lockIcon from "../../../assets/icones/global/lock.svg";
import eye from "../../../assets/icones/global/eye.svg";
import eyeHide from "../../../assets/icones/global/hide.svg";
import ModalPasswordReset from "../../modal/modalInfos/modalPasswordReset";

const InputPassword = ({type, label, name, placeholder, title = false, valueInput, onChangeForm, disabled = false, errorEmpty = false, isRow = false, validationType, buttonReset = false, readOnly = false}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const { value, error, onChange } = useInputValidation('', validationType);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const handleChange = (event) => {
        onChange(event);
        onChangeForm(event);
    }

    return(
        <div className="form__row column">
            {
                label ? <label htmlFor={name} className="form__row__label">{label}</label> : null
            }
            <div className={`form__row__input__password ${errorEmpty ? 'error' : ''}`}>
                <div className={`form__row__icon`}>
                    <img src={lockIcon} alt={lockIcon}/>
                </div>
                <input type={isPasswordVisible ? "text" : "password"}
                       name={name} id={name} placeholder={errorEmpty ? "Remplissez ce champ" : placeholder}
                       value={valueInput ? valueInput : value} onChange={handleChange} disabled={disabled}
                       readOnly={readOnly}
                />
                {

                }
                {
                    buttonReset ?
                        <p className={"text-12 underline"} onClick={() => setIsModalOpen(true)}>Réinitialiser</p>
                    : isPasswordVisible ?
                        <img src={eye} alt={"eye"} className={"form__row__icon__eye pointer"} onClick={() => setIsPasswordVisible(false)}/>
                    : <img src={eyeHide} alt={"eyeHide"} className={"form__row__icon__eye pointer"} onClick={() => setIsPasswordVisible(true)}/>
                }
            </div>
            {
                buttonReset ?
                    <ModalPasswordReset
                        isOpen={isModalOpen}
                        closeModal={() => setIsModalOpen(false)}
                    />
                :
                    null
            }
        </div>
    )

}

export default InputPassword;