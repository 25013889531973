import React, {useContext, useEffect, useState} from 'react';
import '../../styles/views/singleActivity.css';
import {useParams} from "react-router";
import ActivitiesServices from "../../api/services/activities";
import HeaderView from "../../components/header/headerView";
import Form from "../../components/form/form";
import Images from "./formActivity/images";
import Name from "./formActivity/name";
import Schedule from "./formActivity/schedule";
import Category from "./formActivity/category";
import NbPersons from "./formActivity/nbPersons";
import Duration from "./formActivity/duration";
import Description from "./formActivity/description";
import PraticalInfos from "./formActivity/praticalInfos";
import Expected from "./formActivity/expected";
import CancelPolicy from "./formActivity/cancelPolicy";
import Included from "./formActivity/included";
import BookingPolicy from "./formActivity/bookingPolicy";
import Access from "./formActivity/access";
import PricingAndExtras from "./formActivity/pricingAndExtras";
import useParseActivity from "../../hooks/parseDataApi/useParseActivity";
import ToKnow from "./formActivity/toKnow";
import Insurance from "./formActivity/insurance";
import useFormValidation from "../../hooks/form/useFormValidation";
import useFormUpdate from "../../hooks/form/useFormUpdate";
import ModalVerification from "../../components/modal/modalInfos/modalVerification";
import ActivitiesImages from "../../api/services/activitiesImages";
import {ErrorContext} from "../../context/errorContext";
import {useLoader} from "../../context/loaderContext";
import PartnerSubscriptionServices from "../../api/services/partnerSubscription";
import {useSelector} from "react-redux";
import {userPartnerUpdateActivities} from "../../redux/slice/authSlice";
import {useDispatch} from "react-redux";
import Types from "./formActivity/types";
import {usePartner} from "../../context/partnerContext";

const AddActivity = () => {

    const partnerId = useParams().partnerId;
    const activityId = useParams().activityId;
    const [subscription, setSubscription] = useState(null);
    const {parseActivityForApi, parseActivity} = useParseActivity();
    const { validateForm } = useFormValidation();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();
    const dispatch = useDispatch();

    const [isImageChecked, setIsImageChecked] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [errorIsUpdated, setErrorIsUpdated] = useState(false);

    const { currentPartnerId } = usePartner();
    const user = useSelector(state => state.auth.user);
    const currentPartner = useSelector((state) => state.auth.user.partnerID.filter((partner) => partner._id === currentPartnerId)[0] || null);


    const { formState, setFormState, handleFormChange } = useFormUpdate(parseActivity([]));
    const [isModalOpen, setIsModalOpen] = useState(false);

    const generateReference = () => {
        const prefix = "#FRIM";
        const number = Math.floor(100000 + Math.random() * 900000); // Generate a 6-digit number
        return `${prefix}${number}`;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const isFormValid = validateForm(formState, setFormState);

        isImageChecked ? setImageError(false) : setImageError(true);
        isUpdated ? setErrorIsUpdated(false) : setErrorIsUpdated(true);

        if(isFormValid.isFormValid && isImageChecked && isUpdated){
            setLoading(true);
            const activityForApi = parseActivityForApi(formState);
            const parseActivityFiles = new FormData();

            //set partnerId
            activityForApi.partnerId = partnerId;
            const localImages = activityForApi.activityImages.images.filter(img => img.isLocal);

            if (localImages.length > 0) {
                for (const image of localImages) {
                    const blob = await fetch(image.src).then(res => res.blob());
                    const file = new File([blob], image.name, { type: image.type });
                    parseActivityFiles.append('images', file);
                }
            }

            delete activityForApi.activityImages;

            ActivitiesServices.createActivity(partnerId, activityForApi)
            .then((response) => {
                if(response.status === 200){
                    if (parseActivityFiles.has('images')) {
                        const activityId = response.data.activity._id;
                        //update user partnerID activitiesID array
                        if(user.role === "partner" || user.role === "partners-admin"){
                            dispatch(userPartnerUpdateActivities({
                                partnerID: partnerId, // Assuming currentPartner has _id property
                                activity: activityId // Append new activityId to the existing array
                            }));
                        }
                        ActivitiesImages.create(activityId, parseActivityFiles, partnerId)
                        .then((res) => {
                            if(res.status === 200){
                                setIsModalOpen(true);
                                setLoading(false);
                            }
                        })
                        .catch((error) => {
                            showError(error);
                        })
                    }else {
                        dispatch(userPartnerUpdateActivities({
                            partnerID: partnerId, // Assuming currentPartner has _id property
                            activity: activityId // Append new activityId to the existing array
                        }));
                        setIsModalOpen(true);
                        setLoading(false);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                showError(error);
            })
        }else{
            if(!isImageChecked){
                setImageError(true);
                showError("Accept conditions for images");
            }else if (!isUpdated){
                showError("Accept conditions for insurance");
                setErrorIsUpdated(true);
            }
        }
    }

    useEffect(() => {
       handleFormChange("reference.value", generateReference());

        //get subscription of partner
        PartnerSubscriptionServices.getSubscriptionByPartnerId(partnerId)
        .then((response) => {
            if(response.status === 200){
                setSubscription(response.data.subscriptionPlan);
            }else{
                setSubscription(false)
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }, []);


    return(
        <div className={"main-container is__grey"}>
            <HeaderView title={"Ajouter une activitée"} />


            <div className={"header__container"}>
                <h1><span className={"text-18"}>Votre établissement :</span> { user.role === "partner" || user.role === "partners-admin" ? currentPartner.name : ""}</h1>
                <div className={"header__container__col"}>
                    <p className={"text-14"}>Ajouter une nouvelle activité</p>
                </div>
            </div>

            <div className={"main-activity__container"}>
                <div className={"main-activity__container__row"}>
                    <div className={"container__row"}>
                        {
                            formState.reference &&
                            <div className={"container__row__col"}>
                                <p className={"title-18"}>{formState.reference.value}</p>
                            </div>
                        }
                    </div>
                </div>


                <Form
                    children={
                    formState &&
                        <>
                            <Images images={formState.images} setActivity={handleFormChange} type={"local"}
                                    activityId={activityId} isImageChecked={isImageChecked} setIsImageChecked={setIsImageChecked} imageError={imageError}/>
                            <Name name={formState.name} setActivity={handleFormChange}/>
                            <Types type={formState.type} setActivity={handleFormChange}/>
                            <Schedule mode={"add"} schedule={formState.schedule} setActivity={handleFormChange}/>
                            <Category category={formState.category} setActivity={handleFormChange}/>
                            <NbPersons nbPersons={formState.nbPersons} setActivity={handleFormChange}/>
                            <Duration duration={formState.duration} setActivity={handleFormChange}/>
                            <Description description={formState.description} setActivity={handleFormChange}/>
                            <PraticalInfos practicalInfo={formState.practicalInfo} setActivity={handleFormChange}/>
                            <Expected expected={formState.expected} setActivity={handleFormChange}/>
                            <ToKnow toKnow={formState.toKnow} setActivity={handleFormChange}
                                    toKnowInfos={formState.toKnowInfos} toKnowOther={formState.toKnowOther}/>
                            <BookingPolicy bookingPolicy={formState.bookingPolicy} setActivity={handleFormChange}/>
                            <PricingAndExtras pricingAndExtras={formState.pricingAndExtras}
                                              setActivity={handleFormChange}
                                              commission={user.role === "partner" || user.role === "partners-admin" ? currentPartner.subscriptionID.commission : 0} />
                            <Access access={formState.access} setActivity={handleFormChange}/>
                            <CancelPolicy cancel={formState.cancel} setActivity={handleFormChange}/>
                            <Included included={formState.included} setActivity={handleFormChange}/>
                            <Insurance insurance={formState.hasInsurance} setActivity={handleFormChange} isUpdated={isUpdated} setIsUpdated={setIsUpdated} errorIsUpdated={errorIsUpdated}/>
                        </>
                    }
                    onSubmit={handleSubmit}
                />


            </div>

            <ModalVerification isOpen={isModalOpen} link={`/partners/${partnerId}/activities`} type={"activitée"}
                               action={"ajoutée"}/>
        </div>
    )
}

export default AddActivity;