import React from 'react';
import {Outlet} from "react-router-dom";
import './styles/root.css';
import Navbar from "./components/navbar/navbar";
import ErrorModal from "./components/modal/modalError";
import Loader from "./components/loader/loader";
import {useLoader} from "./context/loaderContext";

function GlobalLayout() {

    const { loading } = useLoader();

  return (
    <div className="App">
        <Loader isGlobal={true} />
        <Navbar />
        <div className={"sidebar_nav"}></div>
        <main className={"main"}>
            <Outlet />
            <ErrorModal />
        </main>
    </div>
  );
}

export default GlobalLayout;
