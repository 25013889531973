import React from 'react';
import FormSectionRow from "../../../components/form/formSectionRow";
import Input from "../../../components/form/inputText/input";
const Activity = ({name, setOffer, isDisabled}) => {
    const handleChange = (event) => {
        const { value } = event.target;
        setOffer('name.value', value);
    }

    return(
        <FormSectionRow title={"Titre de l'offre"} isRequired={true}>
            <Input
                name={"name"}
                placeholder={name.placeholder}
                valueInput={name.value}
                onChangeForm={handleChange}
                validationType={name.validationType}
                disabled={isDisabled}
                errorEmpty={name.error}
                errorMessage={name.errorMessage}
            />
        </FormSectionRow>
    );
}

export default Activity;