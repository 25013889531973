import HeaderView from "../../../components/header/headerView";
import React from "react";
import HelpCard from "../../../components/card/helpCard";
import Input from "../../../components/form/inputText/input";
import "../../../styles/views/bookings.css"


const BookingConfigAPI = () => {


    return(
        <div className={"main-container is__grey"}>
            <HeaderView title={`Configuration de mon API`} isGoBack={false} />

            <div className={"main-booking__container"}>
                <div className={"booking_container__column padding-20 gap-35"}>
                    <div className={"form-section__row"}>
                        <div className={"form-section__row__col textInfos"}>
                            <h2 className={"title-16"}> Nom du logiciel de réservation <span
                                className={"is__required"}>*</span></h2>
                            <p className={"text-12"}>Veuillez renseigner le nom de votre logiciel de réservation</p>
                        </div>
                        <div className={`form-section__row__col formInfos isRow`}>
                            <Input
                                key={"bookingName"}
                                type={"text"}
                                name={"bookingName"}
                                placeholder={"4escape"}
                                valueInput={null}
                                onChangeForm={(e) => console.log(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className={"form-section__row"}>
                        <div className={"form-section__row__col textInfos"}>
                            <h2 className={"title-16"}> Clé d'API <span className={"is__required"}>*</span></h2>
                            <p className={"text-12"}>Veuillez renseigner la clé d'API de votre logiciel de réservation pour que nous puissions nous connecter à celui-ci</p>
                        </div>
                        <div className={`form-section__row__col formInfos isRow`}>
                            <Input
                                key={"bookingApiKey"}
                                type={"text"}
                                name={"bookingApiKey"}
                                placeholder={"163456334513516515152"}
                                valueInput={null}
                                onChangeForm={(e) => console.log(e.target.value)}

                            />
                        </div>
                    </div>
                </div>
            </div>

            <HelpCard
                title={"Vous avez besoin d'aide avec votre logiciel de réservation ?"}
                text={"Si vous avez la moindre question ou besoin d'aide, n'hésitez pas à nous contacter. Nous sommes disponibles 24/7 pour vous offrir le support dont vous avez besoin."}/>
        </div>
    )
}

export default BookingConfigAPI;