import React, { useState, useEffect } from "react";

const RadioMultiple = ({ label, name, value, icon = false, isChecked = false, disabled = false, onChange, errorEmpty  = false, errorMessage = false, className}) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(isChecked);
    }, [isChecked]);

    const onChangeForm = (event) => {
        setChecked(event.target.checked);
        onChange(event);
    }

    return (
        <div className={`radio-container ${checked ? "checked" : ""} ${disabled ? "disabled" : ""} ${errorEmpty || errorMessage? 'error' : null} ${className}`}>
            <input
                type="radio"
                name={name}
                value={value}
                checked={checked}
                onChange={disabled ? null : onChangeForm}
            />
            {icon && <img src={icon} alt={"icon"} />}
            <label>{label}</label>
        </div>
    );
};

export default RadioMultiple;
