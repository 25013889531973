import React, {useEffect, useState, useContext} from 'react';
import HeaderView from "../../components/header/headerView";
import RoundStat from "../../components/stats/roundStat";
import CardStat from "../../components/stats/cardStat";
import Table from "../../components/table/table";
import Tag from "../../components/tag/tag";
import Dot from "../../components/dot/dot";
import Button from "../../components/button/button";
import useStatusName from "../../hooks/parseText/useStatusName";
import useOpenModal from "../../hooks/useOpenModal";
import TabBlock from "../../components/navbar/tabBlock";
import ActivitiesServices from "../../api/services/activities";
import ModalIsVerified from "../../components/modal/modalTable/modalIsVerified";
import FilterTable from "../../components/table/filterTable";
import useFilter from "../../hooks/useFilter";
import EntitiesVerifiedServices from "../../api/services/entitiesVerified";
import {ErrorContext} from "../../context/errorContext";
import {useLoader} from "../../context/loaderContext";

const VerificationActivities = () => {

    const [refresh, setRefresh] = useState(false);
    const [filterList, setFilterList] = useState([]);

    const [activities, setActivities] = useState([]);
    const [activitiesVerified, setActivitiesVerified] = useState();
    const { getStatusName } = useStatusName();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();


    const [isModalOpen, openModal, closeModal] = useOpenModal(activities.map(() => false));
    const { updateFilters, filteredData } = useFilter([], activities, (activity, filters) => {
        return filters.length === 0 || filters.includes(activity.status);
    });

    const loadActivities = async () => {
        setLoading(true);
        try {
            const response = await ActivitiesServices.getActivitiesToVerify();
            if(response.status === 200){
                setActivities(response.data);
                setRefresh(false);

                setFilterList([...new Set(response.data.map((activity) => activity.status))]);
            }

            const lenghtActivities = await ActivitiesServices.getActivities();
            if(lenghtActivities.status === 200){
                setActivitiesVerified(lenghtActivities.data.filter((activity) => activity.isVerified === true).length);
            }
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadActivities();
    }, [refresh]);

    const handleVerification = (activityId, partnerId, status) => {
        const data = {
            isVerified: true,
            status: status
        }
        setLoading(true);


        ActivitiesServices.updateActivity(activityId, partnerId, data)
            .then((response) => {
                if(response.status === 200){
                    EntitiesVerifiedServices.sendForActivityVerified(activityId)
                        .then((response) => {
                            if(response.status === 200){
                                setRefresh(true);
                            }
                        })
                        .catch((error) => {
                            showError(error);
                        })
                }
            })
            .catch((error) => {
                showError(error);
            }).finally(() => {
            setLoading(false);
        });

    }


    return (
        <div className={"main-container"}>
            <HeaderView title={"Vérification activité"} actions={
                <>
                    <TabBlock
                        tabs={[
                            {name: "Partenaires", link: "/verification/partners"},
                            {name: "Activités", link: "/verification/activities"},
                            {name: "Offres", link: "/verification/offers"}
                        ]}
                        activeLink={1}
                    />
                </>
            }/>



            <div className={"main-container__row"}>
                <div className={"flex_row stats_rounds"}>
                    <div className={"flex_col"}>
                        <RoundStat title={"Validée"}
                                   value={activitiesVerified}
                                   color={"#47B05E"}/>
                    </div>
                    <div className={"flex_col"}>
                        <RoundStat title={"En attente"} value={activities.filter((activity) => activity.isVerified === false).length} color={"#FFC107"} />
                    </div>
                </div>

                <div className={"flex_col stats_cards"}>
                    <div className={"grid grid_2"}>
                        <CardStat title={"Acquis"}
                                  value={activities.filter((activity) => activity.status === "active").length}
                                  color={"#47B05E"} list={activities.filter((activity) => activity.status === "active")}
                                  titleModal={"Partenaires acquis"} status={"active"}/>
                        <CardStat title={"En cours d'acquisition"}
                                  value={activities.filter((activity) => activity.status === "waiting").length}
                                  status={"#000000"}
                                  list={activities.filter((activity) => activity.status === "pending")}
                                  titleModal={"Partenaires en cours d'acquisition"} status={"pending"}/>
                        <CardStat title={"Refusé"}
                                  value={activities.filter((activity) => activity.status === "error").length}
                                  color={"#FF4D42"} list={activities.filter((activity) => activity.status === "lost")}
                                  titleModal={"Partenaires refusés"} status={"lost"}/>
                    </div>
                </div>
            </div>

            <div className={"main-container__row list"}>
                {
                    filterList && filterList.length > 0 ?
                        <FilterTable
                            filterArray={filterList}
                            onFilterChange={updateFilters}
                            typeValue={"activity"}
                            searchData={activities}
                        />
                        :
                        null
                }
                {
                    filteredData && filteredData.filter((activity) => activity.isVerified === false).length > 0 ?
                        <Table
                            columns={[
                                {name: "Référence", align: "left", width: "30%", field: "reference"},
                                {name: "Activité", align: "left", width: "30%", field: "activity"},
                                {name: "Status", align: "left", width: "20%", field: "status"},
                                {
                                    name: "Actions", align: "center", width: "30%", style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }, field: "actions"
                                },
                                {
                                    name: "Voir plus", align: "center", width: "15%", style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    },
                                    field: "link"
                                },
                            ]}

                            data={
                                filteredData && filteredData.length > 0 ?
                                    filteredData.filter((activity) => activity.isVerified === false).map((activity, index) => {
                                            return {
                                                reference: activity.reference,
                                                activity: activity.name,
                                                status: <Tag text={getStatusName(activity.status)} status={activity.status}/>,
                                                actions: (
                                                    <Dot size={"big"} click={() => openModal(index)}>
                                                        <>
                                                            <ModalIsVerified
                                                                isOpen={isModalOpen[index]}
                                                                closeModal={() => closeModal(index)}
                                                                handleChange={handleVerification.bind(this, activity._id, activity.partnerID)}
                                                                type={"activity"}
                                                                link={`/partners/${activity.partnerID._id}/activities/${activity._id}`}
                                                            />
                                                        </>
                                                    </Dot>
                                                ),
                                                link: <Button text={"Voir le dossier"} link={`/partners/${activity.partnerID._id}/activities/${activity._id}`}/>
                                            }
                                        }
                                    ).reverse() : []
                            }
                        />
                        :
                        <div className={"container__empty"}>
                            <p className={"text-14"}>Aucune offre n'est en attente de validation</p>
                        </div>
                }
            </div>
        </div>
    );


}

export default VerificationActivities;