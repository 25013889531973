import React, {useContext, useState, useEffect} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import close from "../../../assets/icones/arrows/right_chevron.svg";
import "../../../styles/views/bookingsSidebar.css";
import Button from "../../../components/button/button";
import Duration from "./sidebarForm/duration";
import Activity from "./sidebarForm/activity";
import AvailabilityType from "./sidebarForm/availabilityType";
import TimeGap from "./sidebarForm/timeGap";
import DaysOfWeeks from "./sidebarForm/daysOfWeeks";
import TimeSlotHours from "./sidebarForm/timeSlotHours";
import useFormUpdate from "../../../hooks/form/useFormUpdate";
import useFilter from "../../../hooks/useFilter";
import useFormValidation from "../../../hooks/form/useFormValidation";
import {ErrorContext} from "../../../context/errorContext";
import {useLoader} from "../../../context/loaderContext";
import {useDispatch, useSelector} from "react-redux";
import useParseBookingTimeSlot from "../../../hooks/parseDataApi/useParseBookingTimeSlot";
import DatesRange from "./sidebarForm/datesRange";
import BookingTimeSlotsServices from "../../../api/services/bookingTimeSlots";
import {usePartner} from "../../../context/partnerContext";

const BookingManageAvailability = ({ partnerActivities, booking, open, setOpen, setRefresh, bookingToModify = null }) => {

    const {parseBookingTimeSlot, parseBookingTimeSlotForAPI} = useParseBookingTimeSlot();
    const { validateForm } = useFormValidation();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();
    const dispatch = useDispatch();

    const { formState, setFormState, handleFormChange } = useFormUpdate(parseBookingTimeSlot([]));

    const { currentPartnerId } = usePartner();
    const user = useSelector(state => state.auth.user);
    const currentPartner = useSelector((state) => state.auth.user.partnerID.filter((partner) => partner._id === currentPartnerId)[0] || null);

    useEffect(() => {
        if(bookingToModify !== null){
            //get booking time slots to modify
            BookingTimeSlotsServices.getBookingTimeSlotById(bookingToModify)
            .then((response) => {
                if(response.status === 200){
                    setFormState(parseBookingTimeSlot(response.data));
                }
            }).catch((error) => {
                showError(error);
            });
        }else{
            setFormState(parseBookingTimeSlot([]));
        }
    }, [open, bookingToModify]);


    const handleSubmit = async (event) => {
        event.preventDefault();

        const isFormValid = validateForm(formState, setFormState);

        if(isFormValid.isFormValid){
            setLoading(true);

            BookingTimeSlotsServices.createBookingTimeSlot(currentPartner.bookingPartnersID._id, parseBookingTimeSlotForAPI(formState))
            .then((response) => {
                if(response.status === 200){
                    setRefresh(true);
                }
            }).catch((error) => {
                showError(error);
            }).finally(() => {
                setLoading(false);
                setFormState(parseBookingTimeSlot([]));
                setOpen(false);
            });
        }
    };

    const handleClose = () => {
        //erase form state
        setFormState(parseBookingTimeSlot([]));
        bookingToModify = null;
        setOpen(false);
    };

    const backdrop = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { duration: 0.5, ease: "easeInOut" } // Smooth easing for fade
        },
        exit: {
            opacity: 0,
            transition: { duration: 0.5, ease: "easeInOut" }
        }
    };

    const sidebar = {
        hidden: { x: '100%' },
        visible: {
            x: '0',
            transition: {
                type: 'spring',
                stiffness: 120,  // Lower stiffness makes it less rigid
                damping: 20,     // Lower damping makes it more bouncy
                mass: 1          // Mass affects the bounciness; increase for more lethargic movement
            }
        },
        exit: {
            x: '100%',
            transition: {
                type: 'spring',
                stiffness: 120,
                damping: 20,
                mass: 1,
                duration: 0.5  // Optional if you want to explicitly control duration on hiding
            }
        }
    };


    return (
        <AnimatePresence>
            {open && (
                <motion.div className="booking__sidebar__background"
                            variants={backdrop}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                >
                    <div className={"booking__sidebar__background__overlay"} onClick={handleClose}/>
                    <motion.div className="booking__sidebar__section"
                                variants={sidebar}
                                initial="hidden"
                                animate="visible"
                                exit="exit">
                        <div className="booking__sidebar__close">
                            <img src={close} alt="close" onClick={handleClose}/>
                        </div>
                        <div className="booking__sidebar__container">
                            <div className="booking__sidebar__header">
                                <p className="text-20 bold">Gérer vos disponibilités</p>
                            </div>

                            <div className="booking__sidebar__body">

                                <div className="booking__sidebar__form">
                                    <Activity activitiesList={partnerActivities} setActivity={handleFormChange} activity={formState.activityID}/>
                                    <div className="sidebar__form__container__group">
                                        <AvailabilityType setAvailabilityType={handleFormChange} availabilityType={formState.availabilityType}/>
                                    </div>
                                    {
                                        formState.availabilityType.value === "openDates" &&
                                        <div className="sidebar__form__container__group">
                                            <DatesRange datesRange={formState.specificDateRanges} setDatesRange={handleFormChange}/>
                                        </div>
                                    }
                                    <div className="sidebar__form__container__group">
                                        <Duration duration={formState.activityDuration} setDuration={handleFormChange}/>
                                        <TimeGap timeGap={formState.activityTimeGap} setTimeGap={handleFormChange}/>
                                    </div>
                                    <div className="sidebar__form__container__group">
                                        <DaysOfWeeks daysOfWeek={formState.daysOfWeek} setDaysOfWeek={handleFormChange}/>
                                    </div>

                                    <div className="sidebar__form__container__group">
                                        <p className="text-18 bold">Vos créneaux</p>
                                        <div className="sidebar__hours__container">
                                            <TimeSlotHours timeSlotHours={formState.generatedTimeSlots} setTimeSlotHours={handleFormChange} activityDuration={formState.activityDuration.value} activityTimeGap={formState.activityTimeGap.value} />
                                        </div>
                                    </div>
                                </div>

                                <div className="booking__sidebar__actions">
                                    <Button text={bookingToModify ? "Modifier" : "Ajouter"} isPrimary={true} isFull={true} onClick={handleSubmit}/>
                                    <Button text={"Annuler"} isPrimary={false} onClick={handleClose} isFull={true}/>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
                )}
        </AnimatePresence>
    );
};

export default BookingManageAvailability;
