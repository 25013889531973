import React from "react";
import '../../styles/components/stepbar.css';

const StepBar = ({step}) => {

    return(
        <div className="step-bar">
            <div className="step-bar__container">
                <div className={`step-bar__container__step ${step === 2 || step > 2 ? 'active' : ''}`}>
                    <div className={`step-bar__container__step__circle ${step === 1 || step > 1 ? 'active' : ''}`}>
                        <div className={"step-bar__container__step__circle__step"}></div>
                    </div>
                    <p className={"text-12"}>Inscription</p>
                </div>
                <div className={`step-bar__container__step ${step === 3 || step > 3 ? 'active' : ''}`}>
                    <div className={`step-bar__container__step__circle ${step === 2 || step > 2? 'active' : ''}`}>
                        <div className={"step-bar__container__step__circle__step"}></div>
                    </div>
                    <p className={"text-12"}>Informations personnelles</p>
                </div>
                <div className="step-bar__container__step">
                    <div className={`step-bar__container__step__circle ${step === 3 ? 'active' : ''}`}>
                        <div className={"step-bar__container__step__circle__step"}></div>
                    </div>
                    <p className={"text-12"}>Information de votre entreprise </p>
                </div>
            </div>
        </div>
    )
}

export default StepBar;