import React, { useState } from 'react';
import searchIcon from '../../assets/icones/global/search.svg';
import '../../styles/components/searchbar.css';
import ModalSearch from "../modal/modalSearch";

const Searchbar = ({ searchData, typeValue }) => {
    // State to store the search query
    const [searchQuery, setSearchQuery] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [finalData, setFinalData] = useState(searchData);

    // Event handler for input change
    const handleInputChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        // Optional: Filter the data based on the query and pass it to the parent component
        const filteredData = searchData.filter(item => item.name.toLowerCase().includes(query.toLowerCase()));
        setFinalData(filteredData);
    };

    return (
        <div className="searchbar">
            <div className="searchbar__container">
                <img src={searchIcon} alt="icon search"/>
                <input
                    type="text"
                    placeholder="Rechercher"
                    value={searchQuery}
                    onChange={handleInputChange}
                    onClick={() => setIsOpen(true)}
                />
            </div>

            <ModalSearch
                searchList={finalData !== [] ? finalData : searchData}
                titleModal={"Recherche"}
                isOpen={isOpen}
                closeModal={() => setIsOpen(false)}
                type={typeValue}
            />
        </div>
    );
}

export default Searchbar;
