import { useState, useEffect, useContext } from 'react';
import BookingTimeSlotsServices from '../../api/services/bookingTimeSlots';
import ActivitiesServices from '../../api/services/activities';
import { ErrorContext } from '../../context/errorContext';
import { useLoader } from '../../context/loaderContext';

function useBookingTimeSlots(partnerBookingId, partnerId, activitySelected, dateSelected, refresh) {
    const [bookingTimeSlots, setBookingTimeSlots] = useState([]);
    const [activities, setActivities] = useState([]); // State to hold activities

    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const slotsResponse = await BookingTimeSlotsServices.getBookingTimeSlotByPartnerId(partnerBookingId);
                const activitiesResponse = await ActivitiesServices.getActivitiesByPartnerId(partnerId); // Fetch activities

                if (activitiesResponse.status === 200) {
                    setActivities(activitiesResponse.data); // Set activities data
                }

                if (slotsResponse.status === 200) {
                    const filteredSlots = filterBookingTimeSlots(slotsResponse.data, dateSelected, activitySelected);
                    setBookingTimeSlots(filteredSlots);
                } else {
                    showError('Failed to fetch booking slots.');
                }
            } catch (error) {
                showError(error);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [refresh, dateSelected, activitySelected, partnerId, partnerBookingId]);

    return { activities, bookingTimeSlots };
}

function filterBookingTimeSlots(slots, dateSelected, activitySelected) {
    const selectedDate = new Date(dateSelected);
    selectedDate.setHours(0, 0, 0, 0);  // Reset time part to compare only dates

    return slots.filter(slot => {
        const matchesDate = slot.typeTimeSlot === "openAllTime"
            ? slot.daysOfWeek.includes(selectedDate.getDay())
            : slot.specificDateRanges.some(range => {
            const startDate = new Date(range.startDate);
            const endDate = new Date(range.endDate);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999); // Include the whole end day
            return selectedDate >= startDate && selectedDate <= endDate;
        }) && slot.daysOfWeek.includes(selectedDate.getDay());

        const matchesActivity = activitySelected === '' || slot.activityID === activitySelected;

        return matchesDate && matchesActivity;
    }).map(slot => {
        return {
            id: slot._id,
            timeSlots: slot.generatedTimeSlots, // Keep as objects
            activityID: slot.activityID,
        };
    });
}


export function filterBookingTimeSlotsForSingleActivity(slots, dateSelected) {
    const selectedDate = new Date(dateSelected);
    selectedDate.setHours(0, 0, 0, 0);  // Reset time part to compare only dates

    return slots.filter(slot => {

        if (slot.typeTimeSlot === "openAllTime") {
            return slot.daysOfWeek.includes(selectedDate.getDay());
        } else {
            return slot.specificDateRanges.some(range => {
                const startDate = new Date(range.startDate);
                const endDate = new Date(range.endDate);
                startDate.setHours(0, 0, 0, 0);
                endDate.setHours(23, 59, 59, 999); // Include the whole end day
                return selectedDate >= startDate && selectedDate <= endDate;
            }) && slot.daysOfWeek.includes(selectedDate.getDay());
        }


    }).map(slot => {
        return {
            id: slot._id,
            timeSlots: slot.generatedTimeSlots.map(time => `${time.startTime} - ${time.endTime}`),
            activityID: slot.activityID,
        };
    })[0];
}

export default useBookingTimeSlots;
