import React, {useEffect, useState} from 'react';
import useFileHandler from '../../../hooks/parseFiles/useFileHandler';
import importFile from '../../../assets/icones/global/import.svg';
import IconButton from "../../button/iconButton";
import trash from "../../../assets/icones/global/trash_can.svg";
import eyes from "../../../assets/icones/global/eye.svg";
import ModalFilesDeleting from "../../modal/modalDeleting/modalFilesDeleting";

const InputFiles = ({ label, name, documents, onChange, error, type, partnerId, subtitle}) => {

    const { files, handleChange, deleteFile, hiddenInputRef } = useFileHandler({ documents, onChange, type });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filesToDelete, setFilesToDelete] = useState([]);

    return (
        <div className={"input__files__container"}>
            <div className={"form__col col__files text_files"}>
                <label htmlFor={name} className={"text-14"}>{label}</label>
                {subtitle && subtitle}
            </div>
            <div className={"form__col col__files"}>
                {/* Custom input files */}
                <div className={"input__files__custom"}>
                    <img src={importFile} />
                    <div className={"input__files__custom__text"}>
                        <p className={"text-14"}>
                            <span className={"text-14 bold"}>cliquez ou déposez</span> un PDF
                        </p>
                        <span className={"text-14"}>PDF (max 350KB)</span>
                    </div>
                    <input type={"file"} name={name} ref={hiddenInputRef} onChange={(e) => handleChange(e)} multiple={true} accept={'application/pdf'} />
                </div>

                <div className={"files-added"}>
                    {files && files.length > 0 &&
                        files.map((file, index) => {
                            return (
                                <div className={"file-container__col"} key={index}>
                                    <div className={"file-container download"} key={index}>
                                        <p className={"text-14"}>{file.name}</p>
                                        <span> {file.size} KB - 100% télécharger</span>
                                    </div>
                                    <IconButton icon={eyes} onClick={() => {
                                        if (file.type.startsWith('image/')) {
                                            // For images, open in new tab or show in a modal
                                            window.open(file.preview, '_blank');
                                        } else if (file.type === 'application/pdf') {
                                            // For PDFs, embed in an iframe or use a PDF viewer library
                                            // Example: Embedding PDF in an iframe
                                            const pdfWindow = window.open("");
                                            pdfWindow.document.write(
                                                `<iframe width='100%' height='100%' src='${file.preview}'></iframe>`
                                            );
                                        }
                                        // Add more conditions for other file types if necessary
                                    }} />
                                    <IconButton icon={trash} onClick={() => {
                                        setIsModalOpen(true);
                                        setFilesToDelete(index);
                                    }} />
                                </div>
                            );
                        })}
                </div>

                <ModalFilesDeleting isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} deleteFile={() => deleteFile(partnerId, filesToDelete)} />
            </div>
        </div>
    );
};

export default InputFiles;
