import {getRequest, postRequestJson, patchRequest, deleteRequest} from '../request/apiRequest';


const BookingPaymentServices = {

    createConnectAccount: async (data) => {
        return await postRequestJson('/booking-payment/create-connect-account', data);
    },

    updateConnectAccount: async (data) => {
        return await postRequestJson('/booking-payment/update-connect-account', data);
    }
}

export default BookingPaymentServices;