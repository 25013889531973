import React, { useEffect } from 'react';
import add from '../../../../assets/icones/global/add.svg';
import less from '../../../../assets/icones/global/minus.svg';
import InputDate from "../../../../components/form/inputDate/inputDate";

const DatesRange = ({ datesRange, setDatesRange }) => {
    useEffect(() => {
        if (datesRange.value.length === 0) {
            setDatesRange('specificDateRanges.value', [{ startDate: '', endDate: '' }]);
        }
    }, [datesRange.value, setDatesRange]);

    const handleDateRangeChange = (index, field, value) => {
        const newDateRanges = [...datesRange.value];
        newDateRanges[index] = { ...newDateRanges[index], [field]: value };
        setDatesRange('specificDateRanges.value', newDateRanges);
    };

    const addDateRange = () => {
        const newDateRanges = [
            ...datesRange.value,
            { startDate: '', endDate: '' }
        ];
        setDatesRange('specificDateRanges.value', newDateRanges);
    };

    const removeDateRange = (index) => {
        const newDateRanges = [...datesRange.value];
        newDateRanges.splice(index, 1);
        setDatesRange('specificDateRanges.value', newDateRanges);
    };

    return (
        <div className="sidebar__form__container__row">
            <div className="sidebar__form__label">
                <p className="text-16">Dates</p>
                <p className="text-12">Sélectionnez la plage de date à modifier.</p>
            </div>
            <div className="sidebar__form__content is__content__row date__range__container">
                {datesRange.value.map((range, index) => (
                    <div key={index} className="form__row__dateRange">
                        <InputDate
                            dateValue={range.startDate || ''}
                            minDate={new Date()}
                            maxDate={range.endDate ? new Date(range.endDate) : undefined}
                            title={"Date de début"}
                            setDateValue={(value) => handleDateRangeChange(index, 'startDate', value)}
                            placeholder="Début"
                        />

                        <InputDate
                            dateValue={range.endDate || ''}
                            minDate={range.startDate ? new Date(range.startDate) : new Date()}
                            title={"Date de fin"}
                            setDateValue={(value) => handleDateRangeChange(index, 'endDate', value)}
                            placeholder="Fin"
                        />

                        {/* Render buttons conditionally based on the row */}
                        {datesRange.value.length > 1 && index !== datesRange.value.length - 1 ? (
                            <button type="button" className={"button_dateRanges"}
                                    onClick={() => removeDateRange(index)}>
                                <img src={less} alt="Remove date range"/>
                            </button>
                        ) : null}
                        {index === datesRange.value.length - 1 ? (
                            <button type="button" className={"button_dateRanges primary"} onClick={addDateRange}>
                                <img src={add} alt="Add date range"/>
                            </button>
                        ) : null}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DatesRange;
