import React, {useState} from 'react';
import arrowLeft from '../../../assets/icones/arrows/left_chevron.svg';
import arrowRight from '../../../assets/icones/arrows/right_chevron.svg';

const Calendar = ({ onSelect, startDate, endDate, excludedDates, currentMonth, currentYear, handlePreviousMonth, handleNextMonth, calendarDisabled, handleResetEndDate}) => {
    const [hoveredDate, setHoveredDate] = useState(null);
    const [hoveredRange, setHoveredRange] = useState([]);


    const formatDate = (year, month, day) => {
        return `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    };

    const formatDateFromDb = (dateString) => {
        const date = new Date(dateString);
        return formatDate(date.getFullYear(), date.getMonth(), date.getDate());
    }

    const isDateExcluded = (dateString) => {
        return excludedDates.has(dateString);
    };

    const isCurrentDay = (day) => {
        const today = new Date();
        const currentDay = today.getDate();
        const currentMonth = today.getMonth();
        const currentYear = today.getFullYear();
        return day === formatDate(currentYear, currentMonth, currentDay);
    };

    const isBeforeCurrentDay = (day) => {
        const today = new Date();
        const currentDay = today.getDate();
        const currentMonth = today.getMonth();
        const currentYear = today.getFullYear();
        return day < formatDate(currentYear, currentMonth, currentDay);
    }


    const isHovered = (dateString) => {
        const date = new Date(dateString);
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;
        const hover = hoveredDate ? new Date(hoveredDate) : null;

        // If start date is set and no end date, highlight range between start and hovered date
        if (start && !end && hover) {
            return date >= start && date <= hover;
        }

        // If both start and end date are set, highlight range from start to new hovered date
        if (start && end && hover) {
            return date >= start && date <= hover;
        }

        return false;
    };

    // Helper function to check if a date is selected
    const isDateSelected = (dateString) => {
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;
        const date = new Date(dateString);

        if (start && end) {
            return date >= start && date <= end;
        } else if (start) {
            return date.toDateString() === start.toDateString();
        } else {
            return false;
        }
    };


    // Helper to get the name of the month
    const getMonthName = (month) => {
        const monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
            "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
        return monthNames[month];
    };

    // Helper to get the number of days in a month
    const getDaysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };

// Adjusts the given month and year if the month is out of bounds
    const adjustMonthYear = (month, year) => {
        if (month > 11) {
            month = 0;
            year++;
        } else if (month < 0) {
            month = 11;
            year--;
        }
        return { month, year };
    };

    const handleMouseEnterDay = (dateString) => {

        if (!hoveredDate) {
            setHoveredDate(dateString);
            setHoveredRange([dateString]);
        } else {
            // Calculate new range
            const newRange = calculateHoveredRange(hoveredDate, dateString);
            setHoveredRange(newRange);
        }
    };

    const calculateHoveredRange = (startDateString, endDateString) => {
        let range = [];
        let currentDate = new Date(startDateString);
        const endDate = new Date(endDateString);

        // Ensure that the start date is before the end date
        if (currentDate > endDate) {
            [currentDate, endDate] = [endDate, currentDate];
        }

        while (currentDate <= endDate) {
            range.push(formatDate(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return range;
    };




// Helper to generate the calendar grid
    const generateCalendar = (currentYear, currentMonth) => {
        const daysInMonth = getDaysInMonth(currentYear, currentMonth);
        const startDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();

        // Adjust start day based on whether the week starts on Sunday (0) or Monday (1)
        const daysFromPrevMonth = startDayOfMonth === 0 ? 6 : startDayOfMonth - 1; // Adjust if your week starts on Monday

        // Calculate total number of tiles (days) to display in the calendar grid
        const totalTiles = Math.ceil((daysInMonth + daysFromPrevMonth) / 7) * 7;

        const days = new Array(totalTiles).fill(null).map((_, index) => {
            const dayOffset = index - daysFromPrevMonth;
            const day = dayOffset + 1;
            let month = currentMonth;
            let year = currentYear;

            if (dayOffset < 0) {
                // Previous month
                const { month: prevMonth, year: prevYear } = adjustMonthYear(month - 1, year);
                return {
                    day: getDaysInMonth(prevYear, prevMonth) + dayOffset + 1,
                    month: prevMonth,
                    year: prevYear,
                    isCurrentMonth: false
                };
            } else if (dayOffset >= daysInMonth) {
                // Next month
                const { month: nextMonth, year: nextYear } = adjustMonthYear(month + 1, year);
                return {
                    day: day - daysInMonth,
                    month: nextMonth,
                    year: nextYear,
                    isCurrentMonth: false
                };
            }

            // Current month
            return {
                day,
                month,
                year,
                isCurrentMonth: true
            };
        });

        return days;
    };

    // Render the calendar grid using the days array
    const renderCalendarGrid = () => {
        const days = generateCalendar(currentYear, currentMonth);
        return days.map((day, index) => {
            const dateString = formatDate(day.year, day.month, day.day);
            const isStartDate = formatDateFromDb(startDate) === dateString;
            const isEndDate = formatDateFromDb(endDate) === dateString;
            const isDayHovered = isHovered(dateString); // Assuming you have an isHovered function
            let className = `day ${!day.isCurrentMonth ? 'other-month' : ''}`;

            if (isStartDate) {
                className += ' start-date';
            } else if (isEndDate) {
                className += ' end-date';
            } else if (isDateSelected(dateString)) {
                className += ' selected';
            }
            if (isCurrentDay(dateString)) {
                className += ' current-day';
            }
            if (isBeforeCurrentDay(dateString) && day.isCurrentMonth) {
                className += ' past';
            }
            if(isDateExcluded(dateString)) {
                className += ' excluded';
            }

            if (calendarDisabled) {
                className += ' disabled';
            }

            if(isDayHovered && !isDateSelected(dateString)) {
                className += ' hovered'; // Add 'hovered' class if the day is being hovered over
            }

            //get last hovered date
            const lastHoveredDate = hoveredRange[hoveredRange.length - 1];
            const isLastHoveredDate = lastHoveredDate === dateString;

            if(isLastHoveredDate) {
                className += ' last-hovered'; // Add 'last-hovered' class if the day is the last day in the hovered range
            }

            const handleClick = () => {
                if(isStartDate) {
                    handleResetEndDate();
                }else if (day.isCurrentMonth && !calendarDisabled && !isBeforeCurrentDay(dateString)) {
                    onSelect(dateString);
                }
            }

            return (
                <div
                    key={index}
                    className={className}
                    onClick={handleClick}
                    onMouseEnter={() => day.isCurrentMonth && !calendarDisabled && setHoveredDate(dateString) && handleMouseEnterDay(dateString)}
                    onMouseLeave={() =>{ !calendarDisabled && setHoveredDate(null)}}
                >
                    {
                        isDateExcluded(dateString) ?
                            <div className="day__excluded">{day.day}</div>
                            :
                            day.day
                    }
                </div>
            );
        });
    };



    return (
        <div className="calendar__container">
            <div className="calendar-navigation">
                <img src={arrowLeft} onClick={handlePreviousMonth} alt={"previous month"} />
                <span className="month-name">
                    {getMonthName(currentMonth)} {currentYear}
                </span>
                <img src={arrowRight} onClick={handleNextMonth} alt={"next month"} />
            </div>
            <div className="weekdays">
                {['lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.', 'dim.'].map((dayName, index) => (
                    <div key={index} className="weekday">{dayName}</div>
                ))}
            </div>
            <div className="days-grid">
                {renderCalendarGrid()}
            </div>
        </div>
    );
};

export default Calendar;
