import React, {useState, useContext} from 'react';
import check from '../../../assets/images/svg/check_round_green.svg';
import {Link, useNavigate} from "react-router-dom";
import arrow from '../../../assets/icones/arrows/left.svg';
import '../../../styles/views/verifications.css';
import {useDispatch} from "react-redux";
import SubmitButton from "../../../components/button/submitButton";
import Input from "../../../components/form/inputText/input";
import {useLoader} from "../../../context/loaderContext";
import useInputValidation from "../../../hooks/form/useInputValidation";
import PasswordResetServices from "../../../api/services/passwordReset";
import {ErrorContext} from "../../../context/errorContext";

const PasswordUserChange = () => {

    //get local storage email
    const navigate  = useNavigate();
    const dispatch = useDispatch();

    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const {setLoading} = useLoader();
    const { validateInput } = useInputValidation();
    const {resetPassword} = PasswordResetServices;
    const {showError} = useContext(ErrorContext);

    const handleChangePassword = (e) => {
        e.preventDefault();
        setLoading(true);

        const isPasswordValid = validateInput(password, 'password') === '' ? true : false;

        if(isPasswordValid) {
            if(password === passwordConfirm) {
                setError(false);

                const data = {
                    newPassword: password,
                    token: localStorage.getItem('ephemeralToken')
                }

                resetPassword(data)
                .then((response) => {
                    if (response.status === 200) {
                        localStorage.removeItem('ephemeralToken');
                        navigate('/password-reset/check');
                    }
                }).catch((error) => {
                    showError(error);
                }).finally(() => {
                    setLoading(false);
                });
            }else {
                setErrorMessage(true);
                setLoading(false);
            }
        }else {
            setError(true);
            setLoading(false);
        }
    }

    return (
        <div className="main verification">
            <div className="main__container__col password">
                <div className="main__container__col32">
                    <img src={check} alt="logo"/>
                </div>
                <div className="main__container__col32">
                    <h1 className={"title-25"}>Modification du mot de passe</h1>
                    <form className="form__login" onSubmit={handleChangePassword}>
                        <div className={"form__col column"}>
                            <Input
                                label={"Nouveau mot de passe"}
                                placeholder={"Entrez votre nouveau mot de passe"}
                                valueInput={password}
                                validationType={"password"}
                                onChangeForm={(e) => setPassword(e.target.value)}
                                error={error}
                                isColumn={true}
                                type={"text"}
                            />
                            <Input
                                label={"Confirmer le mot de passe"}
                                placeholder={"Confirmez votre nouveau mot de passe"}
                                valueInput={passwordConfirm}
                                validationType={"password"}
                                onChangeForm={(e) => setPasswordConfirm(e.target.value)}
                                type={"text"}
                                error={error}
                                isColumn={true}
                            />
                            {errorMessage && <p className={"is__error"}>Les mots de passe ne correspondent pas</p>}
                        </div>
                        <SubmitButton text={"Modifier le mot de passe"} isPrimary={true} isFull={true}/>
                    </form>
                </div>
                <div className="main__container__col32  action_row">
                    <img src={arrow} alt="arrow"/>
                    <Link to={"/login"} className={"text-14 bold"}>Retour à l'accueil</Link>
                </div>
            </div>
        </div>
    );
}

export default PasswordUserChange;