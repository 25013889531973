import React from 'react';

const Textarea = ({name, title, subtitle = false, placeholder, value, onChange, errorEmpty = false, errorMessage = false, maxLength = false}) => {


    return(
        <div className="form__col">
            <p className={"text-14"}>{title}</p>
            <textarea
                className={`form__textarea ${errorEmpty || errorMessage ? 'error' : null}`}
                name={name}
                id={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                maxLength={maxLength}></textarea>
            {subtitle ? <span className={"text-12"}>{subtitle}</span> : null}
        </div>
    )
}

export default Textarea;