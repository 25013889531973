import React from 'react';

import Button from "../../button/button";

const ModalUpdate = ({isOpen, setModalOpen, setReload}) => {

    const handleClick = () => {
        setReload(true);
        setTimeout(() => {
            setModalOpen(false);
        }, 100);
    }

    return(
        <div className={`bg__modal ${isOpen ? "open" : ""}`}>
            <div className={`modal verification ${isOpen ? "open" : ""}`}>
                <div className={"modal__header"}>
                    <div className={"modal__header__col"}>
                        <p className={"title-20"}>Votre modification a bien été enregistré ✅</p>
                        <div className={"modal__header__text"}>
                            <p className={"text-14"}>Vous pouvez dès à présent consulter vos changements dans votre espace partenaire.</p>
                        </div>
                        <Button text={`Retour à votre espace`} isPrimary={true} onClick={handleClick}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalUpdate;