import { useState } from 'react';

const useInputValidation = (initialState, validationType) => {
    const [value, setValue] = useState(initialState);
    const [error, setError] = useState('');

    const handleChange = (event) => {
        const inputValue = event.target.value;
        setValue(inputValue);
        const validationError = validateInput(inputValue, validationType);
        setError(validationError);
    };

    function isValidDate(dateString) {
        const date = new Date(dateString);
        return !isNaN(date.getTime());
    }

    const validateInput = (inputValue, type) => {
        switch (type) {
            case 'date':
                return isValidDate(inputValue) ? '' : 'Entrez une date valide';
            case 'phone':
                const phoneRegex = /^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$/;
                return phoneRegex.test(inputValue) ? '' : 'Entrez un numéro de téléphone valide à l\'aide du format suivant : 0123456789';
            case 'email':
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailRegex.test(inputValue) ? '' : 'Entrez une adresse mail valide à l\'aide du format suivant : example@gmail.com';
            case 'website':
                //lowercase and uppercase letters (a-z, A-Z)
                const websiteRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
                return websiteRegex.test(inputValue) ? '' : 'Entrez une adresse web valide à l\'aide du format suivant : https://www.example.com';
            case 'postalCode':
                const postalCodeRegex = /^[0-9]{5}$/;
                return postalCodeRegex.test(inputValue) ? '' : 'Entrez un code postal valide à l\'aide du format suivant : 75000';
            case 'siret':
                const siretRegex = /^\d{3}\s?\d{3}\s?\d{3}\s?\d{5}$/;
                return siretRegex.test(inputValue) ? '' : 'Entrez un numéro de SIRET valide à l\'aide du format suivant : 123 456 789 00012';
            case 'kbis':
                const kbisRegex = /^\d{3}\s?\d{3}\s?\d{3}$/;
                return kbisRegex.test(inputValue) ? '' : 'Entrez un numéro de KBIS valide à l\'aide du format suivant : 123 456 789';
            case 'iban':
                //FR76 3000 1005 5000 0000 0000 123
                const ibanRegex = /^[A-Z]{2}\d{2}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{3}$/;
                return ibanRegex.test(inputValue) ? '' : 'Entrez un numéro de RIB valide à l\'aide du format suivant : FR76 3000 1005 5000 0000 0000 123';
            case 'password':
                const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
                return passwordRegex.test(inputValue) ? '' : 'Le mot de passe doit contenir au moins 8 caractères, une majuscule et un chiffre';
            case 'text':
                return inputValue ? '' : 'Ce champ est requis';
            default:
                return '';
        }
    };



    return {
        value,
        error,
        validateInput,
        onChange: handleChange,
    };
};

export default useInputValidation;
