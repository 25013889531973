
import { postRequestFormData, deleteRequest } from '../request/apiRequest';

const PartnersDocuments = {

    create: async (partnerDocumentId, data) => {
        return await postRequestFormData(`/partners/documents/${partnerDocumentId}`, data);
    },

    delete: async (partnerId, documentFilename) => {
        return await deleteRequest(`/partners/documents/${partnerId}/${documentFilename}`);
    }
}

export default PartnersDocuments;