import React from 'react';

import Button from "../button/button";
import {ErrorContext} from "../../context/errorContext";
import {useContext} from "react";
import useParseError from "../../hooks/parseText/useParseError";

const ModalError = () => {

    const { error, hideError } = useContext(ErrorContext);
    const { parseMessage } = useParseError();

    if (!error) return null;

    return(
        <div className={`bg__modal error ${error ? "open" : ""}`}>
            <div className={`modal refund ${error ? "open" : ""}`}>
                <div className={"modal__header"}>
                    <div className={"modal__header__col"}>
                        <p className={"title-20"}>Une erreur est survenue ⚠️</p>
                    </div>
                </div>
                <div className={"modal__content center__container"}>
                    <div className={"modal__content__text"}>
                        <p className={"text-16 bold"}>{parseMessage(error)}</p>
                    </div>
                    <div className={"modal__content__text"}>
                        {
                            error === "Customer already has a subscription with the same plan" || error === "Missing required fields" || error === "Partner already has a subscription" ||
                            error === "This email already used" || error === "Partner already exists" || error === "Email and code are required" || error === "Invalid verification code" ||
                            error === "Verification code is expired" || error === "Verification code still valid, no need to send a new one." ?
                                <p className={"text-12 light"}>Veuillez vérifier les informations saisies.</p>
                                :
                                <p className={"text-12 light"}>Veuillez réessayer ultérieurement. Si le problème persiste, envoyez nous une demande à cette adresse <a href={"mailto:support@frimity.fr"}>support@frimity.fr</a></p>
                        }
                    </div>
                    <div className={"modal__content__div"}>
                        <Button text={`Fermer`} isPrimary={true} onClick={hideError} />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ModalError;