import React from 'react';
import '../../styles/components/button.css';

const SubmitButton = ({text, isFull}) => {

    return(
        <input type="submit" className={`btn btn-primary ${isFull ? "full" : ""}`} value={text}/>
    )
}

export default SubmitButton;