import React from 'react';
import {Link} from "react-router-dom";

const IconButton = ({icon, text, isPrimary, onClick, link = false, isFull = false, isRed= false, isDark = false, className, style}) => {

    return link ? (

        <Link to={link} className={`btn icon-btn ${isPrimary ? "is__primary" : "is__secondary"} ${isFull ? "full" : ""} ${isRed ? "red" : ""} ${isDark ? "black" : ""} ${className}`} style={style}>
            <img src={icon} alt="icon"/>
            <p className={"title-14"}>{text}</p>
        </Link>
    ) : (
        <div className={`btn icon-btn ${isPrimary ? "is__primary" : "is__secondary"} ${isFull ? "full" : ""} ${isRed ? "red" : ""} ${isDark ? "black" : ""} ${className}`} onClick={onClick}  style={style}>
            <img src={icon} alt="icon"/>
            {
                text ? <p className={"title-14"}>{text}</p> : null
            }
        </div>
    )

}

export default IconButton;