import React, {useState, useEffect, useContext} from 'react';
import HeaderView from "../../components/header/headerView";
import IconButton from "../../components/button/iconButton";
import logoutIcon from "../../assets/icones/global/logout.svg";
import phoneIcon from "../../assets/icones/global/phone.svg";
import mailIcon from "../../assets/icones/global/mail.svg";
import useAuth from "../../hooks/useAuth";
import '../../styles/views/settings.css';
import TabBar from "../../components/navbar/tabBar";
import Form from "../../components/form/form";
import { useSelector } from "react-redux";
import importFile from "../../assets/icones/global/import.svg";
import Button from "../../components/button/button";
import Input from "../../components/form/inputText/input";
import useImagesHandler from "../../hooks/parseFiles/useImagesHandler";
import UserAvatarServices from "../../api/services/userAvatar";
import UsersServices from "../../api/services/users";
import { useDispatch } from "react-redux";
import {userUpdateAvatar, userUpdate} from "../../redux/slice/authSlice";
import InputIcon from "../../components/form/inputText/inputIcon";
import InputPassword from "../../components/form/inputText/inputPassword";
import Tag from "../../components/tag/tag";
import {ErrorContext} from "../../context/errorContext";
import {useLoader} from "../../context/loaderContext";
import {usePartner} from "../../context/partnerContext";


const Account = () => {

    const { logout } = useAuth();
    const { currentPartnerId } = usePartner();
    const [userAvatar, setUserAvatar] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [type, setType] = useState("local");
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    //get current partner
    const currentPartner = useSelector((state) => state.auth.user.partnerID.filter((partner) => partner._id === currentPartnerId)[0] || null);

    const { handleChange, hiddenInputRef } = useImagesHandler({ imagesFiles: userAvatar, onChange: setUserAvatar, type: type });

    const [formDatas, setFormDatas] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phoneNumber: ""
    });

    //get user from redux
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    const handleSubmitImage = async () => {
        const imageData = new FormData();
        setLoading(true);

        if (userAvatar.length > 0) {
            const blob = await fetch(userAvatar[0].src).then((r) => r.blob());
            const file = new File([blob], userAvatar[0].name, { type: userAvatar[0].type });
            imageData.append('avatar', file);
        }
        if(imageData.has('avatar') && user._id){
            UserAvatarServices.createUserAvatar(user._id, imageData)
                .then((response) => {
                    if(response.status === 200){
                       UsersServices.getUserById(user._id)
                          .then((response) => {
                            if(response.status === 200){
                                dispatch(userUpdateAvatar(response.data.user.userAvatarID));
                                setRefresh(true);
                            }
                          })
                    }
                }).catch((error) => {
                    showError(error);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }

    const handleDeleteImage = async () => {
        setLoading(true);
        UserAvatarServices.deleteUserAvatar(user._id, user.userAvatarID.avatarMetadata.originalName)
            .then((response) => {
                if(response.status === 200){
                    UsersServices.getUserById(user._id)
                        .then((response) => {
                            if(response.status === 200){
                                dispatch(userUpdateAvatar(null));
                                //empty userAvatar array
                                setUserAvatar([]);
                            }
                        })
                }
            }).catch((error) => {
                showError(error);
            }).finally(() => {
                setLoading(false);
            });
    }

    const handleSubmit = (e) => {

        e.preventDefault();

        const initialData = user;
        const newData = formDatas;

        //compare initial data and new data to get only changed data
        const changedData = Object.keys(newData).reduce((acc, key) => {
            if (initialData[key] !== newData[key]) {
                acc[key] = newData[key];
            }
            return acc;
        }, {});

        if(Object.keys(changedData).length > 0){
            setLoading(true);
            UsersServices.updateUser(user._id, changedData)
                .then((response) => {
                    if(response.status === 200){
                        dispatch(userUpdate(response.data));
                    }
                }).catch((error) => {
                    showError(error);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        setFormDatas({
            firstname: user ? user.firstname : "",
            lastname: user ? user.lastname : "",
            email: user ? user.email : "",
            phoneNumber: user ? user.phoneNumber : ""
        });
    }, [refresh]);

    return user && (
        <div className={"main-container is__grey"}>
            <HeaderView title={"Paramètres"} isGoBack={false} actions={
                <>
                    <IconButton icon={logoutIcon} text={"Déconnexion"} isPrimary={false} onClick={logout} />
                </>
            } />

            <TabBar
                tabs={[
                    {
                        name: "Mon compte",
                        link: `/settings/account/`
                    },
                    {
                        name: "Mes préférences",
                        link: `/settings/preferences/`
                    },
                ]}
                activeTab={0}
            />

            <div className={"settings-container"}>
                <h2 className={"title-18"}>Vos informations de compte</h2>
                <div className={"settings-section isRowGap"}>
                        <div className={"settings-avatar"}>
                            <h3 className={"title-16"}>Photo de profil</h3>
                            {
                                user && user.userAvatarID && user.userAvatarID !== null ?
                                    <div className={"settings-avatar__img dash"}>
                                        <img src={user.userAvatarID.avatarURL} alt={"avatar"}/>
                                    </div>
                                :
                                    userAvatar.length > 0 ?
                                    <div className={"settings-avatar__img dash"}>
                                        <img src={userAvatar[0].preview} alt={"avatar"}/>
                                    </div>
                                :
                                <div className={"settings-avatar__img dash"}>
                                    <img src={importFile}className={"icon"}/>
                                    <div className={"input__files__custom__text"}>
                                        <p className={"text-14"}><span className={"text-14 bold"}>Cliquer ou déposer</span> une photo</p>
                                        <span className={"text-14"}>PNG, JPEG, JPG (max 250KB)</span>
                                    </div>
                                    <input type={"file"} name={"avatar"} accept={'image/png, image/jpeg, image/jpg'} ref={hiddenInputRef} onChange={(e) => handleChange(e)} />
                                </div>
                            }
                            <div className={"settings-avatar__button"}>
                                {
                                    user && user.userAvatarID ?
                                       <Button text={"Supprimer"} isPrimary={true} onClick={handleDeleteImage}/>
                                    :userAvatar.length > 0 ?
                                       <Button text={"Sauvegarder"} isPrimary={true} onClick={handleSubmitImage}/>
                                    :
                                      null
                                }
                            </div>
                        </div>
                        <div className={"settings-form"}>
                            <div className={"settings-section__container"}>
                                <Form
                                    children={
                                        <>
                                            <div className={"form__row"}>
                                                <Input label={"Prénom"} name={"firstname"} type={"text"} placeholder={"Prénom"} valueInput={formDatas.firstname} isColumn={true} onChangeForm={(e) => setFormDatas({...formDatas, firstname: e.target.value})}/>
                                                <Input label={"Nom"} name={"lastname"} type={"text"} placeholder={"Nom"} valueInput={formDatas.lastname} isColumn={true} onChangeForm={(e) => setFormDatas({...formDatas, lastname: e.target.value})} />
                                            </div>
                                            <div className={"form__row"}>
                                                <InputIcon title={"Email"} name={"email"} type={"email"} placeholder={"Email"} valueInput={formDatas.email} isColumn={true} icon={mailIcon} onChangeForm={(e) => setFormDatas({...formDatas, email: e.target.value})} />
                                                <InputIcon title={"Téléphone"} name={"phoneNumber"} type={"text"} placeholder={"Téléphone"} valueInput={formDatas.phoneNumber} isColumn={true} icon={phoneIcon} onChangeForm={(e) => setFormDatas({...formDatas, phoneNumber: e.target.value})} />
                                            </div>
                                            <div className={"form__row"}>
                                                <InputPassword
                                                    label={"Mot de passe"}
                                                    name={"password"}
                                                    type={"password"}
                                                    placeholder={"Mot de passe"}
                                                    valueInput={"password5267?."}
                                                    isColumn={true}
                                                    validationType={"password"}
                                                    buttonReset={true}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </>
                                    }
                                    onSubmit={handleSubmit}
                                />
                            </div>
                        </div>
                     </div>


                {
                    user && user.role === "partner" && user.partnerID &&
                        <div className={"settings-section"}>
                            <h2 className={"title-18 container__row"}>Votre établissement <Tag text={currentPartner.status.name === "active" ? "actif" : currentPartner.status.name} status={currentPartner.status.name} /></h2>
                            <div className={"settings-section__container"}>
                                {
                                    user && user.role === "partner" || user.role === "partners-admin" && user.partnerID &&
                                    <div className={"settings-section__container__col"}>
                                        <p className={"text-16 bold"}>Nom de l'établissement : <span className={"text-16 light"}>{currentPartner.name}</span></p>
                                        <p className={"text-16 bold"}>Nombre d'activités : <span className={"text-16 light"}>{currentPartner.activityID.length}</span></p>
                                        <p className={"text-16 bold"}>Nombre d'offres : <span className={"text-16 light"}>{currentPartner.offersID.length}</span></p>
                                    </div>
                                }
                            </div>
                        </div>
                }
            </div>
        </div>
    );
}

export default Account;