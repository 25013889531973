import React from 'react';
import '../../styles/components/card.css';

const HelpCard = ({title = false, text}) => {

    return (
        <div className={"main-card__help"}>
            <h2 className={"title-20"}>{title ? title : "Vous avez besoin d’aide ?"}</h2>
            <p className={"text-16"}>{text}</p>
            <div className={"main-card__help__contact"}>
                <a href={"mailto:contact@frimity.fr"} className={"text-18"}>📨 contact@frimity.fr</a>
                <a href={"tel:+33770486099"} className={"text-18"}>📞 07 70 48 60 99</a>
            </div>
        </div>
    )
}

export default HelpCard;