import React, {useContext, useEffect, useState} from 'react';
import '../../styles/views/singleActivity.css';
import HeaderView from "../../components/header/headerView";
import TabBar from "../../components/navbar/tabBar";
import Tag from "../../components/tag/tag";
import IconButton from "../../components/button/iconButton";
import trash from '../../assets/icones/global/trash_can.svg';
import Form from "../../components/form/form";
import Images from "./formActivity/images";
import Name from "./formActivity/name";
import Schedule from "./formActivity/schedule";
import Category from "./formActivity/category";
import NbPersons from "./formActivity/nbPersons";
import Duration from "./formActivity/duration";
import Description from "./formActivity/description";
import PraticalInfos from "./formActivity/praticalInfos";
import Expected from "./formActivity/expected";
import CancelPolicy from "./formActivity/cancelPolicy";
import Included from "./formActivity/included";
import BookingPolicy from "./formActivity/bookingPolicy";
import Access from "./formActivity/access";
import PricingAndExtras from "./formActivity/pricingAndExtras";
import {useParams} from "react-router";
import ActivitiesServices from "../../api/services/activities";
import useParseActivity from "../../hooks/parseDataApi/useParseActivity";
import ToKnow from "./formActivity/toKnow";
import useStatusName from "../../hooks/parseText/useStatusName";
import useFormUpdate from "../../hooks/form/useFormUpdate";
import {useNavigate} from "react-router-dom";
import useFormValidation from "../../hooks/form/useFormValidation";
import ActivitiesImages from "../../api/services/activitiesImages";
import ModalVerification from "../../components/modal/modalInfos/modalVerification";
import {ErrorContext} from "../../context/errorContext";
import {useLoader} from "../../context/loaderContext";
import PartnerSubscriptionServices from "../../api/services/partnerSubscription";
import RadioRound from "../../components/form/inputRadio/radioRound";
import Insurance from "./formActivity/insurance";
import {useSelector} from "react-redux";
import Types from "./formActivity/types";
import {usePartner} from "../../context/partnerContext";

const SingleActivity = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const partnerId = useParams().partnerId;
    const activityId = useParams().activityId;
    const [subscription, setSubscription] = useState(false);
    const navigate = useNavigate();
    const { parseActivity, parseActivityforUpdate} = useParseActivity();
    const { getStatusName } = useStatusName();
    const { validateForm } = useFormValidation();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const [isUpdated, setIsUpdated] = useState(false);
    const [errorIsUpdated, setErrorIsUpdated] = useState(false);

    const { currentPartnerId } = usePartner();
    const user = useSelector(state => state.auth.user);
    const currentPartner = useSelector((state) => state.auth.user.partnerID.filter((partner) => partner._id === currentPartnerId)[0] || null);


    const { formState, setFormState, handleFormChange, getFormChanges } = useFormUpdate(parseActivity([]));

    useEffect(() => {
        setLoading(true);
        try {
            PartnerSubscriptionServices.getSubscriptionByPartnerId(partnerId)
            .then((response) => {
                if(response.status === 200){
                    if(response.data && response.data.subscriptionPlan !== null) {
                        setSubscription(response.data.subscriptionPlan);
                    }else{
                        setSubscription(false);
                    }
                }else {
                    setSubscription(false);
                }
            })

           ActivitiesServices.getActivityById(activityId)
           .then((response) => {
               response.status === 200 ? setFormState(parseActivity(response.data)) : navigate(`/partners/${partnerId}/activities`);
           })
       } catch (error) {
           showError(error);
       }
        finally {
           setLoading(false);
       }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formIsValid = validateForm(formState, setFormState);

        if (formIsValid.isFormValid) {
            setLoading(true);
            const newActivityChanges = getFormChanges();
            const activityForApi = parseActivityforUpdate(newActivityChanges);
            const parseActivityImages = new FormData();

            if(activityForApi.activityImages && activityForApi.activityImages.images.length > 0){
                const localImages = activityForApi.activityImages.images.filter(img => img.isLocal);

                if (localImages.length > 0) {
                    for (const image of localImages) {
                        const blob = await fetch(image.src).then(res => res.blob());
                        const file = new File([blob], image.name, { type: image.type });
                        parseActivityImages.append('images', file);
                    }
                }
            }

            delete activityForApi.activityImages;

            if(parseActivityImages.has('images')){
                await ActivitiesImages.create(activityId, parseActivityImages, partnerId)
                .then((response) => {
                    if(response.status === 200){
                        setIsModalOpen(true);
                    }
                })
                .catch((error) => {
                    showError(error);
                }).finally(() => {
                        setLoading(false);
                });
            }


            await ActivitiesServices.updateActivity(activityId, partnerId, activityForApi)
            .then((response) => {
                if(response.status === 200){
                    setIsModalOpen(true);
                }
            })
            .catch((error) => {
                showError(error);
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const handleDelete = (activityId) => {
        ActivitiesServices.deleteActivity(activityId, partnerId)
            .then((response) => {
                if (response.status === 200) {
                    navigate(`/partners/${partnerId}/activities`);
                }
            })
            .catch((error) => {
                showError(error);
            })
    }

    return(
        <div className={"main-container is__grey"}>
            <HeaderView title={"Activité proposé"} />

            <TabBar
                tabs={[
                    {
                        name: "Fiche du partenaire",
                        link: `/partners/${partnerId}`
                    },
                    {
                        name: "Liste d’activités",
                        link: `/partners/${partnerId}/activities`,
                    },
                    {
                        name: "Gérer vos offres",
                        link: `/partners/${partnerId}/offers`,
                    },
                    {
                        name: "Plan tarifaire",
                        link: `/partners/${partnerId}/subscription`,
                    }
                ]}
                activeTab={1}
            />


            <div className={"header__container"}>
                <div className={"header__container__col"}>
                    <h2 lassName={"text-18 bold"}>Détail de vos activités</h2>
                    <p className={"text-14"}>Vous pouvez voir et modifier les informations concernant vos activités</p>
                </div>
            </div>

            <div className={"main-activity__container"}>
                <div className={"main-activity__container__row"}>
                    <div className={"container__row"}>
                        <h1>{formState.reference.value}</h1>
                        <Tag text={getStatusName(formState.status)} status={formState.status} />
                    </div>
                    <div className={"main-activity__container__buttons"}>
                        <IconButton icon={trash} text={"Supprimer"} isPrimary={false} onClick={() => handleDelete(activityId)} />
                    </div>
                </div>


                <Form
                    children={
                        formState &&
                        <>
                            <Images images={formState.images} setActivity={handleFormChange} type={"remote"}
                                    activityId={activityId} partnerId={partnerId} isImageChecked={true} />
                            <Name name={formState.name} setActivity={handleFormChange} isDisabled={user && user.role === "partner" || user.role === "partners-admin" ? true : false}/>
                            <Types type={formState.type} setActivity={handleFormChange} isDisabled={user && user.role === "partner"  || user.role === "partners-admin"?  true : false}/>
                            <Schedule mode={"edit"} schedule={formState.schedule} setActivity={handleFormChange}/>
                            <Category category={formState.category} setActivity={handleFormChange} isDisabled={user && user.role === "partner" || user.role === "partners-admin"? true : false}/>
                            <NbPersons nbPersons={formState.nbPersons} setActivity={handleFormChange}/>
                            <Duration duration={formState.duration} setActivity={handleFormChange}/>
                            <Description description={formState.description} setActivity={handleFormChange}/>
                            <PraticalInfos practicalInfo={formState.practicalInfo} setActivity={handleFormChange} isDisabled={user && user.role === "partner" || user.role === "partners-admin"? true : false}/>
                            <Expected expected={formState.expected} setActivity={handleFormChange}/>
                            <ToKnow toKnow={formState.toKnow} setActivity={handleFormChange}
                                    toKnowInfos={formState.toKnowInfos} toKnowOther={formState.toKnowOther}/>
                            <BookingPolicy bookingPolicy={formState.bookingPolicy} setActivity={handleFormChange}/>
                            <PricingAndExtras pricingAndExtras={formState.pricingAndExtras}
                                              setActivity={handleFormChange}
                                              commission={user.role === "partner" || user.role === "partners-admin" ? currentPartner.subscriptionID.commission : 0} isDisabled={user && user.role === "partner" ||  user.role === "partners-admin" ? true : false}/>
                            <Access access={formState.access} setActivity={handleFormChange} isDisabled={user && user.role === "partner" || user.role === "partners-admin" ? true : false}/>
                            <CancelPolicy cancel={formState.cancel} setActivity={handleFormChange}/>
                            <Included included={formState.included} setActivity={handleFormChange}/>
                            <Insurance insurance={formState.hasInsurance} setActivity={handleFormChange} isUpdated={true} setIsUpdated={setIsUpdated} errorIsUpdated={errorIsUpdated}/>
                        </>
                    }
                    onSubmit={handleSubmit}
                    mode={"edit"}
                />
            </div>

            <ModalVerification isOpen={isModalOpen} link={`/partners/${partnerId}/activities`} type={"activitée"}
                               action={"modifiée"}/>
        </div>
    )
}

export default SingleActivity;