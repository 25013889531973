import React from 'react';
import '../../styles/components/header.css';
import IconButton from "../button/iconButton";
import back from '../../assets/icones/arrows/left_chevron.svg';
import { useNavigate } from 'react-router-dom';

const HeaderView = ({title, actions, isGoBack = true, isActionsWithTitle = false}) => {

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    return isGoBack ? (
        <div className={"header-col"}>
            <div className={"header-view"}>
                {isGoBack ? <IconButton icon={back} text={"Retour"} isPrimary={false} onClick={goBack}/> : null}

                {!isActionsWithTitle ?<div className={"header-view__actions"}>{actions}</div> : null}
            </div>
            <div className={"header-view"}>
                <h1 className={"header_title"}>{title}</h1>
                {isActionsWithTitle ?<div className={"header-view__actions"}>{actions}</div> : null}
            </div>
        </div>
    ) : (
        <div className={"header-col"}>
            <div className={"header-view"}>
                <h1 className={"header_title"}>{title}</h1>
                {actions && <div className={"header-view__actions"}>
                    {actions}
                </div>}
            </div>
        </div>
    )
}

export default HeaderView;