// signInSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: null, // user data
    isEmailSent: false,
    token: null,
};

const signInSlice = createSlice({
    name: 'signIn',
    initialState,
    reducers: {
        setUserSignIn: (state, action) => {
            state.user = action.payload.user;
        },
        setEmailVerificationStatus: (state, action) => {
            state.user.isEmailVerified = true;
        },
        setEmailSent: (state, action) => {
            state.isEmailSent = true;
        },
        setSignInToken: (state, action) => {
            state.token = action.payload.token;
        },
        eraseAll: (state, action) => {
            //set all state to initial state
            state.user = initialState.user;
            state.isEmailSent = initialState.isEmailSent;
            state.token = initialState.token;
        },
    },
});

export const {
    setEmailVerificationStatus,
    setUserSignIn,
    setEmailSent,
    setSignInToken,
    eraseAll
} = signInSlice.actions;
export default signInSlice.reducer;
