//all the request to the api will be done here
import axios from 'axios'
import {apiConfig} from '../config/apiconfig'
import {store} from '../../redux/store'

export const httpClient = axios.create({
    baseURL: apiConfig.baseURL,
    timeout: apiConfig.timeout,
    headers: apiConfig.headers
});


httpClient.interceptors.request.use(
    (config) => {
        const authToken = store.getState().auth.token;
        const signInToken = store.getState().signIn.token;

        if (authToken && authToken !== null) {
            // Set the authorization header with the bearer token
            config.headers.Authorization = `Bearer ${authToken}`;
        }else if(signInToken && signInToken !== null){
            config.headers.Authorization = `Bearer ${signInToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const getRequest = async (url, params = {}) => {
    try {
        const response = await httpClient.get(`${process.env.REACT_APP_API_URL}` + url, { params });
        return { data: response.data, status: response.status };
    } catch (error) {
        throw error.response.data.error;
    }
};

export const postRequestJson = async (url, body) => {
    try{
        const response = await httpClient.post(`${process.env.REACT_APP_API_URL}` + url, body);
        return {data: response.data, status: response.status};
    }catch (error){
        throw error.response.data.error;
    }
}

export const postRequestFormData = async (url, body) => {
    try{
        const response = await httpClient.post(`${process.env.REACT_APP_API_URL}` + url, body, { headers: { 'Content-Type': 'multipart/form-data' }});
        return {data: response.data, status: response.status};
    }catch (error) {
        throw error.response.data.error;
    }
}


export const deleteRequest = async (url) => {
    try{
        const response = await httpClient.delete(`${process.env.REACT_APP_API_URL}` + url);
        return {data: response.data, status: response.status};
    }catch (error){
        throw error.response.data.error;
    }
}

export const patchRequest = async (url, body) => {
    try{
        const response = await httpClient.patch(`${process.env.REACT_APP_API_URL}` + url, body);
        return {data: response.data, status: response.status};
    }catch (error){
        throw error.response.data.error;
    }
}

//post request with no token
export const postRequestJsonNotToken = async (url, data) => {
    try{
        const response = await axios.post(`${process.env.REACT_APP_API_URL}` + url, data);
        return {data: response.data, status: response.status};
    }catch (error){
        throw error.response.data.error;
    }
}



