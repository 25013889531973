import React, {useContext} from 'react';
import HeaderView from "../../components/header/headerView";
import Form from "../../components/form/form";
import Company from "./formPartner/company";
import Personal from "./formPartner/personal";
import LegalDocument from "./formPartner/legalDocument";
import '../../styles/views/singlePartner.css';
import useParsePartner from "../../hooks/parseDataApi/useParsePartner";
import PartnersServices from "../../api/services/partners";
import PartnersDocuments from "../../api/services/partnersDocuments";
import {useNavigate} from "react-router-dom";
import useFormValidation from "../../hooks/form/useFormValidation";
import useFormUpdate from "../../hooks/form/useFormUpdate";
import {ErrorContext} from "../../context/errorContext";
import {useLoader} from "../../context/loaderContext";
import Booking from "./formPartner/booking";


const AddPartner = () => {

    const navigate = useNavigate();
    const { parsePartnerForApi, parsePartner } = useParsePartner();
    const { validateForm } = useFormValidation();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const { formState, setFormState, handleFormChange } = useFormUpdate(parsePartner([]));

    const handleSubmit = async (event) => {
        event.preventDefault();
        const isFormValid = validateForm(formState, setFormState);

       if(isFormValid.isFormValid){
           setLoading(true);
            const partnerForApi= parsePartnerForApi(formState);
           const parsePartnerFiles = new FormData();

           const localDocuments = partnerForApi.legalInfoID.legalDocuments.filter(doc => doc.isLocal);

           if (localDocuments.length > 0) {
               for (const document of localDocuments) {
                   const blob = await fetch(document.src).then(res => res.blob());
                   const file = new File([blob], document.name, { type: document.type });
                   parsePartnerFiles.append('documents', file);
               }
           }

           delete partnerForApi.legalInfoID.legalDocuments;

           PartnersServices.createPartner(partnerForApi)
            .then((response) => {
                if(response.status === 200){
                    if (parsePartnerFiles.has('documents')) {
                        const partnerId = response.data._id;
                        PartnersDocuments.create(partnerId, parsePartnerFiles)
                        .then((response) => {
                            if (response.status === 200) {
                                setLoading(false);
                                navigate(`/partners/${partnerId}`);
                                window.scroll(0, 0)
                            }
                        })
                        .catch((error) => {
                            showError(error);
                        })
                    }else {
                        setLoading(false);
                        navigate(`/partners/${response.data._id}`);
                        window.scroll(0, 0)
                    }
                }
            })
            .catch((error) => {
                showError(error);
            })
       }
    }

    return(
        <div className={"main-container is__grey"}>
            <HeaderView title={"Ajouter un partenaire"} actions={
                <></>
            }/>


            <div className={"header__container"}>
                <h2 className={"title-16"}>Informations sur le partenaire</h2>
                <p className={"text-14"}>Vous pouvez ajouter ici les informations du partenaire</p>
            </div>

            <div className={"main-infos__container"}>
                <div className={"main-infos__container__col"}>
                    <h1 className={"title-20"}>{"Nouveau partenaire"}</h1>
                </div>
            </div>

            <Form
                title={"Informations sur le partenaire"}
                children={
                    <>
                        <Company setPartner={handleFormChange} company={formState.company} />
                        <Booking setPartner={handleFormChange} booking={formState.booking} />
                        <Personal mode={"add"} setPartner={handleFormChange} personal={formState.personal} />
                        <LegalDocument setPartner={handleFormChange} documents={formState.legalDocuments} type={"local"} />
                    </>
                }
                mode={"add"}
                onSubmit={handleSubmit}
            />

        </div>
    )

}

export default AddPartner;