import Input from "../../../../components/form/inputText/input";
import Button from "../../../../components/button/button";
import React, {useEffect, useState} from "react";
import Radio from "../../../../components/form/inputRadio/radio";
import mail from "../../../../assets/icones/global/mail.svg";
import tel from "../../../../assets/icones/global/phone.svg";
import Checkbox from "../../../../components/form/inputCheckbox/checkbox";

const InformationsPersonel = ({setPartner, personal, setStep}) => {

    const [checked, setChecked] = useState([]);

    const handleRadio = (e) => {
        const { name, value } = e.target;

        //add or remove value from checked array
        let newChecked = [...checked];
        if (newChecked.includes(value)) {
            newChecked = newChecked.filter((item) => item !== value);
            setPartner(`personalInfo.${name}.value`, newChecked);
        } else {
            newChecked.push(value);
            setPartner(`personalInfo.${name}.value`, newChecked);
        }
        setChecked(newChecked);
    };

    const handleChange= (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        setPartner(`personalInfo.${name}.value`, value);
    };

    useEffect(() => {
        setChecked(personal.communicationPreference.value);
    }, []);

    return (
        <div className={"main__container__form__col"}>
            <div className={"main__container__form__col__title"}>
                <h1 className={"title-25"}>Vos informations personnel</h1>
            </div>

            <form className={"form__signin"}>
                <div className={"form__signin__row"}>
                    <Input
                        label={personal.lastname.label}
                        name={"lastname"}
                        placeholder={personal.lastname.placeholder}
                        type={"text"}
                        validationType={personal.lastname.validationType}
                        isColumn={true}
                        onChangeForm={handleChange}
                        valueInput={personal.lastname.value}
                        errorEmpty={personal.lastname.error}
                        errorMessage={personal.lastname.errorMessage}
                        isRequired={true}
                    />
                    <Input
                        label={personal.firstname.label}
                        name={"firstname"}
                        placeholder={personal.firstname.placeholder}
                        type={"text"}
                        validationType={personal.firstname.validationType}
                        isColumn={true}
                        onChangeForm={handleChange}
                        valueInput={personal.firstname.value}
                        errorEmpty={personal.firstname.error}
                        errorMessage={personal.firstname.errorMessage}
                        isRequired={true}
                    />
                </div>
                <Input
                    label={personal.email.label}
                    name={"email"}
                    placeholder={personal.email.placeholder}
                    type={"email"}
                    validationType={personal.email.validationType}
                    isColumn={true}
                    onChangeForm={handleChange}
                    valueInput={personal.email.value}
                    errorEmpty={personal.email.error}
                    errorMessage={personal.email.errorMessage}
                    isRequired={true}
                />
                <Input
                    label={personal.phone.label}
                    name={"phone"}
                    placeholder={personal.phone.placeholder}
                    type={"text"}
                    validationType={personal.phone.validationType}
                    isColumn={true}
                    onChangeForm={handleChange}
                    valueInput={personal.phone.value}
                    errorEmpty={personal.phone.error}
                    errorMessage={personal.phone.errorMessage}
                    isRequired={true}
                />
                <div className={"form__row column"}>
                    <label className={"form__row__label"}>Préférence de communication <span className={"is__required"}>*</span></label>
                    <div className={`row__input__radio`}>
                        <Checkbox
                            name={"communicationPreference"}
                            value={"email"}
                            label={"Email"}
                            onChange={handleRadio}
                            isChecked={checked.includes("email")}
                            icon={mail}
                            errorEmpty={personal.communicationPreference.errorMessage}
                        />
                        <Checkbox
                            name={"communicationPreference"}
                            value={"phone"}
                            label={"Téléphone"}
                            onChange={handleRadio}
                            isChecked={checked.includes("phone")}
                            icon={tel}
                            errorEmpty={personal.communicationPreference.errorMessage}
                        />
                    </div>
                </div>
            </form>

            <Button text={"Suivant"} isFull={true} onClick={() => setStep(3)} isPrimary={true}/>
        </div>
    )
}

export default InformationsPersonel;