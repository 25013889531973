import React, {useContext, useEffect, useState} from "react";
import SubscriptionCard from "../../../components/stripe/subscriptionCard";
import SubsrciptionsServices from "../../../api/services/subscriptions";
import '../../../styles/views/subscription.css';
import loop from "../../../assets/images/svg/loop.svg";
import check from "../../../assets/images/svg/check_round_green.svg";
import {useSelector} from "react-redux";
import StripeSubscription from "../../../api/services/stripeSubscription";
import {useNavigate} from "react-router-dom";
import UserSubscriptionServices from "../../../api/services/stripeSubscription";
import StripeServices from "../../../api/services/stripe";
import {ErrorContext} from "../../../context/errorContext";
import {useLoader} from "../../../context/loaderContext";
import {useParams} from "react-router";
import {usePartner} from "../../../context/partnerContext";


const SubscriptionPlan = () => {

    const [subscription, setSubscription] = useState(null);
    const user = useSelector((state) => state.auth.user);
    const navigate = useNavigate();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const { currentPartnerId } = usePartner();

    const [freeProductPriceID, setFreeProductPriceID] = useState(null);

    useEffect(() => {
        setLoading(true);
        SubsrciptionsServices.getSubscriptions().then((response) => {
            if (response.status === 200) {
                setSubscription(response.data);

                StripeServices.getProductById({stripeProductId: response.data[0].stripeSubscriptionId}).then((response) => {
                    if (response.status === 200) {
                        setFreeProductPriceID(response.data.default_price);
                    }
                }).catch((error) => {
                    showError(error);
                });
            }
        }).catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const handlePlanChoice = (productId) => {
        setLoading(true);
        //create stripe customer with email, name, partnerID
        const partner = user.partnerID.find(partner => partner._id === currentPartnerId);

        const data = {
            partnerId: currentPartnerId,
            email: partner.email,
            name: partner.name,
        }

        StripeSubscription.createCustomer(data).then((response) => {
            if (response.status === 200) {
                navigate(`/stripe-payement/${currentPartnerId}/${productId}`);
            }
        }).catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleFreeSubscription = () => {
        setLoading(true);

        const partner = user.partnerID.find(partner => partner._id === currentPartnerId);

        const data = {
            priceId: freeProductPriceID,
            partnerId: currentPartnerId,
            email: partner.email,
            name: partner.name,
        }

        UserSubscriptionServices.createFreeSubscription(data).then((response) => {
            if (response.status === 200) {
                navigate(`/stripe-payement/confirmation`);
            }
        }).catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className="main">
            <div className="main__container subscription">
                <div className={"header__container"}>
                    <div className="main__container__col32">
                        <img src={check} alt="logo"/>
                    </div>
                    <div className="main__container__col32">
                        <h1 className={"title-25"}>Choisissez votre plan</h1>
                        <p className={"text-14 light"}>Choisissez le plan qui vous convient le mieux.</p>
                    </div>
                </div>

                <div className={"subscription__container"}>
                    <div className={"subscription__content welcome__subscription"}>
                        {
                            subscription && subscription.map((item, index) => {
                                return (
                                    <SubscriptionCard
                                        subscription={subscription}
                                        plan={item}
                                        key={index}
                                        isFirst={true}
                                        onClick={() => item.name === "essentiel" ? handleFreeSubscription() : handlePlanChoice(item.stripeSubscriptionId)}
                                        activePlan={null}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
                <img src={loop} className={"frimity_loop"} alt="logo"/>
            </div>
        </div>
    );
}

export default SubscriptionPlan;