import React, {useContext, useEffect, useState} from 'react';
import back from "../../../assets/icones/arrows/left_chevron.svg";
import IconButton from "../../../components/button/iconButton";
import {useParams} from "react-router";
import StripeServices from "../../../api/services/stripe";
import Loader from "../../../components/loader/loader";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "./form/checkoutForm";
import '../../../styles/views/stripe-payement.css';
import loop from "../../../assets/images/svg/loop.svg";
import PayementServices from "../../../api/services/payement";
import {useSelector} from "react-redux";
import PartnerSubscriptionServices from "../../../api/services/partnerSubscription";
import UserSubscriptionServices from "../../../api/services/stripeSubscription";
import {ErrorContext} from "../../../context/errorContext";
import {useLoader} from "../../../context/loaderContext";
import {useNavigate} from "react-router-dom";
import SubsrciptionsServices from "../../../api/services/subscriptions";
import check from "../../../assets/icones/global/check_circle.svg";
import {usePartner} from "../../../context/partnerContext";



const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

const StripePayment = () => {

    const { currentPartnerId } = usePartner();

    const stripeProductId = useParams().stripeProductId;
    const [stripeProduct, setStripeProduct] = useState(null);
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
    const [reload, setReload] = useState(false);
    const [clientSecret, setClientSecret] = useState(null);
    const [stripeCustomerId, setStripeCustomerId] = useState(null);
    const [subscription, setSubscription] = useState(null); // [subscription, setSubscription] = useState([
    const partnerID = currentPartnerId;
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };


    useEffect(() => {
        setReload(false);
        setLoading(true);

        const fetchData = async () => {
            try {
                const productResponse = await StripeServices.getProductById({
                    stripeProductId: stripeProductId.toString(),
                });
                if (productResponse.data) {
                    setStripeProduct(productResponse.data);
                }
                const subscriptionResponse = await PartnerSubscriptionServices.getSubscriptionByPartnerId(partnerID);
                if (subscriptionResponse.status === 200 && subscriptionResponse.data) {
                    const setupIntentResponse = await PayementServices.createSetupIntent({
                        customerId: subscriptionResponse.data.stripeCustomerId,
                        productId: stripeProductId,
                    });

                    setStripeCustomerId(subscriptionResponse.data.stripeCustomerId);
                    setClientSecret(setupIntentResponse.data.client_secret);

                    const paymentMethodResponse = await UserSubscriptionServices.getCustomerbyId(subscriptionResponse.data.stripeCustomerId);

                        if (paymentMethodResponse.status === 200 && paymentMethodResponse.data) {
                            setDefaultPaymentMethod(paymentMethodResponse.data.customer.invoice_settings.default_payment_method);
                        }
                }

                const allSubscriptions = await SubsrciptionsServices.getSubscriptions();
                if (allSubscriptions.status === 200) {
                    //filter with stripeProductId
                    setSubscription(allSubscriptions.data.filter(sub => sub.stripeSubscriptionId === stripeProductId)[0]);
                }
            } catch (error) {
                showError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [stripeProductId, partnerID, reload]);

    if (!stripeProduct || !clientSecret) {
        return <div>Unable to load product details or setup payment.</div>;
    }

    const elementsOptions = {
        clientSecret: clientSecret, // This should be the client secret from the PaymentIntent
        appearance: {
            theme: 'stripe',
        },
        layout: {
            type: 'tabs',
            defaultCollapsed: false,
        }
    };


    return setStripeProduct  && clientSecret ? (
            <div className="main">
                <div className="main__container stripe-payement">
                    <div className={"stripe-payement"}>
                        <div className={"stripe-payement__container checkout"}>
                            <IconButton icon={back} text={"Retour"} isPrimary={false} onClick={goBack}/>

                            <div className={"stripe-payement__container__infos"}>
                                { stripeProduct.name.toLowerCase() !== 'essentiel' ? <div className={"pastille-stripe yellow"}>3 mois offerts</div> : null}
                                <p className={"stripe-title"}>Souscription à l'Offre {stripeProduct.name.toLowerCase().charAt(0).toUpperCase() + stripeProduct.name.toLowerCase().slice(1)}</p>
                                <p className={"text-14 light"}>{stripeProduct.description}</p>
                            </div>

                            <div className={"stripe-payement__container__card"}>
                                <p className={"title-16"}>Payer avec :</p>
                                { clientSecret && stripeCustomerId && (
                                    <Elements stripe={stripePromise} options={elementsOptions}>
                                        <CheckoutForm
                                            customerId={stripeCustomerId}
                                            productPrice={stripeProduct.default_price}
                                            client_secret={clientSecret}
                                            partnerID={partnerID}
                                            defaultCard={defaultPaymentMethod}
                                            setReload={setReload}
                                            subscription={{price: stripeProduct.metadata.price, engagement: stripeProduct.metadata.engagement, name: stripeProduct.name.toLowerCase()}}
                                        />
                                    </Elements>
                                )}
                            </div>

                        </div>
                        <div className={"stripe-payement__container is__grey"}>
                            <div className={"stripe-payement__container__infos gap-8"}>
                                <div className={"pastille-stripe black"}>Offre {stripeProduct.name.toLowerCase()}</div>
                                <p className={"stripe-title"}>Récapitulatif du paiement</p>
                            </div>

                            <div className={"stripe-payement__container__infos is__row"}>
                                <div className={"stripe-payement__col"}>
                                    <p className={"text-16 bold"}>Plan {stripeProduct.name}</p>
                                </div>
                                { stripeProduct.name.toLowerCase() !== 'essentiel' ? <div className={"stripe-payement__col end"}>
                                    <p className={"text-16 bold"}>3 mois offerts</p>
                                    <p className={"text-16 light"}>puis {stripeProduct.metadata ? stripeProduct.metadata.price : null}€/mois
                                        pendant {stripeProduct.metadata ? stripeProduct.metadata.engagement : null} mois</p>
                                </div> :
                                <div className={"stripe-payement__col end"}>
                                    <p className={"text-18 bold"}>{stripeProduct.metadata ? stripeProduct.metadata.price : null}€/mois</p>
                                </div>}
                            </div>

                            <div className={"stripe-payement__container__infos is__row"}>
                                <div className={"subscription__plan__content"}>
                                    <p className={"text-16"}>Services Intégrés </p>
                                    <ul className={"subscription__plan__list"}>
                                        {
                                            subscription && subscription.avantages.map((avantage, index) => {
                                                return (
                                                    <li key={index}><img src={check} alt={"check"}/> {avantage}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <img src={loop} className={"frimity_loop"} alt="logo"/>
                    </div>
                </div>
            </div>
    ) : <Loader/>

}

export default StripePayment;