import React, {useEffect, useState} from 'react';
import Form from "../form/form";
import Input from "../form/inputText/input";
import InputIcon from "../form/inputText/inputIcon";
import mailIcon from "../../assets/icones/global/mail.svg";
import phoneIcon from "../../assets/icones/global/phone.svg";
import InputSelect from "../form/inputSelect/inputSelect";
import close from "../../assets/icones/global/close-stroke.svg";
import lock from "../../assets/icones/global/lock.svg";
import '../../styles/components/sidebar.css';
import userParseUser from "../../hooks/parseDataApi/userParseUser";
import useFormUpdate from "../../hooks/form/useFormUpdate";
import useFormValidation from "../../hooks/form/useFormValidation";
import UsersServices from "../../api/services/users";
import PartnersServices from "../../api/services/partners";
import {useContext} from "react";
import {ErrorContext} from "../../context/errorContext";

const SidebarUser = ({user = [], type = "add", setRefresh, closeModal, isModalOpen}) => {

    const {parseUserUpdate, parseUser, parseUserForApi, parseUserForApiUpdate} = userParseUser();
    const { formState, setFormState, handleFormChange, getFormChanges } = useFormUpdate(type === "add" ? parseUser(user) : parseUserUpdate(user));
    const { validateForm } = useFormValidation();

    const [partners, setPartners] = useState([]);

    const [userPartners, setUserPartners] = useState([]);
    const { showError } = useContext(ErrorContext);


    useEffect(() => {
        PartnersServices.getPartners()
        .then((response) => {
            if(response.status === 200){
                setPartners(response.data);
                if (type === "edit"){
                    //remove user.partnerID from partners
                    setPartners(response.data.filter((partner) => !user.partnerID.find((partnerID) => partnerID._id === partner._id)));
                }else if(type === "add") {
                    setPartners(response.data);
                }
            }
        })
        .catch((error) => {
           showError(error);
        })

        if(type === "edit" && user.partnerID){
            //setUserPartners with name and id depending on partners
            setUserPartners(user.partnerID.map((partner) => {
                return {
                    name: partner.name,
                    _id: partner._id
                }
            }));
        }
    }, [user]);

    const handleSelectChange = (e, typeAction) => {
        if (typeAction === "add"){
            setUserPartners([...userPartners, partners.find((partner) => partner._id === e.target.value)]);
            setPartners(partners.filter((partner) => partner._id !== e.target.value));

            setFormState({
                ...formState,
                partnerID: {
                    ...formState.partnerID,
                    value: [...formState.partnerID.value, e.target.value]
                }
            })
        }else if(typeAction === "remove"){
            setPartners([...partners, userPartners.find((userPartner) => userPartner._id === e)]);
            setUserPartners(userPartners.filter((userPartner) => userPartner._id !== e));

            //remove partnerID from formState.partnerID.value
            setFormState({
                ...formState,
                partnerID: {
                    ...formState.partnerID,
                    value: formState.partnerID.value.filter((partnerID) => partnerID._id !== e)
                }
            });
        }
    }

    const handleAddUser = (event) => {
        event.preventDefault();

        //set formState.partnerID.value to the list of partner selected
        setFormState({
            ...formState,
            partnerID: {
                ...formState.partnerID,
                value: userPartners.map((partner) => partner._id)
            }
        });

        const isFormValid = validateForm(formState, setFormState);

        if(formState.password.value !== formState.passwordConfirm.value){
            setFormState({
                ...formState,
                passwordConfirm: {
                    ...formState.passwordConfirm,
                    errorMessage: "Les mots de passe ne correspondent pas"
                }
            })

            isFormValid.isFormValid = false;
        }

        if(isFormValid.isFormValid){
            const userForApi = parseUserForApi(formState);
            UsersServices.createUser(userForApi)
            .then((response) => {
                if(response.status === 200){
                    setRefresh(true);
                    closeModal();
                }
            })
            .catch((error) => {
                showError(error);
            })
        }
    };

    const handleUpdateUser = async (event) => {
        event.preventDefault();
        setFormState({
            ...formState,
            partnerID: {
                ...formState.partnerID,
                value: userPartners
            }
        });
        const isFormValid = validateForm(formState, setFormState);

        if(isFormValid.isFormValid){
            const userForApi = parseUserForApiUpdate(formState);
            UsersServices.updateUser(user._id, userForApi)
            .then((response) => {
                if(response.status === 200){
                    setRefresh(true);
                    closeModal();
                }
            })
            .catch((error) => {
                showError(error);
            })
        }else{
            showError("Le formulaire n'est pas valide");
        }
    }

    return(
        <div className={`bg_sidebar ${isModalOpen ? "open" : ""}`}>
            <div className={`sidebar ${isModalOpen ? "open" : ""}`}>
                <div className={"sidebar__container"}>
                    <div className={"sidebar__container__user"}>
                        <div className={"sidebar__container__user__avatar"}>
                            <h2 className={"title-16"}>{type === "add" ? "Ajouter un utilisateur" : "Modifier un utilisateur"}</h2>
                            <img src={close} alt={"Fermer"} className={"sidebar__container__user__avatar__close"} onClick={closeModal}/>
                        </div>
                        <div className={"sidebar__container__user__infos"}>
                            <Form
                                children={
                                    <>
                                        <Input label={"Prénom"} name={"firstname"} type={"text"} placeholder={"Jean"}
                                               valueInput={formState.firstname.value} isColumn={true}
                                               validationType={"text"}
                                               errorMessage={formState.firstname.errorMessage}
                                               errorEmpty={formState.firstname.error}
                                               onChangeForm={(e) => handleFormChange(`firstname.value`, e.target.value)}/>
                                        <Input label={"Nom"} name={"lastname"} type={"text"} placeholder={"Didier"}
                                               errorEmpty={formState.lastname.error}
                                               errorMessage={formState.lastname.errorMessage}
                                               valueInput={formState.lastname.value} isColumn={true}
                                               validationType={"text"}
                                                 onChangeForm={(e) => handleFormChange(`lastname.value`, e.target.value)}/>
                                        <InputIcon title={"Email"} name={"email"} type={"email"} placeholder={"j.didier@gmail.com"}
                                                   valueInput={formState.email.value} isColumn={true} icon={mailIcon}
                                                   validationType={"email"}
                                                   errorMessage={formState.email.errorMessage}
                                                   errorEmpty={formState.email.error}
                                                      onChangeForm={(e) => handleFormChange(`email.value`, e.target.value)}/>
                                        <InputIcon title={"Téléphone"} name={"phoneNumber"} type={"text"}
                                                   placeholder={"0876565765"} valueInput={formState.phoneNumber.value}
                                                   validationType={"phone"}
                                                   errorMessage={formState.phoneNumber.errorMessage}
                                                   errorEmpty={formState.phoneNumber.error}
                                                   isColumn={true} icon={phoneIcon}
                                                        onChangeForm={(e) => handleFormChange(`phoneNumber.value`, e.target.value)}/>

                                        <div className={"form_group_tagContainer"}>

                                            <InputSelect
                                                label={"Établissements"}
                                                name={"partnerID"}
                                                placeholder={"Sélectionnez des établissements"}
                                                value={formState.partnerID.value}
                                                errorEmpty={formState.partnerID.error}
                                                errorMessage={formState.partnerID.errorMessage}
                                                isColumn={true}
                                                multiple={true}
                                                options={partners.map((partner) => {
                                                    return {
                                                        value: partner._id,
                                                        name: partner.name
                                                    }
                                                })}
                                                onChange={(e) => handleSelectChange(e, "add")}
                                            />

                                            <div className={"form__group__tag"}>
                                                {
                                                    userPartners.map((partner) => {
                                                        return (
                                                            <div className={"form__group__tag__item"} key={partner._id}>
                                                                <p>{partner.name}</p>
                                                                <img className="form__group__tag__icone" src={close}
                                                                     alt
                                                                     onClick={(e) => handleSelectChange(partner._id, "remove")}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                        </div>
                                        {
                                            type === "add" ?
                                                <>
                                                    <InputIcon
                                                        title={"Mot de passe"}
                                                        icon={lock}
                                                        name={"password"}
                                                        placeholder={"Mot de passe"}
                                                        valueInput={formState.password.value}
                                                        errorEmpty={formState.password.error}
                                                        errorMessage={formState.password.errorMessage}
                                                        validationType={"password"}
                                                        isColumn={true}
                                                        onChangeForm={(e) => handleFormChange(`password.value`, e.target.value)}
                                                    />
                                                    <InputIcon
                                                        title={"Confirmation mot de passe"}
                                                        icon={lock}
                                                        name={"passwordConfirm"}
                                                        placeholder={"Confirmation mot de passe"}
                                                        valueInput={formState.passwordConfirm.value}
                                                        errorEmpty={formState.passwordConfirm.error}
                                                        errorMessage={formState.passwordConfirm.errorMessage}
                                                        isColumn={true}
                                                        validationType={"password"}
                                                        onChangeForm={(e) => handleFormChange(`passwordConfirm.value`, e.target.value)}
                                                    />
                                                </>
                                                :
                                                null
                                        }
                                    </>
                                }
                                onSubmit={type === "add" ? (e) => handleAddUser(e) : (e) => handleUpdateUser(e)}
                                mode={type !== "add" ? "edit" : "add"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SidebarUser;