import React from 'react';

const FormSection = ({title, children}) => {

        return(
            <div className={"form-section"}>
                <h2 className={"title-20"}>{title}</h2>
                {
                    children
                }
            </div>
        )
}

export default FormSection;