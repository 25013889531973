import { useState, useEffect } from 'react';

const useFilter = (initialFilters, data, filterFunction) => {
    const [filters, setFilters] = useState(initialFilters);
    const [filteredData, setFilteredData] = useState(data);


    useEffect(() => {
        if (data) {
            setFilteredData(data.filter(item => filterFunction(item, filters)));
        }
    }, [data, filters]); // Still don't include filterFunctionRef in dependencies

    const updateFilters = (newFilters) => {
        setFilters(newFilters);
    };

    return { filters, updateFilters, filteredData };
};

export default useFilter;
