import React from 'react';
import Button from "../../button/button";
import close from "../../../assets/icones/global/close-stroke.svg";
import deleting from "../../../assets/images/svg/deleting_icon.svg";

const modalFilesDeleting = ({isOpen, closeModal, deleteFile}) => {

    const handleDelete = () => {
        deleteFile();
        closeModal();
    }

    return(
        <div className={`bg__modal ${isOpen ? "open" : ""}`}>
            <div className={`modal deleting ${isOpen ? "open" : ""}`}>
                <div className={"modal__header"}>
                    <div className={"modal__header__col"}>
                        <div className={"modal__delete__icon"}>
                            <img src={deleting} alt={"deleting"}/>
                        </div>
                        <div className={"modal__header__text"}>
                            <p className={"title-14"}>Voulez-vous supprimer ce fichier ?</p>
                            <p className={"text-14"}>Cette action sera sans possibilité de retour.</p>
                        </div>
                    </div>
                    <div className={"modal__header__col"}>
                        <div className={"modal__close"} onClick={closeModal}>
                            <img src={close} alt={"close"}/>
                        </div>
                    </div>
                </div>
                <div className={"modal__content"}>
                    <div className={"modal__content__actions"}>
                        <Button text={"Annuler"} isPrimary={false} onClick={closeModal} />
                        <Button text={"Supprimer"} isRed={true} onClick={handleDelete} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default modalFilesDeleting;