import React from 'react';
import {Link} from 'react-router-dom';
import eye from '../../assets/icones/global/eye.svg';
import infos from '../../assets/icones/global/alert-circle.svg';
import IconButton from "../button/iconButton";
import {useNavigate} from "react-router-dom";
import {usePartner} from "../../context/partnerContext";
import warning from '../../assets/icones/global/warning.svg';

const PartnerCard = ({ partner }) => {

    const navigate = useNavigate();
    const { setPartnerId } = usePartner();

    function extractTown(address) {
        if (!address) return "Adresse inconnue";
        // Check if the address contains a comma
        if (address.includes(',')) {
            // Extract town from address looking like "23 Rue Félix Langlais, 94220 Charenton-le-Pont"
            let parts = address.split(',');
            if (parts.length > 1) {
                let townPart = parts[1].trim().split(' ');
                if (townPart.length > 2) {
                    return townPart.slice(1).join(' ');
                } else {
                    return parts[1].trim();
                }
            }
        }
        // If no comma is present, return the entire address
        return address;
    }

    const handleNavigate = () => {
        setPartnerId(partner._id); // Set the current partner ID in context
        const mediaQuery = window.matchMedia('(max-width: 991px)').matches;
        //check if partner has a subscription
        if(!partner.subscriptionID ) {
            navigate(`/stripe-payement`)
        } else {
            mediaQuery ? navigate(`/partners/${partner._id}/bookings`) : navigate(`/dashboard/${partner._id}`);
        }
    };

    return(
        <div className={`partner-card ${!partner.subscriptionID ? "no-subscription" : null} ${partner.status && partner.status.name === "error" ? "warning" : null}`} onClick={handleNavigate}>
            {
                !partner.subscriptionID ?
                    <div className={"partner-card__row"}>
                        <img src={infos} alt={"Infos circle"} className={"partner-card__icon"}/>
                        <p className={"partner-card__alert"}>Abonnement manquant</p>
                    </div>
                    : null
            }

            {
                partner.status && partner.status.name === "error" ?
                    <div className={"partner-card__row"}>
                        <img src={warning} alt={"Infos circle"} className={"partner-card__icon"}/>
                        <p className={"partner-card__warning"}>Informations manquantes</p>
                    </div>
                    :
                    <img src={eye} alt={"Eyes"} className={"partner-card__icon"}/>
            }

            <div className={"partner-card__col"}>
            <p className={"partner-card__location"}>{extractTown(partner.address)}</p>
                <p className={"partner-card__name"}>{partner.name}</p>
            </div>
        </div>
    )
}

export default PartnerCard;