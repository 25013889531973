import React from 'react';
import '../../styles/components/tabBar.css';
import {Link} from "react-router-dom";

const TabBar = ({tabs, activeTab, setActiveTab}) => {

    return(
        <div className={"tab_bar"}>
            {
                tabs.map((tab, index) => {
                    return(
                        <Link to={tab.link} key={index} className={`tab_bar__item ${index === activeTab ? "active" : ""}`}>
                            <div
                                key={index}
                                className={"tab_bar__tab" + (activeTab === index ? " active" : "")}
                            >
                                <div className={"tab_bar__row"}>
                                    {tab.name}
                                    {tab.index ? <span className={"tab_bar__tab__index"}>{tab.index}</span> : null}
                                </div>
                            </div>
                        </Link>
                    )
                })
            }
        </div>
    )
}

export default TabBar;