import React from 'react';

import Button from "../../button/button";

const ModalRefund = ({isOpen, link, solde, close}) => {

    return(
        <div className={`bg__modal ${isOpen ? "open" : ""}`}>
            <div className={`modal refund ${isOpen ? "open" : ""}`}>
                <div className={"modal__header"}>
                    <div className={"modal__header__col"}>
                        <p className={"title-20"}>Remboursement sur votre solde ✅</p>
                    </div>
                </div>
                <div className={"modal__content"}>
                    <div className={"modal__content__text"}>
                        <p className={"text-14"}>Suite à votre changement de plan tarifaire, nous avons remboursé la
                            différence sur votre solde. Ce solde sera automatiquement déduit de votre prochaine facture.</p>
                    </div>
                    <div className={"modal__content__div"}>
                        <div className={"modal__content__text center "}>
                            <p className={"text-16"}>Solde actuel : <span className={"bold"}>{solde}</span></p>
                        </div>
                        <p className={"text-12 light"}>Si vous avez des questions ou souhaiter un remboursement,
                            n'hésitez pas à nous contacter à l'adresse suivante : <a
                                href="mailto:contact@frimity.fr">contact@frimity.fr</a></p>
                        <Button text={`Fermer`} isPrimary={true} onClick={close} />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ModalRefund;