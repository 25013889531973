import React, {useContext, useEffect, useState} from 'react';
import HeaderView from "../../components/header/headerView";
import IconButton from "../../components/button/iconButton";
import TabBar from "../../components/navbar/tabBar";
import Tag from "../../components/tag/tag";
import download from '../../assets/icones/global/download.svg';
import Form from "../../components/form/form";
import Company from "./formPartner/company";
import Personal from "./formPartner/personal";
import LegalDocument from "./formPartner/legalDocument";
import '../../styles/views/singlePartner.css';
import {useParams} from "react-router";
import PartnersServices from "../../api/services/partners";
import useStatusName from "../../hooks/parseText/useStatusName";
import useParsePartner from "../../hooks/parseDataApi/useParsePartner";
import useFormValidation from "../../hooks/form/useFormValidation";
import useFormState from "../../hooks/form/useFormUpdate";
import PartnersDocuments from "../../api/services/partnersDocuments";
import trash from '../../assets/icones/global/trash_can.svg';
import {useSelector} from "react-redux";
import {ErrorContext} from "../../context/errorContext";
import {LoaderContext, useLoader} from "../../context/loaderContext";
import ModalDeleting from "../../components/modal/modalDeleting/modalDeleting";
import {useNavigate} from "react-router-dom";
import Booking from "./formPartner/booking";
import {usePartner} from "../../context/partnerContext";
import {useDispatch} from "react-redux";
import {userPartnerUpdateEngineID} from "../../redux/slice/authSlice";


const SinglePartner = () => {

    //get user from redux state
    const partnerId = useParams().partnerId;
    const [refresh, setRefresh] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {parsePartner, parsePartnerForUpdate} = useParsePartner();
    const { validateForm } = useFormValidation();
    const { getStatusName } = useStatusName();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();
    const navigate = useNavigate();

    const dispatch = useDispatch();


    //get user from redux state
    const { currentPartnerId } = usePartner();
    const user = useSelector(state => state.auth.user);
    const numberActivity = user.role !== "admin" ? useSelector((state) => state.auth.user.partnerID.find(partner => partner._id === currentPartnerId).activityID.length) : null;


    const { formState, setFormState, handleFormChange, getFormChanges } = useFormState(parsePartner([]));

    useEffect(() => {
        setLoading(true);
        const partnerID = partnerId ? partnerId : currentPartnerId._id;
       PartnersServices.getPartnerById(partnerID)
        .then((response) => {
            response.status === 200 ? setFormState(parsePartner(response.data)) : setFormState(parsePartner([]));
        })
        .catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        });
    }, [refresh]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formIsValid = validateForm(formState, setFormState);

        if (formIsValid.isFormValid) {
            setLoading(true);
            const newPartnerChanges = getFormChanges();
            const partnerForApi = parsePartnerForUpdate(newPartnerChanges);
            const parsePartnerFiles = new FormData();

            const localDocuments = partnerForApi.legalInfoID.legalDocuments.filter(doc => doc.isLocal);

            if (localDocuments.length > 0) {
                for (const document of localDocuments) {
                    const blob = await fetch(document.src).then(res => res.blob());
                    const file = new File([blob], document.name, { type: document.type });
                    parsePartnerFiles.append('documents', file);
                }
            }

            delete partnerForApi.legalInfoID.legalDocuments;

            if (parsePartnerFiles.has('documents')) {
                await PartnersDocuments.create(partnerId, parsePartnerFiles).catch((error) => {
                    showError(error);
                })
            }

            await PartnersServices.updatePartner(partnerId, partnerForApi).then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    setRefresh(true);
                    window.scrollTo(0, 0);

                    //if engineID is updated, dispatch action to update user in redux state
                    if(partnerForApi.engineID) {
                        dispatch(userPartnerUpdateEngineID({partnerID: partnerId, engineID: partnerForApi.engineID}));
                    }
                }
            }).catch((error) => {
                showError(error);
            })
        }
    }


    const handleDelete = async (e) => {
        e.preventDefault();
        setLoading(true);
        await PartnersServices.deletePartner(partnerId).then((response) => {
            if (response.status === 200) {
                setRefresh(true);
                setIsModalOpen(false);
                navigate('/partners');
                setLoading(false);
            }
        }).catch((error) => {
            showError(error);
        });
    }

    return(
        <div className={"main-container is__grey"}>
            <HeaderView title={"Mon espace partenaire"} actions={
                <>
                    {
                        user && user.role === "admin" &&
                        <IconButton icon={download} text={"Exporter la fiche du partenaire"} isPrimary={false} />
                    }
                </>
            }
            isGoBack={user && user.role === "partner" || user.role === "partners-admin"? false : true} />

            <TabBar
                tabs={[
                    {
                        name: "Fiche du partenaire",
                        link: `/partners/${partnerId}`
                    },
                    {
                        name: "Liste d’activités",
                        link: `/partners/${partnerId}/activities`,
                        index: numberActivity ? numberActivity : 0
                    },
                    {
                        name: "Gérer vos offres",
                        link: `/partners/${partnerId}/offers`,
                    },
                    {
                        name: "Plan tarifaire",
                        link: `/partners/${partnerId}/subscription`,
                    }
                ]}
                activeTab={0}
            />


            <div className={"header__container"}>
                <h2 className={"title-20"}>Informations sur votre établissement chez Frimity</h2>
                <p className={"text-14"}>Vous pouvez modifier ici les informations de votre établissement </p>
            </div>

            <div className={"main-infos__container"}>
                <div className={"main-infos__container__col"}>
                    <Tag text={getStatusName(formState.status)} status={formState.status} />
                    <h2 className={"title-25"}>{formState.company.name ? formState.company.name.value : "Nouveau partenaire"}</h2>
                </div>
                <div className={"main-infos__container__col"}>
                    {
                        user && user.role === "admin" && <IconButton icon={trash} text={"Supprimer le partenaire"} isPrimary={false} onClick={() => setIsModalOpen(true)} />
                    }
                </div>
            </div>

            <Form
                title={"Informations sur le partenaire"}
                children={
                    formState && formState.company !== {} && formState.personal !== {} && formState.legalDocuments !== {} && formState.bank !== {} &&
                        <>
                            <Company setPartner={handleFormChange} company={formState.company} user={user} />
                            <Booking setPartner={handleFormChange} booking={formState.booking} />
                            <Personal mode={"edit"} setPartner={handleFormChange} personal={formState.personal} />
                            <LegalDocument setPartner={handleFormChange} documents={formState.legalDocuments} type={"remote"} partnerID={partnerId} />
                        </>
                 }
                mode={"edit"}
                onSubmit={handleSubmit}
            />

            <ModalDeleting
                itemName={"ce partenaire"}
                deleteItem={(e) => handleDelete(e)}
                isOpen={isModalOpen}
                closeModal={() => setIsModalOpen(false)}
            />
        </div>
    )

}

export default SinglePartner;