import {getRequest, postRequestJson, patchRequest, deleteRequest, postRequestFormData} from '../request/apiRequest';

const UserAvatarServices = {

        createUserAvatar: async (userId, data) => {
            return await postRequestFormData(`/users/avatar/${userId}`, data);
        },

        deleteUserAvatar: async (userId, fileName) => {
            return await deleteRequest(`/users/avatar/${userId}/${fileName}`);
        }
}

export default UserAvatarServices;