import check from "../../../assets/images/svg/check_round_green.svg";
import Button from "../../../components/button/button";
import React from "react";
import {useParams} from "react-router-dom";
import {userPartnerUpdateBooking, userUpdateSubscription} from "../../../redux/slice/authSlice";
import BookingPartnersServices from "../../../api/services/bookingPartners";
import {useLoader} from "../../../context/loaderContext";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import '../../../styles/views/payments.css';



const BookingStripeSuccess = () => {

    const { partnerId } = useParams();
    const { setLoading } = useLoader();
    const navigate = useNavigate();
    const disptach = useDispatch();

    const handleCreateBookingPartners = async () => {
        setLoading(true);
        BookingPartnersServices.createBookingPartner(partnerId,{
            bookingType: "internal"
        }).then((response) => {
            disptach(userPartnerUpdateBooking({partnerID: partnerId, booking: response.data.bookingPartner}));
            disptach(userUpdateSubscription({partnerID: partnerId, subscriptionID: response.data.partnerSubscription}));
            setLoading(false);
           navigate(`/partners/${partnerId}/bookings/schedule`);
        }).catch((error) => {
            console.log(error);
        })
    }


    return (
        <div className="main stripeBooking">
            <div className="main__container__full">
                <div className="icon__image">
                    <img src={check} alt="logo"/>
                </div>
                <div className="main__container__content">
                    <h1 className={"title-25"}>Votre système de réservation est prêt !</h1>
                    <p className={"text-16 center"}>Vous avez été correctement connecté à notre partenaire de paiement
                        Stripe. Vous avez maintenant accès à la gestion de votre système de réservation et recevoir des
                        réservations des utilisateurs de notre application</p>
                </div>
                <div className="main__container__col32 marginTop">
                    <Button text={"Remplir mes disponibilités"} isPrimary={true} isBig={true}
                            onClick={() => handleCreateBookingPartners()}/>
                </div>
            </div>
        </div>
    )
}

export default BookingStripeSuccess;