import {useSelector} from "react-redux";
import {Navigate} from "react-router-dom";
import React, {useContext} from "react";
import { jwtDecode } from "jwt-decode";
import {ErrorContext} from "../context/errorContext";
import {useParams} from "react-router-dom";
import {usePartner} from "../context/partnerContext";


const useUserRole = () => {
    //function to check if token is valid and not expired
    const checkToken = (token) => {
        if (!token) return false;

        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            return decodedToken.exp >= currentTime;
        } catch (error) {
            console.error('Error decoding token:', error);
            return false;
        }
    }

    const useAuthState = () => {
        const user = useSelector((state) => state.auth.user);
        const token = useSelector((state) => state.auth.token);
        const isTokenValid = checkToken(token); // Assuming checkToken is implemented correctly
        return { user, token, isTokenValid };
    };

    const getCurrentPartner = (user, currentPartnerId) => {
        return user?.partnerID?.find(partner => partner._id === currentPartnerId) || null;
    };

    const isPartnerVerified = (partner) => {
        return !!partner?.isVerified;
    };

    const hasValidSubscription = (partner) => {
        //return true if partner has a subscription
        return !!partner?.subscriptionID;
    };

    const hasValidBooking = (partner) => {
        //return true if partner has a booking
        return !!partner?.bookingPartnersID;
    }

    const PartnerRouteGuard = ({ user, isTokenValid }) => {
        const { currentPartnerId } = usePartner();
        if (!isTokenValid) return false;

        if (user.role === "admin") return true;

        const currentPartner = getCurrentPartner(user, currentPartnerId);
        if (user.role === "partners-admin") {
            return isPartnerVerified(currentPartner);
        }

        return user.role === "partner" && isPartnerVerified(currentPartner);
    };


    const AdminRouteGuard = ({ user, isTokenValid }) => {
        return isTokenValid && user && user.role === "admin";
    };

    const PartnerEmailVerifiedRouteGuard = ({ user, isTokenValid }) => {
        if (!isTokenValid || !user.isEmailVerified) return false;

        // For admins, email verification is sufficient
        if (user.role === "admin") return true;

        const { currentPartnerId } = usePartner();
        const currentPartner = getCurrentPartner(user, currentPartnerId);
        return isPartnerVerified(currentPartner);
    };


    const PartnerSubscriptionRouteGuard = ({ user, isTokenValid }) => {

        const { currentPartnerId } = usePartner();
        const currentPartner = getCurrentPartner(user, currentPartnerId);
        if (!isTokenValid) return false;

        return hasValidSubscription(currentPartner);
    };

    const PartnerBookingRouteGuard = ({ user, isTokenValid }) => {
        if (!isTokenValid) return false;

        const { currentPartnerId } = usePartner();
        const currentPartner = getCurrentPartner(user, currentPartnerId);

        return hasValidBooking(currentPartner);
    }

    const PartnerAdminRouteGuard = ({ user, isTokenValid }) => {
        // Check if user, partnerID, subscriptionID, and subscriptionPlan exist
        //check for each user.partnerID if they are verified
        const partnerVerified = user.partnerID.map(partner => partner.isVerified)
        // Return true if token is valid, user is a partner, partner is verified, and there's no subscription
        return isTokenValid && user.role === "partners-admin" && partnerVerified;
    };


    const PrivateRoute = ({ element }) => {
        const { user, token, isTokenValid } = useAuthState();
        const { showError } = useContext(ErrorContext);

        if (!isTokenValid) {
            showError("Invalid or expired token");
            return <Navigate to="/login" />;
        }

        if (user.role === "admin") return element;

        if(user.role === "partners-admin" && window.location.pathname.includes("settings") || window.location.pathname.includes("support")) return element;


        if (!PartnerRouteGuard({ user, token, isTokenValid })) {showError("Not verified yet");}
        if (!PartnerEmailVerifiedRouteGuard({ user, isTokenValid })) return <Navigate to="/verify-email" />;
        if (!PartnerSubscriptionRouteGuard({ user, isTokenValid })) return <Navigate to="/stripe-payement" />;

        return element;
    };


    const AdminRoute = ({ element }) => {
        const { user, isTokenValid } = useAuthState();
        if (!AdminRouteGuard({ user, isTokenValid })) return <Navigate to="/login" />;

        return element;
    };


    const PartnerNoSubscriptionRoute = ({ element }) => {
        const { user, isTokenValid } = useAuthState();
        const { currentPartnerId } = usePartner();
        const currentPartner = getCurrentPartner(user, currentPartnerId);

        // This ensures that we only proceed if there's a valid current partner.
        // Otherwise, it might be better to navigate to a general error page or dashboard.
        if (!currentPartner) {
            return <Navigate to="/error" />;
        }

        // Assuming the guard checks the currentPartner for subscription status correctly
        if (PartnerSubscriptionRouteGuard({ user, isTokenValid })) {
            // Navigate to the payment page specifically for the currentPartner if the subscription check fails.
            return <Navigate to={`/partners/${currentPartnerId}`} />;
        }

        return element;
    };

    const PartnerBookingRoute = ({ element }) => {
        const { user, isTokenValid } = useAuthState();
        const { currentPartnerId } = usePartner();
        const { showError } = useContext(ErrorContext);

        const currentPartner = getCurrentPartner(user, currentPartnerId);

        // This ensures that we only proceed if there's a valid current partner.
        // Otherwise, it might be better to navigate to a general error page or dashboard.
        if (!currentPartner) {
            return <Navigate to="/error" />;
        }

        if (!isTokenValid) {
            showError("Invalid or expired token");
            return <Navigate to="/login" />;
        }

        // Assuming the guard checks the currentPartner for booking status correctly
        if (!PartnerBookingRouteGuard({ user, isTokenValid })) return <Navigate to={`/partners/${currentPartnerId}/bookings/no-booking`} />;

        return element;
    }


    const PartnerAdminRoute = ({ element }) => {
        const { user, token, isTokenValid } = useAuthState();

        if (!PartnerAdminRouteGuard({ user, token, isTokenValid })) return <Navigate to="/login" />;

        return element;
    }

    //check if redux signIn state is true
    const MainRouteSignIn = ({ element }) => {
        const isEmailVerified = useSelector((state) => state.signIn.user && state.signIn.user.isEmailVerified);
        if (isEmailVerified) {
            return element;
        }
        return <Navigate to="/signin/verification/" />;
    };

    const VerificationEmailSignInRoute = ({ element }) => {
        const isEmailVerified = useSelector((state) => state.signIn.user && state.signIn.user.isEmailVerified);
        if (!isEmailVerified) {
            return element;
        }
        return <Navigate to="/signin" />;
    };

    const VerificationUserRoute = ({ element }) => {
        const isEmailVerified = useSelector((state) => state.auth.user && state.auth.user.isEmailVerified);
        if (!isEmailVerified) {
            return element;
        }
        return <Navigate to="/login" />;
    };

    return {PrivateRoute, AdminRoute, MainRouteSignIn, VerificationEmailSignInRoute, VerificationUserRoute, PartnerNoSubscriptionRoute, PartnerAdminRoute, PartnerBookingRoute};
}

export default useUserRole;