import React, {useEffect,useState} from 'react';
import FormSectionRow from "../../../components/form/formSectionRow";
import InputSelect from "../../../components/form/inputSelect/inputSelect";

const Duration = ({duration, setActivity}) => {

    //create array of options for select with 120 minutes
    const [options, setOptions] = useState(false);

    const handleChange = (event) => {
        event.preventDefault();
        const { value } = event.target;

        setActivity(`duration.value`, value);
    }
    useEffect(() => {
        let optionsArray = [];
        // Add options for every minute up to 120
        for (let i = 1; i <= 120; i++) {
            let name = `${i} minute${i > 1 ? 's' : ''}`;
            optionsArray.push({ name, value: i });
        }

        // Add options for every quarter hour after 120 minutes
        for (let i = 120; i <= 480; i += 15) {
            if (i % 60 === 0 || i % 15 === 0) {
                const hours = Math.floor(i / 60);
                const minutes = i % 60;
                let name = `${hours}h${minutes === 0 ? '00' : minutes}`;
                optionsArray.push({ name, value: i });
            }
        }

        setOptions(optionsArray);
    }, []);



    return options && (
        <FormSectionRow title={"Durée de l’activité"} isRequired={true}>
            <InputSelect
                name={duration.name}
                subtitle={duration.subtitle}
                value={duration.value}
                valueSelected={duration.value}
                onChange={handleChange}
                errorMessage={duration.errorMessage}
                placeholder={duration.placeholder}
                options={options}
            />
        </FormSectionRow>
    )

}

export default Duration;