import { useState, useCallback, useRef } from 'react';
import _ from 'lodash';

const useFormUpdate = (initialState) => {
    const [formState, setFormState] = useState(initialState);
    // Use a ref to store the initial state to ensure it doesn't change on re-renders
    const initialFormStateRef = useRef(initialState);
    const [changes, setChanges] = useState({});

    const handleFormChange = useCallback((path, value, operation) => {
        setFormState(prevState => {
            let newState = _.cloneDeep(prevState);
            if (operation === 'add') {
                _.get(newState, path).push(value);
            } else if (operation === 'remove') {
                _.remove(_.get(newState, path), item => _.isEqual(item, value));
            } else {
                _.set(newState, path, value);
            }
            return newState;
        });
        setChanges(prevChanges => {
            let newChanges = _.cloneDeep(prevChanges);
            if (operation === 'add') {
                if (!_.has(newChanges, path)) {
                    _.set(newChanges, path, []);
                }
                _.get(newChanges, path).push(value);
            } else if (operation === 'remove') {
                _.remove(_.get(newChanges, path), item => _.isEqual(item, value));
            } else {
                _.set(newChanges, path, value);
            }
            return newChanges;
        });
    }, []);

    const getFormChanges = useCallback(() => {
        let result = {};
        _.forEach(changes, (value, key) => {
            if (!_.isEqual(value, _.get(initialFormStateRef.current, key))) {
                result[key] = value;
            }
        });
        return result;
    }, [changes]);


    return {
        formState,
        setFormState,
        handleFormChange,
        getFormChanges,
    };
};

export default useFormUpdate;
