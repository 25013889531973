
import React, { createContext, useState, useContext } from 'react';


const PartnerContext = createContext();

export const PartnerProvider = ({ children }) => {
    // Attempt to load the current partner ID from localStorage during initial load
    const [currentPartnerId, setCurrentPartnerId] = useState(() => {
        return localStorage.getItem('currentPartnerId');
    });

    // A wrapper function to set the current partner ID in both localStorage and state
    const setPartnerId = (partnerId) => {
        localStorage.setItem('currentPartnerId', partnerId); // Persist to localStorage
        setCurrentPartnerId(partnerId); // Update React state
    };

    const clearPartnerId = () => {
        localStorage.removeItem('currentPartnerId');
        setCurrentPartnerId(null);

    }

    return (
        <PartnerContext.Provider value={{ currentPartnerId, setPartnerId, clearPartnerId }}>
            {children}
        </PartnerContext.Provider>
    );
};

export const usePartner = () => useContext(PartnerContext);
