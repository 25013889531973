import React from 'react';
import ticket from '../../assets/icones/global/coupon_full.svg';
import call from '../../assets/icones/global/call.svg';
import itinerary from '../../assets/icones/global/location.svg';
import website from '../../assets/icones/global/eye.svg';
import CardAnalytics from "./cardAnalytics";
import '../../styles/components/analytics.css';

const calculatePercentageChange = (current, previous) => {
    if (previous === 0) return current > 0 ? 100 : 0;
    const change = ((current - previous) / previous) * 100;
    return parseInt(change.toFixed(0)); // Round to nearest integer
};

const aggregateCounts = (data) => {
    const aggregated = {
        booking: 0,
        call: 0,
        mapClick: 0,
        websiteClick: 0
    };

    if (data && typeof data === 'object') {
        Object.keys(aggregated).forEach(actionType => {
            if (Array.isArray(data[actionType])) {
                data[actionType].forEach(item => {
                    aggregated[actionType] += item.count;
                });
            }
        });
    }

    return aggregated;
};

const AnalyticsSection = ({ analyticsData, previousAnalyticsData }) => {
    const aggregatedData = aggregateCounts(analyticsData);
    const prevAggregatedData = aggregateCounts(previousAnalyticsData);

    const arrayAnalytics = [
        {
            name: "Réservations",
            icon: ticket,
            color: "#ECF8F1",
            colorDark: "#83BF6E",
            value: aggregatedData['booking'] || 0,
            percentage: calculatePercentageChange(aggregatedData['booking'], prevAggregatedData['booking']),
            tooltipText: "Réservations",
            tooltipDescription: "Découvrez le nombre total de réservations réalisées grâce à l'application Frimity."
        },
        {
            name: "Appels",
            icon: call,
            color: "#EBF8FE",
            colorDark: "#679FB9",
            value: aggregatedData['call'] || 0,
            percentage: calculatePercentageChange(aggregatedData['call'], prevAggregatedData['call']),
            tooltipText: "Appels",
            tooltipDescription: "Consultez le nombre total d'appels reçus depuis l'application Frimity."
        },
        {
            name: "Itinéraire suggéré",
            icon: itinerary,
            color: "#F8ECEC",
            colorDark: "#A65555",
            value: aggregatedData['mapClick'] || 0,
            percentage: calculatePercentageChange(aggregatedData['mapClick'], prevAggregatedData['mapClick']),
            tooltipText: "Itinéraire suggéré",
            tooltipDescription: "Consultez le nombre d'itinéraires suggérés ouverts par nos utilisateurs pour accéder à votre établissement."
        },
        {
            name: "Consultation site web",
            icon: website,
            color: "#F2EEFF",
            colorDark: "#6954AB",
            value: aggregatedData['websiteClick'] || 0,
            percentage: calculatePercentageChange(aggregatedData['websiteClick'], prevAggregatedData['websiteClick']),
            tooltipText: "Consultation site web",
            tooltipDescription: "Observez combien de fois le site web de votre établissement a été visité depuis l'application Frimity.",
            className: "last"
        }
    ];

    return (
        <div className={"main-container__row box__container"}>
            {/*<div className={"box__container__row"}>
                <h2 className={"text-20"}>Votre établissement a généré <span className={"text-20 bold"}>0€</span> ce mois-ci</h2>
            </div>*/}

            <div className={"box__container__row"}>
                {
                    arrayAnalytics.map((analytics, index) => (
                        <CardAnalytics
                            key={index}
                            name={analytics.name}
                            icon={analytics.icon}
                            color={analytics.color}
                            colorDark={analytics.colorDark}
                            value={analytics.value}
                            percentage={analytics.percentage}
                            tooltipDescription={analytics.tooltipDescription}
                            tooltipText={analytics.tooltipText}
                            className={analytics.className}
                        />
                    ))
                }
            </div>
        </div>
    );
};

export default AnalyticsSection;
