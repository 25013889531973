import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router";
import {Elements} from "@stripe/react-stripe-js";
import TabBar from "../../components/navbar/tabBar";
import HeaderView from "../../components/header/headerView";
import TabBlock from "../../components/navbar/tabBlock";
import PayementServices from "../../api/services/payement";
import {useSelector} from "react-redux";
import {loadStripe} from "@stripe/stripe-js";
import PaymentPatch from "./paymentPatch";
import UserSubscriptionServices from "../../api/services/stripeSubscription";
import ModalUpdate from "../../components/modal/modalInfos/modalUpdate";
import PartnerSubscriptionServices from "../../api/services/partnerSubscription";
import {ErrorContext} from "../../context/errorContext";
import {useLoader} from "../../context/loaderContext";
import {usePartner} from "../../context/partnerContext";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);


const Payment = () => {

    const partnerId = useParams().partnerId;
    const [reload, setReload] = useState(false);
    const [savedMethods, setSavedMethods] = useState([]);
    const [clientSecret, setClientSecret] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [userCustomerId, setUserCustomerId] = useState(null);
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const { currentPartnerId } = usePartner();
    const user = useSelector(state => state.auth.user);
    const numberActivity = useSelector((state) => state.auth.user.partnerID.find(partner => partner._id === currentPartnerId).activityID.length);
    const currentPartner = useSelector((state) => state.auth.user.partnerID.find(partner => partner._id === currentPartnerId));



    useEffect(() => {
        setReload(false);
        setLoading(true);
        const fetchData = async () => {
            try {
                PayementServices.getSavedPaymentMethods({customerId: currentPartner.subscriptionID.stripeCustomerId})
                .then((res) => {
                    setSavedMethods(res.data.data);
                })
                .catch((error) => {
                    showError(error);
                }).finally(() => {
                    setLoading(false);
                });

                //check if user is admin or partner
                if (user.role === "admin") {
                    //get customer id from partner subscription stripeCustomerId
                    const customerIDResponse = await PartnerSubscriptionServices.getSubscriptionByPartnerId(partnerId);
                    setUserCustomerId(customerIDResponse.data.stripeCustomerId);

                    const customerResponse = await UserSubscriptionServices.getCustomerbyId(customerIDResponse.data.stripeCustomerId);
                    setCustomer(customerResponse.data.customer);
                }else{
                    const setupIntent = await PayementServices.createDefaultSetupIntent({
                        customerId: currentPartner.subscriptionID.stripeCustomerId
                    });
                    setClientSecret(setupIntent.data.client_secret);

                    const customerResponse = await UserSubscriptionServices.getCustomerbyId(currentPartner.subscriptionID.stripeCustomerId);
                    setCustomer(customerResponse.data.customer);
                }
            } catch (error) {
                showError(error);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [reload]);

    const elementsOptions = {
        clientSecret: clientSecret, // This should be the client secret from the PaymentIntent
        appearance: {
            theme: 'stripe',
        },
        layout: {
            type: 'tabs',
            defaultCollapsed: false,
        }
    };


    return (
        <div className={"main-container is__grey"}>
            <HeaderView title={"Paiement"} actions={
                <>
                    <TabBlock
                        tabs={[
                            {name: "Plan tarifaire", link: `/partners/${partnerId}/subscription`},
                            {name: "Facturation", link: `/partners/${partnerId}/subscription/billing`},
                            {name: "Méthode de paiement", link: `/partners/${partnerId}/subscription/payment`}
                        ]}
                        activeLink={2}
                    />
                </>
            }/>

            <TabBar
                tabs={[
                    {
                        name: "Fiche du partenaire",
                        link: `/partners/${partnerId}`
                    },
                    {
                        name: "Liste d’activités",
                        link: `/partners/${partnerId}/activities`,
                        index: numberActivity ? numberActivity : 0
                    },
                    {
                        name: "Gérer vos offres",
                        link: `/partners/${partnerId}/offers`
                    },
                    {
                        name: "Plan tarifaire",
                        link: `/partners/${partnerId}/subscription`,
                    }
                ]}
                activeTab={3}
            />


            <div className={"container__grey center light-padding"}>
                <div className={"subscription__content is__col"}>
                    <div className={"subscription__content__col"}>
                        <h1 className={"title-20"}>Méthode de paiement</h1>
                        <p className={"text-14 light"}>Mettez à jour vos informations de facturation et votre adresse</p>
                    </div>
                    <div className={"subscription__content__row"}>
                        <div className={"subscription__content__col__title"}>
                            <h2 className={"text-16"}>Détails des cartes associées</h2>
                            <p className={"text-14 light"}>Sélectionnez le mode de paiement par défaut</p>
                        </div>
                        {
                            stripePromise && clientSecret &&

                            <Elements stripe={stripePromise} options={elementsOptions}>
                                <PaymentPatch
                                    customerId={user.role === "admin" ? userCustomerId : currentPartner.subscriptionID.stripeCustomerId}
                                    setReload={setReload}
                                    savedMethods={savedMethods}
                                    setSavedMethods={setSavedMethods}
                                    defaultCard={customer ? customer.invoice_settings.default_payment_method : null}
                                    setModalOpen={setModalOpen}
                                />
                            </Elements>
                        }
                    </div>
                </div>
                <ModalUpdate isOpen={modalOpen} setReload={setReload} setModalOpen={setModalOpen}/>
            </div>
        </div>
    );
}

export default Payment;