import React, {useState, useEffect, useContext} from 'react';
import HeaderView from "../components/header/headerView";
import '../styles/views/partners.css';
import {useSelector} from "react-redux";
import PartnerCard from "../components/card/partnerCard";
import {Link} from "react-router-dom";

const MultiPartnerDashboard = () => {

    const user = useSelector(state => state.auth.user);

    return (
        <div className={"main-container"}>
            <HeaderView title={`Bonjour, ${user.firstname} 👋`} isGoBack={false}/>

            <div className={"main-container__row box__container"}>
                <div className={"box__container__row"}>
                    <h2 className={"text-20"}>Quel établissement souhaitez-vous gérer ?</h2>
                </div>

                <div className={"box__container__row wrap"}>
                    {
                        user.partnerID.map(partner => {
                            return <PartnerCard partner={partner}/>
                        })
                    }
                </div>
            </div>

        </div>
    );
}

export default MultiPartnerDashboard;