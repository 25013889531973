import {useState, useEffect, useRef, useContext} from 'react';
import PartnersDocuments from "../../api/services/partnersDocuments";
import {ErrorContext} from "../../context/errorContext";

function useFileHandler({ documents, onChange, type }) {
    const [files, setFiles] = useState(documents || []);
    const hiddenInputRef = useRef(null);
    const [isFilesLoaded, setIsFilesLoaded] = useState(false);
    const { showError } = useContext(ErrorContext);

    const bytesToKB = (bytes) => {
        return (bytes / 1024).toFixed(0);
    };

    const createFileObject = (file, src, isLocal) => {
        // Check if the file has already been processed
        if (file.processed) {
            return file;
        }

        return {
            name: file.name || file.metadata.originalName,
            size: file.size ? bytesToKB(parseInt(file.size)) : bytesToKB(file.metadata.size),
            type: file.type || 'application/pdf', // You can adjust the type as needed
            src: !file.metadata ? src !== "undefined" ? src : file.src : file.url,
            preview: !file.metadata ? src !== "undefined" ? src : file.src : file.url,
            isLocal: isLocal,
            processed: true,
        };
    }

    const handleChange = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            return; // Exit if no files are selected
        }

        const newLocalFiles = Array.from(e.target.files)
            .map(file => {
                if (file.size === 0) {
                    alert('There was a problem with the file you tried to upload.');
                    return null; // Skip this file
                }
                const src = URL.createObjectURL(file);
                return createFileObject(file, src, true);
            }).filter(file => file != null); // Filter out null values

        setFiles(prevFiles => {
            const updatedFiles = [...prevFiles, ...newLocalFiles];
            onChange(updatedFiles);
            return updatedFiles;
        });
    };

    useEffect(() => {
        if (Array.isArray(documents) && documents.length > 0 && type === 'local') {
            const initialFiles = documents.map(file =>
                createFileObject(file, file.url, true)
            );
            setFiles(initialFiles);
        }
    }, [documents, type]);

    useEffect(() => {
        if (!isFilesLoaded && Array.isArray(documents) && documents.length > 0 && type === 'remote') {
            const initialFiles = documents.map(file =>
                createFileObject(file, file.url, false)
            );
            setFiles(initialFiles);
            setIsFilesLoaded(true);
        }
    }, [documents, isFilesLoaded]);

    const updateInputValue = (updatedFiles) => {
        const input = hiddenInputRef.current;
        const fileInputValue = new DataTransfer();

        updatedFiles.forEach((file) => {
            if (file.isLocal) {
                const blob = new Blob([file.src], { type: file.type });
                const newFile = new File([blob], file.name, { type: file.type });
                fileInputValue.items.add(newFile);
            }
        });

        input.files = fileInputValue.files;
    };

    const deleteFile = (partnerId, index) => {
        const fileToDelete = files[index];
        const newFiles = [...files];
        newFiles.splice(index, 1);

        if (fileToDelete.isLocal) {
            setFiles(newFiles);
            updateInputValue(newFiles);
        } else if (type === 'remote') {
            PartnersDocuments.delete(partnerId, fileToDelete.name)
            .then((response) => {
                if(response.status === 200){
                    setFiles(newFiles);
                    updateInputValue(newFiles);
                }
            })
            .catch((error) => {
                console.error('Error deleting remote file:', error);
            });
        }
    };

    return { files, handleChange, deleteFile, hiddenInputRef };
}

export default useFileHandler;
