import React from 'react';

import Button from "../../button/button";

const ModalEmailSend = ({isOpen, link}) => {

    return(
        <div className={`bg__modal ${isOpen ? "open" : ""}`}>
            <div className={`modal email ${isOpen ? "open" : ""}`}>
                <div className={"modal__header"}>
                    <div className={"modal__header__col"}>
                        <p className={"title-20"}>Votre demande nous a bien été envoyée ✅</p>
                    </div>
                </div>
                <div className={"modal__content"}>
                    <div className={"modal__content__col"}>
                        <p className={"text-14"}>Nous vous répondrons dans les plus brefs délais.</p>
                        <p className={"text-14"}>Merci de votre confiance !</p>
                        <p className={"text-14"}>L'équipe de Frimity</p>
                    </div>
                    <Button text={`Retour vers mon espace`} isPrimary={true} link={link}/>
                </div>
            </div>
        </div>
    )
}

export default ModalEmailSend;