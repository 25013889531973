import React, { useState, useEffect } from 'react';
import RadioMultiple from "../../../components/form/inputRadio/radioMultiple";
import FormSectionRow from "../../../components/form/formSectionRow";
import add from '../../../assets/icones/global/add.svg';
import IconButton from "../../../components/button/iconButton";
import Button from "../../../components/button/button";
import Input from "../../../components/form/inputText/input";

const Included = ({ included: initialIncluded, setActivity }) => {
    const [included, setIncluded] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [newItemName, setNewItemName] = useState(""); // Initialize with an empty string

    const openModal = () => {
        setNewItemName(""); // Reset the input field when opening the modal
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setNewItemName(""); // Reset the input field when closing the modal
        setModalIsOpen(false);
    };

    const handleAddItem = () => {
        if (newItemName) {
            setIncluded(prevIncluded => [...prevIncluded, newItemName]);
            setActivity('included.value', [...included, newItemName]);
            setNewItemName(""); // Reset the input field after adding an item
            closeModal();
        }
    };

    useEffect(() => {
        setIncluded(initialIncluded.value);
    }, [initialIncluded]);

    useEffect(() => {
        setIncluded(initialIncluded.value);
    }, [initialIncluded]);

    return (
        <FormSectionRow title={"Inclus"} description={"À remplir"} isRow={true}>
            <div className={"form__container__col grid"}>
                {
                    included && included.length > 0 ?
                        included.map((item, index) => (
                            <RadioMultiple
                                key={index}
                                name={"included"}
                                value={item} // Assuming value is the item name
                                label={item}
                                isChecked={true}
                                disabled={true}
                            />
                        ))
                        :
                        <div className={"empty stroke"}>
                            <p className={"text-14"}>Aucun élément inclus</p>
                        </div>
                }
                <IconButton
                    icon={add}
                    text={"Ajouter une option"}
                    onClick={openModal}
                />
            </div>

            <div className={`bg__modal ${modalIsOpen ? "open" : ""}`}>
                <div className={`modal deleting ${modalIsOpen ? "open" : ""}`}>
                    <div className={"modal__header"}>
                        <div className={"modal__header__text"}>
                            <p className={"title-14"}>Ajouter un élément inclus de votre activité</p>
                        </div>
                    </div>
                    <div className={"modal__content"}>
                        <Input
                            key={modalIsOpen ? 'open' : 'closed'} // Changes the key based on the modal state
                            name={"included"}
                            type={"text"}
                            valueInput={newItemName}
                            onChangeForm={(e) => setNewItemName(e.target.value)}
                            placeholder={"Ex : Spa"}
                        />
                        <div className={"modal__content__actions"}>
                            <Button text={"Annuler"} isPrimary={false} onClick={closeModal}/>
                            <Button text={"Ajouter"} isPrimary={true} onClick={handleAddItem}/>
                        </div>
                    </div>
                </div>
            </div>
        </FormSectionRow>
    );
}

export default Included;
