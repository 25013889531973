import {getRequest, postRequestJson, patchRequest, deleteRequest} from '../request/apiRequest';

const AnalyticsServices = {

    getAnalytics: async (data) => {
        return await postRequestJson('/analytics/get-analytics', data);
    },

}

export default AnalyticsServices;