import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router";
import HeaderView from "../../components/header/headerView";
import TabBar from "../../components/navbar/tabBar";
import TabBlock from "../../components/navbar/tabBlock";
import SubsrciptionsServices from "../../api/services/subscriptions";
import SubscriptionCard from "../../components/stripe/subscriptionCard";
import {useSelector} from "react-redux";
import useSubscriptionName from "../../hooks/parseText/useSubscriptionName";
import {useNavigate} from "react-router-dom";
import PartnerSubscriptionServices from "../../api/services/partnerSubscription";
import {ErrorContext} from "../../context/errorContext";
import {useLoader} from "../../context/loaderContext";
import Input from "../../components/form/inputText/input";
import '../../styles/views/subscription.css';
import IconButton from "../../components/button/iconButton";
import check from '../../assets/icones/global/yellow_check.svg';
import {useDispatch} from "react-redux";
import ModalAccept from "../../components/modal/modalInfos/modalAccept";
import Button from "../../components/button/button";
import {usePartner} from "../../context/partnerContext";

const Subscription = () => {

    const partnerId = useParams().partnerId;
    const [subscription, setSubscription] = useState(null);
    const [userSubscription, setUserSubscription] = useState(false);

    const {getSubscriptionName} = useSubscriptionName();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const dispatch = useDispatch();

    const [modalOpen, setModalOpen] = useState(false);

    const { currentPartnerId } = usePartner();
    const user = useSelector(state => state.auth.user);
    const numberActivity = user.role !== "admin" ? useSelector((state) => state.auth.user.partnerID.find(partner => partner._id === currentPartnerId).activityID.length) : null;
    const currentPartner = user.role !== "admin" ? useSelector((state) => state.auth.user.partnerID.find(partner => partner._id === currentPartnerId)) : null;



    useEffect(() => {
        setLoading(true);
        SubsrciptionsServices.getSubscriptions().then((response) => {
            if (response.status === 200) {
                setSubscription(response.data);
            }
        }).catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        });

        PartnerSubscriptionServices.getSubscriptionByPartnerId(partnerId).then((response) => {
            if (response.status === 200) {
                setUserSubscription(response.data);
            }
        }).catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        })
    }, []);


    const handleCommission = () => {
        const commissionValue = parseInt(userSubscription.commission.replace('%', '')); // Remove "%" and convert to float

        const body = {
            "commission" : commissionValue
        };

        PartnerSubscriptionServices.updateCommission(partnerId, body).then((response) => {
            if (response.status === 200) {
                setUserSubscription({...userSubscription, commission: userSubscription.commission});
            }
        }).catch((error) => {
            showError(error);
        })
    }

    return(
        <div className={"main-container is__grey"}>
            <HeaderView title={"Votre tarification"} actions={
                <>
                    {/*{
                        user.role === "partner" || user.role === "partners-admin" && currentPartner.subscriptionID ?
                            <TabBlock
                                tabs={[
                                    {name: "Plan tarifaire", link: `/partners/${partnerId}/subscription`},
                                    {name: "Facturation", link: `/partners/${partnerId}/subscription/billing`},
                                    {name: "Méthode de paiement", link: `/partners/${partnerId}/subscription/payment`}
                                ]}
                                activeLink={0}
                            />
                        : user.role === "admin" &&  userSubscription && userSubscription.subscriptionPlan ?
                            <TabBlock
                                tabs={[
                                    {name: "Plan tarifaire", link: `/partners/${partnerId}/subscription`},
                                    {name: "Facturation", link: `/partners/${partnerId}/subscription/billing`},
                                ]}
                                activeLink={0}
                            />
                        :
                                <TabBlock
                                    tabs={[
                                        {name: "Plan tarifaire", link: `/partners/${partnerId}/subscription`},
                                    ]}
                                    activeLink={0}
                                />

                    }*/}
                </>
            }/>

            <TabBar
                tabs={[
                    {
                        name: "Fiche du partenaire",
                        link: `/partners/${partnerId}`
                    },
                    {
                        name: "Liste d’activités",
                        link: `/partners/${partnerId}/activities`,
                        index: numberActivity ? numberActivity : 0
                    },
                    {
                        name: "Gérer vos offres",
                        link: `/partners/${partnerId}/offers`
                    },
                    {
                        name: "Plan tarifaire",
                        link: `/partners/${partnerId}/subscription`,
                    }
                ]}
                activeTab={3}
            />

            <div className={"header__container"}>
                {
                    userSubscription && subscription ?
                        <h2><span className={"text-18"}>Votre abonnement en cours :</span> <span
                            className={"bold"}>{userSubscription && getSubscriptionName(userSubscription.subscriptionPlan.name)}</span>
                        </h2>
                        :
                        <h2><span className={"text-18"}>Aucun abonnement en cours</span></h2>
                }
            </div>

            {
                userSubscription && subscription ?
                    <div className={"container__grey"}>
                        <div className={"subscription__content"}>
                            {
                                subscription.map((item, index) => {
                                    return (
                                        <div className={"subscription__card_col"} key={index}>
                                            <SubscriptionCard
                                                subscription={subscription}
                                                plan={item}
                                                active={item.stripeSubscriptionId === userSubscription.subscriptionPlan.stripeSubscriptionId}
                                                activePlan={userSubscription.subscriptionPlan.name}
                                                index={index}
                                                key={index}
                                            />
                                            {
                                                userSubscription.subscriptionPlan.name === "essentiel" && item.name === "essentiel" ?
                                                    <div className={"subscription__card__commission"}>
                                                        <Input
                                                            type={"text"}
                                                            name={"commission"}
                                                            label={"Commission personnalisable"}
                                                            valueInput={`${userSubscription.commission}%`}
                                                            onChangeForm={(e) => {
                                                                e.preventDefault();
                                                                const value = e.target.value.replace('%', '');
                                                                setUserSubscription({
                                                                    ...userSubscription,
                                                                    commission: value
                                                                });
                                                            }}
                                                            isColumn={true}
                                                            disabled={user && user.role === "partner" || user.role === "partners-admin" ? true : false}
                                                        />
                                                        {
                                                            user && user.role === "admin" ?
                                                                <IconButton
                                                                    icon={check}
                                                                    isPrimary={false}
                                                                    onClick={() => setModalOpen(true)}

                                                                />
                                                                :
                                                                null
                                                        }
                                                    </div>

                                                    :
                                                    null
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <p className={"text-16"}>Une offre adaptée à vos besoins pour une visibilité sans frais et une
                            gestion
                            simplifiée. En tant que partenaires, nous prenons soin de vous et vous accompagnons chaque
                            mois.</p>
                    </div>
                    :
                    <div className={"container__empty"}>
                        <p className={"text-14"}>Ce partenaire n’a pas encore d’abonnement.</p>
                    </div>
            }
            <ModalAccept closeModal={() => setModalOpen(false)} isOpen={modalOpen} addOffer={handleCommission}/>
        </div>
    );
}

export default Subscription;