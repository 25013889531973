import React,{useState, useEffect} from "react";
import mail from "../../../assets/icones/global/mail_light.svg";
import '../../../styles/views/signin.css';
import logo from "../../../assets/images/svg/logo_row.svg";
import StepBar from "../../../components/stepBar/stepBar";
import {Link, useNavigate} from "react-router-dom";
import Button from "../../../components/button/button";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import {eraseAll} from "../../../redux/slice/signInSlice";
import {userLogout} from "../../../redux/slice/authSlice";

const SignInCheck = () => {

    const [step, setStep] = useState(3);
    const user = useSelector(state => state.signIn.user);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleNavigation = () => {
        //how to navigate to external link
        localStorage.removeItem('emailSent');
        dispatch(userLogout());
        dispatch(eraseAll());
        setTimeout(() => {
            navigate('/');
            window.open('https://frimity.fr');

        }, 200);

    }

    return (
        <div className="main signin">
            <div className="main__container">
                <StepBar step={step}/>
            </div>
            <div className="main__container check">
                <div className="main__container__section">
                    <div className="main__container__form">
                        <img src={logo} alt="logo"/>

                        <h1 className={"title-25"}>Félicitation votre inscription est terminé ✅</h1>
                        <h2 className={"title-20"}>Merci d'avoir choisi Frimity pour promouvoir votre établissement, vous recevrez une réponse sous 24h !</h2>
                        <p className={"text-14"}>Nous sommes impatients de vous aider à atteindre de nouveaux sommets de visibilité et de succès. Restez à l'affût de l'approbation de votre établissement, et préparez-vous à accueillir une foule d'enthousiastes curieux et avides de nouvelles expériences.</p>
                        <Button isPrimary={true} text={"Revenir sur notre site web"} onClick={handleNavigation} isFull={true} target={"_blank"}/>
                    </div>
                </div>
            </div>
            <div className="main__container last">
                <div className="main__footer">
                    <p className={"text-12"}>© Frimity 2023</p>
                    <div className={"main__footer__links"}>
                        <img src={mail} alt="logo"/>
                        <a href={"mailto:contact@frimity.fr"}><p
                            className={"text-12"}>contact@frimity.fr</p></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignInCheck;