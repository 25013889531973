import React, {useState, useEffect} from 'react';
import FormSectionRow from "../../../components/form/formSectionRow";
import Input from "../../../components/form/inputText/input";
import InputSelect from "../../../components/form/inputSelect/inputSelect";
import Tooltip from "../../../components/tooltips/tooltip";


const Modalities = ({modalities, setOffer, isDisabled}) => {

    const [offerQuantity, setOfferQuantity] = useState([]);
    const [offerPersonNumber, setOfferPersonNumber] = useState([]);
    const [isGroup, setIsGroup] = useState(false);
    const [isQuantity, setIsQuantity] = useState(false);

    const [discountNumber, setDiscountNumber] = useState(0);
    const [discountUnit, setDiscountUnit] = useState("");

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        if (name === 'unit') {
            setDiscountUnit(value);

            const combinedDiscount = `${discountNumber}${value}`;
            setOffer(`modalities.discount.value`, combinedDiscount);
        } else {
            setOffer(`modalities.${name}.value`, value);
        }
    };

    useEffect(() => {
        //1 to 1OO
        let quantity = [];
        //jump 25 by 25
        for (let i = 25; i <= 1000; i += 25) {
            //value and name
            quantity.push({value: i, name: i});
        }
        setOfferQuantity(quantity);

        //1 to 50
        let personNumber = [];
        for (let i = 1; i <= 100; i++) {
            //value and name
            personNumber.push({value: i, name: i});
        }
        setOfferPersonNumber(personNumber);

    }, []);

    useEffect(() => {
        if(modalities.personNumber.value > 1) {
            setIsGroup(true);
        }

        if(modalities.quantity && modalities.quantity.value > 1) {
            setIsQuantity(true);
        }

        if(modalities.discount) {
            //split after last digit "30%" => ["30", "%"]
            const discount = modalities.discount.value.split(/(?<=\d)(?=[^\d])/);
            setDiscountNumber(discount[0]);
            setDiscountUnit(discount[1]);
        }
    }, [modalities]);

    return offerQuantity && (
        <FormSectionRow title={"Modalité de l'offre"} classname={"flex-20"}>
            <div className="form__col__modalities">
                <Input
                    name={"discount"}
                    label={modalities.discount.label}
                    placeholder={modalities.discount.placeholder}
                    valueInput={discountNumber}
                    onChangeForm={(e) => setDiscountNumber(e.target.value)}
                    validationType={modalities.discount.validationType}
                    isColumn={true}
                    disabled={isDisabled}
                    errorEmpty={modalities.discount.error}
                    errorMessage={modalities.discount.errorMessage}
                    type={modalities.discount.type}
                    isRequired={true}
                />
                <InputSelect
                    name={"unit"}
                    label={"Unité du montant"}
                    placeholder={"Unité du montant"}
                    value={discountUnit}
                    valueSelected={discountUnit}
                    onChange={handleChange}
                    isColumn={true}
                    isDisabled={isDisabled}
                    options={[{value: "€", name: "€"}, {value: "%", name: "%"}]}
                    isRequired={true}
                />
            </div>
            <div className="form__col__modalities">
                <div className="form__col checkbox">
                    <div className={"form__row__radio"}>
                        <label htmlFor={"isGroup"} className={"text-14"}>Groupe de personnes</label>
                        <Tooltip children={
                            <>
                                <p className={"title-14"}>Pour un groupe de personnes ?</p>
                                <p className={"text-14"}>Cochez la case si cette offre promotionnelle est destinée à un groupe de participants. Cela permettra d'adapter les conditions de l'offre en fonction du nombre de personnes impliquées, offrant ainsi une flexibilité optimale.</p>
                            </>
                        }/>
                    </div>
                    <input
                        type="checkbox"
                       name={"isGroup"}
                       disabled={isDisabled}
                       id={"isGroup"}
                       checked={isGroup}
                       onChange={() => {
                            setIsGroup(!isGroup);
                            setOffer(`modalities.personNumber.value`, null);
                       }}
                    />
                </div>
                {
                    isGroup ?
                        <InputSelect
                            name={"personNumber"}
                            label={modalities.personNumber.label}
                            placeholder={modalities.personNumber.placeholder}
                            value={modalities.personNumber.value}
                            valueSelected={modalities.personNumber.value}
                            onChange={handleChange}
                            validationType={modalities.personNumber.validationType}
                            isColumn={true}
                            isDisabled={isDisabled}
                            errorEmpty={modalities.personNumber.error}
                            errorMessage={modalities.personNumber.errorMessage}
                            type={modalities.personNumber.type}
                            options={offerPersonNumber}
                            hasTooltip={true}
                            tooltipContent={
                                <>
                                    <p className={"title-14"}>Nombre de personnes</p>
                                    <p className={"text-14"}>Cochez la case si cette offre promotionnelle est destinée à un groupe de participants. Cela permettra d'adapter les conditions de l'offre en fonction du nombre de personnes impliquées, offrant ainsi une flexibilité optimale.</p>
                                </>
                            }
                        />
                        : null
                }
            </div>
            <div className="form__col__modalities">
                <div className="form__col checkbox">
                    <div className={"form__row__radio"}>
                        <label htmlFor={"isQuantity"} className={"text-14"}>Nombre limité de réservations possible</label>
                        <Tooltip children={
                            <>
                                <p className={"title-14"}>Nombre limités de réservation possible</p>
                                <p className={"text-14"}>Cochez la case si cette offre promotionnelle est soumise à une limitation de réservations. Cela permettra d'ajuster l'offre en fonction d'un nombre prédéfini de réservations, assurant ainsi une gestion optimale des disponibilités.</p>
                            </>
                        }/>
                    </div>
                    <input type="checkbox"
                           name={"isQuantity"}
                           disabled={isDisabled}
                           id={"isQuantity"} checked={isQuantity}
                           onChange={() => {
                               setIsQuantity(!isQuantity)
                               setOffer(`modalities.quantity.value`, null);
                           }}/>
                </div>
                {
                    isQuantity ?
                        <InputSelect
                            name={"quantity"}
                            label={modalities.quantity.label}
                            placeholder={modalities.quantity.placeholder}
                            value={modalities.quantity.value}
                            valueSelected={modalities.quantity.value}
                            onChange={handleChange}
                            validationType={modalities.quantity.validationType}
                            isColumn={true}
                            isDisabled={isDisabled}
                            errorEmpty={modalities.quantity.error}
                            errorMessage={modalities.quantity.errorMessage}
                            type={modalities.quantity.type}
                            options={offerQuantity}
                            hasTooltip={true}
                            tooltipContent={
                                <>
                                    <p className={"title-14"}>Quantité disponible de l’offre</p>
                                    <p className={"text-14"}>Veuillez sélectionner la quantité d'offre disponible via le menu déroulant. Cette action est nécessaire pour établir le nombre de réservations de l'offre promotionnelle, garantissant ainsi une adaptation précise et conforme aux exigences spécifiques de l'offre.</p>
                                </>
                            }
                        />
                        : null
                }
            </div>
        </FormSectionRow>
    )
}

export default Modalities;