import React, {useContext, useEffect, useState} from "react";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import '../../../../styles/views/stripe-payement.css';
import PayementServices from "../../../../api/services/payement";
import PayementCard from "../../../../components/stripe/payementCard";
import Button from "../../../../components/button/button";
import UserSubscriptionServices from "../../../../api/services/stripeSubscription";
import {useNavigate} from "react-router-dom";
import SubmitButton from "../../../../components/button/submitButton";
import add from "../../../../assets/icones/global/add.svg";
import {ErrorContext} from "../../../../context/errorContext";
import {useLoader} from "../../../../context/loaderContext";

const UpgradeCheckoutForm = ({ customerId, newPriceId, currentSubscriptionId, stripeProductId }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [savedMethods, setSavedMethods] = useState([]);
    const [error, setError] = useState(null);
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
    const navigate = useNavigate();
    const [isFormVisible, setIsFormVisible] = useState(true);
    const [reload, setReload] = useState(false);
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();



    useEffect(() => {
        setLoading(true);
        // Fetch saved payment methods from your API
        PayementServices.getSavedPaymentMethods({ customerId: customerId })
            .then((res) => {
                setSavedMethods(res.data.data);
                if (res.data.data.length !== 0) {
                    setIsFormVisible(false);
                }
            })
            .catch((error) => showError(error.response.data.message))
            .finally(() => setLoading(false));

            //get customer default payment method
            UserSubscriptionServices.getCustomerbyId(customerId)
            .then((res) => {
                setDefaultPaymentMethod(res.data.customer.invoice_settings.default_payment_method);
            })
            .catch((error) => showError(error.response.data.message))
            .finally(() => setLoading(false));
    }, [customerId, reload]);

    const handleSubmitNewMethod = async (event) => {
        event.preventDefault();
        setError(null); // Clear any previous errors
        setLoading(true);

        const { error, setupIntent } = await stripe.confirmSetup({
            elements,
            redirect: 'if_required'
        });

        if (error) {
            setError(error.message);
            setLoading(false);
        }else{
            if (setupIntent.status === "succeeded") {
                setLoading(true);
                PayementServices.attachPaymentMethod({ customerId, paymentMethodId: setupIntent.payment_method })
                .then((res) => {
                    if(res.status === 200){
                        const data = {
                            customerId: customerId
                        }
                        PayementServices.getSavedPaymentMethods(data)
                        .then((res) => {
                            setSavedMethods(res.data.data);
                            setReload(true);
                            setIsFormVisible(false);
                        })
                        .catch((error) => {
                            showError(error);
                            setLoading(false);
                        }).finally(() => {
                            setLoading(false);
                        })
                    }
                })
                .catch((error) => {
                    showError(error);
                    setLoading(false);
                }).finally(() => {
                    setLoading(false);
                });
            }
        }
    };

    const handleUpgradeSubscription = async () => {
        setError(null);
        setLoading(true);
        try {
            setLoading(true);
            const response = await UserSubscriptionServices.updateSubscription({
                customerId: customerId,
                newPriceId:newPriceId,
                subscriptionId: currentSubscriptionId,
                stripeProductID: stripeProductId
            });
            if (response.status === 200) {
                navigate("/partner/subscription/update/confirmation");
            } else {
                setError("Une erreur est survenue veuillez réessayer");
            }
        } catch (err) {
            showError(error);
            setError("Une erreur est survenue");
        } finally {
            setLoading(false);
        }
    };


    return savedMethods && (
        <div className={"payment-methods"}>
            <div className={"payment-methods__container__payement"}>
                <form id="payment-form" onSubmit={handleSubmitNewMethod} className={!isFormVisible ? "hidden" : ""}>
                    <PaymentElement id="payment-element" />
                    <SubmitButton text={"Ajouter cette carte"} isPrimary={true} disabled={setLoading}/>
                    {error && <div className="error">{error}</div>}
                </form>
                {
                    !isFormVisible && savedMethods.length !== 0 && defaultPaymentMethod &&
                        savedMethods.map((method) => (
                            <PayementCard
                                key={method.id}
                                method={method}
                                defaultMethod={method.id === defaultPaymentMethod}
                                customerId={customerId}
                            />
                        ))
                }
                {
                    !isFormVisible ?
                        <>
                            <div className={"payment-methods__row actions"} onClick={() => setIsFormVisible(true)}>
                                <img src={add} alt="add"/>
                                <p className={"text-16 light"}>Ajouter une nouvelle carte</p>
                            </div>

                            <Button text={"Mettre à niveau l'abonnement"} isPrimary={true} isFull={true} onClick={handleUpgradeSubscription} />
                            {error && <div className="error">{error}</div>}

                        </>
                        : null
                }
            </div>
        </div>
    );
};

export default UpgradeCheckoutForm;
