import React, {useState} from 'react';
import mail from '../../../assets/images/svg/mail_round_yellow.svg';
import { Link } from "react-router-dom";
import arrow from '../../../assets/icones/arrows/left.svg';
import '../../../styles/views/verifications.css';
import {useParams} from "react-router";
import {useContext} from "react";
import {ErrorContext} from "../../../context/errorContext";
import {useLoader} from "../../../context/loaderContext";
import PasswordResetServices from "../../../api/services/passwordReset";
import {useNavigate} from "react-router-dom";
import SubmitButton from "../../../components/button/submitButton";
import useFormValidation from "../../../hooks/form/useFormValidation";
import Input from "../../../components/form/inputText/input";
import useInputValidation from "../../../hooks/form/useInputValidation";


const PasswordUserEmail = () => {
    const {sendPasswordCode} = PasswordResetServices;

    const navigate = useNavigate();

    const {setLoading} = useLoader();
    const {showError} = useContext(ErrorContext);
    const {validateInput} = useInputValidation();

    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);



    const handleSendCode = (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            email: email
        };

        const isValidEmail = validateInput(email, 'email') === '' ? true : false;

        if(isValidEmail) {
            setError(false)
            sendPasswordCode(data)
            .then((response) => {
                if (response.status === 200) {
                    //params email
                    navigate('/password-reset/verify/' + email);
                }
            }).catch((error) => {
                showError(error);
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
        }else {
            setLoading(false);
            setError(true);
        }
    }

    return (
        <div className="main verification">
            <div className="main__container__col password">
                <div className="main__container__col32">
                    <img src={mail} alt="logo"/>
                </div>
                <div className="main__container__col32">
                    <h1 className={"title-25"}>Renseignez le mail de votre compte</h1>
                    <p className={"text-14"}>Nous allons envoyé un code pour réinitialisé votre mot de passe à l'adresse mail correspondante à votre compte.</p>
                </div>
                <div className="main__container__col32">
                    <form className="form__login" onSubmit={handleSendCode}>
                        <Input
                            label={"Email"}
                            name={"email"}
                            placeholder={"Entrez votre email"}
                            type={"email"}
                            valueInput={email}
                            validationType={"email"}
                            isColumn={true}
                            onChangeForm={(e) => setEmail(e.target.value)}
                            errorMessage={error}
                        />
                        <SubmitButton text={"Envoyer le code"} isPrimary={true} isFull={true} />
                    </form>
                </div>
                <div className="main__container__col32  action_row">
                    <img src={arrow} alt="arrow"/>
                    <Link to={"/login"} className={"text-14 bold"}>Retour à l'accueil</Link>
                </div>
            </div>
        </div>
    );
}

export default PasswordUserEmail;