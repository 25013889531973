import React, {useEffect} from 'react';
import mail from '../../../assets/images/svg/mail_round_yellow.svg';
import Button from "../../../components/button/button";
import { Link } from "react-router-dom";
import arrow from '../../../assets/icones/arrows/left.svg';
import '../../../styles/views/verifications.css';
import {useParams} from "react-router";
import VerificationServices from "../../../api/services/verification";
import {useSelector} from "react-redux";
import {useContext} from "react";
import {ErrorContext} from "../../../context/errorContext";
import {useLoader} from "../../../context/loaderContext";


const VerificationUserEmail = () => {

    const user = useSelector((state) => state.auth.user);
    const email = user ? user.email : null;
    const {sendVerificationCode} = VerificationServices;
    const { email: emailUrl } = useParams();

    const {setLoading} = useLoader();
    const {showError} = useContext(ErrorContext);


    useEffect(() => {
        //get email from user object in local storage
        const firstname = user ? user.firstname : null;
        const emailSent = localStorage.getItem('emailSent');

        const data = {
            email: email,
            name: firstname,
            isSignIn: false
        }

        const emailSentBoolean = JSON.parse(emailSent);
        if (emailSentBoolean === false || emailSentBoolean === null || emailSentBoolean === undefined) {
            localStorage.setItem('emailSent', true);
            sendVerificationCode(data)
                .then((response) => {
                    if (response.status === 200) {
                        console.log("email sent");
                    }
                }).catch((error) => {
                showError(error);
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, []);

    return (
        <div className="main verification">
            <div className="main__container__col">
                <div className="main__container__col32">
                    <img src={mail} alt="logo"/>
                </div>
                <div className="main__container__col32">
                    <h1 className={"title-25"}>Vérifiez votre boîte mail</h1>
                    <p className={"text-14 light"}>Nous avons envoyé un lien de vérification à {email}. Veuillez vérifier votre boîte mail et cliquer sur le lien pour continuer.</p>
                </div>
                <div className="main__container__col32">
                    <Button text={"Entrez le code manuellement"} link={"/verify-email/code"} isPrimary={true} isBig={true} />
                </div>
                <div className="main__container__col32  action_row">
                    <img src={arrow} alt="arrow"/>
                    <Link to={"/login"} className={"text-14 bold"}>Retour à l'accueil</Link>
                </div>
            </div>
        </div>
    );
}

export default VerificationUserEmail;