
const useParseDate = () => {
    const parseDate = (dateString) => {
        if (!dateString) return null;

        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };


    const parseBookingDate = (dateString) => {
        const dateParse = new Date(dateString);
        const date = new Date(dateParse.setHours(dateParse.getHours() - 2));

        const formattedDate = date.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        }) + ' à ' + date.toLocaleTimeString('fr-FR', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        })

        return formattedDate;
    }

    const parseBookingDateDay = (dateString) => {
        const dateParse = new Date(dateString);
        const date = new Date(dateParse.setHours(dateParse.getHours() - 2));

        const formattedDate = date.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        })

        return formattedDate;
    }

    const parseSlotTimeBooking = (dateString, durationBooking) => {
        const dateParse = new Date(dateString);
        const date = new Date(dateParse.setHours(dateParse.getHours() - 2));

        const dateEnd = new Date(date.getTime() + durationBooking * 60000);

        const formattedDate = date.toLocaleTimeString('fr-FR', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }) + ' - ' + dateEnd.toLocaleTimeString('fr-FR', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        })

        return formattedDate;
    }

    const parseTimeCancellationPeriodEndBooking = (dateString, cancellationPolicy) => {

        // Parse the booking date
        const dateParse = new Date(dateString);
        const date = new Date(dateParse.setHours(dateParse.getHours() - 2)); // Adjusting for time zone or other considerations

        // Calculate the cancellation period end date based on the policy
        let dateEnd = new Date(date.getTime());
        if (cancellationPolicy === "72h") {
            dateEnd = new Date(date.getTime() - 72 * 60 * 60 * 1000);
        } else if (cancellationPolicy === "48h") {
            dateEnd = new Date(date.getTime() - 48 * 60 * 60 * 1000);
        } else if (cancellationPolicy === "24h") {
            dateEnd = new Date(date.getTime() - 24 * 60 * 60 * 1000);
        }

        // Get the current date and time
        const currentDate = new Date();

        // Compare current date with the end date of the cancellation period
        const hasEnteredCancellationPeriod = currentDate >= dateEnd;

        return hasEnteredCancellationPeriod; // Return true if within cancellation period, false otherwise
    };

    const parseNotificationsDate = (createdAt) => {
        // Parse the createdAt string to get the date
        const createdAtDate = new Date(createdAt);
        new Date(new Date().getTime() + 2 * 60 * 60 * 1000);

        // Get the current date and time
        const now = new Date(new Date().getTime() + 2 * 60 * 60 * 1000);

        // Calculate the difference in milliseconds
        const diffMs = now - createdAtDate;

        // Convert milliseconds to minutes, hours, and days
        const diffMins = Math.round(diffMs / 60000); // 60,000 ms in a minute
        const diffHours = Math.floor(diffMins / 60);
        const diffDays = Math.floor(diffHours / 24);

        // Approximate months calculation assuming average month length
        const diffMonths = Math.floor(diffDays / 30);

        // Format based on the conditions specified
        if (diffMins < 60) {
            return `Reçu il y a ${diffMins} min`;
        } else if (diffHours < 24) {
            return `Reçu il y a ${diffHours} h`;
        } else if (diffDays < 30) {
            return `Reçu il y a ${diffDays} jours`;
        } else {
            return `Reçu il y a ${diffMonths} mois`;
        }
    };


    return {parseDate, parseBookingDate, parseBookingDateDay, parseSlotTimeBooking, parseTimeCancellationPeriodEndBooking, parseNotificationsDate};
}

export default useParseDate;

