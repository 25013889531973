//create a hook to translate the error message from the server

const useParseErrorMessage = (error) => {

    //Missing email or password
    //Wrong password

    const parseErrorMessage = (error) => {
        switch (error) {
            case 'Missing email or password':
                return 'Votre email ou votre mot de passe est invalide';
            case 'Wrong password':
                return 'Votre mot de passe est incorrect';
        }
    }

    return {parseErrorMessage};
}

export default useParseErrorMessage;