import {getRequest, postRequestJson, patchRequest, deleteRequest} from '../request/apiRequest';

const PartnersServices = {

    getPartners: async () => {
        return await getRequest('/partners');
    },

    getPartnerById: async (partnerId) => {
        return await getRequest(`/partners/${partnerId}`);
    },

    createPartner: async (data) => {
        return await postRequestJson('/partners', data);
    },

    updatePartner: async (partnerId, data) => {
        return await patchRequest(`/partners/${partnerId}`, data);
    },

    deletePartner: async (partnerId) => {
        return await deleteRequest(`/partners/${partnerId}`);
    }

}

export default PartnersServices;