import React from 'react';
import green_arrow from '../../assets/icones/arrows/arrow_up_green.svg';
import red_arrow from '../../assets/icones/arrows/arrow_up_red.svg';
import line from '../../assets/icones/arrows/line_neutral.svg';
import Tooltip from "../tooltips/tooltip";

const CardAnalytics = ({name, icon, color, colorDark, value, percentage, tooltipText, tooltipDescription, className}) => {

    return (
        <div className={`card_analytics ${className}`} style={{backgroundColor: color}}>
            <div className={"card_analytics__tooltip_absolute"}>
                <Tooltip
                    children={
                        <>
                            <p className={"title-14"}>{tooltipText}</p>
                            <p className={"text-14"}>{tooltipDescription}</p>
                        </>
                    }
                />
            </div>
            <div className={"card_analytics__icon"}>
                <img src={icon} alt={name}/>
            </div>

            <div className={"card_analytics__content"}>
                <div className={"card_analytics__content__row"}>
                    <div className={"card_analytics__content__col"}>
                        <div className={"text-14"}>{name}</div>
                        <div className={"text-25 bold value"}>{value}</div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="84" height="42" viewBox="0 0 84 42" fill="none">
                        <rect width="80" height="40" transform="translate(2)" fill={color}/>
                        <path
                            d="M2 40C2 40 11.6217 22.5 22 22.5C32 22.5 32 29 42 29C52.3783 29 51.6217 11.5 62 11.5C71.5 11.5 82 21 82 21"
                            stroke={colorDark} stroke-width="4" stroke-linecap="round"/>
                    </svg>
                </div>
                <div className={"card_analytics__content__percentage"}>
                    {
                        percentage > 0 ?
                            <img src={green_arrow} alt={"arrow_up"}/>
                        : percentage < 0 ?
                            <img src={red_arrow} alt={"arrow_down"}/>
                        :
                            <img src={line} alt={"arrow_up"}/>
                    }
                    <div className={"text-12 bold row_gap"}>
                        <span
                            className={`percentage`}
                            style={{
                                color: percentage > 0 ? "#83BF6E" : percentage < 0 ? "#FF8079" : "#667085FF"
                            }}>
                                 {percentage}%
                        </span>
                          mois précédent
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardAnalytics;