
const useSubscriptionName = () => {

    const getSubscriptionName = (name) => {
        switch (name) {
            case "essentiel":
                return "Essentiel";
            case "professional":
                return "Professionnel";
            case "premium":
                return "Premium";
            case "professionnel":
                return "Professionnel";
            case "prenium":
                return "Premium";
            default:
                return "Essentiel";
        }
    }

    return {getSubscriptionName}
}

export default useSubscriptionName