import {postRequestJson} from '../request/apiRequest';

const SupportServices = {

    sendSupportPartnerEmail: async (data) => {
        return await postRequestJson('/support/partner-email', data);
    }

}

export default SupportServices;
