import React, {useContext} from 'react';
import {Outlet} from "react-router-dom";
import './styles/root.css';
import Navbar from "./components/navbar/navbar";
import ErrorModal from "./components/modal/modalError";
import {useLoader} from "./context/loaderContext";
import Loader from "./components/loader/loader";

function OutLayout() {

    const { loading } = useLoader();

  return (
    <div className="App">
        <main className={"main"}>
            <Loader isGlobal={false} />
            <Outlet />
            <ErrorModal />
        </main>
    </div>
  );
}

export default OutLayout;
