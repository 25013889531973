import React, { useState, useEffect } from 'react';
import Calendar from './calendar';
import '../../../styles/components/calendar.css';
import Button from "../../button/button";
import {isDisabled} from "@testing-library/user-event/dist/utils";

const DateRangePicker = ({ offer, setOffer, errorEmpty, errorMessage, disabled = false }) => {
    const [startDate, setStartDate] = useState(offer.startDate.value);
    const [endDate, setEndDate] = useState(offer.endDate.value);
    const [selectedDates, setSelectedDates] = useState(new Set());
    const [excludedDates, setExcludedDates] = useState(new Set(offer.excludedDates.value));
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [nextMonth, setNextMonth] = useState((currentMonth + 1) % 12);
    const [nextYear, setNextYear] = useState(currentMonth === 11 ? currentYear + 1 : currentYear);
    const [isCalendarDisabled, setIsCalendarDisabled] = useState(false); // new state variable


    useEffect(() => {
        // Fetch initial date range from the database and set it
        // This is a simplified example, adjust based on how you fetch data
        setStartDate(offer.startDate.value);
        setEndDate(offer.endDate.value);
        //format excludedDates from db
        setExcludedDates(new Set(offer.excludedDates.value.map(date => date.split('T')[0])));
    }, [offer]);

    useEffect(() => {
        const currentDate = new Date();
        const end = endDate ? new Date(endDate) : null;

        if (end && end < currentDate || disabled) {
            setIsCalendarDisabled(true); // Disable the calendar if end date is in the past
        } else {
            setIsCalendarDisabled(false); // Enable the calendar otherwise
        }
    }, [endDate]);

    const handleFormChange = (value, field) => {
        // Update the offer object with the new date
        setOffer(`validity.${field}.value`, value);
        // Also update the local state to reflect the change
        if (field === 'startDate') {
            setStartDate(value);
        } else if (field === 'endDate') {
            setEndDate(value);
        }else if (field === 'excludedDates') {
            setExcludedDates(value);
        }
    };

    const handleDateClick = (dateString) => {
        const clickedDate = new Date(dateString);
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;

        if (start && end && clickedDate >= start && clickedDate <= end) {
            toggleDateExclusion(dateString);
        } else if (!startDate || (!endDate && clickedDate < start)) {
            setStartDate(dateString);
            setEndDate(null);
            // Here we call setOffer to update the offer's start date
            setOffer('validity.startDate.value', dateString);
        } else if (!endDate || clickedDate > start) {
            setEndDate(dateString);
            // Here we call setOffer to update the offer's end date
            setOffer('validity.endDate.value', dateString);
        }
    };


    const toggleDateExclusion = (dateString) => {
        const newExcludedDates = new Set(excludedDates);
        let action;

        if (newExcludedDates.has(dateString)) {
            newExcludedDates.delete(dateString);
            action = 'remove';
        } else {
            newExcludedDates.add(dateString);
            action = 'add';
        }

        setExcludedDates(newExcludedDates);

        // Update the offer object accordingly
        // Assuming setOffer is a function that updates the offer state in your app
        const updatedExcludedDatesArray = Array.from(newExcludedDates);
        setOffer('validity.excludedDates.value', updatedExcludedDatesArray);

    };


    const handleResetClick = () => {
        setSelectedDates(new Set());
        setStartDate(null);
        setEndDate(null);
        setExcludedDates(new Set());

        // Here we call setOffer to update the offer's start date
        setOffer('validity.startDate.value', null);
        setOffer('validity.endDate.value', null);
        setOffer('validity.excludedDates.value', []);
    };

    const handleResetEndDate = () => {
        setEndDate(null);
        setExcludedDates(new Set());
        setOffer('validity.endDate.value', null);
        setOffer('validity.excludedDates.value', []);
    }

    const resetMonth = () => {
        const currentDate = new Date();
        setCurrentMonth(currentDate.getMonth());
        setCurrentYear(currentDate.getFullYear());
        // If you want to reset the next month as well,
        // you need to calculate what the next month and year would be
        setNextMonth((currentDate.getMonth() + 1) % 12);
        setNextYear(currentDate.getMonth() === 11 ? currentDate.getFullYear() + 1 : currentDate.getFullYear());

    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', options).replace(/\./g, '');
    };

    const handlePreviousMonth = () => {
        setCurrentMonth(prev => {
            if (prev === 0) {
                setCurrentYear(currentYear - 1);
                return 11; // December
            } else {
                return prev - 1;
            }
        });
    };

    const handleNextMonth = () => {
        setCurrentMonth(prev => {
            if (prev === 11) {
                setCurrentYear(currentYear + 1);
                return 0; // January
            } else {
                return prev + 1;
            }
        });
    };


// Use useEffect to set nextMonth and nextYear based on currentMonth and currentYear
    useEffect(() => {
        if (currentMonth === 11) {
            setNextMonth(0);
            setNextYear(currentYear + 1);
        } else {
            setNextMonth(currentMonth + 1);
            setNextYear(currentYear);
        }
    }, [currentMonth, currentYear]);

    return (
        <div className={`container__date-picker ${disabled ? "disabled" : null}`}>
            <div className="date-picker__body">
                <Calendar
                    onSelect={isCalendarDisabled || disabled ? null : handleDateClick}
                    selectedDates={selectedDates}
                    startDate={startDate}
                    endDate={endDate}
                    excludedDates={excludedDates}
                    currentMonth={currentMonth}
                    currentYear={currentYear}
                    handlePreviousMonth={handlePreviousMonth}
                    handleNextMonth={handleNextMonth}
                    calendarDisabled={isCalendarDisabled}
                    handleResetEndDate={handleResetEndDate}
                />
                <Calendar
                    onSelect={isCalendarDisabled || disabled ? null : handleDateClick}
                    startDate={startDate}
                    endDate={endDate}
                    excludedDates={excludedDates}
                    currentMonth={nextMonth}
                    currentYear={nextYear}
                    handlePreviousMonth={handlePreviousMonth}
                    handleNextMonth={handleNextMonth}
                    calendarDisabled={isCalendarDisabled}
                    handleResetEndDate={handleResetEndDate}
                />
            </div>
            <div className="date-picker__bottom">
                <div className="date-picker__selected-dates">
                    <div className={`date-picker__selected-date ${!startDate ? "placeholder" : ""} ${errorEmpty || errorMessage ? "error" : null}`}>{startDate ? formatDate(startDate) : "3 janv. 2024"}</div>
                    -
                    <div className={`date-picker__selected-date ${!endDate ? "placeholder" : ""} ${errorEmpty || errorMessage ? "error" : null}`}>{endDate ? formatDate(endDate) : "15 janv. 2024"}</div>
                </div>
                {
                    !isCalendarDisabled || !disabled ?
                        <div className="date-picker__selected-actions">
                            <Button text={"Réinitialiser"} onClick={() => {
                                resetMonth();
                                handleResetClick();
                            }} isPrimary={false}/>
                        </div>
                        : null
                }

            </div>
        </div>
    );
};

export default DateRangePicker;
