import React from 'react';
import check from '../../../assets/images/svg/check_round_green.svg';
import Button from "../../../components/button/button";
import {Link, useNavigate} from "react-router-dom";
import arrow from '../../../assets/icones/arrows/left.svg';
import '../../../styles/views/verifications.css';
import {useDispatch, useSelector} from "react-redux";
import {userSetEmailVerificationStatus} from "../../../redux/slice/authSlice";




const VerificationUserCheck = () => {

    //get local storage email
    const user = useSelector((state) => state.auth.user);
    const email = user ? user.email : null;
    const navigate  = useNavigate();
    const dispatch = useDispatch();

    const handleVerification = () => {
        //dispatch singIn action
        localStorage.removeItem('emailSent');
        dispatch(userSetEmailVerificationStatus());
        navigate(`/partners/${user.partnerID._id}`);
    }

    return (
        <div className="main verification">
            <div className="main__container__col">
                <div className="main__container__col32">
                    <img src={check} alt="logo"/>
                </div>
                <div className="main__container__col32">
                    <h1 className={"title-25"}>Email verifié</h1>
                    <p className={"text-14 light"}>Votre email {email} a été vérifié avec succès.</p>
                </div>
                <div className="main__container__col32">
                    <Button text={"Continuer"} isPrimary={true} isBig={true} onClick={handleVerification} />
                </div>
                <div className="main__container__col32  action_row">
                    <img src={arrow} alt="arrow"/>
                    <Link to={"/login"} className={"text-14 bold"}>Retour à l'accueil</Link>
                </div>
            </div>
        </div>
    );
}

export default VerificationUserCheck;