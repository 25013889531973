import React from "react";

const DaysOfWeeks = ({ daysOfWeek, setDaysOfWeek }) => {
    const daysOfWeekOptions = [
        { id: 1, name: "Lundi", value: 1 },
        { id: 2, name: "Mardi", value: 2 },
        { id: 3, name: "Mercredi", value: 3 },
        { id: 4, name: "Jeudi", value: 4 },
        { id: 5, name: "Vendredi", value: 5 },
        { id: 6, name: "Samedi", value: 6 },
        { id: 0, name: "Dimanche", value: 0 },
    ];

    // This function toggles the selected day in the daySelected state
    const handleChange = (dayValue) => {
        // Check if the day is already included in the array
        const isDaySelected = daysOfWeek.value.includes(dayValue);

        // Determine the new array of days based on the check status
        const newDaysOfWeek = isDaySelected
            ? daysOfWeek.value.filter(day => day !== dayValue)  // Remove the day
            : [...daysOfWeek.value, dayValue];  // Add the day


        // Update the state with the new array
        setDaysOfWeek(`daysOfWeek.value`, newDaysOfWeek);
    }


    return (
        <div className="sidebar__form__container__row">
            <div className="sidebar__form__label">
                <p className="text-16">Jour de la semaine</p>
                <p className="text-12">Cochez les jours de la semaine où l'activité est disponible.</p>
            </div>
            <div className="sidebar__form__content is__content__row row__wrap">
                {daysOfWeekOptions.map((day) => (
                    <div key={day.id} className="form__row checkbox">
                        <input
                            type="checkbox"
                            className="checkbox"
                            name={day.name}
                            id={`day-${day.id}`}
                            checked={daysOfWeek.value.includes(day.value)}
                            onChange={() => handleChange(day.value)}
                        />
                        <label htmlFor={`day-${day.id}`} className="text-14 light">{day.name}</label>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default DaysOfWeeks;
