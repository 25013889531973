import React, {useState} from 'react';
import '../../styles/components/modal.css';
import Tag from "../tag/tag";
import useStatusName from "../../hooks/parseText/useStatusName";
import close from '../../assets/icones/global/close-stroke.svg';
import {Link} from "react-router-dom";
import Avatar from "../avatar/avatar";

const ModalSearch = ({isOpen, closeModal, searchList = [], titleModal, status = false, type = false}) => {

    const { getStatusName } = useStatusName();

    return(
        <div className={`modal search ${isOpen ? "open" : ""}`}>
            <div className={"modal__header"}>
                {type ? <p className={"text-14"}>{type.charAt(0).toUpperCase() + type.slice(1)}s {status ? <Tag text={getStatusName(status)} status={status} /> : null}</p> : null}
                <div className={"modal__header__actions"}>
                    <div className={"modal__close"} onClick={closeModal}>
                        <img src={close} alt={"close"}/>
                    </div>
                </div>
            </div>
            <div className={"modal__content"}>
                <div className={"modal__content__list"}>
                    {
                        searchList.length > 0 ?
                            searchList.map((item, index) => (
                                <Link
                                    to={
                                        type === "partner" ? `/partners/${item._id}` :
                                            type === "activity" ? `/partners/${item.partnerID}/activities/${item._id}` :
                                                type === "offer" ? `/partners/${item.partnerID._id}/offers/${item._id}`
                                                    : null
                                    }
                                    onClick={closeModal}
                                    className={"modal__content__list__item"}
                                    key={index}
                                >
                                    {
                                        type === "users" ?
                                            <>
                                                <Avatar avatarURL={item.avatar} name={item.firstname} />
                                                <div className={"text-14"}>{item.firstname} {item.lastname}</div>
                                            </>
                                            :
                                            <>
                                                <Avatar avatarURL={null} name={item.name} />
                                                <div className={"text-14"}>{item.name}</div>
                                            </>
                                     }

                                </Link>
                            ))
                            :
                            <div className={"text-14"}>Aucun {type}s recensé</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ModalSearch;
