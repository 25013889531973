import React from 'react';
import {Link} from "react-router-dom";

const Button = ({text, isPrimary = false, isRed= false,onClick, link = false, isFull = false, target = "", isDisabled = false}) => {

    return !link ? (
        <div className={`btn ${isPrimary ? "is__primary" : "is__secondary"} ${isRed ? "red" : ""} ${isFull ? "full" : ""} ${isDisabled ? "disabled": ""}`} onClick={onClick}>
            <p className={"title-14"}>{text}</p>
        </div>
    ) : (
        <Link to={link} target={target}>
            <div className={`btn ${isPrimary ? "is__primary" : "is__secondary"} ${isRed ? "red" : ""} ${isFull ? "full" : ""} ${isDisabled ? "disabled": ""}`} onClick={onClick}>
                <p className={"title-14"}>{text}</p>
            </div>
        </Link>
    )
}

export default Button;