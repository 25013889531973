import InputSelect from "../../../../components/form/inputSelect/inputSelect";
import { useState, useEffect } from "react";

const Duration = ({ duration, setDuration }) => {

    const [durationTime, setDurationTime] = useState({
        hours: duration.value !== '' ? duration.value.slice(0, duration.value.indexOf('h')) : null,
        minutes: duration.value !== '' ? duration.value.slice(duration.value.indexOf('h')+1) : null
    });

    const hours = Array.from({length: 24}, (_, i) => i);
    const minutes = Array.from({length: 60}, (_, i) => i < 10 ? `0${i}` : i);

    const handleChange = (e) => {
        setDurationTime({...durationTime, minutes: e.target.value});
        setDuration(`activityDuration.value`, `${durationTime.hours}h${e.target.value}`);
    }

    useEffect(() => {
        if(duration.value !== '' && duration.value !== null){
            setDurationTime({
                hours: duration.value.slice(0, duration.value.indexOf('h')),
                minutes: duration.value.slice(duration.value.indexOf('h')+1)
            });
        }
    }, [duration]);


    return(
        <div className="sidebar__form__container__row">
            <div className="sidebar__form__label">
                <p className="text-16">Durée</p>
                <p className="text-12">Définissez combien de temps dure chaque session de votre activité, en heures et minutes.</p>
            </div>
            <div className="sidebar__form__content is__content__row">
                <InputSelect
                    name={"hours"}
                    label={"Heure"}
                    options={hours.map((hour) => ({id: hour, name: hour, value: hour}))}
                    valueSelected={durationTime.hours}
                    onChange={(e) => setDurationTime({...durationTime, hours: e.target.value})}
                    isColumn={true}
                    errorMessage={duration.errorMessage}
                    error={duration.error}
                />

                <InputSelect
                    name={"minutes"}
                    label={"Minute"}
                    options={minutes.map((minute) => ({id: minute, name: minute, value: minute}))}
                    valueSelected={durationTime.minutes}
                    onChange={(e) => handleChange(e)}
                    isColumn={true}
                    errorMessage={duration.errorMessage}
                    error={duration.error}
                />
            </div>
        </div>
    );
}

export default Duration;