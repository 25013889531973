import React from "react";
import mail from "../../../assets/icones/global/mail_light.svg";
import '../../../styles/views/policy.css';
import logo from "../../../assets/images/svg/logo_row.svg";
import Button from "../../../components/button/button";
import {useNavigate} from "react-router-dom";

const FAQ = () => {

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    return (
        <div className="main policy">
            <div className="main__container text">
                <div className="main__container__section">
                    <div className="main__container__form">
                        <img src={logo} alt="logo"/>

                        <div className={"container__infos"}>
                            <h1 className={"title-25"}>Vous avez une question ?</h1>

                            <p className={"text-16"}>Vous trouverez ci-dessous les réponses aux questions les plus fréquemment posées par nos utilisateurs.</p>

                            <div className={"container__content"}>
                                <div className={"container__infos_text"}>
                                    <h2 className={"text-16"}>Qu’est-ce que Frimity ?</h2>
                                    <p className={"text-16"}>Frimity est une application mobile conçue pour enrichir
                                        votre expérience sociale en facilitant la planification et la réservation
                                        d'activités entre amis. Servant de passeport pour l'aventure, Frimity vous ouvre
                                        la porte à une multitude d'activités passionnantes situées à proximité. Avec
                                        cette application, vous pouvez facilement organiser des sorties avec vos amis,
                                        créer des souvenirs mémorables, et même élargir votre cercle social en
                                        rejoignant une communauté dynamique de personnes partageant les mêmes centres
                                        d'intérêt.</p>
                                </div>
                                <div className={"container__infos_text"}>
                                    <h2 className={"text-16"}>Comment puis-je devenir partenaire Frimity ?</h2>
                                    <p className={"text-16"}>Rejoignez Frimity en tant que partenaire en sélectionnant
                                        d'abord le plan qui correspond à vos objectifs. Une fois le plan choisi, accédez
                                        à votre tableau de bord personnalisé où vous pourrez gérer vos informations.
                                        Partagez ensuite les détails de votre entreprise et des activités que vous
                                        proposez, et mettez vos offres en ligne sur la plateforme. À chaque étape du
                                        processus, l'équipe de Frimity est là pour vous assister et s'assurer que la
                                        mise en ligne de vos offres se passe en toute simplicité, vous permettant ainsi
                                        de rejoindre aisément notre communauté dynamique.</p>
                                </div>
                                <div className={"container__infos_text"}>
                                    <h2 className={"text-16"}>>Quelles sont les avantages de devenir partenaire Frimity
                                        ?
                                    </h2>
                                    <p className={"text-16"}>Devenir partenaire chez Frimity offre une visibilité
                                        étendue à votre entreprise, attirant de nouveaux clients et augmentant votre
                                        chiffre d'affaires. Vous bénéficierez d'une gestion flexible, d'analyses
                                        approfondies, et d'un support client dédié. Nous sommes là pour maximiser votre
                                        succès et offrir des expériences exceptionnelles à vos clients.</p>
                                </div>
                                <div className={"container__infos_text"}>
                                    <h2 className={"text-16"}>Combien ça coûte ?</h2>
                                    <p className={"text-16"}>Les coûts varient en fonction du plan d'abonnement que vous
                                        choisissez en tant que partenaire. N'hésitez pas à nous contacter pour obtenir
                                        des informations détaillées sur nos offres. Nous serons ravis de vous guider
                                        vers l'offre la plus adaptée à vos besoins.</p>
                                </div>
                                <div className={"container__infos_text"}>
                                    <h2 className={"text-16"}>Y'a-t-il des obligations de ma part en tant que partenaire
                                        ?
                                    </h2>
                                    <p className={"text-16"}>Vous n’êtes en aucun cas obligé. Vous avez la liberté de
                                        désactiver votre activité ou votre compte à tout moment. Nous vous offrons
                                        également un contrôle total sur votre disponibilité et vos tarifs, que vous
                                        pouvez modifier à votre guise.</p>
                                </div>
                                <div className={"container__infos_text"}>
                                    <h2 className={"text-16"}>Quelles sont les conditions d'annulation ?</h2>
                                    <p className={"text-16"}>Les conditions d'annulation varient en fonction de votre
                                        activité. Vous pouvez les consulter sur la page de votre activité.</p>
                                </div>
                            </div>
                            <p className={"text-14 light"}>Date de la Dernière Mise à Jour : 12/12/2023</p>
                        </div>
                        <Button isPrimary={true} text={"Revenir en arrière"} isFull={true} onClick={goBack}/>
                    </div>
                </div>
            </div>
            <div className="main__container last">
                <div className="main__footer">
                    <p className={"text-12"}>© Frimity 2023</p>
                    <div className={"main__footer__links"}>
                        <img src={mail} alt="logo"/>
                        <a href={"mailto:contact@frimity.fr"}><p
                            className={"text-12"}>contact@frimity.fr</p></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FAQ;