import React, { useState } from 'react';
import IconButton from "../../button/iconButton";
import useImagesHandler from "../../../hooks/parseFiles/useImagesHandler";
import trash from "../../../assets/icones/global/trash_can.svg";
import add from "../../../assets/icones/global/add_full.svg";
import eye from "../../../assets/icones/global/eye.svg";
import ModalFilesDeleting from "../../modal/modalDeleting/modalFilesDeleting";
import ModalImagePreview from "../../modal/modalImagePreview";
import Loader from "../../loader/loader";

const InputImages = ({ name, onChange, type, imagesFiles, activityId, isCheck, partnerId, error }) => {
    const { images, handleChange, deleteImage, hiddenInputRef, resetInput, isLoading } = useImagesHandler({ imagesFiles, onChange, type });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imagesToDelete, setImagesToDelete] = useState([]);
    const [isModalImageOpen, setIsModalImageOpen] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);

    return (
        <div className={`input__images__container ${error ? 'errorImg' : ''} ${isLoading ? "center" : null}`}>
            {isLoading ? (
                <div className={`container__loading`}>
                    <div className={`loader notAbsolute`}>
                        <div className="loader__container">
                            <svg role="img"
                                 aria-label="Mouth and eyes come from 9:00 and rotate clockwise into position, right eye blinks, then all parts rotate and merge into 3:00"
                                 className="smiley" viewBox="0 0 128 128" width="128px" height="128px">
                                <defs>
                                    <clipPath id="smiley-eyes">
                                        <circle className="smiley__eye1" cx="64" cy="64" r="8"
                                                transform="rotate(-40,64,64) translate(0,-56)"/>
                                        <circle className="smiley__eye2" cx="64" cy="64" r="8"
                                                transform="rotate(40,64,64) translate(0,-56)"/>
                                    </clipPath>
                                    <linearGradient id="smiley-grad" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="0%" stopColor="#000"/>
                                        <stop offset="100%" stopColor="#fff"/>
                                    </linearGradient>
                                    <mask id="smiley-mask">
                                        <rect x="0" y="0" width="128" height="128" fill="url(#smiley-grad)"/>
                                    </mask>
                                </defs>
                                <g strokeLinecap="round" strokeWidth="12" strokeDasharray="175.93 351.86">
                                    <g>
                                        <rect fill="#FECE2F" width="128" height="64" clipPath="url(#smiley-eyes)"/>
                                        <g fill="none" stroke="#FECE2F">
                                            <circle className="smiley__mouth1" cx="64" cy="64" r="56"
                                                    transform="rotate(180,64,64)"/>
                                            <circle className="smiley__mouth2" cx="64" cy="64" r="56"
                                                    transform="rotate(0,64,64)"/>
                                        </g>
                                    </g>
                                    <g mask="url(#smiley-mask)">
                                        <rect fill="#FECE2F" width="128" height="64" clipPath="url(#smiley-eyes)"/>
                                        <g fill="none" stroke="#FECE2F">
                                            <circle className="smiley__mouth1" cx="64" cy="64" r="56"
                                                    transform="rotate(180,64,64)"/>
                                            <circle className="smiley__mouth2" cx="64" cy="64" r="56"
                                                    transform="rotate(0,64,64)"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <p className={'text-14 bold'}>Chargement...</p>
                    </div>
                </div>
            ) : (
                <div className={"images-added"}>
                    {images && images.length > 0 ? (
                        images.map((image, index) => {
                            return (
                                <div className={"image-container__col"} key={index}>
                                    <div className={"image-container download"}>
                                        <div className={"image-container__col__icons"}>
                                            <IconButton icon={eye}
                                                        onClick={() => {
                                                            setIsModalImageOpen(true);
                                                            setImagePreview(image.preview);
                                                        }}
                                                        disabled={isLoading} // Disable button if loading
                                            />
                                            <IconButton icon={trash}
                                                        onClick={() => {
                                                            setIsModalOpen(true);
                                                            setImagesToDelete(index);
                                                        }}
                                                        disabled={isLoading} // Disable button if loading
                                            />
                                        </div>
                                        <img
                                            src={image.preview}
                                            alt={image.name}
                                            onLoad={() => console.log("Image loaded successfully", image.preview)}
                                            onError={() => console.error("Error loading image", image.preview)}
                                        />
                                    </div>
                                    <span className={"text-14"}>
                                        {image.size} KB {image.dimensions ? ` - ${image.dimensions.width} x ${image.dimensions.height}px` : null}
                                    </span>
                                </div>
                            );
                        })
                    ) : (
                        <div className={"image-container download empty"}>
                            <p className={"text-14"}>Aucune image ajoutée pour le moment</p>
                        </div>
                    )}

                    <div className={"input__images__custom"}>
                        <IconButton
                            icon={add}
                            onClick={() => !isLoading && hiddenInputRef.current.click()} // Prevent new upload if loading
                            disabled={isLoading} // Disable button if loading
                        />
                        <input
                            type={"file"}
                            name={name}
                            ref={hiddenInputRef}
                            onChange={(e) => handleChange(e)}
                            multiple={true}
                            accept={'image/*'}
                            disabled={isLoading} // Prevent file input if loading
                        />
                    </div>
                </div>
            )}


            <ModalFilesDeleting
                isOpen={isModalOpen}
                closeModal={() => setIsModalOpen(false)}
                deleteFile={() => deleteImage(activityId, imagesToDelete, partnerId)}
            />
            <ModalImagePreview
                isOpen={isModalImageOpen}
                closeModal={() => setIsModalImageOpen(false)}
                image={imagePreview}
            />
        </div>
    );
};

export default InputImages;
