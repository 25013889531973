import { useState } from 'react';

const useParseError = (errorMessage) => {

    const parseMessage = (errorMessage) => {
        switch (errorMessage) {
            case 'Service Unavailable':
                return 'Nos service sont temporairement indisponibles. Veuillez réessayer plus tard.';
                break;
            case 'Gateway Timeout':
                return 'Le délai d\'attente de notre serveur a été dépassé. Veuillez réessayer plus tard.';
                break;
            case 'Bad Gateway':
                return 'Une mauvaise requête a été envoyée à notre serveur. Veuillez réessayer.';
                break;
            case 'Internal Server Error':
                return 'Une erreur interne s\'est produite. Veuillez réessayer.';
                break;
            case 'Request Timeout':
                return 'Le délai d\'attente de notre serveur a été dépassé. Veuillez réessayer plus tard.';
                break;
            case 'Method Not Allowed':
                return 'La méthode de requête n\'est pas autorisée. Veuillez réessayer.';
                break;

            // Partners
            case 'Partners not found':
                return 'Nous n\'avons trouvé aucun partenaire correspondant à votre recherche.';
                break;
            case 'Partner not found':
                return 'Le partenaire que vous recherchez n\'existe pas.';
                break;
            case 'Legal information not found':
                return 'Les informations légales du partenaire n\'ont pas été trouvées.';
                break;
            case 'Partner already exists':
                return 'Ce partenaire existe déjà avec le même emil ainsi que le même numéro de siret.';
                break;
            case 'Status not found':
                return 'Nous n\'avons pas trouvé le statut du partenaire.';
                break;
            case 'Partner cannot be deleted as there are associated activities.':
                return 'Le partenaire ne peut pas être supprimé car il est lié à une ou des activités existantes. Veuillez supprimer les activités avant de supprimer le partenaire.';
                break;
            case 'Partner already exists with this email or SIRET':
                return 'Ce partenaire existe déjà avec le même email ou que le même numéro de siret.';
                break;
            case 'This email already used':
                return 'L\'adresse email est déjà utilisée par un autre partenaire.';
                break;

            // PartnerDocuments
            case 'No document files were uploaded':
                return 'Vous n\'avez pas transféré de fichiers.';
                break;
            case 'PartnerLegalInfo not found':
                return 'Les informations légales du partenaire n\'ont pas été trouvées.';
                break;
            case 'Document not found in the legalDocuments array':
                return 'Le document n\'a pas été trouvé dans le tableau des documents légaux.';
                break;

            // PartnerEngine
            case 'Partner Engine not found':
                return 'Le moteur de réservation du partenaire n\'a pas été trouvé.';
                break;

            // PartnerOffers
            case 'No offers found':
                return 'Nous n\'avons trouvé aucune offre correspondant à votre recherche.';
                break;
            case 'No offer found':
                return 'L\'offre que vous recherchez n\'existe pas.';
                break;
            case 'Error updating partner with offer':
                return 'Une erreur s\'est produite lors de la mise à jour du partenaire avec l\'offre.';
                break;
            case 'Error deleting partner offer':
                return 'Une erreur s\'est produite lors de la suppression de l\'offre du partenaire.';
                break;
            case 'Activity not found':
                return 'L\'activité de l\'offre n\'a pas été trouvée.';
                break;

            // PartnerSubscription
            case 'Subscription not found':
                return 'Nous n\'avons trouvé aucun abonnement correspondant à votre recherche.';
                break;
            case 'No subscriptions found':
                return 'Nous n\'avons trouvé aucun abonnement correspondant à votre recherche.';
                break;
            case 'Partner already has a subscription':
                return 'Le partenaire a déjà un abonnement en cours.';
                break;

            // User
            case 'No users found':
                return 'Nous n\'avons trouvé aucun utilisateur correspondant à votre recherche.';
                break;
            case 'User not found':
                return 'L\'utilisateur que vous recherchez n\'existe pas.';
            case 'No user found':
                return 'L\'utilisateur que vous recherchez n\'existe pas.';
                break;
            case 'User avatar not found':
                return 'L\'avatar de l\'utilisateur n\'a pas été trouvé.';
                break;
            case 'No image file found':
                return 'Aucun fichier image n\'a été trouvé.';
                break;
            case 'This email already used by another user':
                return 'Cette adresse email est déjà utilisée par un autre utilisateur.';
                break;

            // ActivityCategories
            case 'No categories found':
                return 'Nous n\'avons trouvé aucune catégorie correspondant à votre recherche.';
                break;
            case 'No category found':
                return 'La catégorie que vous recherchez n\'existe pas.';
                break;

            // ActivityTags
            case 'No tags found':
                return 'Nous n\'avons trouvé aucun tag correspondant à votre recherche.';
                break;
            case 'No tag found':
                return 'Le tag que vous recherchez n\'existe pas.';
                break;

            // ActivityImages
            case 'No image files were uploaded':
                return 'Vous n\'avez pas transféré de fichiers.';
                break;
            case 'Activity not found':
                return 'L\'activité n\'a pas été trouvée.';
                break;
            case 'ActivityImages not found':
                return 'Les images de l\'activité n\'ont pas été trouvées.';
                break;
            case 'Image not found':
                return 'L\'image n\'a pas été trouvée.';
                break;

            // Activities
            case 'No activities found':
                return 'Nous n\'avons trouvé aucune activité correspondant à votre recherche.';
                break;
            case 'No activity found':
                return 'L\'activité que vous recherchez n\'existe pas.';
                break;
            case 'ActivityImages not found':
                return 'Les images de l\'activité n\'ont pas été trouvées.';
                break;

            // Support
            case 'Missing required fields':
                return 'Des champs obligatoires sont manquants.';
                break;

            // Stripe
            case 'Customer already has a subscription with the same plan':
                return 'Vous avez déjà un abonnement en cours avec le même plan tarifaire.';
                break;
            case 'Subscription plan not found':
                return 'Le plan tarifaire de l\'abonnement n\'a pas été trouvé.';
                break;
            case 'PartnerSubscription':
                return 'L\'abonnement du partenaire n\'a pas été trouvé.';
                break;

            // FILES
            case 'File already exists':
                return 'Le fichier existe déjà, vous ne pouvez pas ajouter le même fichier.';
                break;


            // Auth
            case 'Invalid password':
                return 'Le mot de passe est invalide.';


                // Verification Email
            case 'Email and code are required':
                return 'L\'email et le code sont requis.';
                break;
            case 'Invalid verification code':
                return 'Votre code de vérification n\'est pas le bon. Veuillez vérifier votre code.';
                break;
            case 'Code is required':
                return 'Le code est requis.';
            case 'Verification code is expired':
                return 'Votre code de vérification a expiré. Veuillez en demander un nouveau.';
                break;
            case 'Verification code still valid, no need to send a new one.':
                return 'Votre code de vérification est toujours valide, veuillez vérifier votre boîte mail.';
                break;


            case 'Accept conditions':
                return 'Vous devez accepter les conditions d\'utilisation.';
                break;

            case 'Accept conditions for insurance':
                return 'Vous devez accepter les conditions sur le contrat d\'assurance de responsabilité civile.';
                break;

            case 'Accept conditions for images':
                return 'Vous devez accepter les conditions pour ajouter les images de votre activité.';
                break;


            case 'Not verified yet':
                return 'Votre établissement n\'a pas encore été vérifié. Veuillez patienter afin que notre équipe vérifie votre établissement, vous recevrez un email de confirmation.';
                break;

            case 'Checkbox images not accepted':
                return 'Vous devez accepter les conditions d\'utilisation pour les images de votre activité.';
                break;

            default:
                return 'Une erreur inconnue s’est produite.';
        }

    }

    return {
        parseMessage
    }
}


export default useParseError;