import React, {useContext} from "react";
import '../../../styles/views/stripe-payement.css';
import Button from "../../../components/button/button";
import loop from "../../../assets/images/svg/loop.svg";
import UsersServices from "../../../api/services/users";
import {useSelector, useDispatch} from "react-redux";
import {userUpdateSubscription} from "../../../redux/slice/authSlice";
import {useNavigate} from "react-router-dom";
import {ErrorContext} from "../../../context/errorContext";
import {useLoader} from "../../../context/loaderContext";
import {usePartner} from "../../../context/partnerContext";

const ConfirmPayment = () => {

    const user = useSelector((state) => state.auth.user);
    const { currentPartnerId } = usePartner();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();


    //reset user in redux
    const handleUpdateUser = () => {
        setLoading(true);
        UsersServices.getUserById(user._id)
        .then((res) => {
            if(res.status === 200){
                const updatedSubscription = res.data.user.partnerID.find(partner => partner._id === currentPartnerId).subscriptionID;

                dispatch(userUpdateSubscription({
                    subscriptionID: updatedSubscription,
                    partnerID: currentPartnerId
                }));

                // Navigate based on the subscription plan name
                if(updatedSubscription.subscriptionPlan.name === 'essentiel'){
                    navigate(`/partners/${currentPartnerId}`);
                }else{
                    navigate(`/partners/${currentPartnerId}/subscription`);
                }
            }
        })
        .catch((error) => {
            showError(error);
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    return(
        <div className="main confirm_container">
            <div className="main__container confirm_payement">
                <h1 className={"title-25"}>Votre abonnement à bien été pris en compte ✅</h1>
                <div className={"confirm_payement__text"}>
                    <Button text={"Revenir sur votre espace"} isPrimary={true} isFull={true} onClick={handleUpdateUser}/>
                    <p className={"text-16 light"}>Merci pour votre confiance !</p>
                </div>
            </div>
            <img src={loop} className={"frimity_loop"} alt="logo"/>
        </div>
    )

}

export default ConfirmPayment;