import {postRequestJson} from '../request/apiRequest';

const EntitiesVerifiedServices = {

        sendForPartnerVerified: async (partnerId) => {
            return await postRequestJson(`/entities/partner/${partnerId}/verify`);
        },

        sendForActivityVerified: async (activityId) => {
            return await postRequestJson(`/entities/activity/${activityId}/verify`);
        },

        sendForOfferVerified: async (offerId) => {
            return await postRequestJson(`/entities/offer/${offerId}/verify`);
        }

}

export default EntitiesVerifiedServices;