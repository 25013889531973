import {getRequest, postRequestJson, patchRequest, deleteRequest} from '../request/apiRequest';

const UserSubscriptionServices = {

    createCustomer: async (data) => {
        return await postRequestJson(`/stripe/subscription/create-customer`, data);
    },

    getCustomerbyId: async (customerId) => {
        return await getRequest(`/stripe/subscription/get-customer/${customerId}`);
    },

    getSubscriptionById: async (subscriptionId) => {
        return await getRequest(`/stripe/subscription/get-customer-subscription/${subscriptionId}`);
    },

    createSubscription: async (data) => {
        return await postRequestJson(`/stripe/subscription/create-subscription`, data);
    },

    createFreeSubscription: async (data) => {
        return await postRequestJson(`/stripe/subscription/create-free-subscription`, data);
    },

    calculatePriceDifference: async (data) => {
        return await postRequestJson(`/stripe/subscription/calculate-price-difference`, data);
    },

    updateSubscription: async (data) => {
        return await postRequestJson(`/stripe/subscription/update-subscription`, data);
    },

    updateBillingInfo: async (data) => {
        return await postRequestJson(`/stripe/subscription//update-subscription/billing`, data);
    },

    upateCustomerEmail: async (data) => {
        return await postRequestJson(`/stripe/subscription/update-subscription/customer`, data);
    },

    deleteSubscription: async (subscriptionId) => {
        return await deleteRequest(`/stripe/subscription/delete-subscription/${subscriptionId}`);
    },

}

export default UserSubscriptionServices;