import React, {useEffect, useState} from 'react';
import '../../styles/components/stats.css';
import Dot from "../dot/dot";

import ModalSearch from "../modal/modalSearch";

const CardStat = ({title, value, color, list = [], titleModal, status}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleModal = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div className={"card-stat"}>
            <Dot color={color} click={handleModal}>
                <ModalSearch searchList={list} titleModal={titleModal} status={status} isOpen={isOpen} setIsOpen={setIsOpen} closeModal={handleModal} type={"partner"} />
            </Dot>
            <div className={"card-stat__title"}>{title}</div>
            <div className={"card-stat__value"} style={{color: color}}>{value}</div>
        </div>
    );
}

export default CardStat;