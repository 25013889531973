import React, {useState} from 'react';
import Radio from "../../form/inputRadio/radio";
import '../../../styles/components/modal.css';
import Tag from "../../tag/tag";
import useStatusName from "../../../hooks/parseText/useStatusName";
import close from '../../../assets/icones/global/close-stroke.svg';

const ModalStatus = ({isOpen, closeModal, statusActif, handleChange, type}) => {

    const statusListPartner = [ "active", "error", "pending", "review", "lost"];
    const statusListActivity = ["active", "error", "waiting"];

    const { getStatusName } = useStatusName();


    return(
        <div className={`modal status ${isOpen ? "open" : ""}`}>
            <div className={"modal__header"}>
                <p className={"text-14"}>Modifier le status</p>
                <div className={"modal__header__actions"}>
                    <p className={"title-14"} onClick={closeModal}>
                        <img src={close} alt={"close"}/>
                    </p>
                </div>
            </div>
            <div className={"modal__content"}>
                {
                    type === "partner" ?
                        statusListPartner.map((statusItem) => {
                            return(
                                <div className={"modal__content__row"}>
                                    <input type="checkbox" name={statusItem} id={statusItem} value={statusItem} onChange={(e) => handleChange(e.target.value)} checked={statusActif === statusItem}/>
                                    <Tag text={getStatusName(statusItem)} status={statusItem} />
                                </div>
                            )
                        })
                        :
                        statusListActivity.map((statusItem) => {
                            return(
                                <div className={"modal__content__row"}>
                                    <input type="checkbox" name={statusItem} id={statusItem} value={statusItem} onChange={(e) => handleChange(e.target.value)} checked={statusActif === statusItem}/>
                                    <Tag text={getStatusName(statusItem)} status={statusItem} />
                                </div>
                            )
                        })

                }
            </div>
        </div>
    )
}

export default ModalStatus;
