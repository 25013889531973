import React from 'react';
import '../../styles/components/form/form.css';
import SubmitButton from "../button/submitButton";
import Button from "../button/button";
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Form = ({children, onSubmit, mode, isSubmitDisabled = false}) => {

    const isAdding = mode === 'add';
    const isSending = mode === 'send';
    const isOffer = mode === 'offer';
    const navigate = useNavigate();

    return(
        <div className={"form-container"}>
            <form onSubmit={onSubmit} className={`${isSubmitDisabled ? "is__disabled" : ""}`}>
                {
                    children
                }

                <div className="form__row button">
                    <Button text={"Annuler"} Secondary={true} onClick={() => navigate(-1)} />
                    <SubmitButton text={isAdding ? "Ajouter" : isSending ? "Envoyer" : isOffer ? "Soumettre mon l’offre" : "Sauvegarder"} />
                </div>
            </form>
        </div>
    )

}

export default Form;