import React, {useEffect, useState} from 'react';
import FormSection from "../../../components/form/formSection";
import Input from "../../../components/form/inputText/input";
import Radio from "../../../components/form/inputRadio/radio";

const Booking = ({mode, booking, setPartner}) => {

    const [checked, setChecked] = useState([]);

    const handleChange= (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        setPartner(`booking.${name}.value`, value);
    };

    return(
        <FormSection title={"Réservations"}>
            {Object.keys(booking).map((field) => (
                field === "bookingPolicy" ?
                    <div className={`form__row row__input`} key={field} id={"booking"}>
                        <label>Préférence de communication</label>
                        <div className={`row__input__radio`}>
{/*
                            <Radio name={"bookingPolicy"} label={"API"} value={"instant"} checked={booking.bookingPolicy.value === "instant" || checked === "instant"} onChange={handleChange} errorEmpty={booking.bookingPolicy.error} errorMessage={booking.bookingPolicy.errorMessage}/>
*/}
                            <Radio name={"bookingPolicy"} label={"En attente de validation par email"} value={"pending"} checked={booking.bookingPolicy.value === "pending" || checked === "pending"} onChange={handleChange} errorEmpty={booking.bookingPolicy.error} errorMessage={booking.bookingPolicy.errorMessage}/>
                        </div>
                    </div>
                    :
                    field === "engineAPI" && booking.bookingPolicy.value !== "instant" || field === "managementSoftware" && booking.bookingPolicy.value !== "instant" ?
                        null
                    :
                        <Input
                            key={field}
                            type={field === "phoneNumber" ? "number" : "text"}
                            label={booking[field].label}
                            name={field}
                            valueInput={booking[field].value}
                            placeholder={booking[field].placeholder}
                            onChangeForm={handleChange}
                            validationType={booking[field].validationType}
                            errorEmpty={booking[field].error}
                            errorMessage={booking[field].errorMessage}
                        />

            ))}
        </FormSection>
    )
}

export default Booking;