
import React, {useContext, useEffect, useState} from 'react';
import {ErrorContext} from "../../context/errorContext";
import {useParams} from "react-router";
import {useLoader} from "../../context/loaderContext";
import BookingInvoicesService from "../../api/services/bookingInvoices";
import logo from "../../assets/images/svg/logo_row.svg";
import "../../styles/views/bookingInvoice.css";
import useParseDate from "../../hooks/useParseDate";

const GenerateProofOfBookingUser = () => {

    const { bookingIdentifier, bookingAppId } = useParams();
    const { setLoading } = useLoader();
    const { showError } = useContext(ErrorContext);
    const {parseBookingDate, parseNotificationsDate} = useParseDate();

    const [bookingInfos, setBookingInfos] = useState(null);


    useEffect(() => {
        //get booking time slots to modify
        setLoading(true);
        BookingInvoicesService.generateProofOfBookingUser(bookingIdentifier, bookingAppId)
        .then((response) => {
            if(response.status === 200){
                setBookingInfos(response.data);
            }
        }).catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    return bookingInfos && (
        <div className={"container-invoice"}>
            <img className={"logo"} src={logo} alt={"logo"} />

            <div className={"invoice-section infos-row"}>
                <div className={"infos-column"}>
                    <p className={"text-16"}>{bookingInfos.bookingUser.firstname} {bookingInfos.bookingUser.lastname}</p>
                    <p className={"text-16"}>{bookingInfos.bookingUser.email}</p>
                </div>
                <div className={"infos-column flex_end"}>
                    <p className={"text-16"}>Date de réservation</p>
                    <p className={"text-16"}>{parseBookingDate(bookingInfos.bookingDate)}</p>
                    <p className={"text-16"}>Numéro de réservation</p>
                    <p className={"text-16"}>{bookingInfos.bookingIdentifier}</p>
                </div>
            </div>

            <div className={"invoice-section"}>
                <h2>Justificatif de paiement</h2>

                <div className={"infos-col"}>
                    <div className={"infos-row"}>
                        <h3>Référence de la réservation ({bookingInfos.bookingIdentifier})</h3>
                        <h3>{bookingInfos.bookingAmount}€</h3>
                    </div>
                    <div className={"infos-column"}>
                        <p className={"text-16"}>Produit : </p>
                        {
                            bookingInfos.bookingPricingDetails.map((pricing, index) => {
                                return (
                                    <div className={"infos-row"}>
                                        <p key={index} className={"text-16"}>x{pricing.pricingQuantity}   {pricing.pricingName}</p>
                                        <p key={index} className={"text-16"} >{pricing.pricingAmount}€</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={"infos-column"}>
                        <p className={"text-16"}>Date d'activité : {parseBookingDate(bookingInfos.bookingDate)}</p>
                        <p className={"text-16"}>Participants : {bookingInfos.bookingPersonNumber}</p>
                    </div>
                    <div className={"separationLine"}></div>
                    <div className={"infos-row"}>
                        <h3>Montant total payé</h3>
                        <h3>{bookingInfos.bookingAmount}€</h3>
                    </div>
                </div>

                <p className={"text-16"}>Le paiement d'une valeur de {bookingInfos.bookingAmount}€ à bien été reçu et vous seras transmis une fois l'activité terminée.</p>

                <p className={"text-16"}>Cette facture ne peut être utilisée afin de percevoir la TVA. <br/>Veuillez noter que ce document est uniquement une preuve de paiement. Il ne peut en aucun cas être utilisé en tant que bon et ne vous permet donc pas d'accéder aux services réservés.</p>

            </div>

            <div className={"invoice-footer"}>
                <h2>Frimity SAS</h2>
                <div className={"infos-grid"}>
                    <div className={"infos-col"}>
                        <p className={"text-14"}>13 Rue de Charenton,</p>
                        <p className={"text-14"}>75012 Paris,</p>
                        <p className={"text-14"}>France</p>
                    </div>
                    <div className={"infos-line"}></div>
                    <div className={"infos-col"}>
                        <p className={"text-14"}>Téléphone : (+33) 0669931543</p>
                        <p className={"text-14"}>Email: contact@frimity.fr</p>
                        <p className={"text-14"}>Site web: frimity.app</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GenerateProofOfBookingUser