// Import necessary libraries
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Import your reducers
import authReducer from './slice/authSlice';
import signInReducer from './slice/signInSlice';

// Redux Persist configuration
const persistConfig = {
    key: 'root_key',
    storage,
    version: 1,
    whitelist: ['auth', 'signIn'], // Specify parts of the state to persist
};

// Combine reducers
const rootReducer = combineReducers({
    auth: authReducer,
    signIn: signInReducer,
});

// Enhanced root reducer to handle the rehydration
const enhancedReducer = (state, action) => {
    if (action.type === REHYDRATE) {
        // Get the state before rehydration
        const incoming = action.payload;
        // If the rehydrated state exists, update the persisted state
        if (incoming) {
            return {
                ...state, // Preserve the existing state
                ...incoming, // Update with rehydrated state
                // For userUpdateSubscription, update the subscriptionID
                auth: incoming.auth ? {
                    ...state.auth,
                    user: incoming.auth.user,
                } : state.auth,
                // For userSetEmailVerificationStatus, update the email verification status
                signIn: incoming.signIn ? {
                    ...state.signIn,
                    user: incoming.signIn.user,
                } : state.signIn,
            };
        }
    }
    return rootReducer(state, action);
};

// Persist the root reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);


// Configure the store
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

// Persistor for the store
const persistor = persistStore(store);

// Export the store and persistor
export { store, persistor };
