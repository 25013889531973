import React, { useState, useEffect } from 'react';
import '../../styles/components/analytics.css';
import green_arrow from "../../assets/icones/arrows/arrow_up_green.svg";
import red_arrow from "../../assets/icones/arrows/arrow_up_red.svg";
import line from "../../assets/icones/arrows/line_neutral.svg";
import LineChartComponent from "./lineChartComponent";

const BookingSection = ({ bookingData, previousBookingData, selectedPeriod }) => {
    const [labels, setLabels] = useState([]);
    const [data, setData] = useState([]);
    const [totalReservations, setTotalReservations] = useState(0);
    const [calculatedPercentage, setCalculatedPercentage] = useState(0);

    const calculateTotalReservations = (data) => {
        return Array.isArray(data) ? data.reduce((acc, item) => acc + item.count, 0) : 0;
    };

    const calculatePercentageChange = (current, previous) => {
        if (previous === 0 && current > 0) return 100; // When there's no data for the previous period, any current data means a 100% increase
        if (previous === 0 && current === 0) return 0; // No data in both periods means no change
        return ((current - previous) / previous) * 100;
    };

    const generateHourlyLabels = () => {
        return Array.from({ length: 24 }, (_, i) => `${i}h`);
    };

    const generateDailyLabels = (daysInMonth) => {
        return Array.from({ length: daysInMonth }, (_, i) => `${i + 1}`);
    };

    const generateWeeklyLabels = () => {
        return ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];
    };

    const generateMonthlyLabels = () => {
        return ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'];
    };

    const generateQuarterlyLabels = () => {
        const currentMonth = new Date().getMonth();
        const labels = [];

        if (currentMonth < 3) { // Q1
            for (let month = 0; month <= currentMonth; month++) {
                labels.push(generateMonthlyLabels()[month]);
            }
        } else if (currentMonth < 6) { // Q2
            for (let month = 3; month <= currentMonth; month++) {
                labels.push(generateMonthlyLabels()[month]);
            }
        } else if (currentMonth < 9) { // Q3
            for (let month = 6; month <= currentMonth; month++) {
                labels.push(generateMonthlyLabels()[month]);
            }
        } else { // Q4
            for (let month = 9; month <= currentMonth; month++) {
                labels.push(generateMonthlyLabels()[month]);
            }
        }

        return labels;
    };

    const parseDate = (dateString) => {
        return new Date(dateString);
    };

    const filterFutureDates = (labels, data) => {
        const today = new Date();
        const filteredLabels = [];
        const filteredData = [];

        labels.forEach((label, index) => {
            const date = new Date(label);
            if (date <= today) {
                filteredLabels.push(label);
                filteredData.push(data[index]);
            }
        });

        return { filteredLabels, filteredData };
    };

    useEffect(() => {
        if (!bookingData.length) {
            return;
        }

        const processData = () => {
            let groupedData = {};
            let sum = 0;
            const today = new Date();

            let processedLabels = [];
            let processedData = [];

            if (selectedPeriod === "yesterday") {
                const hourlyLabels = generateHourlyLabels();
                const hourlyData = Array(24).fill(0);
                const yesterday = new Date(today);
                yesterday.setDate(today.getDate() - 1);

                bookingData.forEach(item => {
                    const date = parseDate(item.date);
                    if (date.toDateString() === yesterday.toDateString()) {

                        const hour = date.getHours();
                        hourlyData[hour] += item.count;
                    }
                });

                processedLabels = hourlyLabels;
                processedData = hourlyData;
                sum = hourlyData.reduce((acc, value) => acc + value, 0);

            } else if (selectedPeriod === "weekly") {
                const weeklyLabels = generateWeeklyLabels();
                const weeklyData = Array(7).fill(0);

                // Get the start of the current week (assuming week starts on Monday)
                const startOfWeek = new Date(today);
                startOfWeek.setDate(today.getDate() - ((today.getDay() + 6) % 7)); // Adjust to get Monday

                bookingData.forEach(item => {
                    const date = parseDate(item.date);
                    let day = date.getDay();

                    // Adjust day so Monday is 0 and Sunday is 6
                    day = (day + 6) % 7;

                    // Only include data that falls within the current week, spanning across months
                    if (date >= startOfWeek && date <= today) {
                        weeklyData[day] += item.count;
                    }
                });

                processedLabels = weeklyLabels;
                processedData = weeklyData;
                sum = weeklyData.reduce((acc, value) => acc + value, 0);

            } else if (selectedPeriod === "monthly") {
                const daysInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
                const dailyLabels = generateDailyLabels(daysInMonth);
                const dailyData = Array(daysInMonth).fill(0); // Initialize an array with number of days in month

                bookingData.forEach(item => {
                    const date = parseDate(item.date);
                    const day = date.getDate() - 1; // 1 to daysInMonth
                    if (date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() <= today.getDate()) {
                        dailyData[day] += item.count;
                    }
                });

                processedLabels = dailyLabels;
                processedData = dailyData;
                sum = dailyData.reduce((acc, value) => acc + value, 0);

            } else if (selectedPeriod === "quarterly") {
                const quarterlyLabels = generateQuarterlyLabels();
                const quarterlyData = Array(quarterlyLabels.length).fill(0); // Initialize an array with quarterly labels length

                bookingData.forEach(item => {
                    const date = parseDate(item.date);
                    const month = date.getMonth();
                    const currentMonth = today.getMonth();
                    const currentYear = today.getFullYear();

                    if (date.getFullYear() === currentYear) {
                        if (currentMonth < 3 && month <= currentMonth) { // Q1
                            quarterlyData[month] += item.count;
                        } else if (currentMonth >= 3 && currentMonth < 6 && month >= 3 && month <= currentMonth) { // Q2
                            quarterlyData[month - 3] += item.count;
                        } else if (currentMonth >= 6 && currentMonth < 9 && month >= 6 && month <= currentMonth) { // Q3
                            quarterlyData[month - 6] += item.count;
                        } else if (currentMonth >= 9 && currentMonth < 12 && month >= 9 && month <= currentMonth) { // Q4
                            quarterlyData[month - 9] += item.count;
                        }
                    }
                });

                processedLabels = quarterlyLabels;
                processedData = quarterlyData;
                sum = quarterlyData.reduce((acc, value) => acc + value, 0);

            } else if (selectedPeriod === "yearly") {
                const monthlyLabels = generateMonthlyLabels();
                const monthlyData = Array(12).fill(0); // Initialize an array with 12 zeros

                bookingData.forEach(item => {
                    const date = parseDate(item.date);
                    const month = date.getMonth();
                    if (date.getFullYear() === today.getFullYear() && month <= today.getMonth()) {
                        monthlyData[month] += item.count;
                    }
                });

                // Filter out future months
                const currentMonth = today.getMonth();
                const filteredLabels = monthlyLabels.slice(0, currentMonth + 1);
                const filteredData = monthlyData.slice(0, currentMonth + 1);

                processedLabels = filteredLabels;
                processedData = filteredData;
                sum = filteredData.reduce((acc, value) => acc + value, 0);
            }

            return { processedLabels, processedData, sum };
        };

        const { processedLabels, processedData, sum } = processData();

        setLabels(processedLabels);
        setData(processedData);
        setTotalReservations(sum);

        if (bookingData.length > 0) {
            const currentTotal = calculateTotalReservations(bookingData);
            const previousTotal = calculateTotalReservations(previousBookingData);

            const percentageChange = calculatePercentageChange(currentTotal, previousTotal);
            setCalculatedPercentage(percentageChange);
        }
    }, [bookingData, previousBookingData, selectedPeriod]);

    return (
        <div className={"main-container__row box__container booking"}>
            <div className={"box__container__row"}>
                <h2 className={"text-20"}>Nombre total de réservation sur Frimity</h2>
            </div>

            <div className={"box__container__row__chart"}>
                <div className={"box__container__row__column"}>
                    <h2 className={"booking_title"}>{totalReservations} réservations effectuées</h2>
                    <div className={"booking_subtitle"}>
                        <div className={"booking__content__percentage"}
                             style={{ backgroundColor: calculatedPercentage > 0 ? "#EAFAE5" : calculatedPercentage < 0 ? "#F8ECEC" : "#F2EEFF" }}>
                            {
                                calculatedPercentage > 0 ?
                                    <img src={green_arrow} alt={"arrow_up"} />
                                    : calculatedPercentage < 0 ?
                                        <img src={red_arrow} alt={"arrow_down"} />
                                        :
                                        <img src={line} alt={"line_neutral"} />
                            }
                            <div className={"text-12 bold row_gap"}>
                                <span className={`booking__percentage`}
                                      style={{ color: calculatedPercentage > 0 ? "#83BF6E" : calculatedPercentage < 0 ? "#FF8079" : "#667085FF" }}>
                                    {calculatedPercentage.toFixed(0)}%
                                </span>
                            </div>
                        </div>
                        <p className={"text-14"}>par rapport au mois précédent</p>
                    </div>
                </div>
            </div>
            <div className={"box__container__row chartLine"}>
                <LineChartComponent labels={labels} data={data} />
            </div>
        </div>
    );
};

export default BookingSection;
