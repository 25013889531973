import React, {useContext, useEffect, useState} from "react";
import Form from "../../../components/form/form";
import Input from "../../../components/form/inputText/input";
import UserSubscriptionServices from "../../../api/services/stripeSubscription";
import {ErrorContext} from "../../../context/errorContext";
import {useLoader} from "../../../context/loaderContext";

const FormInvoice = ({customerId, setModalOpen, billingInfo}) => {

    const [formData, setFormData] = useState({
        address: "",
        city: "",
        country: "",
        postalCode: "",
    });
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();



    const handleSubmit = (e) => {
        e.preventDefault();

        setLoading(true);

        const data = {
            address: formData.address,
            city: formData.city,
            country: formData.country,
            postalCode: formData.postalCode,
            customerId: customerId,
        }

       UserSubscriptionServices.updateBillingInfo(data)
        .then((response) => {
            setModalOpen(true);
        })
        .catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        if(billingInfo && billingInfo !== undefined) {
            setFormData({
                address: billingInfo.address ? billingInfo.address.line1 : "",
                city: billingInfo.address ? billingInfo.address.city : "",
                country: billingInfo.address ? billingInfo.address.country : "",
                postalCode: billingInfo.address ? billingInfo.address.postal_code : "",
            })
        }
    }, []);

    return formData && (
        <Form mode={"edit"} onSubmit={handleSubmit}
              children={
                  <>
                      <Input
                          type={"text"}
                          name={"address"}
                          label={"Adresse"}
                          placeholder={"Adresse"}
                          required={false}
                          valueInput={formData.address}
                          isColumn={true}
                          onChangeForm={(e) => setFormData({
                                ...formData,
                                address: e.target.value
                            })
                          }
                      />
                      <Input
                          type={"text"}
                          name={"city"}
                          label={"Ville"}
                          placeholder={"Ville"}
                          required={false}
                          valueInput={formData.city}
                          isColumn={true}
                          onChangeForm={
                                (e) => {
                                    setFormData({
                                        ...formData,
                                        city: e.target.value
                                    })
                                }
                            }
                      />
                      <div className={"form__row"}>
                          <Input
                              type={"text"}
                              name={"postalCode"}
                              label={"Code postal"}
                              placeholder={"Code postal"}
                              validationType={"postalCode"}
                              required={true}
                              valueInput={formData.postalCode}
                              onChangeForm={
                                  (e) => {
                                      setFormData({
                                          ...formData,
                                          postalCode: e.target.value
                                      })
                                  }
                              }
                              isColumn={true}
                          />
                          <Input
                              type={"text"}
                              name={"country"}
                              label={"Pays"}
                              placeholder={"Pays"}
                              required={false}
                              valueInput={formData.country}
                              isColumn={true}
                              onChangeForm={
                                  (e) => {
                                      setFormData({
                                          ...formData,
                                          country: e.target.value
                                      })
                                  }
                              }
                          />
                      </div>
                  </>
              }
        />

    )
}

export default FormInvoice;