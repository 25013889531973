import React from 'react';
import warning from "../../assets/icones/global/warning.svg";

const WarningMessage = ({ excludedDates }) => {
    if (excludedDates.length === 0) return null;

    let dates = excludedDates.map(d => new Date(d)).sort((a, b) => a - b);
    let years = new Set(dates.map(d => d.getFullYear()));
    let months = dates.reduce((acc, d) => {
        let m = d.toLocaleString('fr-FR', { month: 'long' });
        let y = years.size > 1 ? ` ${d.getFullYear()}` : '';
        let key = `${m}${y}`;
        (acc[key] = acc[key] || []).push(d.getDate());
        return acc;
    }, {});

    return (
        <div className="is__warning">
            <img src={warning} alt="attention"/>
            <div className={"warning_text"}>
                Information :
                {Object.entries(months).map(([month, days], i, arr) => (
                    <span key={month}>
                      {i > 0 ? ' et le ' : 'Le '}
                        <span className={"bold"}>{days.join(', ')}</span> {month}
                    </span>
                ))}
                votre offre sera inactive.
            </div>
        </div>
    );
};

export default WarningMessage;