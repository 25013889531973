
import { getRequest, postRequestJson, patchRequest, deleteRequest } from '../request/apiRequest';

const BookingUsersService = {
    getBookingUsers: async () => {
        return await getRequest('/booking-users');
    },

    getBookingInWaiting : async (bookingPartnerID) => {
        return await getRequest(`/booking-users/in-waiting/${bookingPartnerID}`);
    },

    getBookingUserByBookingPartnerId : async (bookingPartnerID) => {
        return await getRequest(`/booking-users/bookingPartner/${bookingPartnerID}`);
    },

    getBookingUserByBookingId : async (bookingID) => {
        return await getRequest(`/booking-users/${bookingID}`);
    },

    createBookingUser : async (partnerID, data) => {
        return await postRequestJson(`/booking-users/${partnerID}/create`, data);
    },

    updateBookingUser : async (bookingID, data) => {
        return await patchRequest(`/booking-users/update/${bookingID}`, data);
    },

    updateBookingUserStatus : async (bookingID, data) => {
        return await patchRequest(`/booking-users/updateStatus/${bookingID}`, data);
    },

    deleteBookingUser : async (partnerID, bookingID) => {
        return await deleteRequest(`/booking-users/${partnerID}/${bookingID}`);
    },

    generateProofOfBookingUser: async (bookingIdentifier, bookingAppId) => {
        return await postRequestJson(`/booking-users/generate-proof-of-booking-user/${bookingIdentifier}/${bookingAppId}`);
    }
}

export default BookingUsersService;