import React from "react";
import mail from "../../../assets/icones/global/mail_light.svg";
import '../../../styles/views/policy.css';
import logo from "../../../assets/images/svg/logo_row.svg";
import Button from "../../../components/button/button";
import {useNavigate} from "react-router-dom";

const ConfidentialPolicy = () => {

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    return (
        <div className="main policy">
            <div className="main__container text">
                <div className="main__container__section">
                    <div className="main__container__form">
                        <img src={logo} alt="logo"/>

                        <div className={"container__infos"}>
                            <h1 className={"title-25"}>La Politique de Confidentialité du Dashboard Frimity</h1>

                            <p className={"text-16"}>En utilisant le tableau de bord Frimity, vous acceptez la politique
                                de confidentialité suivante :</p>

                            <p className={"text-16"}>Chez Frimity, la confidentialité de nos partenaires est au cœur de
                                nos préoccupations. Cette politique de confidentialité détaille comment nous collectons,
                                utilisons, divulguons et protégeons les informations personnelles que vous nous
                                fournissez lors de l'utilisation de notre dashboard.</p>
                            <p className={"text-16"}><strong>Collecte des Informations Personnelles:</strong> Lorsque
                                vous utilisez notre
                                dashboard, nous pouvons collecter des informations telles que votre nom, votre adresse
                                e-mail et d'autres détails que vous fournissez volontairement. Ces informations sont
                                essentielles pour personnaliser votre expérience sur notre plateforme.</p>
                            <p className={"text-16"}><strong>Utilisation des Informations:</strong> Les informations
                                personnelles sont
                                utilisées pour personnaliser votre accès au dashboard, répondre à vos demandes et vous
                                informer sur les nouvelles fonctionnalités et promotions pertinentes pour votre
                                établissement.</p>
                            <p className={"text-16"}><strong>Divulgation des Informations:</strong> Nous ne partageons
                                pas vos
                                informations personnelles avec des tiers à des fins commerciales. Elles sont divulguées
                                uniquement si vous y consentez expressément ou si c'est nécessaire pour répondre à des
                                exigences légales.</p>
                            <p className={"text-16"}><strong>Protection des Informations:</strong> Nous mettons en place
                                des mesures de
                                sécurité appropriées pour protéger vos informations contre tout accès non autorisé ou
                                toute divulgation. Cependant, gardez à l'esprit qu'aucune méthode de transmission
                                électronique n'est totalement sécurisée.</p>
                            <p className={"text-16"}><strong>Cookies et Technologies Similaires:</strong> Nous utilisons
                                des cookies pour améliorer votre expérience sur le dashboard. Vous pouvez gérer les
                                cookies dans les paramètres de votre navigateur.</p>
                            <p className={"text-16"}><strong>Liens Vers des Sites Tiers:</strong> Notre dashboard peut
                                contenir des liens vers des sites tiers. Nous ne sommes pas responsables de leurs
                                pratiques de confidentialité, donc veuillez consulter leurs politiques respectives.</p>
                            <p className={"text-16"}><strong>Vos Droits:</strong> Vous avez le droit d'accéder, de
                                corriger ou de supprimer vos informations personnelles. Vous pouvez également vous
                                désinscrire de nos communications marketing à tout moment.</p>
                            <p className={"text-16"}><strong>Modifications de la Politique de
                                Confidentialité:</strong> Cette politique de confidentialité peut être modifiée à tout
                                moment. Les modifications seront publiées ici, alors veuillez vérifier régulièrement.
                            </p>
                            <p className={"text-16"}><strong>Nous Contacter:</strong> Pour toute question ou
                                préoccupation concernant notre politique de confidentialité, contactez-nous à l'adresse
                                suivante : <a href={"mailto:contact@frimity.fr"}>contact@frimity.fr</a></p>
                            <p className={"text-16"}>En utilisant notre dashboard, vous acceptez les termes de cette
                                politique de confidentialité.</p>
                            <p className={"text-14 light"}>Date de la Dernière Mise à Jour : 12/12/2023</p>
                        </div>
                        <Button isPrimary={true} text={"Revenir en arrière"} isFull={true} onClick={goBack}/>
                    </div>
                </div>
            </div>
            <div className="main__container last">
                <div className="main__footer">
                    <p className={"text-12"}>© Frimity 2023</p>
                    <div className={"main__footer__links"}>
                        <img src={mail} alt="logo"/>
                        <a href={"mailto:contact@frimity.fr"}><p
                            className={"text-12"}>contact@frimity.fr</p></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfidentialPolicy;