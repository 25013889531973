import React from 'react';
import arrowLeft from '../../../assets/icones/arrows/left_chevron.svg';
import arrowRight from '../../../assets/icones/arrows/right_chevron.svg';
import Button from "../../button/button";

const SingleCalendar = ({ onSelect, onReset, currentDate, currentMonth, currentYear, handlePreviousMonth, handleNextMonth, calendarDisabled, arrayDates, arrayDays, isBooking = false, disabledPastDates, currentDateCard }) => {

    const formatDate = (year, month, day) => {
        return `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    };

    const formatDateInput = (dateString) => {
        const date = new Date(dateString);
        // Use 'fr-FR' to ensure French month names and adjust the format options to match "Avril 1, 2024"
        return date.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' }).replace(/\sde\s/g, ' ');
    };

    const isCurrentDay = (day) => {
        const today = new Date();
        const currentDay = today.getDate();
        const currentMonth = today.getMonth();
        const currentYear = today.getFullYear();
        return day === formatDate(currentYear, currentMonth, currentDay);
    };

    const isDayClosed = (day) => {
        //make it random
        return Math.random() < 0.1;
    }

    const getMonthName = (month) => {
        const monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
        return monthNames[month];
    };

    const getDaysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };


    const adjustMonthYear = (month, year) => {
        if (month > 11) {
            month = 0;
            year++;
        } else if (month < 0) {
            month = 11;
            year--;
        }
        return { month, year };
    };

    const getStatusForDate = (dateString) => {
        // Filter for all date objects that match the dateString and return combined statuses
        const statuses = arrayDates.filter(d => d.date.startsWith(dateString)).map(d => d.status);
        if (statuses.includes('booked') && statuses.includes('in-progress')) {
            return 'booked-in-progress'; // Combine both statuses
        } else if (statuses.includes('booked')) {
            return 'booked';
        } else if (statuses.includes('in-progress')) {
            return 'in-progress';
        }
        return null;
    };

    const getStatusForDay = (day) => {
        // Filter for all date objects that match the day and return combined statuses
        const statuses = arrayDays.filter(d => d.dayOfWeek === day).map(d => d.status);
        if (statuses.includes('closed')) {
            return 'closed'; // Combine both statuses
        }
        return null;

    };


    const generateCalendar = (currentYear, currentMonth) => {
        const daysInMonth = getDaysInMonth(currentYear, currentMonth);
        const startDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
        const daysFromPrevMonth = startDayOfMonth === 0 ? 6 : startDayOfMonth - 1;
        const totalTiles = Math.ceil((daysInMonth + daysFromPrevMonth) / 7) * 7;

        return new Array(totalTiles).fill(null).map((_, index) => {
            const dayOffset = index - daysFromPrevMonth;
            const day = dayOffset + 1;
            let month = currentMonth;
            let year = currentYear;

            if (dayOffset < 0) {
                // Previous month
                const { month: prevMonth, year: prevYear } = adjustMonthYear(month - 1, year);
                return {
                    day: getDaysInMonth(prevYear, prevMonth) + dayOffset + 1,
                    month: prevMonth,
                    year: prevYear,
                    isCurrentMonth: false
                };
            } else if (dayOffset >= daysInMonth) {
                // Next month
                const { month: nextMonth, year: nextYear } = adjustMonthYear(month + 1, year);
                return {
                    day: day - daysInMonth,
                    month: nextMonth,
                    year: nextYear,
                    isCurrentMonth: false
                };
            }
            // Current month
            return {
                day,
                month,
                year,
                isCurrentMonth: true
            };
        });
    };

    const renderCalendarGrid = () => {
        const days = generateCalendar(currentYear, currentMonth);
        const today = new Date(); // Get today's date
        today.setHours(0, 0, 0, 0); // Reset time part to avoid hour/minute mismatch

        return days.map((day, index) => {
            const dayDate = new Date(day.year, day.month, day.day);
            dayDate.setHours(0, 0, 0, 0); // Ensure time part doesn't affect comparison
            const dayString = formatDate(day.year, day.month, day.day);
            let className = `day ${!day.isCurrentMonth ? 'other-month' : ''}`;


            if( arrayDates.length > 0 ){
                const status = getStatusForDate(dayString);
                if (status === 'booked-in-progress') {
                    className += ' booked-in-progress'; // Add combined class for special styling
                } else if (status === 'booked') {
                    className += ' booked';
                } else if (status === 'in-progress') {
                    className += ' in-progress';
                }
            }

            if( arrayDays.length > 0 ){
                const day = new Date(dayString).getDay();
                const status = getStatusForDay(new Date(dayString).getDay());
                if (status === 'closed') {
                    className += ' closed-day'; // Add combined class for special styling
                }
            }

            // Check if the day is before today
            const isBeforeToday = disabledPastDates && dayDate < today;
            // Determine if this is the current selected day
            const isSelectedDay = currentDate === dayString;


            if (isSelectedDay) {
                className += ' selected-day';  // This class can be styled in CSS to have a yellow background
            }
            if (isCurrentDay(dayString)) {
                className += ' current-day';
            }

            if (isBeforeToday) {
                className += ' past-day';
            }

            const handleDayClick = () => {
                if (calendarDisabled || isBeforeToday) {
                    return; // Do nothing if the calendar is disabled or the day is before today
                }

                if (!day.isCurrentMonth) {
                    // Navigate to the correct month first
                    if (day.month < currentMonth || (day.month === 11 && currentMonth === 0)) {
                        handlePreviousMonth();
                    } else if (day.month > currentMonth || (day.month === 0 && currentMonth === 11)) {
                        handleNextMonth();
                    }
                }

                // Select the day
                onSelect(dayString);
            };

            return (
                <div
                    key={index}
                    className={className}
                    onClick={handleDayClick}
                >
                    {day.day}
                </div>
            );
        });
    };


    return (
        <div className="calendar__container single">
            <div className="calendar-navigation">
                <img src={arrowLeft} onClick={handlePreviousMonth} alt={"previous month"}/>
                <span className="month-name">{getMonthName(currentMonth)} {currentYear}</span>
                <img src={arrowRight} onClick={handleNextMonth} alt={"next month"}/>
            </div>

            <div className="calendar-row">
                {
                    isBooking ?
                        <Button
                            text={"Voir toutes les dates"}
                            onClick={onReset}
                            isPrimary={true}
                        />
                        : currentDateCard ?
                        <div className={"date-picker__selected-date"}>{formatDateInput(currentDate)}</div>
                        : null

                }
                <Button
                    text={"Aujourd'hui"}
                    onClick={() => {
                        const today = new Date();
                        const todayMonth = today.getMonth();
                        const todayYear = today.getFullYear();
                        const formattedToday = formatDate(todayYear, todayMonth, today.getDate());
                        onSelect(formattedToday); // Select today's date

                        // Function to navigate to the correct month and year
                        const navigateToCurrentMonth = () => {
                            const difference = (todayYear - currentYear) * 12 + (todayMonth - currentMonth);

                            if (difference > 0) {
                                for (let i = 0; i < difference; i++) {
                                    handleNextMonth();
                                }
                            } else {
                                for (let i = 0; i < Math.abs(difference); i++) {
                                    handlePreviousMonth();
                                }
                            }
                        };

                        // Perform the navigation
                        navigateToCurrentMonth();
                    }}
                    isPrimary={false}
                />

            </div>
            <div className="weekdays">
                {['lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.', 'dim.'].map((dayName, index) => (
                    <div key={index} className="weekday">{dayName}</div>
                ))}
            </div>
            <div className="days-grid single">
                {renderCalendarGrid()}
            </div>
        </div>
    );
};

export default SingleCalendar;
