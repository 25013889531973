import React from 'react';

import Button from "../../button/button";

const modalVerification = ({isOpen, link, type, action}) => {

    return(
        <div className={`bg__modal ${isOpen ? "open" : ""}`}>
            <div className={`modal verification ${isOpen ? "open" : ""}`}>
                <div className={"modal__header"}>
                    <div className={"modal__header__col"}>
                        <p className={"title-20"}>Votre {type} a bien été {action} ✅</p>
                        <div className={"modal__header__text"}>
                            <p className={"text-14"}>Une vérification est nécessaire par notre équipe avant que vous
                                puissiez la consulter et qu'elle soit visible par les utilisateurs.</p>
                            <p className={"text-14"}>Vous recevrez un email de confirmation une fois cette vérification
                                effectuée sous les prochaines 24 heures.</p>
                        </div>
                        <Button text={`Retour vers les ${type}s`} isPrimary={true} link={link} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default modalVerification;