import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router";
import TabBar from "../../components/navbar/tabBar";
import HeaderView from "../../components/header/headerView";
import TabBlock from "../../components/navbar/tabBlock";
import {useSelector} from "react-redux";
import StripeServices from "../../api/services/stripe";
import Table from "../../components/table/table";
import Tag from "../../components/tag/tag";
import download from "../../assets/icones/global/cloud_download.svg";
import eye from "../../assets/icones/global/eye.svg";
import mail from "../../assets/icones/global/mail.svg";
import info from "../../assets/icones/global/info_circle.svg";
import IconButton from "../../components/button/iconButton";
import RadioRound from "../../components/form/inputRadio/radioRound";
import InputIcon from "../../components/form/inputText/inputIcon";
import FormInvoice from "./invoice/formInvoice";
import UserSubscriptionServices from "../../api/services/stripeSubscription";
import {Form} from "react-router-dom";
import SubmitButton from "../../components/button/submitButton";
import ModalUpdate from "../../components/modal/modalInfos/modalUpdate";
import PartnerSubscriptionServices from "../../api/services/partnerSubscription";
import ModalRefund from "../../components/modal/modalInfos/modalRefund";
import useSubscriptionName from "../../hooks/parseText/useSubscriptionName";
import {ErrorContext} from "../../context/errorContext";
import {useLoader} from "../../context/loaderContext";
import {usePartner} from "../../context/partnerContext";


const Invoice = () => {
    const partnerId = useParams().partnerId
    const [reload, setReload] = useState(false);
    const [invoices, setInvoices] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [checked, setChecked] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [userCustomerId, setUserCustomerId] = useState(null);
    const [modalRefundOpen, setModalRefundOpen] = useState(false);
    const [isEmailInputFocused, setIsEmailInputFocused] = useState(false);

    const { currentPartnerId } = usePartner();
    const user = useSelector(state => state.auth.user);
    const numberActivity = user.role !== "admin" ? useSelector((state) => state.auth.user.partnerID.find(partner => partner._id === currentPartnerId).activityID.length) : null;
    const currentPartner = user.role !== "admin" ? useSelector((state) => state.auth.user.partnerID.find(partner => partner._id === currentPartnerId)) : null;

    const {getSubscriptionName} = useSubscriptionName();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();


    useEffect(() => {
        setLoading(true);
        setIsEmailInputFocused(false)
        setChecked("");
        async function fetchCustomerData() {
            try {
                // Get subscription partner customer ID
                const subscriptionPartner= await PartnerSubscriptionServices.getSubscriptionByPartnerId(partnerId);
                setUserCustomerId(subscriptionPartner.data.stripeCustomerId);

                const data = { customerId: subscriptionPartner.data.stripeCustomerId };

                // Fetch invoices by customer ID
                const invoiceResponse = await StripeServices.getInvoicesByCustomerId(data);

                if (invoiceResponse.status === 200) {
                    setInvoices(invoiceResponse.data);
                }

                // Fetch customer details
                const customerResponse = await UserSubscriptionServices.getCustomerbyId(subscriptionPartner.data.stripeCustomerId);
                if (customerResponse.status === 200) {
                    setCustomer(customerResponse.data.customer);

                    if (user.partnerID && customerResponse.data.customer.email !== user.partnerID.email) {
                        setChecked(customerResponse.data.customer.email);
                        setIsEmailInputFocused(true);
                    }
                }
            } catch (error) {
                showError(error);
            } finally {
                setLoading(false);
            }
        }

        fetchCustomerData();
    }, [reload, userCustomerId, partnerId]);

    function formatPrice(priceInCents) {
        const priceInEuros = (priceInCents / 100).toFixed(2);

        //if price is negative remove the minus sign and add a + sign
        if(priceInEuros < 0){
            return priceInEuros.toString().replace('-', '+') + '€';
        }

        return priceInEuros.toString().replace('.', ',') + '€';
    }

    function formatDate(timestamp) {
        // Create a new Date object from the Unix timestamp (in milliseconds)
        const date = new Date(timestamp * 1000);

        // Format the date as "Month Day, Year"
        return date.toLocaleDateString("fr-FR", {
            month: 'short', // "short" gives the abbreviated month name
            day: 'numeric',
            year: 'numeric',
        });
    }

    const handleChangeEmail = (email) => (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            customerId: userCustomerId,
            email: email
        }

        UserSubscriptionServices.upateCustomerEmail(data)
        .then((response) => {
            setLoading(false);
            setModalOpen(true)
            setReload(!reload);
        })
        .catch((error) => {
            showError(error);
        });
    }

    return (
        <div className={"main-container is__grey"}>
            <HeaderView title={"Paiement"} actions={
                <>
                    {
                        user.role === "partner" || user.role === "partners-admin" ?
                            <TabBlock
                                tabs={[
                                    {name: "Plan tarifaire", link: `/partners/${partnerId}/subscription`},
                                    {name: "Facturation", link: `/partners/${partnerId}/subscription/billing`},
                                    {name: "Méthode de paiement", link: `/partners/${partnerId}/subscription/payment`}
                                ]}
                                activeLink={1}
                            />
                            :
                            <TabBlock
                                tabs={[
                                    {name: "Plan tarifaire", link: `/partners/${partnerId}/subscription`},
                                    {name: "Facturation", link: `/partners/${partnerId}/subscription/billing`}
                                ]}
                                activeLink={1}
                            />
                    }

                </>
            }/>

            <TabBar
                tabs={[
                    {
                        name: "Fiche du partenaire",
                        link: `/partners/${partnerId}`
                    },
                    {
                        name: "Liste d’activités",
                        link: `/partners/${partnerId}/activities`,
                        index: numberActivity ? numberActivity : 0
                    },
                    {
                        name: "Gérer vos offres",
                        link: `/partners/${partnerId}/offers`
                    },
                    {
                        name: "Plan tarifaire",
                        link: `/partners/${partnerId}/subscription`
                    }
                ]}
                activeTab={3}
            />

            <div className={"container__grey center light-padding"}>
                <div className={"subscription__content is__col"}>
                    <div className={"subscription__content__col"}>
                        <h1 className={"title-20"}>Facturation</h1>
                        <p className={"text-14 light"}>Mettez à jour vos informations de facturation et votre adresse</p>
                    </div>
                    <div className={"subscription__content__row"}>
                        <div className={"subscription__content__col__title"}>
                            <h2 className={"text-16"}>E-mail de facturation</h2>
                            <p className={"text-14 light"}>À quelle adresse envoyer les factures ?</p>
                        </div>
                        <div className={"subscription__content__col__content"}>
                            {
                            customer &&
                                <div className="form__col radio">
                                    {
                                        user.role === "partner" || user.role === "partners-admin" &&
                                        <RadioRound name={"emailCustomer"} value={currentPartner.email} onChange={(e) => handleChangeEmail(currentPartner.email)(e)}
                                            checked={customer.email === currentPartner.email}
                                            label={"Envoyer sur mon adresse mail"}
                                            subtitle={currentPartner.email}
                                            isLabel={false}
                                        />
                                    }

                                    <RadioRound name={"emailCustomer"} value={customer.email}
                                                checked={isEmailInputFocused || checked === customer.email}
                                                label={"Envoyer sur une autre adresse mail"}
                                                subtitle={
                                                    <Form onSubmit={(e) => handleChangeEmail(checked)(e)} className={"form__row"}>
                                                        <InputIcon
                                                            icon={mail}
                                                            validationType={"email"}
                                                            type={"email"}
                                                            name={"email"}
                                                            placeholder={"Email"}
                                                            required={false}
                                                            valueInput={checked}
                                                            isColumn={true}
                                                            onChangeForm={(e) => setChecked(e.target.value)}
                                                            onInputFocus={() => setIsEmailInputFocused(true)}
                                                        />
                                                        <SubmitButton text={"Enregistrer"} />
                                                    </Form>
                                                }
                                                isLabel={false}
                                    />
                                </div>
                            }
                        </div>
                    </div>

                    <div className={"subscription__content__row"}>
                        <div className={"subscription__content__col__title"}>
                            <h2 className={"text-16"}>Adresse de facturation</h2>
                            <p className={"text-14 light"}>À quelle adresse envoyer les factures ?</p>
                        </div>
                        <div className={"subscription__content__col__content"}>
                            {
                                customer && <FormInvoice customerId={userCustomerId} billingInfo={customer} setModalOpen={setModalOpen} />
                            }
                        </div>
                    </div>

                    <div className={"subscription__content__col gap-25"}>
                        <div className={"subscription__content__col__title"}>
                            <h2 className={"title-18"}>Historique des factures</h2>
                        </div>


                        <div className={"main-container__row list"}>
                            {
                                invoices && invoices.length > 0 &&
                                    <Table
                                        columns={[
                                            { name: "Facture", align: "left", width: "50%", field: "name" },
                                            { name: "Montant", align: "left", width: "10%", field: "price" },
                                            { name: "Date", align: "left", width: "13%", field: "date" },
                                            { name: "Statut", align: "left", width: "12%", field: "status" },
                                            { name: "Actions", align: "center", width: "15%", style: {
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }, field: "actions" },
                                        ]}

                                        data={
                                            invoices && invoices.length > 0 &&
                                            invoices.filter((invoice) => {
                                                return  invoice.lines.data[0]
                                            }).map((invoice, index) => {
                                                    return {
                                                        name: `Plan ${getSubscriptionName(invoice.productName)} - ${formatDate(invoice.created)}`,
                                                        price: formatPrice(invoice.subtotal),
                                                        date: formatDate(invoice.created),
                                                        status: invoice.subtotal < 0 ? <Tag text={"Ajout au solde"} status={"review"} /> : <Tag text={invoice.paid ? "Payé" : "Erreur"} status={invoice.paid ? "active" : "error"} />,
                                                        actions:
                                                            (
                                                                invoice.subtotal >= 0 ?
                                                                <div className={"invoice__row"} key={index}>
                                                                    <IconButton icon={eye} isPrimary={false}
                                                                                onClick={() => {
                                                                                    window.open(invoice.hosted_invoice_url, "_blank");
                                                                                }}/>
                                                                    <IconButton icon={download} isPrimary={false}
                                                                                link={invoice.invoice_pdf}
                                                                                download={true}/>
                                                                </div>
                                                                :
                                                                //icon button modal refund
                                                                <div className={"invoice__row"} key={index}>
                                                                    <IconButton icon={info} isPrimary={false} onClick={() => {
                                                                        setModalRefundOpen(true)
                                                                    }}/>
                                                                    <ModalRefund isOpen={modalRefundOpen} link={`/partners/${partnerId}/subscription/billing`} solde={formatPrice(invoice.subtotal)} close={() => {
                                                                        setModalRefundOpen(false)
                                                                    }}/>
                                                                </div>
                                                            )
                                                    }
                                                }
                                            )
                                        }
                                    />
                            }
                        </div>
                    </div>
                </div>
                <ModalUpdate isOpen={modalOpen} setReload={setReload} setModalOpen={setModalOpen}/>
            </div>
        </div>
    );
}

export default Invoice;