import React, {useState, useEffect, useContext} from 'react';
import Button from "../../button/button";
import {useSelector} from "react-redux";
import useAuth from "../../../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import {useLoader} from "../../../context/loaderContext";
import {ErrorContext} from "../../../context/errorContext";
import useInputValidation from "../../../hooks/form/useInputValidation";
import PasswordResetServices from "../../../api/services/passwordReset";

const ModalPasswordReset = ({isOpen, closeModal}) => {

    const user = useSelector((state) => state.auth.user);
    const [count, setCount] = useState(0);
    const [isClicked, setIsClicked] = useState(false);
    const navigate = useNavigate();
    const { logout } = useAuth();

    const {setLoading} = useLoader();
    const {showError} = useContext(ErrorContext);
    const {validateInput} = useInputValidation();
    const {sendPasswordCode} = PasswordResetServices;

    const handleChange = () => {
        setIsClicked(true);

        setTimeout(() => {
            sendPasswordCode({email: user.email})
                .then((response) => {
                    if (response.status === 200) {
                        navigate(`/password-reset/verify/${user.email}`);
                    }
                }).catch((error) => {
                showError(error);
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
        }, 10000);
    }

    useEffect(() => {
        if(isClicked){
            const timer = setTimeout(() => {
                setCount(count + 1);
                if(count === 10){
                    closeModal();
                }
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [count, isClicked]);

    return (
        <div className={`bg__modal ${isOpen ? "open" : ""}`}>
            <div className={`modal deleting ${isOpen ? "open" : ""}`}>
                <div className={"modal__header"}>
                    <div className={"modal__header__col"}>
                        <div className={"modal__header__text"}>
                            <p className={"title-16"}>Voulez-vous réinitialiser votre mot de passe ?</p>
                        </div>
                    </div>
                </div>
                <div className={"modal__content"}>
                    <div className={"modal__content__text"}>
                        <p className={"text-14"}>Un email sera envoyé à <strong>{user.email}</strong> pour réinitialiser votre mot de passe.</p>
                    </div>
                    <div className={"modal__content__actions"}>

                        {
                            count < 1 ?
                                <>
                                    <Button text={"Annuler"} isPrimary={false} onClick={closeModal}/>
                                    <Button text={"Envoyer le mail"} isPrimary={true} onClick={handleChange}/>
                                </>
                                :
                                <Button text={`Redirection dans ${10 - count} secondes`} isPrimary={false} onClick={closeModal}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalPasswordReset;