import React from 'react';
import HeaderView from "../../components/header/headerView";
import IconButton from "../../components/button/iconButton";
import logoutIcon from "../../assets/icones/global/logout.svg";
import useAuth from "../../hooks/useAuth";
import '../../styles/views/settings.css';
import TabBar from "../../components/navbar/tabBar";
import {Link} from "react-router-dom";
import lockCheck from "../../assets/icones/global/lock_check.svg";
import documentIcon from "../../assets/icones/global/document.svg";
import bag from "../../assets/icones/global/bag.svg";
import shieldValidate from "../../assets/icones/global/shield_validate_grey.svg";
import Toggle from "../../components/toggle/toggle";
import confidentiality from "../../assets/files/confidential_politics.pdf";
import legals from "../../assets/files/legals_mentions.pdf";
import condition from "../../assets/files/usage_conditions.pdf";
import {useSelector} from "react-redux";

const Preferences = () => {

    const { logout } = useAuth();
    const user = useSelector(state => state.auth.user);

    return user && (
        <div className={"main-container is__grey"}>
            <HeaderView title={"Paramètres"} isGoBack={false} actions={
                <>
                    <IconButton icon={logoutIcon} text={"Déconnexion"} isPrimary={false} onClick={logout} />
                </>
            } />

            <TabBar
                tabs={[
                    {
                        name: "Mon compte",
                        link: `/settings/account/`
                    },
                    {
                        name: "Mes préférences",
                        link: `/settings/preferences/`
                    },
                ]}
                activeTab={1}
            />

            <div className={"settings-container"}>
                <div className={"settings-section"}>
                    <h2 className={"title-18"}>Gérer ici vos paramètres et vos préférences </h2>
                    <p className={"text-14 light"}>Personnalisez votre expérience sur Frimity en réglant vos préférences
                        et paramètres. Contrôlez les notifications, gérez vos informations d'établissement et configurez
                        vos choix pour une utilisation optimale de notre plateforme.</p>
                </div>

                <div className={"settings-section"}>
                    <h2 className={"title-18"}>Gestion des préférences de notifications</h2>
                    <p className={"text-14 light"}>Nous pouvons toujours vous envoyer des notifications importantes
                        concernant votre compte en dehors de vos paramètres de notification.</p>

                    <div className={"settings-section__container"}>
                        <Toggle text={"Email"} isToggle={user.communication.email} user={user} />
                        <Toggle text={"SMS"} isToggle={user.communication.sms} user={user} />
                        <Toggle text={"Push"} isToggle={user.communication.push} user={user} />
                    </div>
                </div>

                <div className={"settings-section"}>
                    <h2 className={"title-18"}>Sécurité et Confidentialité</h2>
                    <p className={"text-14 light"}>Contrôlez votre mot de passe, restez à jour et assurez-vous de la
                        sécurité de votre établissement en un instant <Link to={"/settings/account"}>ici</Link>.</p>
                </div>

                <div className={"settings-section gap-25"}>
                    <div className={"settings-section__container gap-10"}>
                        <h2 className={"title-18"}>Vos Droits et notre Engagement</h2>
                        <p className={"text-14 light"}>Explorez nos engagements. Votre vie privée, notre priorité.</p>
                    </div>
                    <div className={"settings-section__container gap-25"}>
                        <p className={"text-14 bold"}>Décrouvrez nos informations légales et politique de confidentialité :</p>
                        <div className={"settings-section__container"}>
                            <IconButton icon={lockCheck} text={"Politique de confidentialité"} isPrimary={false} link={"/privacy-policy"} />
                            <IconButton icon={documentIcon} text={"Conditions générales d'utilisation"} isPrimary={false} link={"/usage-policy"} />
                            <IconButton icon={bag} text={"Conditions générales d'abonnement"} isPrimary={false} link={"/subscription-policy"} />
                            <IconButton icon={shieldValidate} text={"Mentions légales"} isPrimary={false} link={"/legals-mentions"} />
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );

}

export default Preferences;