import {postRequestJsonNotToken} from '../request/apiRequest';

const VerificationServices = {

    sendVerificationCode: async (data) => {
        return await postRequestJsonNotToken('/verification/request', data);
    },

    verifyVerificationCode: async (data) => {
        return await postRequestJsonNotToken('/verification/verify', data);
    }
}

export default VerificationServices;