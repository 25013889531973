import React from 'react';
import FormSectionRow from "../../../components/form/formSectionRow";
import Input from "../../../components/form/inputText/input";

const Name = ({name, setActivity, isDisabled = false}) => {

    const handleChange= (event) => {
        event.preventDefault();
        const { value } = event.target;

        setActivity(`name.value`, value);
    }


    return(
        <FormSectionRow title={"Nom de l'activité"} isRequired={true}>
            <Input
                name={"name"}
                valueInput={name.value}
                placeholder={name.placeholder}
                validationType={name.validationType}
                onChangeForm={handleChange}
                errorMessage={name.errorMessage}
                errorEmpty={name.error}
                disabled={isDisabled}
            />
        </FormSectionRow>
    )

}

export default Name;