import React, {useState} from 'react';
import '../../../styles/components/modal.css';
import close from '../../../assets/icones/global/close-stroke.svg';
import { useNavigate } from 'react-router-dom';
import OffersServices from "../../../api/services/offers";


const ModalOffer= ({isOpen, closeModal, partnerID, offerID, setRefresh, statusActive}) => {

    const navigate = useNavigate();

    const handleModify = () => {
        navigate(`/partners/${partnerID}/offers/${offerID}`)
    }

    const handleDelete = () => {
        OffersServices.deleteOffer(partnerID, offerID)
        .then((response) => {
            if(response.status === 200){
                closeModal();
                setRefresh(true);
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const handleDisable = async () => {
        try{
            const response = await OffersServices.updateOffer(offerID, {status: statusActive === "inactive" ? "active" : "inactive"});

            if (response.status === 200) {
                closeModal();
                setRefresh(true);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return(
        <div className={`modal offer ${isOpen ? "open" : ""}`}>
            <div className={"modal__header grey"}>
                <p className={"text-14"}>Attribuer</p>
                <div className={"modal__header__actions"}>
                    <div className={"modal__close"} onClick={() => closeModal()}>
                        <img src={close} alt={"close"}/>
                    </div>
                </div>
            </div>
            <div className={"modal__content"}>
                <p className={"text-14 offer_div"} onClick={handleModify}>Modifier</p>
                <p className={"text-14 offer_div"} onClick={handleDelete}>Supprimer</p>
                <p className={"text-14 offer_div"} onClick={handleDisable}>{statusActive === "inactive" ? "Réactiver" : "Mettre en pause"}</p>
            </div>
        </div>
    )
}

export default ModalOffer;
