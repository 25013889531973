
const useParseActivity= () => {

    const parseActivity = (activity) => {
        const partnerId = activity.partnerId ? activity.partnerId : "";

        const reference = {
            value: activity.reference ? activity.reference : "",
            error: false,
            errorMessage: false,
            label: "Référence",
            placeholder: "",
            validationType: "text"
        }

        const images = {
            images: activity.activityImages ? activity.activityImages.images : [],
            error: false,
            errorMessage: false,
            label: "Images",
            placeholder: "Images",
            isRequired: true
        }

        const name = {
            value: activity.name ? activity.name : "",
            error: false,
            errorMessage: false,
            label: "Nom de l'activité",
            placeholder: "Tyrolienne",
            isRequired: true
        }

        const status = activity.status ? activity.status : "";

        const schedule = {
            openOnHolidays: activity.openOnHolidays ? activity.openOnHolidays : false,
            temporarilyClosed: activity.temporarilyClosed ? activity.temporarilyClosed : false,
            schedule: {
                value: activity.schedule ? activity.schedule.schedulePlan : [],
                isRequired: true,
                errorMessage: false,
                error: false, // or a specific error message
            },
        }
        const category = activity.category ? activity.category : [];

        const nbPersons = {
            nbPersonsMin: {
                value: activity.details && activity.details.minParticipants ? activity.details.minParticipants : 0,
                error: false,
                errorMessage: false,
                label: "Nombre de personnes minimum",
                placeholder: "Choisissez un nombre",
                validationType: "number",
                isRequired: true
            },
            nbPersonsMax: {
                value: activity.details && activity.details.maxParticipants ? activity.details.maxParticipants : 0,
                error: false,
                errorMessage: false,
                label: "Nombre de personnes maximum",
                placeholder: "Choisissez un nombre",
                validationType: "number",
                isRequired: true
            }
        }

        const duration = {
            value: activity.details && activity.details.duration ? activity.details.duration : 0,
            error: false,
            errorMessage: false,
            label: "Durée de l'activité",
            placeholder: "Choisissez une durée",
            validationType: "number",
            isRequired: true
        }

        const description = {
            description: {
                value: activity.details && activity.details.description ? activity.details.description : "",
                error: false,
                errorMessage: false,
                label: "Déroulement de l’activité :",
                placeholder: `Attention : réservation en ligne obligatoire afin de garantir votre place.\n\nA seulement quelques kilomètres de Paris, facilement accessible en transports en commun (RER C ou D), par de nombreuses lignes de bus ou en voiture, ce parc vous accueille avec ses 100 ateliers, répartis sur 1200 mètres de parcours, dont de nombreuses tyroliennes au-dessus de l'eau`,
                subtitle: "275 caractères maximum",
                validationType: "text",
                isRequired: true
            },
            goodPoints: {
                value: activity.details && activity.details.goodPoints ? activity.details.goodPoints : "",
                error: false,
                errorMessage: false,
                label: "Points forts :",
                placeholder: "A seulement quelques kilomètres de Paris, facilement accessible en transports en commun (RER C ou D), par de nombreuses lignes de bus ou en voiture, ce parc vous accueille avec ses 100 ateliers, répartis sur 1200 mètres de parcours, dont de nombreuses tyroliennes au-dessus de l'eau ",
                validationType: "text",
                subtitle: "Donnez envie....",
                isRequired: true
            }
        }

        const practicalInfo = {
            value: activity.details && activity.details.practicalInfo ? activity.details.practicalInfo : "",
            error: false,
            errorMessage: false,
            label: "Description",
            placeholder: `Le billet est valable pour 1 journée - prévoir à minima 3 heures sur place pour profiter pleinement des parcours.\n\nL’accompagnement d’adultes responsables est obligatoire sur le site pour les enfants jusqu’à 14 ans. Sa présence est obligatoire, mais il peut rester au sol / n'est pas obligé de participer aux activités (gratuit dans ce cas, billet à prévoir dans le cas contraire / pour monter dans les arbres).\n\nOuvert les week-ends, les mercredis, jours fériés et tous les jours pendant les vacances scolaires (zone C) à partir du mois de mars. Horaires : de 13h à 19h\n\nOuverture dès 10h30 pendant les vacances d’été. Attention, les parcours de test et l’accueil en caisse sont interrompus de 11h45 à 13h.\n\nParking gratuit à l'entrée Plaine Nord du PArc Interdépartemental des Sports\n\nPort de gants textiles obligatoire sur les parcours (gants de jardinage, de sport – pas de gants en latex/plastique/mitaines). Possibilité d’achat sur place à 3€.`,
            validationType: "text"
        }

        const expected = {
            value: activity.details && activity.details.expected ? activity.details.expected : "",
            error: false,
            errorMessage: false,
            label: "À prévoir",
            placeholder: "Tout l'équipement à la pratique de l'activité",
            validationType: "text"
        }

        const toKnow = activity.tags ? activity.tags.map((tag) => {
            return {name: tag}
        }) : [];

        const toKnowOther = activity.details && activity.details.toKnowOther ? activity.details.toKnowOther : {
            accessibility: [],
            services: [],
            payment: [],
            restriction: [],
            animals: []
        }

        const toKnowInfos = {
            minAge: {
                value: activity.details && activity.details.minAge ? activity.details.minAge : "",
                error: false,
                errorMessage: false,
                label: "Âge minimum",
                placeholder: "Âge minimum",
                validationType: "number"
            },
            experience: {
                value: activity.details && activity.details.experience ? activity.details.experience : "",
                error: false,
                errorMessage: false,
                label: "Niveau d'expérience",
                placeholder: "Niveau d'expérience",
                validationType: "text"
            },
            clothingRecommendation: {
                value: activity.details && activity.details.clothingRecommendation ? activity.details.clothingRecommendation : "",
                error: false,
                errorMessage: false,
                label: "Tenue recommandée",
                placeholder: "Tenue recommandée",
                validationType: "text"
            },
            otherRecommendation: {
                value: activity.details && activity.details.otherRecommendation ? activity.details.otherRecommendation : "",
                error: false,
                errorMessage: false,
                label: "Autres recommandations",
                placeholder: "Autres recommandations",
                validationType: "text"
            }
        }

        const access = {
            location: {
                value: activity.details && activity.details.location ? activity.details.location : "",
                error: false,
                errorMessage: false,
                label: "Le lieu de l’activité",
                placeholder: "1 rue de la paix",
                validationType: "text",
                isRequired: true
            },
            accessDetails: {
                value: activity.details && activity.details.accessDetails ? activity.details.accessDetails : "",
                error: false,
                errorMessage: false,
                label: "Accès",
                placeholder: "En voiture depuis Paris- prendre l’A4 à Porte de Bercy puis utiliser les 2 voies de droite pour prendre la sortie A86 en direction de A6/A10/Sénart/Créteil.- Rester sur l’A86 jusqu’à la sortie 23 en direction de Provins/Troyes/Bonneuil/Valenton et suivre « Parc Interdépartemental des Sports ».- Rester sur la file de droite et suivre N6/Sénart/VAlenton-Centre/Villeneuve-Saint-Georges/ pour rejoindre l’avenue du maréchal Foch (D6).- Au carrefour Pompadour, prendre la 1ère sortie sur avenue de la Pompadour (D86).Parking gratuit à l'entrée Plaine Nord du Parc Interdépartemental des SportsEn transports en commun- BUS : 393, arrêt Parc Interdépartemental des Sports- TVM : arrêt Parc Interdépartemental des Sports- RER D : arrêt Créteil Pompadour",
                validationType: "text"
            }
        }

        const pricingAndExtras = {
            pricingOptions: activity.details && activity.details.pricingOptions ? activity.details.pricingOptions : [],
            extraPrice: activity.details && activity.details.extraPrice ? activity.details.extraPrice : []
        }

        const bookingPolicy = {
            value: activity.bookingPolicy ? activity.bookingPolicy : "",
            error: false,
            errorMessage: false,
            label: "Politique de réservation",
            placeholder: "Politique de réservation...",
            validationType: "text",
            isRequired: true
        }

        const included = {
            value: activity.details && activity.details.included ? activity.details.included : [],
            error: false,
            errorMessage: false,
            label: "Ce qui est inclus",
            placeholder: "Ce qui est inclus...",
            validationType: "text"
        }

        const cancel = {
            cancelPolicy: {
                value: activity.details && activity.details.cancellationPolicy ? activity.details.cancellationPolicy : "",
                error: false,
                errorMessage: false,
                label: "Politique d'annulation",
                placeholder: "Politique d'annulation...",
                validationType: "text",
                isRequired: true
            },
            refundPolicy: {
                value: activity.details && activity.details.refundPolicy ? activity.details.refundPolicy : "",
                error: false,
                errorMessage: false,
                label: "Politique de remboursement",
                placeholder: "Politique de remboursement...",
                validationType: "text",
                isRequired: true
            }
        }

        const type = {
            value: activity.type ? activity.type : "",
            error: false,
            errorMessage: false,
            label: "Type",
            validationType: "text",
            isRequired: true
        }

        const hasInsurance = {
            value: activity.hasInsurance ? activity.hasInsurance : false,
            error: false,
            errorMessage: false,
            label: "Assurance",
            placeholder: "Assurance...",
            validationType: "text",
            isRequired: true
        }


        const parsedActivity = {
            partnerID: partnerId,
            reference: reference,
            images: images,
            name: name,
            type: type,
            status: status,
            schedule: schedule,
            category: category,
            nbPersons: nbPersons,
            duration: duration,
            description: description,
            toKnow: toKnow,
            toKnowOther: toKnowOther,
            toKnowInfos: toKnowInfos,
            practicalInfo: practicalInfo,
            expected: expected,
            access: access,
            pricingAndExtras: pricingAndExtras,
            bookingPolicy: bookingPolicy,
            included: included,
            cancel: cancel,
            hasInsurance: hasInsurance,
        }

        return parsedActivity;

    }

    const  parseActivityForApi = (activity) => {

        const parseActivity = {
            reference: activity.reference ? activity.reference.value : "",
            isVerified: false,
            isSponsored: false,
            status: activity.status ? activity.status : "waiting",
            activityImages: {
                images: activity.images.images ? activity.images.images : []
            },
            detailsOfStatus: "",
            name: activity.name ? activity.name.value : "",
            type: activity.type ? activity.type.value : "",
            openOnHolidays: activity.schedule ? activity.schedule.openOnHolidays : false,
            temporarilyClosed: activity.schedule ? activity.schedule.temporarilyClosed : false,
            schedule: {
                schedulePlan: activity.schedule ? activity.schedule.schedule.value : [],
            },
            category: activity.category ? activity.category.map((category) => {
                return {name: category}
            }) : [],
            details: {
                maxParticipants: activity.nbPersons && activity.nbPersons.nbPersonsMax ? activity.nbPersons.nbPersonsMax.value : "",
                minParticipants: activity.nbPersons && activity.nbPersons.nbPersonsMin ? activity.nbPersons.nbPersonsMin.value : "",
                duration: activity.duration ? activity.duration.value : "",
                description: activity.description && activity.description.description ? activity.description.description.value : "",
                goodPoints: activity.description && activity.description.goodPoints ? activity.description.goodPoints.value : "",
                minAge: activity.toKnowInfos && activity.toKnowInfos.minAge ? activity.toKnowInfos.minAge.value : "",
                experience: activity.toKnowInfos && activity.toKnowInfos.experience ? activity.toKnowInfos.experience.value : "",
                clothingRecommendation: activity.toKnowInfos && activity.toKnowInfos.clothingRecommendation ? activity.toKnowInfos.clothingRecommendation.value : "",
                otherRecommendation: activity.toKnowInfos && activity.toKnowInfos.otherRecommendation ? activity.toKnowInfos.otherRecommendation.value : "",
                toKnowOther: activity.toKnowOther ? activity.toKnowOther : "",
                expected: activity.expected ? activity.expected.value : "",
                practicalInfo: activity.practicalInfo ? activity.practicalInfo.value : "",
                pricingOptions: activity.pricingAndExtras.pricingOptions ? activity.pricingAndExtras.pricingOptions : [],
                extraPrice: activity.pricingAndExtras.extraPrice ? activity.pricingAndExtras.extraPrice : [],
                location: activity.access.location ? activity.access.location.value : "",
                accessDetails: activity.access.accessDetails ? activity.access.accessDetails.value : "",
                cancellationPolicy: activity.cancel && activity.cancel.cancelPolicy ? activity.cancel.cancelPolicy.value : "",
                refundPolicy: activity.cancel && activity.cancel.refundPolicy ? activity.cancel.refundPolicy.value : "",
                included: activity.included ? activity.included.value : []
            },
            bookingPolicy: activity.bookingPolicy ? activity.bookingPolicy.value : "",
            tags: activity.toKnow ? activity.toKnow : [],
            hasInsurance: activity.hasInsurance.value,
        }

        return parseActivity;

    }

    const parseActivityforUpdate = (activity) => {

            const parseActivity = {
                reference: activity.reference ? activity.reference.value : "",
                isVerified: false,
                isSponsored: false,
                status: activity.status ? activity.status : "waiting",
                activityImages: {
                    images: activity.images && activity.images.images ? activity.images.images : []
                },
                detailsOfStatus: "",
                name: activity.name ? activity.name.value : "",
                type: activity.type ? activity.type.value : "",
                openOnHolidays: activity.schedule ? activity.schedule.openOnHolidays : "",
                temporarilyClosed: activity.schedule ? activity.schedule.temporarilyClosed : "",
                schedule: {
                    schedulePlan: activity.schedule ? activity.schedule.schedule.value : [],
                },
                category: activity.category ? activity.category.map((category) => {
                    return {name: category}
                }) : [],
                details: {
                    maxParticipants: activity.nbPersons && activity.nbPersons.nbPersonsMax ? activity.nbPersons.nbPersonsMax.value : "",
                    minParticipants: activity.nbPersons && activity.nbPersons.nbPersonsMin ? activity.nbPersons.nbPersonsMin.value : "",
                    duration: activity.duration ? activity.duration.value : "",
                    description: activity.description && activity.description.description ? activity.description.description.value : "",
                    goodPoints: activity.description && activity.description.goodPoints ? activity.description.goodPoints.value : "",
                    minAge: activity.toKnowInfos && activity.toKnowInfos.minAge ? activity.toKnowInfos.minAge.value : "",
                    experience: activity.toKnowInfos && activity.toKnowInfos.experience ? activity.toKnowInfos.experience.value : "",
                    clothingRecommendation: activity.toKnowInfos && activity.toKnowInfos.clothingRecommendation ? activity.toKnowInfos.clothingRecommendation.value : "",
                    otherRecommendation: activity.toKnowInfos && activity.toKnowInfos.otherRecommendation ? activity.toKnowInfos.otherRecommendation.value : "",
                    toKnowOther: activity.toKnowOther ? activity.toKnowOther : "",
                    expected: activity.expected ? activity.expected.value : "",
                    practicalInfo: activity.practicalInfo ? activity.practicalInfo.value : "",
                    pricingOptions: activity.pricingAndExtras && activity.pricingAndExtras.pricingOptions ? activity.pricingAndExtras.pricingOptions : [],
                    extraPrice: activity.pricingAndExtras && activity.pricingAndExtras.extraPrice ? activity.pricingAndExtras.extraPrice : [],
                    location: activity.access && activity.access.location ? activity.access.location.value : "",
                    accessDetails: activity.access && activity.access.accessDetails ? activity.access.accessDetails.value : "",
                    cancellationPolicy: activity.cancel && activity.cancel.cancelPolicy ? activity.cancel.cancelPolicy.value : "",
                    refundPolicy: activity.cancel && activity.cancel.refundPolicy ? activity.cancel.refundPolicy.value : "",
                    included: activity.included ? activity.included.value : []
                },
                bookingPolicy: activity.bookingPolicy ? activity.bookingPolicy.value : "",
                tags: activity.toKnow ? activity.toKnow : []
            }

            // Filter out empty values
        const filterNonEmptyValues = (obj) => {
            const filteredObj = {};

            Object.entries(obj).forEach(([key, value]) => {
                if (Array.isArray(value)) {
                    // Filter out empty arrays or arrays with only empty values
                    const nonEmptyArray = value.filter(item => item && !(Array.isArray(item) && item.length === 0) && !(item && typeof item === 'object' && Object.keys(item).length === 0));
                    if (nonEmptyArray.length > 0) {
                        filteredObj[key] = nonEmptyArray;
                    }
                } else if (typeof value === 'object' && value !== null) {
                    // Filter nested objects recursively
                    const nestedFilteredObj = filterNonEmptyValues(value);
                    if (Object.keys(nestedFilteredObj).length > 0) {
                        filteredObj[key] = nestedFilteredObj;
                    }
                } else if (value !== "" && value !== null && value !== undefined) {
                    // Check for non-empty, non-false, and non-null/undefined values
                    filteredObj[key] = value;
                }
            });

            return filteredObj;
        };


        const filteredActivity = filterNonEmptyValues(parseActivity);
        return filteredActivity;

    }

    return {parseActivity, parseActivityForApi, parseActivityforUpdate};

}

export default useParseActivity