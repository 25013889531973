import React from 'react';
import FormSectionRow from "../../../components/form/formSectionRow";
import Textarea from "../../../components/form/InputTextarea/textarea";
import search from "../../../assets/icones/global/search.svg";
import InputAddress from "../../../components/form/inputText/inputAddress";


const Access = ({access, setActivity, isDisabled = false}) => {

    const handleChange = (event) => {
        event.preventDefault();
        const {name, value} = event.target;

        setActivity(`access.${name}.value`, value);
    }

    const handleChangeAddress = (name, addressDetails) => {
        const newAddress = `${addressDetails.addressLine ? addressDetails.addressLine : ""}`;
        if (access.location.value !== newAddress) { // Only update if there's a change
            setActivity(`access.location.value`, newAddress);
        }
    }


    return(
        <FormSectionRow classContainer={"is__full"} isRequired={true}>
            <InputAddress
                title={access.location.label}
                name="location"
                valueInput={access.location.value}
                onChangeForm={handleChangeAddress}
                errorEmpty={access.location.error}
                errorMessage={access.location.errorMessage}
                placeholder={access.location.placeholder}
                icon={search}
                validationType={"text"}
                disabled={isDisabled}
                isRequired={true}
            />
            <Textarea
                title={access.accessDetails.label}
                name="accessDetails"
                value={access.accessDetails.value}
                onChange={handleChange}
                errorEmpty={access.accessDetails.error}
                errorMessage={access.accessDetails.errorMessage}
                placeholder={access.accessDetails.placeholder}
                disabled={isDisabled}
            />
        </FormSectionRow>
    )
}

export default Access;