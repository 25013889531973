// authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null, // user data
        token: null, // token
    },
    reducers: {
        userLogin: (state, action) => {
            state.user = action.payload.user;
            state.token = action.payload.token;
        },
        userLogout: (state) => {
            state.user = null;
            state.token = null;
        },
        userUpdateAvatar: (state, action) => {
            state.user.userAvatarID = action.payload;
        },
        userUpdate: (state, action) => {
            // Update only the fields present in the payload
            const fieldsToUpdate = ['firstname', 'lastname', 'email', 'phoneNumber'];
            fieldsToUpdate.forEach(field => {
                if (action.payload.hasOwnProperty(field)) {
                    state.user[field] = action.payload[field];
                }
            });
        },
        userUpdateCommunication: (state, action) => {
            state.user.communication = action.payload;
        },
        userPartnerUpdateOffers: (state, action) => {
            // Assuming action.payload includes both partnerID and the new offer to be added
            const { partnerID, offer } = action.payload; // `offer` is the new offer ID to add

            if (!state.user || !Array.isArray(state.user.partnerID)) {
                return state; // Return current state if user or user.partnerID is not as expected
            }

            const updatedPartners = state.user.partnerID.map(partner => {
                if (partner._id === partnerID) {
                    // Ensure offersID is an array before trying to spread it
                    const existingOffers = Array.isArray(partner.offersID) ? partner.offersID : [];
                    // Append the new offer ID to the existing array of offersID
                    return { ...partner, offersID: [...existingOffers, offer] };
                }
                return partner;
            });

            return {
                ...state,
                user: {
                    ...state.user,
                    partnerID: updatedPartners,
                },
            };
        },
        userPartnerUpdateActivities: (state, action) => {
            const { partnerID, activity } = action.payload; // `activity` is the new activity ID to add

            if (!state.user || !Array.isArray(state.user.partnerID)) {
                return state;
            }

            const updatedPartners = state.user.partnerID.map(partner => {
                if (partner._id === partnerID) {
                    // Ensure activitiesID is an array before trying to spread it
                    const existingActivities = Array.isArray(partner.activitiesID) ? partner.activitiesID : [];
                    // Append the new activity ID to the existing array of activitiesID
                    return { ...partner, activitiesID: [...existingActivities, activity] };
                }
                return partner;
            });

            return {
                ...state,
                user: {
                    ...state.user,
                    partnerID: updatedPartners,
                },
            };
        },
        userSetEmailVerificationStatus: (state, action) => {
            state.user.isEmailVerified = true;
        },
        userUpdateSubscription: (state, action) => {
            // Destructure payload for clearer access
            const { partnerID, subscriptionID } = action.payload;

            // Create a new array of partners where the subscriptionID is updated for the matching partner
            const updatedPartners = state.user.partnerID.map(partner =>
                partner._id === partnerID ? { ...partner, subscriptionID } : partner
            );

            // Return the new state with the updated list of partners
            // Ensure you're updating the state immutably
            return {
                ...state,
                user: {
                    ...state.user,
                    partnerID: updatedPartners,
                },
            };
        },
        userPartnerUpdateBooking: (state, action) => {
            const { partnerID, booking } = action.payload; // `booking` is the new booking ID to add

            if (!state.user || !Array.isArray(state.user.partnerID)) {
                return state;
            }

            const updatedPartners = state.user.partnerID.map(partner => {
                if (partner._id === partnerID) {
                    // Ensure bookingsID is an array before trying to spread it
                    const existingBookings = Array.isArray(partner.bookingPartnersID) ? partner.bookingPartnersID : [];
                    // Append the new booking ID to the existing array of bookingsID
                    return { ...partner, bookingPartnersID: booking };
                }
                return partner;
            });

            return {
                ...state,
                user: {
                    ...state.user,
                    partnerID: updatedPartners,
                },
            };
        },
        userPartnerUpdateEngineID: (state, action) => {
            const { partnerID, engineID } = action.payload;

            if (!state.user || !Array.isArray(state.user.partnerID)) {
                return state;
            }

            const updatedPartners = state.user.partnerID.map(partner => {
                if (partner._id === partnerID) {
                    return { ...partner, engineID };
                }
                return partner;
            });

            return {
                ...state,
                user: {
                    ...state.user,
                    partnerID: updatedPartners,
                },
            };
        }
    },
});

export const {
    userLogin,
    userLogout,
    userUpdate,
    userSetEmailVerificationStatus,
    userUpdateSubscription,
    userUpdateAvatar,
    userPartnerUpdateOffers,
    userPartnerUpdateActivities,
    userUpdateCommunication,
    userPartnerUpdateBooking,
    userPartnerUpdateEngineID
} = authSlice.actions;
export default authSlice.reducer;
