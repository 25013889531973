import React, {useState} from 'react';
import Button from "../../button/button";
import close from "../../../assets/icones/global/close-stroke.svg";
import deleting from "../../../assets/images/svg/deleting_icon.svg";
import InputSelect from "../../form/inputSelect/inputSelect";

const ModalBookingDeleting = ({isOpen, closeModal, deleteBooking, status, text, description, button, booking, mediaQuery}) => {

    const [reason, setReason] = useState(null);

    const handleDelete = () => {
        deleteBooking(reason);
        closeModal();
        setReason(null);
    }

    const handleClose = () => {
        closeModal();
        setReason(null);
    }

    return (
        <div className={`bg__modal ${isOpen ? "open" : ""}`}>
            <div className={`modal booking ${isOpen ? "open" : ""} ${mediaQuery ? "media-query" : ""}`}>
                <div className={"modal__header"}>
                    <div className={"modal__header__col"}>
                        <div className={"modal__delete__icon"}>
                            <img src={deleting} alt={"deleting"}/>
                        </div>
                        <div className={"modal__header__text"}>
                            <p className={"title-16"}>{text}</p>
                            <p className={"text-14"}>{description}</p>

                            <InputSelect
                                label={"Raison du refus"}
                                options={[
                                    {id:1, name: "Indisponible à ce créneau", value: "unavailable"},
                                    {id:2, name: "Fermeture exceptionnelle", value: "exceptionalClosing"},
                                    {id:3, name: "Capacité maximale atteinte", value: "maxCapacityReached"},
                                    {id:4, name: "Maintenance ou sécurité", value: "maintenanceOrSecurity"},
                                    {id:5, name: "Conditions météo (pour extérieur)", value: "weatherConditions"},
                                ]}
                                onChange={(e) => setReason(e.target.value)}
                                valueSelected={reason}
                                placeholder={"Sélectionner une raison"}
                                isColumn={true}
                            />
                        </div>
                    </div>
                    <div className={"modal__header__col"}>
                        <div className={"modal__close"} onClick={handleClose}>
                            <img src={close} alt={"close"}/>
                        </div>
                    </div>
                </div>
                <div className={"modal__content"}>
                    <div className={"modal__content__actions"}>
                        <Button text={"Annuler"} isPrimary={false} onClick={handleClose} />
                        <Button text={button} isRed={true} onClick={handleDelete} isDisabled={!reason} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalBookingDeleting;