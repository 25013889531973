import React, {useState, useEffect, useRef} from 'react';
import IconButton from "../button/iconButton";
import add from "../../assets/icones/global/add_full.svg";
import deleteIcon from "../../assets/icones/global/trash_can.svg";
import {current} from "@reduxjs/toolkit";

function ScheduleRow({ day, mode, onScheduleChange, error, onDropdownFocus, isDropdownActive }) {
    const [isDropdownVisible, setDropdownVisible] = useState({ open: false, close: false });
    const [times, setTimes] = useState({ opening: day.openingTime || '', closing: day.closingTime || '', pauseStart: day.pauseStartTime || '', pauseEnd: day.pauseEndTime || '' });
    const rowRef = useRef(null);
    const [addPauseTime, setAddPauseTime] = useState(day.pauseStartTime !== '' ? true : false);

    const timeOptions = [...Array(24).keys()].flatMap((hour) => {
        // Adjust hour to start from 7:00 AM
        const adjustedHour = (hour + 7) % 24;
        return [
            `${adjustedHour.toString().padStart(2, '0')}:00`,
            `${adjustedHour.toString().padStart(2, '0')}:30`
        ];
    });

    const handleAddPauseTime = () => {
        setAddPauseTime(true);

        const currentClosing = times.closing;
        // This assumes that when adding a pause time, the closing time becomes the pause end time
        setTimes((prev) => ({ ...prev, pauseStart: prev.closing, pauseEnd: '', closing: '' }));
        onScheduleChange({
            ...day,
            pauseStartTime: "",
            pauseEndTime: '',
            closingTime: currentClosing
        });
    }

    const handleRemovePauseTime = () => {
        setAddPauseTime(false);
        // Get the current pauseStart before it gets cleared.
        const currentClosing = times.closing;
        // Now update the state with the new values.
        setTimes((prev) => ({ ...prev, closing: prev.pauseStart, pauseStart: '', pauseEnd: '' }));
        // Call the onScheduleChange with the updated values.
        onScheduleChange({
            ...day,
            pauseStartTime: '',
            pauseEndTime: '',
            closingTime: currentClosing
        });
    };


    const handleRowChange = (e) => {
        const { name, value, checked } = e.target;

        if (name === "isClosed") {
            onScheduleChange({
                ...day,
                isClosed: checked,
                openingTime: checked ? '' : day.openingTime,
                closingTime: checked ? '' : day.closingTime,
                pauseStartTime: checked ? '' : day.pauseStartTime,
                pauseEndTime: checked ? '' : day.pauseEndTime,
            });

            if (checked) {
                setTimes({ opening: '', closing: '', pauseStart: '', pauseEnd: '' });
            }
        }else {
            const formattedTime = formatTimeInput(value);
            setTimes((prev) => ({ ...prev, [name]: formattedTime }));
            // Depending on the input field, update the correct property
            let scheduleUpdate = {};
            if(name === "pauseStart") {
                scheduleUpdate = { pauseStartTime: formattedTime };
            } else if(name === "pauseEnd") {
                scheduleUpdate = { pauseEndTime: formattedTime };
            } else {
                scheduleUpdate = { [`${name}Time`]: formattedTime };
            }

            onScheduleChange({
                ...day,
                ...scheduleUpdate
            });
        }
    };

    const handleFocus = (type) => {
        onDropdownFocus(day.dayOfWeek, type);
    };

    const handleOptionClick = (name, time) => {
        setTimes((prev) => ({ ...prev, [name]: time }));
        setDropdownVisible({ opening: false, closing: false, pauseStart: false, pauseEnd: false }); // Close all dropdowns when typing
        onScheduleChange({
            ...day,
            [`${name}Time`]: time
        });

        onDropdownFocus(null, null); // Indicate that no dropdown should be active
    };

    const formatTimeInput = (value) => {
        const digits = value.replace(/\D/g, ''); // Remove non-digit characters
        if (digits.length === 2) {
            let hours = digits.slice(0, 2);
            const hoursInt = parseInt(hours, 10);
            if (!isNaN(hoursInt) && hoursInt > 23) {
                hours = '23';
            }
            return hours + ':'; // Add colon after hours
        } else if (digits.length > 2) {
            const hours = digits.slice(0, 2);
            let minutes = digits.slice(2, 4);

            // Validate minutes to be within 0 and 59
            const minutesInt = parseInt(minutes, 10);
            if (!isNaN(minutesInt) && minutesInt > 59) {
                minutes = '59';
            }

            return hours + ':' + minutes; // Insert colon between hours and minutes
        }
        return digits; // Return the original value if less than 2 digits
    };


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (rowRef.current && !rowRef.current.contains(event.target)) {
                setDropdownVisible({ opening: false, closing: false, pauseStart: false, pauseEnd: false });
            }
        };

        //set times
        setTimes({ opening: day.openingTime || '', closing: day.closingTime || '', pauseStart: day.pauseStartTime || '', pauseEnd: day.pauseEndTime || '' });

        if(day.pauseStartTime !== "") {
            setAddPauseTime(true);
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [day]);

    return (
        <div className="schedule-row">
            <div className="day-header">
                <p className={"text-16"}>{day.dayOfWeek}</p>
                <div className="day-header_row">
                    <input type="checkbox" className={error ? 'error' : null} name={"isClosed"} id={day.dayOfWeek.toLowerCase()} checked={day.isClosed} onChange={handleRowChange} />
                    <label htmlFor={day.dayOfWeek.toLowerCase()}>Fermé</label>
                </div>
            </div>
            <div className="day-col">
                <div className={'day-hours'}>
                    <div className={`hour open ${day.isClosed ? "disabled" : null}`}>
                        <input
                            type="text"
                            className={error ? 'error' : ''}
                            name="opening"
                            value={times.opening}
                            onChange={handleRowChange}
                            onFocus={() => handleFocus('opening')}
                            disabled={day.isClosed}
                        />
                        {isDropdownActive === 'opening' && (
                            <div className="dropdown-options">
                                {timeOptions.map((time, index) => (
                                    <div key={`open-${index}`} onClick={() => handleOptionClick('opening', time)} className="dropdown-option">
                                        {time}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className={`hour close ${day.isClosed ? "disabled" : null}`}>
                        <input
                            type="text"
                            className={error ? 'error' : ''}
                            name={addPauseTime ? "pauseStart" : "closing"}
                            value={addPauseTime ? times.pauseStart : times.closing}
                            onChange={handleRowChange}
                            onFocus={() => handleFocus(addPauseTime ? 'pauseStart' : 'closing')}
                            disabled={day.isClosed}
                        />
                        {isDropdownActive === (addPauseTime ? 'pauseStart' : 'closing') && (
                            <div className="dropdown-options">
                                {timeOptions.map((time, index) => (
                                    <div key={`close-${index}`} onClick={() => handleOptionClick(addPauseTime ? 'pauseStart' : 'closing', time)} className="dropdown-option">
                                        {time}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <IconButton
                        icon={add}
                        onClick={handleAddPauseTime}
                    />
                </div>
                {
                    addPauseTime &&
                    <div className={'day-hours'}>
                        <div className={`hour open ${day.isClosed ? "disabled" : null}`}>
                            <input
                                type="text"
                                className={error ? 'error' : ''}
                                name="pauseEnd"
                                value={times.pauseEnd}
                                onChange={handleRowChange}
                                onFocus={() => handleFocus('pauseEnd')}
                                disabled={day.isClosed}
                            />
                            {isDropdownActive === 'pauseEnd' && (
                                <div className="dropdown-options">
                                    {timeOptions.map((time, index) => (
                                        <div key={`pauseEnd-${index}`} onClick={() => handleOptionClick('pauseEnd', time)}
                                             className="dropdown-option">
                                            {time}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className={`hour close ${day.isClosed ? "disabled" : null}`}>
                            <input
                                type="text"
                                className={error ? 'error' : ''}
                                name="closing"
                                value={times.closing}
                                onChange={handleRowChange}
                                onFocus={() => handleFocus('closing')}
                                disabled={day.isClosed}
                            />
                            {isDropdownActive === 'closing' &&  (
                                <div className="dropdown-options">
                                    {timeOptions.map((time, index) => (
                                        <div key={`close-${index}`} onClick={() => handleOptionClick('closing', time)} className="dropdown-option">
                                            {time}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <IconButton
                            icon={deleteIcon}
                            onClick={handleRemovePauseTime}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default ScheduleRow;
