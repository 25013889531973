import React, {useEffect, useState, useContext} from 'react';
import HeaderView from "../../components/header/headerView";
import RoundStat from "../../components/stats/roundStat";
import CardStat from "../../components/stats/cardStat";
import Table from "../../components/table/table";
import Tag from "../../components/tag/tag";
import Dot from "../../components/dot/dot";
import Button from "../../components/button/button";
import useStatusName from "../../hooks/parseText/useStatusName";
import useOpenModal from "../../hooks/useOpenModal";
import PartnersServices from "../../api/services/partners";
import TabBlock from "../../components/navbar/tabBlock";
import ModalIsVerified from "../../components/modal/modalTable/modalIsVerified";
import FilterTable from "../../components/table/filterTable";
import useFilter from "../../hooks/useFilter";
import EntitiesVerified from "../../api/services/entitiesVerified";
import {ErrorContext} from "../../context/errorContext";
import {useLoader} from "../../context/loaderContext";


const VerificationPartners = () => {

    const [refresh, setRefresh] = useState(false);
    const [filterList, setFilterList] = useState([]);

    //get partners list from api
    const [partners, setPartners] = useState([]);
    const { getStatusName } = useStatusName();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const [isModalOpen, openModal, closeModal] = useOpenModal(partners.map(() => false));

    const { updateFilters, filteredData } = useFilter([], partners, (partner, filters) => {
        return filters.length === 0 || filters.includes(partner.status);
    });


    useEffect(() => {
        setLoading(true);
        PartnersServices.getPartners()
        .then((response) => {
            if(response.status === 200){
                setPartners(response.data);
                setRefresh(false);
                //set filter list with status of partners but remove duplicate
                setFilterList([...new Set(response.data.map((partner) => partner.status))]);
            }
        })
        .catch((error) => {
            showError(error);
        }).finally(() => {
        setLoading(false);
        });
    }, [refresh]);


    const handleVerification = (partnerId, status) => {

        const data = {
            isVerified: true,
            status: status
        }
        setLoading(true);

        PartnersServices.updatePartner(partnerId, data)
        .then((response) => {
            if(response.status === 200){
                EntitiesVerified.sendForPartnerVerified(partnerId)
                .then((response) => {
                    if(response.status === 200){
                        setRefresh(true);
                    }
                }).then((error) => {
                    showError(error);
                })
            }
        })
        .catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        });

    }


    return (
        <div className={"main-container"}>
            <HeaderView title={"Vérification partenaire"} actions={
                <>
                    <TabBlock
                        tabs={[
                            {name: "Partenaires", link: "/verification/partners"},
                            {name: "Activités", link: "/verification/activities"},
                            {name: "Offres", link: "/verification/offers"}
                        ]}
                        activeLink={0}
                    />
                </>
            }/>

            <div className={"main-container__row"}>
                <div className={"flex_row stats_rounds"}>
                    <div className={"flex_col"}>
                        <RoundStat title={"Validée"}
                                   value={partners.filter((partner) => partner.isVerified === true).length}
                                   color={"#47B05E"}/>
                    </div>
                    <div className={"flex_col"}>
                        <RoundStat title={"En attente"} value={partners.filter((partner) => partner.isVerified === false).length} color={"#FFC107"} />
                    </div>
                </div>

                <div className={"flex_col stats_cards"}>
                    <div className={"grid grid_2"}>
                        <CardStat title={"Acquis"}
                                  value={partners.filter((partner) => partner.status === "active").length}
                                  color={"#47B05E"} list={partners.filter((partner) => partner.status === "active")}
                                  titleModal={"Partenaires acquis"} status={"active"}/>
                        <CardStat title={"En cours d'acquisition"}
                                  value={partners.filter((partner) => partner.status === "pending").length}
                                  status={"#000000"}
                                  list={partners.filter((partner) => partner.status === "pending")}
                                  titleModal={"Partenaires en cours d'acquisition"} status={"pending"}/>
                        <CardStat title={"Refusé"}
                                  value={partners.filter((partner) => partner.status === "lost").length}
                                  color={"#FF4D42"} list={partners.filter((partner) => partner.status === "lost")}
                                  titleModal={"Partenaires refusés"} status={"lost"}/>
                    </div>
                </div>
            </div>

            <div className={"main-container__row list"}>
                {
                    filterList && filterList.length > 0 ?
                        <FilterTable
                            filterArray={filterList}
                            onFilterChange={updateFilters}
                            typeValue={"partner"}
                            searchData={partners}
                        />
                        :
                        null
                }
                {
                    filteredData && filteredData.filter((partner) => partner.isVerified === false).length > 0 ?
                            <Table
                                columns={[
                                    {name: "Partenaires", align: "left", width: "30%", field: "partner"},
                                    {name: "Status", align: "left", width: "20%", field: "status"},
                                    {name: "Détails du dossier", align: "left", width: "30%", field: "details"},
                                    {
                                        name: "Actions", align: "center", width: "30%", style: {
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }, field: "actions"
                                    },
                                    {
                                        name: "Voir plus", align: "center", width: "15%", style: {
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        } , field: "link"
                                    },
                                ]}

                                data={
                                    filteredData && filteredData.length > 0 ?
                                        filteredData.filter((partner) => partner.isVerified === false).map((partner, index) => {
                                                return {
                                                    partner: partner.name,
                                                    status: <Tag text={getStatusName(partner.status)} status={partner.status}/>,
                                                    details: partner.comment !== "" && partner.comment ? partner.comment : "Aucun commentaire",
                                                    actions: (
                                                        <Dot size={"big"} click={() => openModal(index)}>
                                                            <ModalIsVerified
                                                                isOpen={isModalOpen[index]}
                                                                closeModal={() => closeModal(index)}
                                                                handleChange={handleVerification.bind(this, partner._id)}
                                                                type={"partner"}
                                                                link={`/partners/${partner._id}`}
                                                            />
                                                        </Dot>
                                                    ),
                                                    link: <Button text={"Voir le dossier"} link={`/partners/${partner._id}`}/>
                                                }
                                            }
                                        ).reverse() : []
                                }
                            />

                        :
                        <div className={"container__empty"}>
                            <p className={"text-14"}>Aucun partenaire n'a été ajouté pour le moment</p>
                        </div>
                }
            </div>
        </div>
    );
}

export default VerificationPartners;