import React from 'react';

import Button from "../../button/button";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {usePartner} from "../../../context/partnerContext";
import { HashLink } from 'react-router-hash-link';



const ModalErrorEngineID = ({isOpen, setModalOpen}) => {


    const navigate = useNavigate();
    const { currentPartnerId } = usePartner();


    const handleClick = () => {
        setModalOpen(false);
    }

    return(
        <div className={`bg__modal ${isOpen ? "open" : ""}`}>
            <div className={`modal verification ${isOpen ? "open" : ""}`}>
                <div className={"modal__header"}>
                    <div className={"modal__header__col"}>
                        <p className={"title-20"}>Vous n'avez pas rempli les informations nécessaires</p>
                    </div>
                </div>
                <div className={"modal__content center__container"}>
                    <div className={"modal__content__text"}>
                        <p className={"text-12 light"}>Veuillez remplir les informations concernant vos informations des
                            réservations dans votre espace "Mes informations".</p>
                    </div>
                    <div className={"modal__content__div"}>
                        <HashLink
                            to={`/partners/${currentPartnerId}#booking`}
                            className={"btn is__primary"}
                            scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                        >
                                <p className={"title-14"}>Remplir mes informations</p>
                        </HashLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalErrorEngineID;