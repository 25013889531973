import React, { useState, useEffect } from 'react';
import Calendar from '../singleView/singleCalendar';
import '../../../styles/components/calendar.css';
import Button from "../../button/button";
import {isDisabled} from "@testing-library/user-event/dist/utils";

const SingleDateRangePicker = ({ errorEmpty, errorMessage, disabled = false, setDateSelected = () => {}, arrayDates = [], arrayDays = [], isBooking = false, title, currentDateCard = true, disabledPastDates = true }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDates, setSelectedDates] = useState(new Set());
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [nextMonth, setNextMonth] = useState((currentMonth + 1) % 12);
    const [nextYear, setNextYear] = useState(currentMonth === 11 ? currentYear + 1 : currentYear);
    const [isCalendarDisabled, setIsCalendarDisabled] = useState(false); // new state variable

    useEffect(() => {
        // Fetch initial date range from the database and set it
        // This is a simplified example, adjust based on how you fetch data
        setCurrentDate(currentDate);
        isBooking ? setDateSelected(null) : setDateSelected(currentDate);
    }, []);


    const handleFormChange = (value, field) => {
        // Also update the local state to reflect the change
        if (field === 'currentDate') {
            setCurrentDate(value);
        }
    };

    const handleDateClick = (dateString) => {
        setSelectedDates(new Set([dateString]));
        setCurrentDate(dateString);
        setDateSelected(dateString);
    };


    const handleResetClick = () => {
        setSelectedDates(new Set());
        setCurrentDate(new Date());
        setDateSelected(null);
    };

    const resetMonth = () => {
        const currentDate = new Date();
        setCurrentMonth(currentDate.getMonth());
        setCurrentYear(currentDate.getFullYear());
        // If you want to reset the next month as well,
        // you need to calculate what the next month and year would be
        setNextMonth((currentDate.getMonth() + 1) % 12);
        setNextYear(currentDate.getMonth() === 11 ? currentDate.getFullYear() + 1 : currentDate.getFullYear());

    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', options).replace(/\./g, '');
    };

    const handlePreviousMonth = () => {
        setCurrentMonth(prev => {
            if (prev === 0) {
                setCurrentYear(currentYear - 1);
                return 11; // December
            } else {
                return prev - 1;
            }
        });
    };

    const handleNextMonth = () => {
        setCurrentMonth(prev => {
            if (prev === 11) {
                setCurrentYear(currentYear + 1);
                return 0; // January
            } else {
                return prev + 1;
            }
        });
    };


// Use useEffect to set nextMonth and nextYear based on currentMonth and currentYear
    useEffect(() => {
        if (currentMonth === 11) {
            setNextMonth(0);
            setNextYear(currentYear + 1);
        } else {
            setNextMonth(currentMonth + 1);
            setNextYear(currentYear);
        }
    }, [currentMonth, currentYear]);

    return (
        <div className={`container__date-picker single ${disabled ? "disabled" : null}`}>
            <div className="date-picker__header">
                {
                    title ?  <p className={"text-18"}>{title}</p> : <p className={"text-18"}>Réservation du <strong>{formatDate(currentDate)}</strong></p>
                }
            </div>
            <div className="date-picker__body">
                <Calendar
                    onSelect={isCalendarDisabled || disabled ? null : handleDateClick}
                    onReset={isCalendarDisabled || disabled ? null : handleResetClick}
                    selectedDates={selectedDates}
                    currentDate={currentDate}
                    currentMonth={currentMonth}
                    currentYear={currentYear}
                    handlePreviousMonth={handlePreviousMonth}
                    handleNextMonth={handleNextMonth}
                    calendarDisabled={isCalendarDisabled}
                    arrayDates={arrayDates}
                    arrayDays={arrayDays}
                    isBooking={isBooking}
                    disabledPastDates={disabledPastDates}
                    currentDateCard={currentDateCard}
                />
            </div>
        </div>
    );
};

export default SingleDateRangePicker;
