import React, { useState } from 'react';
import Calendar from 'react-calendar';
import '../../../styles/components/form/inputDate.css';
import Button from "../../button/button";
import InputIcon from "../inputText/inputIcon";
import calendar from "../../../assets/icones/global/calendar.svg";
import useParseDate from "../../../hooks/useParseDate";

const InputDate = ({ dateValue, setDateValue, name, errorEmpty = false, errorMessage = false, title, disabled, minDate, maxDate = false }) => {
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const { parseDate } = useParseDate();

    const handleDateChange = (value) => {
        if (disabled) return;
        setDateValue(value, name);
        setIsCalendarOpen(false);
    };

    const handleOpenCalendar = (event) => {
        if (disabled) return;
        setIsCalendarOpen(true);
    };

    return (
        <div className='calendar-section'>
            <div className={"calendar__input"} onClick={handleOpenCalendar}>
                <InputIcon
                    name={name}
                    title={title}
                    placeholder={"01/01/2023"}
                    valueInput={dateValue !== '' ? parseDate(dateValue) : ""}
                    isColumn={true}
                    icon={calendar}
                    type={"date"}
                    disabled={disabled}
                    errorEmpty={errorEmpty}
                    errorMessage={errorMessage}
                />
                {errorEmpty || errorMessage && <div className="error-message">{"Remplissez ce champ"}</div>}
            </div>

            <div className={`calendar-container ${isCalendarOpen ? "calendar__open" : "calendar__close"}`}>
                {/* Calendar component now accepts minDate and maxDate */}
                <Calendar
                    onChange={handleDateChange}
                    value={dateValue}
                    minDate={minDate}
                    {
                        ...(maxDate && { maxDate: maxDate })
                    }
                />
                <div className={"calendar__buttons"}>
                    <Button text={"Annuler"} isFull={true} onClick={() => setIsCalendarOpen(false)} />
                    <Button text={"Valider"} isPrimary={true} isFull={true} onClick={() => setIsCalendarOpen(false)} />
                </div>
            </div>
        </div>
    );
};

export default InputDate;
