import {getRequest, postRequestJson, patchRequest, deleteRequest} from '../request/apiRequest';


const ActivitiesCategoriesServices = {

    getActivitiesCategories: async () => {
        return await getRequest('/activity/categories/');
    },

    getActivityCategoryById: async (activityCategoryId) => {
        return await getRequest(`/activity/categories/${activityCategoryId}`);
    },

    createActivityCategory: async (data) => {
        return await postRequestJson('/activity/categories/', data);
    },

    updateActivityCategory: async (activityCategoryId, data) => {
        return await patchRequest(`/activity/categories/${activityCategoryId}`, data);
    },

    deleteActivityCategory: async (activityCategoryId) => {
        return await deleteRequest(`/activity/categories/${activityCategoryId}`);
    }
}

export default ActivitiesCategoriesServices;