import React, {useState} from 'react';
import FormSectionRow from "../../../components/form/formSectionRow";
import InputImages from "../../../components/form/inputFile/inputImages";
import RadioRound from "../../../components/form/inputRadio/radioRound";

const Images = ({images, setActivity, type, activityId, partnerId = false, isImageChecked = false, setIsImageChecked, imageError}) => {
    const [checked, setChecked] = useState("");

    const handleChange = (event) => {
       setActivity(`images.images`, event);
    }

    return(
        <>
            <FormSectionRow title={"Image de l'activité"}
                            isRequired={true}
                            description={"Pensez à mettre les images les plus représentative de votre activité"}>

                <InputImages name={"images"} imagesFiles={type === "remote" ? images.images : images.images}
                             onChange={handleChange} type={type}
                             activityId={activityId} isCheck={checked === "accept"} partnerId={partnerId}
                             error={images.error}/>
                {
                    images.error ? <span
                        className={"error-message"}>Vous devez ajouter au moins <strong>3 images</strong></span> : null
                }
            </FormSectionRow>

            <div className={"form__row__input__container__radio form__col  form__signin__row"}>
                <div className="form__row checkbox">
                    <input type="checkbox"
                           name={"confidentiality"}
                           id={"confidentiality"} checked={isImageChecked}
                           onChange={setIsImageChecked ? () => setIsImageChecked(!isImageChecked) : () => {}}
                    />
                    <div className={"form__col__radio"}>
                        <p className={"text-15"}>En ajoutant des images, je confirme que les photos que je soumets pour mon établissement sur Frimity sont de ma propriété ou que j'ai le droit légal de les utiliser. J'accepte la responsabilité légale en cas de réclamation ou d'infraction liée à l'utilisation de ces images.</p>
                    </div>
                </div>
            </div>
            <div className={"form__row__input__container__radio form__col  form__signin__row"}>
                { imageError ? <span className={"error-message"}>Vous devez accepter les conditions</span> : null }
            </div>
        </>
    )

}

export default Images;