import React from 'react';
import '../../styles/components/dot.css';

const Dot = ({size = "", children, click = () => {}}) => {
    return (
        <div className={"dot-container"}>
            <div className={"dot-container__items"} onClick={click}>
                <span className={`dot ${size}`}></span>
                <span className={`dot ${size}`}></span>
                <span className={`dot ${size}`}></span>
            </div>

            <div className={"dot-container__content"}>
                {children}
            </div>
        </div>
    );
}

export default Dot;