import {getRequest, postRequestJson, patchRequest, deleteRequest} from '../request/apiRequest';


const ActivitiesTypesServices = {
    getActivitiesTypes: async () => {
        return await getRequest('/activity/type/');
    },

    getActivitiesTypeByID: async (typeId) => {
        return await getRequest(`/activity/type/${typeId}`);
    },

    createActivitiesType: async (data) => {
        return await postRequestJson('/activity/type/', data);
    },

    deleteActivitiesType: async (typeId) => {
        return await deleteRequest(`/activity/type/${typeId}`);
    }
}

export default ActivitiesTypesServices;