import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router";
import useStatusName from "../../hooks/parseText/useStatusName";
import HeaderView from "../../components/header/headerView";
import IconButton from "../../components/button/iconButton";
import coupon from "../../assets/icones/global/coupon.svg";
import TabBar from "../../components/navbar/tabBar";
import Button from "../../components/button/button";
import Table from "../../components/table/table";
import Tag from "../../components/tag/tag";
import Dot from "../../components/dot/dot";
import OffersServices from "../../api/services/offers";
import ModalOffer from "../../components/modal/modalTable/modalOffer";
import useOpenModal from '../../hooks/useOpenModal';
import useFilter from "../../hooks/useFilter";
import FilterTable from "../../components/table/filterTable";
import PartnerSubscriptionServices from "../../api/services/partnerSubscription";
import ActivitiesServices from "../../api/services/activities";
import {useSelector} from "react-redux";
import "../../styles/views/offers.css";
import {ErrorContext} from "../../context/errorContext";
import {useLoader} from "../../context/loaderContext";
import {usePartner} from "../../context/partnerContext";



const Offers = () => {
    const partnerId = useParams().partnerId;
    const [filterList, setFilterList] = useState([]);
    const { currentPartnerId } = usePartner();
    const user = useSelector(state => state.auth.user);
    const numberActivity = user.role !== "admin" ? useSelector((state) => state.auth.user.partnerID.find(partner => partner._id === currentPartnerId).activityID.length) : null;

    const [offers, setOffers] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [activities, setActivities] = useState([]);
    const [subscription, setSubscription] = useState(false);

    const { getStatusName } = useStatusName();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const [isModalOpen, openModal, closeModal] = useOpenModal(offers.map(() => false));
    const { updateFilters, filteredData } = useFilter([], offers, (offer, filters) => {
        return filters.length === 0 || filters.includes(offer.status);
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const offersResponse = await OffersServices.getOffersByPartnerId(partnerId);
                const subscriptionResponse = await PartnerSubscriptionServices.getSubscriptionByPartnerId(partnerId);
                const activitiesResponse = await ActivitiesServices.getActivitiesByPartnerId(partnerId);

                if (offersResponse.status === 200) {
                    setOffers(offersResponse.data.reverse());
                    setFilterList([...new Set(offersResponse.data.map((offer) => offer.status))]);
                }

                if (subscriptionResponse.status === 200) {
                    setSubscription(subscriptionResponse.data);
                } else {
                    setSubscription(false);
                }

                if (activitiesResponse.status === 200) {
                    setActivities(activitiesResponse.data);
                }

            } catch (error) {
                showError(error);
            } finally {
                setLoading(false);
                setRefresh(false);
            }
        };

        fetchData();
    }, [refresh]);


    return (
        <div className={"main-container offer is__grey"}>
            <HeaderView title={"Gérer vos offres"} actions={
                <>
                    {
                        //check if user.partnerID.subscriptionID.offerRemaining - user.partnerID.subscriptionID.offerUsed > 0
                        activities && activities.length > 0 && subscription && subscription.offerRemaining === "infinite" ?
                            <IconButton icon={coupon} text={"Créer une offre promotionelle"} isPrimary={false} link={`/partners/${partnerId}/offers/add`} />
                            :
                            activities && activities.length > 0 && subscription && subscription.offerRemaining && subscription.offerRemaining > 0 ?
                                <IconButton icon={coupon} text={"Créer une offre promotionelle"} isPrimary={false} link={`/partners/${partnerId}/offers/add`} />
                                :
                                null
                    }
                </>
            } isActionsWithTitle={true} />

            <TabBar
                tabs={[
                    {
                        name: "Fiche du partenaire",
                        link: `/partners/${partnerId}`
                    },
                    {
                        name: "Liste d’activités",
                        link: `/partners/${partnerId}/activities`,
                        index: numberActivity ? numberActivity : activities.length
                    },
                    {
                        name: "Gérer vos offres",
                        link: `/partners/${partnerId}/offers`
                    },
                    {
                        name: "Plan tarifaire",
                        link: `/partners/${partnerId}/subscription`,
                    }
                ]}
                activeTab={2}
            />


            <div className={"header__container"}>
                <div className={"header__container__col"}>
                    <h2 className={"title-18"}>Détail de vos offres</h2>
                    {
                        subscription && subscription.offerRemaining < 1 ?
                            <p className={"text-14 is__error"}>Vous avez atteint votre limite d'offres pour cette
                                année</p> :
                            <p className={"text-14"}>Vous pouvez voir et créer les offres qui sont disponibles sur le
                                plan que vous avez sélectionné</p>
                    }
                </div>
                <div className={"header__container__col"}>
                    <div className={"offers_card"}>
                        <div className={"offers_card__row"}>
                            <p className={"text-14"}>Offres utilisées</p>
                            <p className={"text-20"}>{subscription && subscription.offerUsed ? subscription.offerUsed : 0}</p>
                        </div>
                        <div className={"offers_card__row"}>
                            <p className={"text-14"}>Offres restantes</p>
                            <p className={"text-20"}>{subscription ? subscription.offerRemaining === "infinite" ? "∞" : subscription.offerRemaining : 0}</p>
                        </div>
                    </div>
                </div>

            </div>

            {
                activities && activities.length > 0 ?
                    <div className={"main-container__row list"}>
                        {
                            filterList && filterList.length > 0 ?
                                <FilterTable
                                    filterArray={filterList}
                                    onFilterChange={updateFilters}
                                    typeValue={"offer"}
                                    searchData={offers}
                                />
                                :
                                null
                        }
                        {
                            filteredData && filteredData.length > 0 ?
                                <Table
                                    columns={[
                                        {name: "Offres", align: "left", width: "10%", field: "offers"},
                                        {name: "Nom", align: "left", width: "15%", field: "name"},
                                        {name: "Réduction", align: "left", width: "30%", field: "reduction"},
                                        {name: "Statut", align: "left", width: "25%", field: "status"},
                                        { name: "Actions", align: "center", width: "20%", style: {
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }, field: "actions" },
                                        { name: "Voir plus", align: "center", width: "15%", style: {
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }, field: "link" },
                                    ]}

                                    data={
                                        filteredData && filteredData.length > 0 ?
                                            filteredData.map((offer, index) => {
                                                return {
                                                    offers: `0${offers.length - index}`,
                                                    name: offer.name,
                                                    reduction: offer.discount,
                                                    isVerified: offer.isVerified,
                                                    status: <Tag text={getStatusName(offer.status)} status={offer.status} />,
                                                    actions:(
                                                        <Dot size={"big"} click={() => openModal(index)}>
                                                            <ModalOffer isOpen={isModalOpen[index]} closeModal={() => closeModal(index)} partnerID={partnerId} offerID={offer._id} refresh={refresh} setRefresh={setRefresh} statusActive={offer.status}/>
                                                        </Dot>
                                                    ),
                                                    link: <Button text={"Voir l'offre"} link={`/partners/${partnerId}/offers/${offer._id}`} />
                                                }
                                            })
                                            : []
                                    }
                                />
                                :
                                subscription ?
                                    <div className={"container__empty"}>
                                        <p className={"text-14"}>Vous n'avez pas encore d'offre validée</p>
                                        <Button text={"Créer une offre"} link={`/partners/${partnerId}/offers/add`} isPrimary={true} />
                                    </div>
                                :
                                    <div className={"container__empty"}>
                                        <p className={"text-14"}>Vous n'avez pas encore d'abonnement actif pour créer des offres</p>
                                    </div>


                        }
                    </div>
                    :
                    <div className={"container__empty"}>
                        <p className={"text-14"}>Vous n'avez pas encore d'activité</p>
                        <Button text={"Ajouter une activité"} link={`/partners/${partnerId}/activities/add`} isPrimary={true} />
                    </div>
            }
        </div>
    )
}

export default Offers;