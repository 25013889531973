import {getRequest, postRequestJson, patchRequest, deleteRequest} from '../request/apiRequest';

const PartnerSubscriptionServices = {

    getSubscriptions: async (data) => {
        return await getRequest(`/partner/subscription`);
    },

    getSubscriptionById: async (subscriptionId) => {
        return await getRequest(`/partner/subscription/${subscriptionId}`);
    },

    getSubscriptionByPartnerId: async (partnerID) => {
        return await getRequest(`/partner/subscription/subscribe/${partnerID}`);
    },

    createSubscription: async (partnerId, data) => {
        return await postRequestJson(`/partner/${partnerId}/subscription`, data);
    },

    updateSubscription: async (subscriptionId, data) => {
        return await patchRequest(`/partner/subscription/${subscriptionId}`, data);
    },

    updateCommission: async (partnerId, data) => {
        return await patchRequest(`/partner/subscription/commission/${partnerId}`, data);
    },

    deleteSubscription: async (partnerId, subscriptionId) => {
        return await deleteRequest(`/partner/${partnerId}/subscription/${subscriptionId}`);
    },

}

export default PartnerSubscriptionServices;