import {postRequestJsonNotToken} from '../request/apiRequest';

const PasswordResetServices = {

    sendPasswordCode: async (data) => {
        return await postRequestJsonNotToken('/password-reset/request', data);
    },

    checkPasswordCode: async (data) => {
        return await postRequestJsonNotToken('/password-reset/verify', data);
    },

    resetPassword: async (data) => {
        return await postRequestJsonNotToken('/password-reset/reset', data);
    }
}

export default PasswordResetServices;