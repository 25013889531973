import React from 'react';

const RoundStat = ({title, value, color}) => {

    return (
        <div className={"round-stat"} style={{borderColor: color}}>
            <div className={"round-stat__value"}>{value}</div>
            <div className={"round-stat__title"}>{title}</div>
        </div>
    );

}

export default RoundStat;