import React, {useState, useEffect, useContext} from 'react';
import HeaderView from "../../components/header/headerView";
import RoundStat from "../../components/stats/roundStat";
import CardStat from "../../components/stats/cardStat";
import IconButton from "../../components/button/iconButton";
import Table from "../../components/table/table";
import Tag from "../../components/tag/tag";
import add from '../../assets/icones/global/add.svg';
import '../../styles/views/partners.css';
import Dot from "../../components/dot/dot";
import PartnersServices from "../../api/services/partners";
import useStatusName from "../../hooks/parseText/useStatusName";
import Button from "../../components/button/button";
import useOpenModal from '../../hooks/useOpenModal';
import ModalStatus from "../../components/modal/modalTable/modalStatus";
import FilterTable from "../../components/table/filterTable";
import useFilter from "../../hooks/useFilter";
import {useLoader} from "../../context/loaderContext";
import {ErrorContext} from "../../context/errorContext";

const Partners = () => {

    const [refresh, setRefresh] = useState(false);
    const [filterList, setFilterList] = useState([]);
    //get partners list from api
    const [partners, setPartners] = useState([]);
    const { getStatusName } = useStatusName();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();


    const [isModalOpen, openModal, closeModal] = useOpenModal(partners.map(() => false));

    const { updateFilters, filteredData } = useFilter([], partners, (partner, filters) => {
        return filters.length === 0 || filters.includes(partner.status);
    });


    useEffect(() => {
        setLoading(true);
        PartnersServices.getPartners()
        .then((response) => {
            if(response.status === 200){
                setPartners(response.data);
                setRefresh(false)
                //set filter list with status of partners but remove duplicate
                setFilterList([...new Set(response.data.map((partner) => partner.status))]);
            }
        })
        .catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        });
    }, [refresh]);

    const handleStatusChange = (partnerId, status) => {
        const data = {
            status: status
        }

        PartnersServices.updatePartner(partnerId, data)
        .then((response) => {
            if(response.status === 200){
                setRefresh(true);
            }
        })
        .catch((error) => {
            showError(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleClick = (partnerId) => {

    }


    return (
        <div className={"main-container"}>
            <HeaderView title={"Dashboard"} isGoBack={false} actions={
                <>
                    <IconButton icon={add} text={"Ajouter un établissement"} isPrimary={false} link={"/partners/add"} />
                </>
            } />

            <div className={"main-container__row"}>
                <div className={"flex_row stats_rounds"}>
                    <div className={"flex_col"}>
                        <div className={"stats_title"}>Partenaire réservation</div>
                        <RoundStat title={"Actifs"} value={partners.filter((partner) => partner.status === "active").length} color={"#47B05E"} />
                    </div>
                    <div className={"flex_col"}>
                        <div className={"stats_title"}>Partenaire consultation</div>
                        <RoundStat title={"Référencés"} value={partners.filter((partner) => partner.status === "review").length} color={"#FFC107"} />
                    </div>
                </div>

                <div className={"flex_col stats_cards"}>
                    <div className={"grid grid_2"}>
                        <CardStat title={"Acquis"} value={partners.filter((partner) => partner.status === "active").length} color={"#47B05E"} list={partners.filter((partner) => partner.status === "active")} titleModal={"Partenaires acquis"} status={"active"} />
                        <CardStat title={"En cours d'acquisition"} value={partners.filter((partner) => partner.status === "review").length} status={"#000000"} list={partners.filter((partner) => partner.status === "review")} titleModal={"Partenaires en cours d'acquisition"} status={"pending"} />
                        <CardStat title={"Refusé"} value={partners.filter((partner) => partner.status === "lost").length} color={"#FF4D42"} list={partners.filter((partner) => partner.status === "lost")} titleModal={"Partenaires refusés"} status={"lost"} />
                    </div>
                </div>
            </div>

            <div className={"main-container__row list"}>
                {
                    filterList && filterList.length > 0 ?
                        <FilterTable
                            filterArray={filterList}
                            onFilterChange={updateFilters}
                            typeValue={"partner"}
                            searchData={partners}
                        />
                        :
                        null
                }
                {
                    filteredData && filteredData.filter((partner) => partner.isVerified === true).length > 0 ?
                        <Table
                            columns={[
                                { name: "Partenaires", align: "left", width: "30%", field: "partner" },
                                { name: "Statut", align: "left", width: "20%", field: "status" },
                                { name: "Détails du dossier", align: "left", width: "30%", field: "details" },
                                { name: "Actions", align: "center", width: "30%", style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }, field: "actions" },
                                { name: "Voir plus", align: "center", width: "15%", style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }, field: "link" },
                            ]}

                            data={
                                filteredData && filteredData.length > 0 ?
                                    filteredData.filter((partner) => partner.isVerified === true && partner.isPartnerWaiter === false).map((partner, index) => {
                                            return {
                                                partner: partner.name,
                                                status: <Tag text={getStatusName(partner.status)} status={partner.status} />,
                                                details: partner.comment !== "" && partner.comment ? partner.comment : "Aucun commentaire",
                                                actions: (
                                                    <Dot size={"big"} click={() => openModal(index)}>
                                                        <ModalStatus isOpen={isModalOpen[index]} closeModal={() => closeModal(index)} statusActif={partner.status} handleChange={handleStatusChange.bind(this, partner._id)} type={"partner"} />
                                                    </Dot>
                                                ),
                                                link: <Button text={"Voir le dossier"} link={`/partners/${partner._id}`} />
                                            }
                                        }
                                    ).reverse() : []
                            }
                        />
                        :
                        <div className={"container__empty"}>
                            <p className={"text-14"}>Aucun partenaire n'a été ajouté pour le moment</p>
                        </div>
                }
            </div>
        </div>
    );
}

export default Partners;