import React from 'react';
import FormSectionRow from "../../../components/form/formSectionRow";
import Input from "../../../components/form/inputText/input";
import Checkbox from "../../../components/form/inputCheckbox/checkbox";
const LastMinute = ({isLastMinute, setOffer, isDisabled}) => {

    return(
        <FormSectionRow title={"Offre de dernière minute"}
                        description={"Créer une offre de dernière minute pour attirer plus de clients rapidement"}>
            <div className="form__col__modalities">
                <div className="form__col checkbox">
                    <input type="checkbox"
                           name={"isLastMinute"}
                           disabled={isDisabled}
                           id={"isLastMinute"}
                           checked={isLastMinute}
                           onChange={() => {
                               setOffer('isLastMinute.value', !isLastMinute);
                           }}/>
                </div>
            </div>
        </FormSectionRow>
);
}

export default LastMinute;