import React, {useState} from "react";
import {useParams} from "react-router";
import HeaderView from "../../components/header/headerView";
import "../../styles/views/bookings.css"
import searchGrey from "../../assets/icones/global/search-grey.svg";
import calendar from "../../assets/icones/global/calendar.svg";
import IconButton from "../../components/button/iconButton";
import InputSelect from "../../components/form/inputSelect/inputSelect";
import Input from "../../components/form/inputText/input";
import Button from "../../components/button/button";
import HelpCard from "../../components/card/helpCard";
import {useDispatch, useSelector} from "react-redux";
import BookingPartnersServices from "../../api/services/bookingPartners";
import {userPartnerUpdateBooking} from "../../redux/slice/authSlice";
import {useNavigate} from "react-router-dom";
import {useStripeAccountToken} from "../../hooks/stripe/useStripeToken";
import {usePartner} from "../../context/partnerContext";
import {useLoader} from "../../context/loaderContext";
import ModalErrorEngineID from "../../components/modal/modalInfos/modalErrorEngineID";


const NoBooking = () => {

    const { partnerId } = useParams();
    const { setLoading } = useLoader();
    const { createAccountToken } = useStripeAccountToken();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [bookingSelected, setBookingSelected] = useState(null);
    const bookingAPI = [
        {id: 1, name: "Bookun"},
        {id: 2, name: "4escape"},
        {id: 3, name: "Guidap"}
    ]


    const { currentPartnerId } = usePartner();
    const user = useSelector(state => state.auth.user);
    const currentPartner = useSelector((state) => state.auth.user.partnerID.filter((partner) => partner._id === currentPartnerId)[0] || null);

    const [isStripeForm, setIsStripeForm] = useState(false);
    const [openModal, setOpenModal] = useState(false);


    const handleNoBooking = () => {
        setLoading(true);
        if(!currentPartner.engineID || currentPartner.engineID.bookingEmail === "") {
            setOpenModal(true)
        }else{
            if (currentPartner ) {
                createAccountToken(currentPartner);
            }
        }
    }

    return (
        <div className={"main-container is__grey"}>
            <HeaderView title={`Vos réservations`} isGoBack={false} />

            <div className={"main-booking__container is__center"}>

                <div className={"booking_container__column padding-80 is__center width-60"}>
                    {
                        !isStripeForm ?
                            <>
                                <img src={searchGrey} width={55} height={55}/>
                                <p className={"title-20"}>Sélectionner le moyen de réservation</p>
                                <p className={"text-16 light"}>Veuillez sélectionner votre système de réservation actuel afin de
                                    recevoir des réservations correspondant à vos disponibilités.</p>
                                <div className={"container__buttons"}>
                                    <IconButton icon={calendar} text={"Rejoindre le système de réservation Frimity"} isPrimary={true}
                                                onClick={handleNoBooking}/>

                                    {/*<InputSelect
                                            name={"bookingAPI"}
                                            options={bookingAPI}
                                            valueSelected={bookingSelected}
                                            placeholder={"Recherchez votre logiciel de réservation"}
                                            onChange={(e) => setBookingSelected(e.target.value)}
                                        />*/}
                                </div>
                            </>
                            :
                            <div className={"booking_input_api"}>

                            </div>
                    }

                </div>



                {/*
                <div className={"separation-line"}/>

                <div className={"booking_container__column not-center"}>
                    <p className={"title-18"}>Votre logiciel de réservation n'est pas dans la liste ?</p>
                    <p className={"text-14 light"}>Vous pouvez nous soumettre votre logiciel de réservation en nous envoyant le lien de celui-ci. Notre équipe vous recontactera au plus vite afin de vous informez si votre logiciel est compatible avec notre système.
                        Dans le cas échéant, nous vous inviterons à passer par notre système interne de réservation</p>

                    <div className={"booking_input_api"}>
                        <Input
                            label={"Logiciel de réservation"}
                            name={"bookingAPI"}
                            placeholder={"www.4escape.io"}
                            valueInput={null}
                            onChange={(e) => setBookingSelected(e.target.value)}
                            subtitle={"Recherchez votre logiciel de réservation"}
                            isColumn={true}
                        />
                        <Button isPrimary={true} text={"Envoyer"}/>
                    </div>

                </div>*/}

            </div>

            <HelpCard
                title={"Vous avez besoin d'aide avec votre logiciel de réservation ?"}
                text={"Si vous avez la moindre question ou besoin d'aide, n'hésitez pas à nous contacter. Nous sommes disponibles 24/7 pour vous offrir le support dont vous avez besoin."}/>

            <ModalErrorEngineID isOpen={openModal} setModalOpen={setOpenModal} />
        </div>
    )
};

export default NoBooking;