import {getRequest, postRequestJson, patchRequest, deleteRequest} from '../request/apiRequest';


const ActivitiesTagsServices = {

    getActivitiesTags: async () => {
        return await getRequest('/activity/tags');
    }
}

export default ActivitiesTagsServices;