import React from 'react';
import '../../styles/components/tag.css';
const Tag = ({text, status}) => {
    return (
        <div className={`tag ${status}`}>
            <p className={"title-14"}>{text}</p>
        </div>
    );
}

export default Tag;