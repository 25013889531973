import error from "../../../assets/images/svg/error_icon.svg";
import Button from "../../../components/button/button";
import React from "react";
import {useParams} from "react-router-dom";
import {useLoader} from "../../../context/loaderContext";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import {usePartner} from "../../../context/partnerContext";
import {useStripeAccountToken} from "../../../hooks/stripe/useStripeToken";
import '../../../styles/views/payments.css';


const BookingStripeError = () => {
    const { partnerId } = useParams();
    const { setLoading } = useLoader();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { currentPartnerId } = usePartner();
    const { createAccountToken } = useStripeAccountToken();
    const user = useSelector(state => state.auth.user);
    const currentPartner = useSelector((state) => state.auth.user.partnerID.filter((partner) => partner._id === currentPartnerId)[0] || null);

    const handleConnectAccount = async () => {
        setLoading(true);
        if (currentPartner ) {
            createAccountToken(currentPartner);
        }
    }


    return (
        <div className="main stripeBooking">
            <div className="main__container__full">
                <div className="icon__image">
                    <img src={error} alt="logo"/>
                </div>
                <div className="main__container__content">
                    <h1 className={"title-25"}>Une erreur est survenue...</h1>
                    <p className={"text-16 center"}>Une erreur est survenue lors de la connexion à notre partenaire de paiement Stripe. Veuillez réessayer</p>
                </div>
                <div className="main__container__col32 marginTop">
                    <Button text={"Réessayer"} isPrimary={true} isBig={true} onClick={() => handleConnectAccount()}/>
                </div>
            </div>
        </div>
    )
}

export default BookingStripeError;