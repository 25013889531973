import { useState, useEffect, useRef, useContext } from 'react';
import ActivitiesImages from "../../api/services/activitiesImages";
import { ErrorContext } from "../../context/errorContext";

function useImagesHandler({ imagesFiles, onChange, type }) {
    const [images, setImages] = useState(imagesFiles || []);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isLoading, setIsLoading] = useState(type === "remote"); // Initialize loading state based on type
    const hiddenInputRef = useRef(null);
    const { showError } = useContext(ErrorContext);

    // Helper function to convert bytes to KB
    const bytesToKB = (bytes) => (bytes / 1024).toFixed(0);

    // Function to create an image object from a File/Blob
    const createImageObject = (image, src, isLocal = false) => {
        return {
            name: image.name || image.metadata?.originalName,
            size: bytesToKB(image.size || image.metadata?.size),
            type: image.type || 'image/*',
            src: src || image.url,
            preview: src || image.url,
            isLocal: isLocal
        };
    };

    // Handler for file input change
    const handleChange = (e) => {
        setIsLoading(true); // Set loading state to true
        const newLocalImages = Array.from(e.target.files).filter(file => {
            // Check if the file name already exists in the current state
            const isDuplicate = images.some(image => image.name === file.name);
            if (isDuplicate) {
                alert('Le fichier existe déjà, vous ne pouvez pas ajouter le même fichier.');
                setIsLoading(false); // Reset loading state if duplicate
                return false; // Exclude this file
            }
            return true; // Include this file
        }).filter(file => {
            // Check if the file size exceeds 400KB
            if (file.size > 400 * 1024) {
                alert('Le fichier est trop volumineux, veuillez sélectionner un fichier de moins de 400KB.');
                setIsLoading(false); // Reset loading state if too large
                return false; // Exclude this file
            }
            return true;
        }).map(imageFile => {
            // Create a URL for the new local image
            const src = URL.createObjectURL(imageFile);
            return createImageObject(imageFile, src, true); // Mark as local
        });

        if (newLocalImages.length === 0) {
            setIsLoading(false); // Reset loading state if no valid images
            return;
        }

        // Update state with new images
        setImages(prevImages => {
            const updatedImages = [...prevImages, ...newLocalImages];
            onChange(updatedImages);
            setIsLoading(false); // Reset loading state after images are updated
            return updatedImages;
        });

        resetInput();
    };

    // Effect for initializing local images
    useEffect(() => {
        if (!isInitialized && Array.isArray(imagesFiles) && imagesFiles.length > 0 && type === "local") {
            const initialImages = imagesFiles.map(file =>
                createImageObject(file, file.src || file.preview, !file.metadata)
            );
            setImages(initialImages);
            setIsInitialized(true); // Mark as initialized to prevent reinitialization
        }
    }, [imagesFiles, type]);

    // Effect for initializing remote images
    useEffect(() => {
        if (!isInitialized && Array.isArray(imagesFiles) && imagesFiles.length > 0 && type === "remote") {
            const initialImages = imagesFiles.map(file =>
                createImageObject(file, file.url, !file.metadata)
            );
            setImages(initialImages);
            setIsLoading(false); // Reset loading state after remote images are loaded
            setIsInitialized(true); // Set the flag to true after loading images
        }

        setTimeout(() => {
            setIsLoading(false);
            setIsInitialized(true);
        }
        , 2500);

    }, [imagesFiles, type]);

    // Function to reset the file input value
    const resetInput = () => {
        hiddenInputRef.current.value = '';
    };

    // Function to delete an image
    const deleteImage = (activityId, index, partnerId) => {
        setImages(prevImages => {
            const newImages = prevImages.filter((_, i) => i !== index);

            // If the image to delete is local, we can just update the state
            if (prevImages[index].isLocal) {
                onChange(newImages);
                return newImages;
            } else {
                // For remote images, call the API to delete and then update the state
                ActivitiesImages.delete(activityId, prevImages[index].name, partnerId)
                    .then((response) => {
                        if (response.status === 200) {
                            setImages(newImages);
                            onChange(newImages);
                        }
                    })
                    .catch((error) => {
                        console.error('Error deleting remote images:', error);
                    });
                // Return newImages immediately, as the delete operation is asynchronous
                return newImages;
            }
        });
    };

    return { images, handleChange, deleteImage, hiddenInputRef, resetInput, isLoading };
}

export default useImagesHandler;
