import React, {useState} from 'react';
import Radio from "../../form/inputRadio/radio";
import '../../../styles/components/modal.css';
import Tag from "../../tag/tag";
import useStatusName from "../../../hooks/parseText/useStatusName";
import close from '../../../assets/icones/global/close-stroke.svg';
import Button from "../../button/button";
import { useNavigate } from 'react-router-dom';

const ModalVerifiedStatus = ({isOpen, statusActif, closeModal, handleChange, type}) => {

    const statusListPartner = ["active", "lost", "pending", "review"];
    const statusListActivity = ["active", "error", "waiting"];
    const statusListOffer = ["active", "inactive", "ended", "waiting"];
    const [status, setStatus] = useState(statusActif);

    const {getStatusName} = useStatusName();

    const handleStatusChange = (status) => {
        setStatus(status);
    }

    const handleValidate = () => {
        handleChange(status);
        closeModal();
    }

    return(
        <div className={`bg__modal ${isOpen ? "open" : ""}`}>
            <div className={`modal verifiedStatus ${isOpen ? "open" : ""}`}>
                <div className={"modal__header"}>
                    <p className={"text-16"}>{
                        type === "partner" ? "Status du partenaire" :
                            type === "activity" ? "Status de l'activité" :
                                type === "offer" ? "Status de l'offre" : null
                    }</p>
                    <div className={"modal__header__actions"}>
                        <p className={"title-14"} onClick={closeModal}>
                            <img src={close} alt={"close"}/>
                        </p>
                    </div>
                </div>
                <div className={"modal__content"}>
                    {
                        type === "partner" ?
                            statusListPartner.map((statusItem) => {
                                return (
                                    <div className={"modal__content__row"}>
                                        <input type="checkbox" name={statusItem} id={statusItem}
                                               value={statusItem}
                                               onChange={(e) => {
                                                   handleStatusChange(statusItem)
                                               }} checked={status === statusItem}/>
                                        <Tag text={getStatusName(statusItem)} status={statusItem}/>
                                    </div>
                                )
                            })
                            : type === "offer" ?
                                statusListOffer.map((statusItem) => {
                                    return (
                                        <div className={"modal__content__row"}>
                                            <input type="checkbox" name={statusItem} id={statusItem}
                                                   value={statusItem}
                                                   onChange={(e) => {
                                                       handleStatusChange(statusItem)
                                                   }} checked={status === statusItem}/>

                                            <Tag text={getStatusName(statusItem)} status={statusItem}/>
                                        </div>
                                    )
                                })
                            : type === "activity" ?
                                statusListActivity.map((statusItem) => {
                                    return (
                                        <div className={"modal__content__row"}>
                                            <input type="checkbox" name={statusItem} id={statusItem}
                                                   value={statusItem}
                                                   onChange={(e) => {
                                                       handleStatusChange(statusItem)
                                                   }} checked={status === statusItem}/>
                                            <Tag text={getStatusName(statusItem)} status={statusItem}/>
                                        </div>
                                    )
                                })
                            : null

                    }
                    <div className={"modal__content__actions"}>
                        <Button text={"Valider"} isPrimary={true} onClick={handleValidate}/>
                    </div>
                            </div>
                    </div>
                </div>
    )
}

export default ModalVerifiedStatus;
