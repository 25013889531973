import React, {useContext, useEffect, useState} from "react";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import add from "../../../../assets/icones/global/add.svg";
import SubmitButton from "../../../../components/button/submitButton";
import '../../../../styles/views/stripe-payement.css';
import PayementServices from "../../../../api/services/payement";
import PayementCard from "../../../../components/stripe/payementCard";
import Button from "../../../../components/button/button";
import UserSubscriptionServices from "../../../../api/services/stripeSubscription";
import {useNavigate} from "react-router-dom";
import {ErrorContext} from "../../../../context/errorContext";
import {useLoader} from "../../../../context/loaderContext";

const CheckoutForm = ({ customerId, productPrice, partnerID, setReload, defaultCard, subscription}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [savedMethods, setSavedMethods] = useState([]);
    const [error, setError] = useState(null);
    const [isFormVisible, setIsFormVisible] = useState(true);
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const [isSubscriptionChecked, setIsSubscriptionChecked] = useState(false);
    const [errorCheckbox, setErrorCheckbox] = useState(false);


    const navigate = useNavigate();

    useEffect(() => {
        // Make sure to include the 'customerId' in your component's props
        // Fetch saved payment methods from your API
        setLoading(true);
        const data = {
            customerId: customerId
        }
        PayementServices.getSavedPaymentMethods(data)
            .then((res) => {
                if(res.data.data.length !== 0){
                    setIsFormVisible(false);
                }
                setSavedMethods(res.data.data);
                setReload(true);
            })
            .catch((error) => {
                showError(error);
            }).finally(() => {
                setLoading(false);
            })
    }, [customerId]); // Ensure customerId is passed to the component

    const handleSubmitNewMethod = async (event) => {
        event.preventDefault();
        setError(null); // Clear any previous errors
        setLoading(true);

        const { error, setupIntent } = await stripe.confirmSetup({
            elements,
            redirect: 'if_required'
        });

        if (error) {
            setError(error.message);
            setLoading(false);
        }else{
            if (setupIntent.status === "succeeded") {
                PayementServices.attachPaymentMethod({ customerId, paymentMethodId: setupIntent.payment_method })
                .then((res) => {
                    if(res.status === 200){
                        const data = {
                            customerId: customerId
                        }
                        PayementServices.getSavedPaymentMethods(data)
                        .then((res) => {
                            setSavedMethods(res.data.data);
                            setReload(true);
                            setIsFormVisible(false);
                        })
                        .catch((error) => {
                            showError(error);
                        }).finally(() => {
                            setLoading(false);
                        });
                    }
                })
                .catch((error) => {
                    showError(error);
                }).finally(() => {
                    setLoading(false);
                });
            }
        }
    };

    const handleSubscription = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!isSubscriptionChecked) {
            setErrorCheckbox(true);
            setLoading(false);
            return;
        }else {
            UserSubscriptionServices.createSubscription({
                customerId: customerId,
                priceId: productPrice,
                partnerID: partnerID
            })
                .then((res) => {
                    if (res.status === 200) {
                        const url = window.location.pathname;
                        if (url.includes("/partner/subscription")) {
                            navigate("/partner/subscription/confirmation")
                        } else {
                            navigate("/stripe-payement/confirmation");
                        }
                    } else {
                        setError("Une erreur est survenue veuillez réessayer");
                        setLoading(false);
                    }
                }).catch((err) => {
                showError(error);
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    return (
        <div className={"payment-methods"}>
            <div className={"payment-methods__container__payement"}>
                <form id="payment-form" onSubmit={handleSubmitNewMethod} className={!isFormVisible ? "hidden" : ""}>
                    <PaymentElement id="payment-element" />
                    <SubmitButton text={"Ajouter cette carte"} isPrimary={true} disabled={setLoading}/>
                    {error && <div className="error">{error}</div>}
                </form>
                {
                    !isFormVisible &&

                        <div className={"payment-methods__container"}>
                            {
                                savedMethods.length !== 0 && savedMethods.map((method) => (
                                    <PayementCard
                                        method={method}
                                        defaultMethod={method.id === defaultCard}
                                        setReload={setReload}
                                        customerId={customerId}
                                    />
                                ))
                            }
                        </div>
                }
                {
                    !isFormVisible ?
                        <div className={"payment-methods__col"}>
                            <div className={"payment-methods__row actions"} onClick={() => setIsFormVisible(true)}>
                                <img src={add} alt="add"/>
                                <p className={"text-16 light"}>Ajouter un nouveau mode de paiement</p>
                            </div>

                            { subscription.name !== 'essentiel' ? <p className={"text-15 bold"}>Démarrer mon essai offert de 3 mois, puis payer {subscription.price} €/mois pendant {subscription.engagement} mois.</p> : null }

                            <div className={"form__row__input__container__radio form__col  form__signin__col_payment"}>
                                <div className="form__row checkbox">
                                    <input type="checkbox"
                                           name={"subscription"}
                                           id={"subscription"} checked={isSubscriptionChecked}
                                           onChange={(e) => setIsSubscriptionChecked(!isSubscriptionChecked)}
                                    />
                                    <div className={"form__col__radio"}>
                                        <p className={"text-12 light grey"}>En cochant cette case, je reconnais avoir
                                            lu, compris et accepté les Conditions Générales d'Abonnement de Frimity. Je
                                            m'engage à respecter toutes les termes et conditions énoncées dans ce
                                            document pour l'utilisation des services et produits de Frimity</p>
                                    </div>
                                </div>

                                {errorCheckbox &&
                                    <p className={"error-message"}>Veuillez accepter les conditions générales
                                        d'abonnement de Frimity</p>}
                            </div>


                            <Button text={ subscription.name !== 'essentiel' ? "Démarrer mon essai offert" : "Devenir partenaire"} isPrimary={true} onClick={handleSubscription} isFull={true}/>

                        </div>
                        : null
                }
            </div>
        </div>

    );
};

export default CheckoutForm;
