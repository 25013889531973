import React, {useContext, useEffect, useState} from 'react';
import FormSectionRow from "../../../components/form/formSectionRow";
import RadioMultiple from "../../../components/form/inputRadio/radioMultiple";
import ActivitiesCategoriesServices from "../../../api/services/activitiesCategories";
import useParseCategories from "../../../hooks/parseText/useParseCategories";
import {ErrorContext} from "../../../context/errorContext";
import {useLoader} from "../../../context/loaderContext";

const Category = ({ category, setActivity, isDisabled = false }) => {
    const [categoryList, setCategoryList] = useState([]);
    const [indoorOutdoorCategory, setIndoorOutdoorCategory] = useState(false);
    const [otherCategory, setOtherCategory] = useState(false);
    const { parseCategories } = useParseCategories();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();


    useEffect(() => {
        setLoading(true);
        ActivitiesCategoriesServices.getActivitiesCategories()
            .then((response) => {
                if (response.status === 200) {
                    setCategoryList(response.data);
                }
            })
            .catch((error) => {
                showError(error);
            }).finally(() => {
            setLoading(false);
        })
    }, []);

    const handleIndoorOutdoorCategoryChange = (event) => {
        const value = event.target.value;
        setIndoorOutdoorCategory([value]); // Only one selection allowed
        setActivity('category', [value, ...otherCategory]);
    };

    const handleOtherCategoryChange = (event) => {
        const value = event.target.value;
        setOtherCategory([value]); // Only one selection allowed
        setActivity('category', [value, ...indoorOutdoorCategory]);
    };

    useEffect(() => {
        setIndoorOutdoorCategory(category.filter((item) => item === "indoor" || item === "outdoor"));
        setOtherCategory(category.filter((item) => item !== "indoor" && item !== "outdoor"));
    }, [category]);


    return indoorOutdoorCategory && otherCategory && (
        <FormSectionRow title={"Catégorie de l’activité"}
                        description={"Vous devez avoir coché obligatoirement en intérieur ou en extérieur + un tag de filtrage"}
                        classname={"category"} isRequired={true}>
            <div className={"form__container__col category__col"}>
                <p className={"text-15"}>Déroulement de l’activité :</p>
                <div className={"form__container__col grid"}>
                    {
                        categoryList.filter((item) => item.name === "indoor" || item.name === "outdoor").map((item) => (
                            <RadioMultiple
                                key={item.id}
                                name="category"
                                value={item.name}
                                onChange={handleIndoorOutdoorCategoryChange}
                                label={parseCategories(item.name)}
                                isChecked={indoorOutdoorCategory.includes(item.name)}
                                errorEmpty={indoorOutdoorCategory.error}
                                disabled={isDisabled}
                            />
                        ))
                    }
                </div>
                <p className={"text-14 light"}>Sélectionner 1 catégorie maximum</p>
            </div>
            <div className={"form__container__col category__col"}>
                <p className={"text-16"}>Champs d'expérience : </p>
                <div className={"form__container__col grid"}>
                    {
                        categoryList.filter((item) => item.name !== "indoor" && item.name !== "outdoor").map((item) => (
                            <RadioMultiple
                                key={item.id}
                                name="category"
                                value={item.name}
                                onChange={handleOtherCategoryChange}
                                label={parseCategories(item.name)}
                                isChecked={otherCategory.includes(item.name)}
                                errorEmpty={otherCategory.error}
                                disabled={isDisabled}
                            />
                        ))}
                </div>
                <p className={"text-14 light"}>Sélectionner 1 catégorie maximum</p>
            </div>
        </FormSectionRow>
    );
}

export default Category;
