import React, { useState, useEffect } from "react";

const Radio = ({ label, name, value, icon = false, checked = "", onChange, errorEmpty = false, errorMessage = false, disabled = false, className }) => {


    return (
        <div className={`radio-container ${checked ? "checked" : ""} ${disabled ? "disabled" : ""} ${errorEmpty ||  errorMessage ? "error" : ""} ${className}`}>
            {/* custom input radio */}
            <input
                type="radio"
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
                disabled={disabled}
                readOnly={disabled}

            />
            {icon ? <img src={icon} alt={"icon"} /> : null}
            <label>{label}</label>
        </div>
    );
};

export default Radio;
