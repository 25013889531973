
import { getRequest, postRequestJson, patchRequest, deleteRequest } from '../request/apiRequest';

const BookingInvoicesService = {

    generateProofOfBookingUser: async (bookingIdentifier, bookingAppId) => {
        return await postRequestJson(`/booking-invoices/generate-proof-of-booking-user/${bookingIdentifier}/${bookingAppId}`);
    }
}

export default BookingInvoicesService;