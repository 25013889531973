import React from 'react';
import FormSection from "../../../components/form/formSection";
import InputFiles from "../../../components/form/inputFile/inputFiles";

const LegalDocument = ({documents, setPartner, type, partnerID = ""}) => {

    const handleChange = (event) => {
        setPartner(`legalDocuments`, event);
    };


    return(
        <FormSection title={"Documents légaux"}>
            <InputFiles
                label={"Vous devez renseigner tous les documents et fichiers qui concernent votre établissement"}
                subtitle={
                    <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                        <p className={"text-14 light"}> • Envoyer un justificatif d'identité (CNI ou passeport) en cours de validité</p>
                        <p className={"text-14 light"}> • Une copie de votre KBIS de moins de 3 mois</p>
                        <p className={"text-14 light"}> • Une copie des derniers statuts signés</p>
                    </div>
                }
                name={"legalDocuments"}
                onChange={handleChange}
                documents={documents}
                type={type}
                partnerId={partnerID}
            />

        </FormSection>
    )
}

export default LegalDocument;