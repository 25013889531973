import {getRequest, postRequestJson, patchRequest, deleteRequest} from '../request/apiRequest';

const OffersServices = {

    getOffers: async () => {
        return await getRequest('/partners/offers/getAll');
    },

    getOfferById: async (offerId) => {
        return await getRequest(`/partners/offers/${offerId}`);
    },

    getOffersByPartnerId: async (partnerId) => {
        return await getRequest(`/partners/offers/${partnerId}/verified`);
    },

    createOffer: async (partnerId, data) => {
        return await postRequestJson(`/partners/offers/${partnerId}`, data);
    },

    updateOffer: async (offerId, data) => {
        return await patchRequest(`/partners/offers/${offerId}`, data);
    },

    deleteOffer: async (partnerId, offerId) => {
        return await deleteRequest(`/partners/offers/${partnerId}/${offerId}`);
    }

}

export default OffersServices;