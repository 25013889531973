import React from "react";
import close from "../../assets/icones/global/close-stroke.svg";
import Button from "../button/button";

const ModalImagePreview = ({isOpen, closeModal, image}) => {

    return(
        <div className={`bg__modal imageBg ${isOpen ? "open" : ""}`}>
            <div className={`modal imagePreview ${isOpen ? "open" : ""}`}>
                <div className={"modal__content"}>
                    <div className={"modal__content__image"}>
                        <img src={image} alt={"image"}/>
                    </div>
                    <div className={"modal__content__actions"}>
                        <Button text={"Fermer"} isPrimary={true} onClick={closeModal} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalImagePreview;