import React, {useState} from 'react';
import '../../styles/components/toggle.css';
import UsersServices from "../../api/services/users";
import {useDispatch} from "react-redux";
import {userUpdateCommunication} from "../../redux/slice/authSlice";

const Toggle = ({text, isToggle = false, user}) => {
    const [isToggled, setIsToggled] = useState(isToggle);
    const dispatch = useDispatch();


    const onToggle = (name) => {
        setIsToggled(!isToggled);

        const data = {
            communication: {
                ...user.communication,
                [name]: !user.communication[name]
            }
        };

        UsersServices.updateUser(user._id, data)
        .then(res => {
            dispatch(userUpdateCommunication(res.data.communication));
        }).catch(err => {
            console.log(err);
        });
    };

    return (
        <div className={"toggle-container"}>
            <div className="toggle">
                <input
                    type="checkbox"
                    className="toggle-checkbox"
                    name={text.toLowerCase()}
                    checked={isToggled}
                    onChange={() => onToggle(text.toLowerCase())}
                    aria-label="Toggle Switch"
                />
                <div className={`toggle-bg ${isToggled ? "active" : ""}`}>
                    <div className="toggle-btn">
                        <div className="toggle-circle"></div>
                    </div>
                </div>
            </div>
            <p className={"text-16"}>{text}</p>
        </div>
    );
}
export default Toggle;