import useValidations from './useInputValidation';

const useFormValidation = () => {

    const { validateInput } = useValidations();

    const validateField = (field, isRequired = false) => {
        if (!field || typeof field !== 'object' || !field.hasOwnProperty('value')) {
            // Debugging log
            return field;
        }

        if (!isRequired) {
            return field;
        }

        if (field.validationType) {
            const validationError = validateInput(field.value, field.validationType);
            return {
                ...field,
                errorMessage: validationError !== '' ? validationError : '',
                error: validationError !== '',
            };
        }

        return {
            ...field,
            error: !field.value,
        };
    };


    const validateForm = (formState, setFormState) => {
        let isFormValid = true;
        let newFormState = Array.isArray(formState) ? [] : {};
        const allDays = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

        Object.keys(formState).forEach((key) => {
            const field = formState[key];

            // Check if both fields are numbers and min is less than max
            if (key === 'schedule' && field && field.schedule.value && Array.isArray(field.schedule.value)) {
                const scheduleErrors = field.schedule.value.map(entry => {
                    // If the day is closed, we don't need to check the times
                    if (entry.isClosed) return false;
                    if(field.temporarilyClosed === true) return false;
                    // If the day is not closed, both times must be filled and in correct format
                    const timeFormat = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
                    const hasOpeningTime = entry.openingTime.match(timeFormat);
                    const hasClosingTime = entry.closingTime.match(timeFormat);
                    return !(hasOpeningTime && hasClosingTime);
                });

                const hasError = scheduleErrors.includes(true);
                newFormState[key] = {
                    ...field,
                    error: hasError,
                };

                if (hasError) isFormValid = false;
            }else if (key === "nbPersons" && field && field.nbPersonsMin && field.nbPersonsMax) {
                const min = parseInt(field.nbPersonsMin.value);
                const max = parseInt(field.nbPersonsMax.value);
                const isError = min >= max; // Error if min is not less than maxs

                // Update the form state for nbPersonsMin and nbPersonsMax with the error flag
                newFormState[key] = {
                    ...field,
                    nbPersonsMin: {
                        ...field.nbPersonsMin,
                        error: isError, // Flag error if min is greater than or equal to max
                        errorMessage: isError ? "Le minimum ne peut pas être supérieur ou égal au maximum" : "", // Provide an error message
                    },
                    nbPersonsMax: {
                        ...field.nbPersonsMax,
                        error: isError, // Same error condition applied
                        errorMessage: isError ? "Le maximum doit être supérieur au minimum" : "", // Provide an error message
                    },
                };

                // If there's an error, set isFormValid to false
                if (isError) isFormValid = false;

            } else if (key === 'duration') {
                const duration = parseInt(field.value);

                const isError = duration <= 0; // Error if duration is less than or equal to 0

                // Update the form state for duration with the error flag
                newFormState[key] = {
                    ...field,
                    error: isError, // Flag error if duration is less than or equal to 0
                    errorMessage: isError ? "La durée doit être supérieure à 0" : "", // Provide an error message
                };

                // If there's an error, set isFormValid to false
                if (isError) isFormValid = false;
            } else if (key === 'images' && field && Array.isArray(field.images)) {
                // Validate the images field
                newFormState[key] = {
                    ...field,
                    error: field.isRequired && field.images.length < 3, // Check if the images array has less than 3 items and is required
                };
                if (newFormState[key].error) isFormValid = false;
            } else if (field && typeof field === 'string') {
                const isRequired = field.isRequired;
                newFormState[key] = validateField(field, isRequired);
                if (newFormState[key].error) isFormValid = false;
            } else if (field && Array.isArray(field)) {
                newFormState[key] = field;
            } else if (field && typeof field === 'object' && field.hasOwnProperty('value')) {
                const isRequired = field.isRequired;
                newFormState[key] = validateField(field, isRequired);
                if (newFormState[key].error) isFormValid = false;
            } else if (field && typeof field === 'object' && key !== "images" && key !== "schedule") {
                newFormState[key] = {};
                Object.keys(field).forEach((nestedKey) => {
                    const nestedField = field[nestedKey];
                    const nestedIsRequired = nestedField.isRequired;
                    newFormState[key][nestedKey] = validateField(nestedField, nestedIsRequired);
                    if (newFormState[key][nestedKey].error) isFormValid = false;
                });
            }
        });

        setFormState(newFormState);

        if(!isFormValid) {
            setTimeout(() => {

                console.log('scrolling to first error');

                //check if .form__input.error or .form__row__input.error exists and scroll to the first one
                const firstError = document.querySelector('.form__input.error, .form__row__input.error, .form__col .error, .input__images__container.errorImg, .error');
                if (firstError) {
                    const offset = 400; // Adjust this value based on your needs
                    const bodyRect = document.body.getBoundingClientRect().top;
                    const elementRect = firstError.getBoundingClientRect().top;
                    const elementPosition = elementRect - bodyRect;
                    const offsetPosition = elementPosition - offset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                }
            }, 0); // Delay might need adjustment
        }
        return { isFormValid };
    };

    return {
        validateForm,
    };
};

export default useFormValidation;
