import React, {useState, useEffect, useContext} from 'react';
import mail from '../../../assets/images/svg/mail_round_yellow.svg';
import Button from "../../../components/button/button";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import arrow from '../../../assets/icones/arrows/left.svg';
import '../../../styles/views/verifications.css';
import {useParams} from "react-router";
import InputCode from "../../../components/form/inputText/InputCode";
import VerificationServices from "../../../api/services/verification";
import {useDispatch, useSelector} from "react-redux";
import {setEmailSent} from "../../../redux/slice/signInSlice";
import {ErrorContext} from "../../../context/errorContext";
import {useLoader} from "../../../context/loaderContext";


const VerificationCode = () => {

    const { email: emailUrl, code: codeUrl } = useParams();
    const user = useSelector(state => state.signIn.user);
    const email = user ? user.email : null;
    const [code, setCode] = useState(Array(4).fill(""));
    const [focusIndex, setFocusIndex] = useState(0);
    const {verifyVerificationCode} = VerificationServices;
    const navigate = useNavigate();
    const [emailReSend, setEmailReSend] = useState(false);


    const {sendVerificationCode} = VerificationServices;
    const dispatch = useDispatch();

    const {showError} = useContext(ErrorContext);
    const {setLoading} = useLoader();


    useEffect(() => {
        if (emailUrl && codeUrl) {
            // Automatically submit the verification if email and code are present
            autoSubmitVerification(emailUrl, codeUrl);
        }
    }, [emailUrl, codeUrl]);

    const handleChange = (e, index) => {
        const inputCode = e.target.value;
        setCode((prevState) => {
            const newState = [...prevState];
            newState[index] = inputCode;
            return newState;
        });
        if (inputCode && index < 3) {
            setFocusIndex(index + 1);
        }
    };

    const handleNewEmail = () => {
        const data = {
            email: user.email,
            name: user.firstname,
            isSignIn: true
        };

        sendVerificationCode(data)
        .then(res => {
            dispatch(setEmailSent());
            setEmailReSend(true);
        })
        .catch(err => {
            showError(err);
        });
    }

    useEffect(() => {
        const inputRefs = Array.from({ length: 4 }, () => React.createRef());
        const handleKeyPress = (event) => {
            if (event.key >= 0 && event.key <= 9) {
                const index = parseInt(event.key);
                if (focusIndex < 4) {
                    setCode((prevState) => {
                        const newState = [...prevState];
                        newState[focusIndex] = index.toString();
                        return newState;
                    });
                    setFocusIndex(focusIndex + 1);
                }
            }
        };
        window.addEventListener("keypress", handleKeyPress);
        return () => {
            window.removeEventListener("keypress", handleKeyPress);
        };
    }, [focusIndex]);

    const handleSubmit = () => {
        const codeString = code.join("");
        const data = {
            email: email,
            code: codeString
        }
        verifyVerificationCode(data)
        .then((response) => {
            if (response.status === 200 || response.status === 201){
                navigate('/signin/verification/check/');
            }
        }).catch((error) => {
            showError(error);
        });
    }

    const autoSubmitVerification = (email, code) => {
        const data = {
            email: email,
            code: code
        }
        verifyVerificationCode(data)
        .then(response => {
            if (response.status === 200 || response.status === 201){
                navigate('/signin/verification/check/');
            }
        })
        .catch(error => {
            showError(error);
        });
    };

    return (
        <div className="main verification">
            <div className="main__container__col">
                <div className="main__container__col32">
                    <img src={mail} alt="logo"/>
                </div>
                <div className="main__container__col32">
                    <h1 className={"title-25"}>Vérifiez votre email</h1>
                    <p className={"text-14 light"}>Nous avons envoyé un lien de vérification à {email}</p>
                </div>

                <div className="main__container__col32 digit_code">
                    {
                        code.map((value, index) => (
                            <InputCode
                                key={index}
                                onChange={handleChange}
                                index={index}
                                code={code[index]}
                                focusNextInput={focusIndex === index}
                            />
                        ))
                    }
                </div>

                <div className="main__container__col32">
                    <Button text={"Vérifier"} isPrimary={true} isBig={true} onClick={handleSubmit} />
                </div>
                <div className="main__container__col32">
                    {
                        emailReSend ?
                            <p className={"text-14 light"}>Un nouvel email a été envoyé à {email}</p>
                            :
                            <p className={"text-14 light pointer"} onClick={handleNewEmail}>Vous n'avez pas reçu l'email ? <br/>Cliquez pour renvoyer</p>
                    }
                </div>
                <div className="main__container__col32  action_row">
                    <img src={arrow} alt="arrow"/>
                    <Link to={"/signin"} className={"text-14 bold"}>Retour à l'inscription</Link>
                </div>
            </div>
        </div>
    );
}

export default VerificationCode;