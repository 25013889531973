import Input from "../../../../components/form/inputText/input";
import SubmitButton from "../../../../components/button/submitButton";
import React from "react";
import Radio from "../../../../components/form/inputRadio/radio";
import RadioMultiple from "../../../../components/form/inputRadio/radioMultiple";
import RadioRound from "../../../../components/form/inputRadio/radioRound";
import '../../../../styles/components/form/form.css';
import {Link} from "react-router-dom";

const Inscription = ({handleChangeForm, handleSubmit, error, errorEmpty, isUsageChecked, setIsUsageChecked, isConfidentialityChecked, setIsConfidentialityChecked, errorCheckbox}) => {

    return (
        <div className={"main__container__form__col"}>
            <div className={"main__container__form__col__title"}>
                <h1 className={"title-25"}>S’inscrire</h1>
            </div>

            <form className={"form__signin"} onSubmit={handleSubmit}>
                <div className={"form__signin__row"}>
                    <Input
                        label={"Nom"}
                        name={"lastname"}
                        placeholder={"Entrer votre nom"}
                        type={"text"}
                        validationType={"text"}
                        isColumn={true}
                        onChangeForm={handleChangeForm}
                        errorEmpty={errorEmpty}
                        isRequired={true}
                    />
                    <Input
                        label={"Prénom"}
                        name={"firstname"}
                        placeholder={"Entrer votre prénom"}
                        type={"text"}
                        validationType={"text"}
                        isColumn={true}
                        onChangeForm={handleChangeForm}
                        errorEmpty={errorEmpty}
                        isRequired={true}
                    />
                </div>
                <Input
                    label={"Email"}
                    name={"email"}
                    placeholder={"Entrer votre email"}
                    type={"email"}
                    validationType={"email"}
                    isColumn={true}
                    onChangeForm={handleChangeForm}
                    errorEmpty={errorEmpty}
                    isRequired={true}
                />
                <div className={"form__signin__row"}>
                    <Input
                        label={"Mot de passe"}
                        name={"password"}
                        placeholder={"Entrer votre mot de passe"}
                        type={"text"}
                        isColumn={true}
                        validationType={"password"}
                        onChangeForm={handleChangeForm}
                        errorEmpty={errorEmpty}
                        isRequired={true}
                    />
                    <Input
                        label={"Confirmer le mot de passe"}
                        name={"passwordConfirm"}
                        placeholder={"Confirmer votre mot de passe"}
                        type={"text"}
                        validationType={"password"}
                        isColumn={true}
                        onChangeForm={handleChangeForm}
                        errorEmpty={errorEmpty}
                        isRequired={true}
                    />
                </div>

                <div className={"form__row__input__container__radio form__col  form__signin__col"}>
                    <div className="form__row checkbox">
                        <input type="checkbox"
                               name={"usage"}
                               id={"usage"} checked={isUsageChecked}
                               onChange={(e) => setIsUsageChecked(!isUsageChecked)}
                        />
                        <div className={"form__col__radio"}>
                            <p className={"text-15 light grey"}>En cochant cette case, vous confirmez avoir lu, compris
                                et accepté les présentes <a href={"/usage-policy"} className={"underline"}>Conditions
                                    d'utilisation</a></p>
                        </div>
                    </div>

                    <div className="form__row checkbox">
                        <input type="checkbox"
                               name={"confidentiality"}
                               id={"confidentiality"} checked={isConfidentialityChecked}
                               onChange={(e) => setIsConfidentialityChecked(!isConfidentialityChecked)}
                        />
                        <div className={"form__col__radio"}>
                            <p className={"text-15 light grey"}>En cochant cette case, vous confirmez avoir lu, compris
                                et accepté les présentes <a href={"/privacy-policy"} className={"underline"}>Politique
                                    de confidentialité</a></p>
                        </div>
                    </div>

                    {errorCheckbox &&
                        <p className={"is__error"}>Veuillez accepter les conditions générales d'utilisation et la
                            politique de confidentialité</p>}
                </div>


                <SubmitButton text={"Suivant"} isFull={true} type={"submit"}/>
            </form>

            <div className={"main__container__form__col__footer"}>
                <p className={"text-14"}>Vous avez déjà un compte ? <Link
                    to={"/login"}>Se connecter</Link>
                </p>
            </div>
        </div>
    )
}

export default Inscription;