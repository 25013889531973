import React from 'react';
import '../../../styles/components/form/form.css';
import useInputValidation from "../../../hooks/form/useInputValidation";

const InputIcon = ({type, label, name, placeholder, icon, title = false, valueInput, onChangeForm, disabled = false, errorEmpty = false, errorMessage = false, isRow = false, validationType, onInputFocus = () => {}}) => {

    const { value, error, onChange } = useInputValidation('', validationType);
    const handleChange = (event) => {
        onChange(event);
        onChangeForm(event);
    }

    return !isRow ? (
            <div className="form__col">
                {
                    title ? <p className={"text-14"}>{title}</p> : null
                }
                <div className={`form__col__icon`}>
                    <div className={`form__row__input ${disabled ? "disabled": ''} ${errorEmpty || errorMessage? 'error' : ''} ${error ? 'error' : ''}`}>
                        <div className={`form__row__icon`}>
                            <img src={icon} alt={icon}/>
                        </div>
                        <input type={type} className={`form__input__icon`} name={name} id={name}
                                 placeholder={errorEmpty ? "Remplissez ce champ" : placeholder}
                               value={valueInput ? valueInput : value} onChange={handleChange} disabled={disabled}
                               onFocus={onInputFocus}
                        />
                    </div>
                    {
                        errorMessage ? <div className="error-message">{errorMessage}</div> :
                            error ? <div className="error-message">{error}</div>
                                : null
                    }
                </div>
            </div>
        )
        :
        (
            <div className="form__row">
            {
                label ? <label htmlFor={name} className="form__row__label">{label}</label> : null
            }
            <div className={`form__col__icon`}>
                <div className={`form__row__input ${disabled ? "disabled": ''} ${errorEmpty || errorMessage? 'error' : ''} ${error ? 'error' : ''}`}>
                    <div className={`form__row__icon`}>
                        <img src={icon} alt={icon}/>
                    </div>
                    <input type={type} className={`form__input__icon`}
                           name={name} id={name} placeholder={errorEmpty ? "Remplissez ce champ" : placeholder}
                           value={valueInput ? valueInput : value} onChange={handleChange} disabled={disabled}
                            onFocus={onInputFocus}
                    />
                </div>
                {
                    errorMessage ? <div className="error-message">{errorMessage}</div> :
                    error ? <div className="error-message">{error}</div>
                    : null
                }
            </div>

        </div>
    )

}

export default InputIcon;