import React from 'react';
import pending from '../assets/images/svg/illustration_search.svg';
const Pending = () => {
    return(
        <div className={"main-container center"}>
            <div className={"center__container"}>
                <img src={pending} alt={"pending"} />
                <h1>Petit curieux !</h1>
                <p className={"text-16 center"}>
                    Cette page est en cours de développement
                    <br/>
                    Reviens plus tard pour voir le résultat
                </p>
            </div>
        </div>
    )

}
export default Pending;