import React, { useState, useEffect } from 'react';
import FormSectionRow from "../../../components/form/formSectionRow";
import ScheduleRow from "../../../components/form/scheduleRow";

const Schedule = ({ schedule, setActivity, mode }) => {
    const daysOfWeek = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
    const [scheduleData, setScheduleData] = useState(schedule.schedule.value.length > 0 ? schedule.schedule.value : daysOfWeek.map(day => ({
        dayOfWeek: day,
        openingTime: "",
        closingTime: "",
        pauseStartTime: "",
        pauseEndTime: "",
        isClosed: false
    })));
    const [scheduleError, setScheduleError] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState({ day: null, type: null });

    const handleDropdownFocus = (dayOfWeek, type) => {
        setActiveDropdown({ day: dayOfWeek, type });
    };

    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setActivity('schedule', {
            ...schedule,
            [name]: checked
        });
    };

    const handleScheduleChange = (row) => {
        const index = scheduleData.findIndex(d => d.dayOfWeek === row.dayOfWeek);
        let newScheduleData = [...scheduleData];
        if (index !== -1) {
            newScheduleData[index] = { ...newScheduleData[index], ...row };
        }
        setScheduleData(newScheduleData);
    };


    useEffect(() => {
        setActivity('schedule', {
            ...schedule,
            schedule: {
                value: scheduleData,
                isRequired: true,
            }
        });
    }, [scheduleData]);


    useEffect(() => {
        if (schedule.schedule.value.length > 0) {
            setScheduleData(schedule.schedule.value);
        }
    }, [schedule.schedule]);

    return (
        <FormSectionRow title={"Disponiblités de l'activité"} classname={"schedule_gap"} isRequired={true}>
            <div className="form__col radio">

                <div className="form__row checkbox">
                    <input type="checkbox" className={schedule.openOnHolidays.error ? 'error' : null} name={"openOnHolidays"}
                           id={"openOnHolidays"} checked={schedule.openOnHolidays} onChange={handleCheckbox}/>
                    <div className={"form__col__radio"}>
                        <label htmlFor={"openOnHolidays"} className={"text-14 bold"}>Ouvert les jours fériés</label>
                        <p className={"text-14 light"}>Indique que votre établissement est ouvert les jours fériés</p>
                    </div>
                </div>

                <div className="form__row checkbox">
                    <input type="checkbox" className={schedule.temporarilyClosed.error ? 'error' : null} name={"temporarilyClosed"}
                           id={"temporarilyClosed"} checked={schedule.temporarilyClosed} onChange={handleCheckbox}/>
                    <div className={"form__col__radio"}>
                        <label htmlFor={"temporarilyClosed"} className={"text-14 bold"}>Fermé temporairement</label>
                        <p className={"text-14 light"}>Indique que votre établissement est fermé temporairement</p>
                    </div>
                </div>
            </div>


            <div className={"form__row schedule"}>
                {schedule.error && <p className={"error-message"}>Veuillez remplir tous les champs</p>}
                {
                    scheduleData &&
                    scheduleData.map((day) => (
                        <ScheduleRow
                            key={day.dayOfWeek}
                            day={day}
                            mode={"edit"}
                            error={schedule.error}
                            onScheduleChange={handleScheduleChange}
                            onDropdownFocus={handleDropdownFocus}
                            isDropdownActive={activeDropdown.day === day.dayOfWeek && activeDropdown.type}s
                        />
                    ))
                }
            </div>
        </FormSectionRow>
    );
};

export default Schedule;
