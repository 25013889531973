import React from 'react';
import '../../../styles/components/form/form.css';

const RadioRound = ({name, value, onChange, label, subtitle, checked = "", isLabel = true, error = false}) => {

    return(
        <div className={`form__row radio ${error ? "error" : ""}`}>
            <div className={"form__col__radio__round"}>
                <input type="radio" name={name} id={value} value={value} onChange={onChange} checked={checked} />
            </div>
            <div className={"form__col__radio"} style={isLabel ? {display: "none"} : {}}>
                <label htmlFor={value} className={"text-14 bold"}>{label}</label>
                <p className={"text-14 light"}>{subtitle}</p>
            </div>
        </div>
    )
}

export default RadioRound;