import React, {useState, useEffect} from "react";
import HeaderView from "../../components/header/headerView";
import TabBar from "../../components/navbar/tabBar";
import ActivitiesTypesServices from "../../api/services/activitiesTypes";
import "../../styles/views/typeActivityManager.css";
import close from "../../assets/icones/global/close-stroke.svg";
import IconButton from "../../components/button/iconButton";
import add from "../../assets/icones/global/add.svg";
import ModalAddTypeActivity from "../../components/modal/modalSending/modalAddTypeActivity";


const TypeActivityManager = () => {

    const [typeActivityList, setTypeActivityList] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        ActivitiesTypesServices.getActivitiesTypes()
        .then((response) => {
            if (response.status === 200) {
                setTypeActivityList(response.data);
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }, []);

    const handleDeleteTypeActivity = (typeId) => {
        ActivitiesTypesServices.deleteActivitiesType(typeId)
        .then((response) => {
            if (response.status === 200) {
                setTypeActivityList(typeActivityList.filter((item) => item._id !== typeId));
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const handleAddTypeActivity = (newItemName) => {

        ActivitiesTypesServices.createActivitiesType({name: newItemName})
        .then((response) => {
            if (response.status === 200) {
                let updatedList = [...typeActivityList];

                // Add the new item before the item "Autre"
                updatedList.splice(updatedList.length - 1, 0, response.data);
                setTypeActivityList(updatedList);
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    return typeActivityList && (
        <div className={"main-container is__grey"}>
            <HeaderView title={"Gestionnaire"} isGoBack={false} actions={
                <>
                    <IconButton icon={add} text={"Ajouter un type d'activité"} onClick={() => setModalIsOpen(true)} />
                </>
            } />

            <TabBar
                tabs={[
                    {
                        name: "Type d'activitée",
                        link: `/manager`
                    },
                ]}
                activeTab={0}
            />

            <div className={"settings-container"}>
                <h2 className={"title-18"}>Les types d'activitées</h2>
                <div className={"settings-container__content"}>
                    <div className={"type__list"}>
                        {
                            typeActivityList.map((item) => (
                                <div className={"type__list__item"} key={item._id}>
                                    <div className={"type__delete"}>
                                        <img src={close} alt={"delete"} onClick={() => handleDeleteTypeActivity(item._id)} />
                                    </div>
                                    <p className={"text-16"}>{item.name}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

            <ModalAddTypeActivity
                isOpen={modalIsOpen}
                closeModal={() => setModalIsOpen(false) }
                addType={handleAddTypeActivity}
            />
        </div>
    )
}

export default TypeActivityManager;