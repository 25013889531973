import React from 'react';
import {Link} from "react-router-dom";
import '../../styles/components/tabBlock.css';

const TabBlock = ({tabs, activeLink}) => {

    return(
        <div className={"tabBlock"}>
            <div className={"tabBlock__tabs"}>
                {
                    tabs.map((link, index) => {
                        return(
                            <Link to={link.link} className={`tabBlock__tabs__tab ${activeLink === index ? "active" : ""}`}>
                                <p className={"text-14"}>{link.name}</p>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default TabBlock;