import React, {useContext, useEffect, useState} from 'react';
import { useParams } from "react-router";
import StripeServices from "../../../api/services/stripe";
import Loader from "../../../components/loader/loader";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import UpgradeForm from "./form/upgradeCheckoutForm";
import '../../../styles/views/stripe-payement.css';
import loop from "../../../assets/images/svg/loop.svg";
import PayementServices from "../../../api/services/payement";
import { useSelector } from "react-redux";
import PartnerSubscriptionServices from "../../../api/services/partnerSubscription";
import IconButton from "../../../components/button/iconButton";
import back from "../../../assets/icones/arrows/left_chevron.svg";
import UserSubscriptionServices from "../../../api/services/stripeSubscription";
import Tag from "../../../components/tag/tag";
import {ErrorContext} from "../../../context/errorContext";
import {useLoader} from "../../../context/loaderContext";
import {useNavigate} from "react-router-dom";
import {usePartner} from "../../../context/partnerContext";
import SubsrciptionsServices from "../../../api/services/subscriptions";
import check from "../../../assets/icones/global/check_circle.svg";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

const StripePayment = () => {

    const { stripeProductId } = useParams();
    const [stripeProduct, setStripeProduct] = useState(null);
    const [clientSecret, setClientSecret] = useState(false);
    const [currentSubscription, setCurrentSubscription] = useState(null);
    const [subscription, setSubscription] = useState(null); // [subscription, setSubscription] = useState([


    const { currentPartnerId } = usePartner();
    const user = useSelector(state => state.auth.user);
    const currentPartner = useSelector((state) => state.auth.user.partnerID.find(partner => partner._id === currentPartnerId));

    const [priceDifference, setPriceDifference] = useState(null);
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                setLoading(true);

                // Fetch the new product details and current subscription details in parallel
                const [productResponse, subscriptionResponse] = await Promise.all([
                    StripeServices.getProductById({ stripeProductId: stripeProductId.toString() }),
                    PartnerSubscriptionServices.getSubscriptionByPartnerId(currentPartner._id)
                ]);

                let currentPriceId;

                if (productResponse.data) {
                    setStripeProduct(productResponse.data);
                }

                if (subscriptionResponse.status === 200 && subscriptionResponse.data) {
                    setCurrentSubscription(subscriptionResponse.data.subscriptionStripePlanId);
                    // Fetch the current price ID from Stripe
                    const stripeSubscription =  await UserSubscriptionServices.getSubscriptionById(subscriptionResponse.data.subscriptionStripePlanId);
                    currentPriceId = stripeSubscription.data.subscription.plan.id;
                }

                // Create a setup intent if needed
                const setupIntentResponse = await PayementServices.createSetupIntent({
                    customerId: currentPartner.subscriptionID.stripeCustomerId,
                    productId: stripeProductId,
                });
                setClientSecret(setupIntentResponse.data.client_secret);


                // Calculate the price difference if we have all necessary IDs
                if (currentPriceId && productResponse.data && productResponse.data.default_price) {
                    const priceDifferenceResponse = await UserSubscriptionServices.calculatePriceDifference({
                        currentPriceId, // Current price ID from the subscription
                        newPriceId: productResponse.data.default_price, // New price ID for the plan
                        subscriptionId: subscriptionResponse.data.subscriptionStripePlanId,
                    });

                    if (priceDifferenceResponse.status === 200 && priceDifferenceResponse.data) {
                        setPriceDifference(priceDifferenceResponse.data.priceDifference);
                    }
                }

                const allSubscriptions = await SubsrciptionsServices.getSubscriptions();
                if (allSubscriptions.status === 200) {
                    //filter with stripeProductId
                    setSubscription(allSubscriptions.data.filter(sub => sub.stripeSubscriptionId === stripeProductId)[0]);
                }
            } catch (error) {
                showError(error);
                // Set an error state here to show the error on the UI
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [stripeProductId, currentPartner._id]);


    const handleBackClick = () => {
        window.history.back();
    };

    if (!stripeProduct) {
        return <div>Unable to load product details.</div>;
    }

    const elementsOptions = {
        clientSecret: clientSecret, // This should be the client secret from the PaymentIntent
        appearance: { theme: 'stripe' },
        layout: { type: 'tabs', defaultCollapsed: false }
    };

    return currentSubscription && clientSecret ? (
        <div className="main">
            <div className="main__container stripe-payement">
                <div className={"stripe-payement"}>
                    <div className={"stripe-payement__container checkout"}>
                        <IconButton icon={back} text={"Retour"} isPrimary={false} onClick={goBack}/>

                        <div className={"stripe-payement__container__infos"}>
                            {stripeProduct.name.toLowerCase() !== 'essentiel' ?
                                <div className={"pastille-stripe yellow"}>3 mois offerts</div> : null}
                            <p className={"stripe-title"}>Souscription à
                                l'Offre {stripeProduct.name.toLowerCase().charAt(0).toUpperCase() + stripeProduct.name.toLowerCase().slice(1)}</p>
                            <p className={"text-14 light"}>{stripeProduct.description}</p>
                        </div>

                        <div className={"stripe-payement__container__card"}>
                            <p className={"title-16"}>Modifier votre abonnement :</p>
                            {currentSubscription && (
                                <Elements stripe={stripePromise} options={elementsOptions}>
                                    <UpgradeForm
                                        currentSubscriptionId={currentSubscription}
                                        newPriceId={stripeProduct.default_price}
                                        customerId={currentPartner.subscriptionID.stripeCustomerId}
                                        stripeProductId={stripeProductId}
                                    />
                                </Elements>
                            )}
                        </div>
                    </div>
                    <div className={"stripe-payement__container is__grey"}>
                        <div className={"stripe-payement__container__infos gap-8"}>
                            <div className={"pastille-stripe black"}>Offre {stripeProduct.name.toLowerCase()}</div>
                            <p className={"stripe-title"}>Récapitulatif du paiement</p>
                        </div>

                        <div className={"stripe-payement__container__infos is__row"}>
                            <div className={"stripe-payement__col"}>
                                <p className={"text-16 bold"}>Plan {stripeProduct.name}</p>
                            </div>
                            {stripeProduct.name.toLowerCase() !== 'essentiel' ?
                                <div className={"stripe-payement__col end"}>
                                    <p className={"text-16 bold"}>3 mois offerts</p>
                                    <p className={"text-16 light"}>puis {stripeProduct.metadata ? stripeProduct.metadata.price : null}€/mois
                                        pendant {stripeProduct.metadata ? stripeProduct.metadata.engagement : null} mois</p>
                                </div> :
                                <div className={"stripe-payement__col end"}>
                                    <p className={"text-18 bold"}>{stripeProduct.metadata ? stripeProduct.metadata.price : null}€/mois</p>
                                </div>}
                        </div>

                        <div className={"stripe-payement__container__infos is__row"}>
                            <div className={"subscription__plan__content"}>
                                <p className={"text-16"}>Services Intégrés </p>
                                <ul className={"subscription__plan__list"}>
                                    {
                                        subscription && subscription.avantages.map((avantage, index) => {
                                            return (
                                                <li key={index}><img src={check} alt={"check"}/> {avantage}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>

                    </div>

                    <img src={loop} className={"frimity_loop"} alt="logo"/>
                </div>
            </div>
        </div>
    ) : <Loader/>
}

export default StripePayment;
