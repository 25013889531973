
const useParseCategories = () => {

    const parseCategories = (categories) => {
        switch (categories) {
            case 'outdoor':
                return 'En extérieur'
            case 'indoor':
                return 'En intérieur'
            case 'unsual':
                return 'Insolite'
            case 'sport-adventure':
                return 'Sport et aventure'
            case 'culture-heritage':
                return 'Culture et patrimoine'
            case 'wellness-relaxation':
                return 'Bien-être et relaxation'
            case 'games':
                return 'Jeux et divertissement'
            case 'pmr':
                return 'Accès handicapé'
            case 'parking':
                return 'Parking à proximité'
            case 'transports':
                return 'Proche des transports en commun'
            case 'wifi':
                return 'Wi-Fi gratuit'
            case 'toilet':
                return 'Toilettes publiques'
            case 'childcare':
                return 'Espace enfants'
            case 'credit-card':
                return 'Carte bancaire'
            case 'cash':
                return 'Espèces'
            case 'cheque':
                return 'Chèques'
            case 'check-holiday':
                return 'Chèques vacances'
            case 'minAge':
                return 'Âge minimum requis'
            case 'clothingRecommendation':
                return 'Tenue vestimentaire requise'
            case 'no-smoking':
                return 'Espace non-fumeur'
            case 'animals':
                return 'Animaux acceptés'
            case 'no-animals':
                return 'Animaux non-acceptés'
            case 'accessibility':
                return 'Accessibilité'
            case 'services':
                return 'Services'
            case 'payment':
                return 'Moyens de paiement'
            case 'restriction':
                return 'Restrictions'
            case 'experience':
                return 'Niveau d\'expérience'
            case 'otherRecommendation':
                return 'Autre'
            case 'animals':
                return 'Animaux'
            default:
                return '';
        }
    }

    return {parseCategories};
}

export default useParseCategories